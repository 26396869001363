import { httpGet, httpPost } from '../common';
import {IProject} from "../projects";


export function getProjectsById(projectId: string) {
    return httpGet<IProject>(`/api/presentation-mode/view/${projectId}`, { authorize: false });
}

export function shareProject(projectId: string) {
    return httpPost(`/api/presentation-mode/share/${projectId}`, { authorize: true });
}

export function closeProject(projectId: string) {
    return httpPost(`/api/presentation-mode/close/${projectId}`, { authorize: true });
}

