import React from 'react';
import { ContentComponentType, IContentItem } from '../../../../../models';
import { ImageIcon, TextIcon, LinkIcon } from '../../../../../icons';
import ResizableTextArea from '../ResizableTextArea';
import cn from 'classnames';
import MovementPanel from '../../../../MovementPanel';

interface ICompositeListItemOwnState {
    values: string[];
    hovered: boolean;
}


interface ICompositeListItemOwnProps {
    index: number;
    items: IContentItem[];
    types: ContentComponentType[];
    isPresentationMode: boolean;
    updateValue: (value:IContentItem[], index: number) => void;
    selectBlock: () => void;
    moveup: (id: number|string) => void;
    movedown: (id: number|string) => void;
    remove: (id: number|string) => void;
    itemsMoreThan1: boolean;
}

export default class CompositeListItem extends React.Component<ICompositeListItemOwnProps, ICompositeListItemOwnState> {

    constructor(props:any){
        super(props);

        this.state = {values:[], hovered: false};
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.imageComponent = this.imageComponent.bind(this);
        this.titleComponent = this.titleComponent.bind(this);
        this.textComponent = this.textComponent.bind(this);
        this.linkComponent = this.linkComponent.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    componentDidUpdate(prevProps: any) {

        if (this.props.items[0].value !== prevProps.items[0].value 
            || this.props.items[1].value !== prevProps.items[1].value 
            || this.props.items[2].value !== prevProps.items[2].value
            || this.props.items[3].value !== prevProps.items[3].value) {
            this.setState(state => { return { ...state, values: this.props.items.map(_ => _.value) } });
        }

    }

    componentDidMount() {
        this.setState({ ...this.state, values: this.props.items.map(_=>_.value) });
    }

    onFocus(e:any, index:number){
        this.props.selectBlock(); // активируем блок
    }

    onBlur(e:any, index:number){        
        const itemsCopy = [...this.props.items];
        itemsCopy[index] = {isDefault:false, value: this.state.values[index]};
        this.props.updateValue(itemsCopy, this.props.index);
    }    

    handleChange(e:any, index:number) {
        let value = e && e.target && e.target.value;
        const copyValues = [...this.state.values];
        copyValues[index] = value;
        this.setState({...this.state, values: copyValues});

        if(this.props.items[index].isDefault) {
            const itemsCopy = [...this.props.items];
            itemsCopy[index] = {isDefault:false, value: value};
            this.props.updateValue(itemsCopy, this.props.index);
        }            
    }

    imageComponent(){
        const i = 0;
        const { isPresentationMode, items } = this.props;
        const style = items[i].isDefault ? 'content-list-item-default': '';
        return <div className={cn(style, 'styled-list-composite-item-image')}>
                    <ImageIcon />
                    <ResizableTextArea value={this.state.values[i]} onChange={(e:any) => this.handleChange(e, i)} disabled={isPresentationMode} onFocus={(e:any) => this.onFocus(e, i)} onBlur={(e:any) => this.onBlur(e, i)} />
               </div>;
    }

    titleComponent(){
        const i = 1;
        const { isPresentationMode, items } = this.props;
        const style = items[i].isDefault ? 'content-list-item-default': '';
        return <div className={cn(style, 'styled-list-composite-item-title')}>
                    <TextIcon />
                    <ResizableTextArea value={this.state.values[i]} onChange={(e:any) => this.handleChange(e, i)} disabled={isPresentationMode} onFocus={(e:any) => this.onFocus(e, i)} onBlur={(e:any) => this.onBlur(e, i)} />
               </div>;
    }

    textComponent(){
        const i = 2;
        const { isPresentationMode, items } = this.props;
        const style = items[i].isDefault ? 'content-list-item-default': '';
        return <div className={cn(style, 'styled-list-composite-item-text')}>
                    <TextIcon />
                    <ResizableTextArea value={this.state.values[i]} onChange={(e:any) => this.handleChange(e, i)} disabled={isPresentationMode} onFocus={(e:any) => this.onFocus(e, i)} onBlur={(e:any) => this.onBlur(e, i)} />
               </div>;
    }

    linkComponent(){
        const i = 3;
        const { isPresentationMode, items } = this.props;
        const style = items[i].isDefault ? 'content-list-item-default': '';
        return <div className={cn(style, 'styled-list-composite-item-link')}>
                    <LinkIcon />
                    <ResizableTextArea value={this.state.values[i]} onChange={(e:any) => this.handleChange(e, i)} disabled={isPresentationMode} onFocus={(e:any) => this.onFocus(e, i)} onBlur={(e:any) => this.onBlur(e, i)} />
               </div>;
    }

        
    handleMouseEnter(e:any){
        this.setState(state => {return {...state, hovered: true }});
    }

    handleMouseLeave(e:any){
        this.setState(state => {return {...state, hovered: false }});
    }
        
    render(){
        const { types, index, movedown, moveup, remove, itemsMoreThan1} = this.props;
       
        return <li className='content-list-item' onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    {types.indexOf(ContentComponentType.Image)>=0 && this.imageComponent()}
                    {types.indexOf(ContentComponentType.Title)>=0 && this.titleComponent()}
                    {types.indexOf(ContentComponentType.Text)>=0 && this.textComponent()}
                    {types.indexOf(ContentComponentType.Link)>=0 && this.linkComponent()}
                    {this.state.hovered && <MovementPanel id={index} movedown={movedown} moveup={moveup} remove={itemsMoreThan1 ? remove : undefined}/>} 
               </li>;
    }
}