import React from 'react';
import { BlockType, DetailLevel, ElementBlockType, IBlock, IPoint, pointToString, pointFromString } from '../../models';
import { connect } from "react-redux";
import { IState } from '../../redux/reducers';
import "./styles/work-canvas.scss";
import "./styles/cursors.scss";
import * as d3 from "d3";
import BlockPlaceholderPresenter from '../BlockPlaceholderPresenter';
import * as blockPresenter1Helpers from '../BlockPresenter/helpers';
import * as blockPresenter2Helpers from '../BlockPresenterDetailLevel/helpers';
import canvasMovementTool from '../../core/common/canvasMovementTool';
import cn from 'classnames';
import { getCursorClassName } from './helpers/getCursorClassName';
import WorkCanvasHotKeys from './helpers/WorkCanvasHotKeys';
import SelectionPresenter from '../SelectionPresenter';
import { match } from "react-router";
import { IProject } from "../../api/projects";
import {
    loadPresentationMode,
    loadProject,
    loadProjectSettings,
    loadStorageProject,
    saveProjectSettings,
    updateBlocks
} from "./helpers/maketLoader";
import { saveProject } from "./helpers/saveProjectsFast";
import BaseBlock from '../../core/blocks/base';
import { createPlaceholderBlock } from '../../core/blocks';
import WorkCanvasTopMenu from './components/TopMenu';
import { IErrorResponse, ITariffSettings } from "../../api";
import { BlocksMovements } from '../../core/blocks-movements';
import selectedBlocks from '../../models/selection';
import { Action } from 'redux';
import * as actions from '../../redux/actions';

import SideBar from "./components/Sidebar/SideBar";
import { withTranslation, WithTranslation } from 'react-i18next';
import { getBlocks } from '../../redux/selectors/blocks';
import canvasBlockCreator from './helpers/canvasBlockCreator';
import { ActionCreators } from 'redux-undo'
import { IProjectSettings } from "../../api/projectSettings";
import UserBloсksEditor from './components/UserBlocksEditor';
import { getCreatorToolType } from '../../redux/selectors/app';
import WorkCanvasTooltip from './components/WorkCanvasTooltip';
import detailLevelUpdateCoords, { blocksLayoutPaddings, blocksCoords } from './helpers/detailLevelUpdateCoords';
import { simulateMouseDown } from './helpers/simulateMouseDown';
import { appWorkareaPositionChange } from '../../redux/actions';
import { store } from '../../store/configureStore';

const filtersTypes: BlockType[] = [BlockType.Element, BlockType.Section, BlockType.Page, BlockType.Composite, BlockType.Fixed];
const getFilteredBlocks = getBlocks(filtersTypes)

interface IStateCanvas {
    isCollapse: boolean;
    isCollapseRightPanel: boolean
    detailLevel: DetailLevel;
}

interface IWorkCanvasProps {
    workareaPosition: IPoint;
    blocks: IBlock[];
    newPositionBlockPlaceHolder?: IPoint;
    creationTool: BlockType | ElementBlockType;
    project: IProject | null;
    presentation: IProject | null;
    isPresentationMode: boolean;
    isAuthorized: boolean;
    projectSettings: IProjectSettings | null;
    editedBlockId?: string
    isPing: boolean,
    tariffSettings: ITariffSettings | null
}

const mapStateToProps = (state: IState): IWorkCanvasProps => {
    return {
        workareaPosition: state.app.workareaPosition,
        blocks: getFilteredBlocks(state),
        newPositionBlockPlaceHolder: state.app.newPositionBlockPlaceHolder,
        creationTool: getCreatorToolType(state),
        project: state.projects.view.item,
        presentation: state.presentationMode.view.item,
        isPresentationMode: state.presentationMode.view.isPresentationMode,
        isAuthorized: state.auth.isAuthorized,
        projectSettings: state.projectSettings.view.item,
        editedBlockId: state.app.editingTextBlockId,
        isPing: state.healthCheck.healthCheck.isPing,
        tariffSettings: state.invoices.tariff.item
    };
};

interface IDispatchProps {
    appWorkCanvasTooltip: (coord?: IPoint, text?: string) => Action;
    setDisplayIdPopupMenu: (displayId: string) => Action;
    clearProject: () => Action;
    clearBlocks: (index: number) => Action;
    setAppEditTextRegime: (blockId?: string) => Action;
    getTariffSettings: () => Action
}

const mapDispatchToProps: IDispatchProps = {
    appWorkCanvasTooltip: actions.appWorkCanvasTooltip,
    setDisplayIdPopupMenu: actions.appDisplayIdTypePopupMenu,
    clearProject: actions.actions.projects.view.clear,
    clearBlocks: ActionCreators.jumpToPast,
    setAppEditTextRegime: actions.appEditText,
    getTariffSettings: actions.actions.invoices.tariff.fetch.init
};

interface IRouterProps {
    match?: match<{ id: string, projectId: string }>;
}

interface IStateCanvas {
    intervalId: any;
    project: IProject | null;
    storageProject: IProject | null
}

class WorkCanvas extends React.Component<IWorkCanvasProps & IRouterProps & IDispatchProps & WithTranslation, IStateCanvas> {

    private blocksMovements: BlocksMovements;
    private component: any;

    private updatedCount2Level: number;

    constructor(props: any) {
        super(props);
        this.blocksMovements = canvasBlockCreator.getBlocksMovementsInstance();
        this.state = { intervalId: 0, isCollapse: false, isCollapseRightPanel: true, storageProject: null, project: null, detailLevel: 1 }
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.moveWorkCanvas = this.moveWorkCanvas.bind(this);
        this.renderWorkCanvas = this.renderWorkCanvas.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.dropWorkCanvas = this.dropWorkCanvas.bind(this);
        this.handleCollapseClick = this.handleCollapseClick.bind(this);
        this.handleCollapseRightPanelClick = this.handleCollapseRightPanelClick.bind(this);
        this.handleSetDetailLevel = this.handleSetDetailLevel.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.changePageTitle = this.changePageTitle.bind(this);

        this.updatedCount2Level = 0;
    }

    componentDidMount() {
        createPlaceholderBlock();

        if (this.props.match && this.props.match.params.projectId) {
            loadPresentationMode(this.props.match.params.projectId);
        }

        if (this.props.match && this.props.match.params.id) {
            loadProject(this.props.match.params.id);
            const storageProjects = loadStorageProject(this.props.match.params.id);
            loadProjectSettings(this.props.match.params.id);

            const intervalId = setInterval(() => { saveProject(this.props.project); }, 60000);
            this.setState({ ...this.state, intervalId: intervalId, storageProject: storageProjects })
        }
    }

    componentWillUnmount() {

        if (this.props.isAuthorized) {
            clearInterval(this.state.intervalId);
        }

        const { project, projectSettings } = this.props;
        saveProject(project);

        if (project) {
            const newProjectSettings: IProjectSettings = {
                isCollapseLeftSideBar: this.state.isCollapse,
                isCollapseRightSideBar: this.state.isCollapseRightPanel,
                projectId: project.id,
                id: projectSettings == null ? "0" : projectSettings.id,
                detailLevel: this.state.detailLevel,
                workCanvasPosition: pointToString(this.props.workareaPosition)
            }

            saveProjectSettings(newProjectSettings);
        }

        document.title = 'Vizls. The new approach to prototyping';

        this.props.clearProject();
        this.props.clearBlocks(-1);
        BaseBlock.resetSelection();
    }

    changePageTitle() {
        if(this.props.project)
            document.title = `Vizls - ${this.props.project.title}`;
        else if(this.state.storageProject)
            document.title = `Vizls - ${this.state.storageProject.title}`;
    }

    componentDidUpdate(prevProps: Readonly<IWorkCanvasProps & IRouterProps & WithTranslation>, prevState: any) {
        createPlaceholderBlock();
        
        if (prevState.detailLevel !== this.state.detailLevel) {
            if (this.state.detailLevel === 1) {
                blocksLayoutPaddings.clear();
                blocksCoords.clear();
                this.updatedCount2Level = 0;
            }
            else if (this.state.detailLevel === 2) {
                this.updatedCount2Level++;
                simulateMouseDown(); // необходимо для перерасчета координат на 2ом уровне                
            }
        }
        else if (this.state.detailLevel === 2 && (this.updatedCount2Level === 1 || this.updatedCount2Level === 2)) { //TODO: This is workaround to correct display blocks on 2 level
            this.updatedCount2Level++;
            simulateMouseDown(); // необходимо для перерасчета координат на 2ом уровне            
        }

        canvasBlockCreator.setDetailLevel(this.state.detailLevel);

        // При потере фокуса возвращаю его на рабочую область 
        if (document.activeElement && document.activeElement.tagName == "BODY")
            this.component.node().focus();

        if (this.props.project && (this.props.project !== prevProps.project || this.props.isPing !== prevProps.isPing) && this.props.isPing) {
            this.changePageTitle();
            if (this.state.storageProject
                && this.props.project.version < this.state.storageProject.version
                && this.props.project.id === this.state.storageProject.id
                && this.state.storageProject.content !== "") {
                updateBlocks(this.state.storageProject)
                this.setState({ ...this.state, project: this.state.storageProject })
            } else if (this.props.project.content !== "") {
                updateBlocks(this.props.project)
            }
            else {
                this.setState({ ...this.state, project: this.props.project })
            }
        }

        if (this.props.isPing !== prevProps.isPing  && !this.props.isPing && this.props.project == null && this.state.storageProject) {
            this.changePageTitle();
            updateBlocks(this.state.storageProject);
        }

        if (this.props.presentation && prevProps.presentation !== this.props.presentation && this.props.presentation.content !== "") {
            updateBlocks(this.props.presentation)
        }

        if (prevProps.creationTool == BlockType.HandTool && this.props.creationTool !== BlockType.HandTool) {
            this.dropWorkCanvas();
        }

        if (prevProps.creationTool != BlockType.SelectTool && this.props.creationTool == BlockType.SelectTool) {
            this.blocksMovements.unsubscribeNecessaryMovementEvents();
        }

        if (this.props.creationTool == BlockType.SelectTool || this.props.creationTool == BlockType.HandTool) {
            canvasBlockCreator.resetPreCreation();
        }

        if (this.props.projectSettings && prevProps.projectSettings !== this.props.projectSettings) {
            
            this.setState({
                ...this.state,
                isCollapse: this.props.projectSettings.isCollapseLeftSideBar,
                isCollapseRightPanel: this.props.projectSettings.isCollapseRightSideBar
                // detailLevel: this.props.projectSettings.detailLevel
            });
            store.dispatch(appWorkareaPositionChange(pointFromString(this.props.projectSettings.workCanvasPosition)));     
        }
    }

    handleSaveClick() {
        const { project } = this.state;
        saveProject(project);
    }


    handleCollapseClick() {
        this.setState({ ...this.state, isCollapse: !this.state.isCollapse });
    }

    handleCollapseRightPanelClick() {
        this.setState({ ...this.state, isCollapseRightPanel: !this.state.isCollapseRightPanel });
    }

    handleSetDetailLevel(event: any, l: DetailLevel) {
        this.setState({ ...this.state, detailLevel: l });

        if (l === 2) {
            BaseBlock.resetSelection();
            canvasBlockCreator.changeCreatorTool(event, BlockType.SelectTool);
            BaseBlock.resetSelection(BaseBlock.getBlocks().filter(b => b.isSelected).map(b => b.id));
        }
    }

    onMouseDown(event: any) {
        if (this.props.creationTool == BlockType.HandTool) {
            canvasMovementTool.catchWorkarea(event.target && event.target.className);
            this.component.on("mousemove", this.moveWorkCanvas);
        }
        else if (event.target && event.target.className && event.target.className.includes && event.target.className.includes("work-canvas")) {
            
            if(event.metaKey){ // Необходимо для перерисовки элементов на 2ом уровне: simulateMouseDown(true)
                // BaseBlock.resetSelection();
                // this.props.setDisplayIdPopupMenu("");
                return;
            }

            BaseBlock.resetSelection();
            this.props.setDisplayIdPopupMenu("");

            // Сброс выделения для 2го уровня
            if (this.props.editedBlockId) {
                BaseBlock.resetSelection([this.props.editedBlockId]);
                this.props.setAppEditTextRegime(undefined);
            }
        }
    }

    moveWorkCanvas() {
        canvasMovementTool.onChangeStartPoint({ x: d3.event.x, y: d3.event.y });
    }

    onMouseUp(event: any) {
        if (this.props.creationTool !== BlockType.SelectTool && this.props.creationTool !== BlockType.HandTool) {
            canvasBlockCreator.preCreationBlock(); // Пресоздаем блок но не помещаем его в state
        }
        else if (this.props.creationTool == BlockType.HandTool) {
            this.dropWorkCanvas();
        }
    }

    handleMouseMove(e: any) {
        this.props.appWorkCanvasTooltip();
    }

    dropWorkCanvas() {
        this.component.on("mousemove", null);
        canvasMovementTool.dropWorkarea();
    }

    renderWorkCanvas() {

        const { blocks, workareaPosition, newPositionBlockPlaceHolder, creationTool, isAuthorized, t, isPresentationMode, editedBlockId } = this.props;
        const workareaPositionPoint: IPoint = workareaPosition;

        const { isCollapse, isCollapseRightPanel, detailLevel } = this.state;
        const cursorClassName = getCursorClassName(creationTool);
        const classNames = cn("work-canvas", cursorClassName);

        let selection: string[] = [];

        if (creationTool == BlockType.SelectTool) {
            selection = selectedBlocks.getSelectedBlocksId();
        }
        else {
            const b = canvasBlockCreator.getCreationLeadBlock()
            if (b) selection.push(b.id);
        }

        return (<div id="work-canvas" className={classNames} tabIndex={0} onMouseMove={this.handleMouseMove}
            onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp}

            style={{ backgroundPositionX: workareaPositionPoint.x || 0, backgroundPositionY: workareaPositionPoint.y || 0 }}
            ref={handle => (this.component = d3.select(handle))}>

            <WorkCanvasTopMenu isAuthorized={isAuthorized} detailLevel={detailLevel} editedBlockId={editedBlockId} />
            <SideBar isCollapse={isCollapse} onCollapse={this.handleCollapseClick} isPresentationMode={isPresentationMode} detailLevel={detailLevel} setDetailLevel={this.handleSetDetailLevel} />
            {detailLevel === 1 && <UserBloсksEditor isCollapse={isCollapseRightPanel} onCollapse={this.handleCollapseRightPanelClick} />}

            {this.renderNorSyncMessage()}

            {detailLevel === 1 && blocks.map(b => blockPresenter1Helpers.blockPresenter(b))}
            {detailLevel === 2 && detailLevelUpdateCoords(blocks).map(b => blockPresenter2Helpers.blockPresenter(b))}

            {detailLevel === 1 && selection.length > 0 && newPositionBlockPlaceHolder && <BlockPlaceholderPresenter position={newPositionBlockPlaceHolder} selection={selection} />}

            {detailLevel === 1 && creationTool == BlockType.SelectTool && selection.length > 0 && <SelectionPresenter selection={selection} />}

            <WorkCanvasTooltip />
        </div>);
    }

    renderNorSyncMessage() {
        const { project, t } = this.props
        if (project && !project.isActive) {
            return <span className="form-error">{t('workCanvas.sync')}</span>
        }
    }

    render() {
        return (<WorkCanvasHotKeys detailLevel={this.state.detailLevel}>
            {this.renderWorkCanvas()}
        </WorkCanvasHotKeys>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WorkCanvas));
