import {IErrorResponse, IPageResult} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';
import {IProject, IProjectQuery} from "../../../api/projects";

const prefix = 'PROJECTS_TABLE';

export const ACTIONS = {
    TABLE: actionsSet(prefix, 'VIEW')
};

export interface ISaveInitAction extends Action {
    params: IProjectQuery | null;
}

export interface ISaveDoneAction extends Action {
    item: IPageResult<IProject[]>;
}

export const actions = {
    init: (params: IProjectQuery | null) => ({type: ACTIONS.TABLE.INIT, params}) as ISaveInitAction,
    done: (item: IPageResult<IProject[]>) => ({type: ACTIONS.TABLE.DONE, item}) as ISaveDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.TABLE.FAIL, error}) as IFailAction
}
