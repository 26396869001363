import {IPoint, IBlock, ElementBlockType, BlockType, IContent} from "../../models";
import BaseBlock from "../blocks/base";
import TemplateCompositeBlock from "../blocks/template-composite";
import { createDefaultElementBlock } from "./createElementBlock";

export function createDefaultTemplateCompositeBlock(){
    const newTemplate = new TemplateCompositeBlock("Your new block", undefined);
    BaseBlock.addBlocks([newTemplate]);
    return newTemplate as IBlock;
}

export function createTemplateCompositeBlockWithChild(){
    const templateBlock = createDefaultTemplateCompositeBlock();
    const child = createDefaultElementBlock(ElementBlockType.Text, undefined, templateBlock.id, "Some element")
    BaseBlock.addChild(templateBlock, child.id, 0);
    BaseBlock.updateBlock(templateBlock, true);
}

export function createTemplateCompositeBlock(id:string, title:string, children:string[], content?: IContent, parentId?:string, position?:IPoint){
    const template = new TemplateCompositeBlock(title, id, children);
    template.coord = position;
    template.parentId = parentId;
    template.content = content;
    return template as IBlock;
}