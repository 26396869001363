import { IErrorResponse } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'RESET_PASSWORD';

export const ACTIONS = {
    RESET_PASSWORD: actionsSet(prefix, ""),
    CLEAR: "RESET_PASSWORD_CLEAR"
};

export interface IResetPasswordInitAction extends Action {
    password: string;
    token: string
}

export interface IResetPasswordDoneAction extends Action {
}

export const actions = {
    init: (token: string, password: string) => ({type: ACTIONS.RESET_PASSWORD.INIT, token, password}) as IResetPasswordInitAction,
    done: () => ({type: ACTIONS.RESET_PASSWORD.DONE}) as IResetPasswordDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.RESET_PASSWORD.FAIL, error}) as IFailAction,
    clear: () => ({type: ACTIONS.CLEAR})
}