import { openDB } from 'idb';

const storeName = 'vizls-db-keyval';
const dbPromise = openDB('vizls-db', 1, {
    upgrade(db) {
        db.createObjectStore(storeName);
    },
});

const idbApp = {
    async get(key:string) {
        return (await (await dbPromise).get(storeName, key));
    },
    async set(key:string, val:string) {
        return (await dbPromise).put(storeName, val, key);
    },
    async delete(key:string) {
        return (await dbPromise).delete(storeName, key);
    },
    async clear() {
        return (await dbPromise).clear(storeName);
    },
    async keys() {
        return (await dbPromise).getAllKeys(storeName);
    },
};

export default idbApp;