import { createStore, applyMiddleware, Store } from "redux";
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga'
import * as saga from "../redux/configure"
import * as root from "../redux/reducers";
import { History } from 'history';
import {saveState} from "./persistedStore";
import { createBrowserHistory as createHistory } from 'history';
import ReactGA from 'react-ga';

const sagaMiddleware = createSagaMiddleware();

export const APP_STATE = 'VizlsApp';
export const history = createHistory();

if (process.env.NODE_ENV === 'production' && document.domain === 'vizls.com') {
    history.listen(location => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });
}

function configureStore(history: History) {       

    const routersMiddleware = routerMiddleware(history)

    const store: any = createStore(
        root.appReducer,
        root.initial as any,
        applyMiddleware(sagaMiddleware,routersMiddleware)
    );
    sagaMiddleware.run(saga.saga);

    store.subscribe(() => saveState(store));

    return store;
};

export interface IStore extends Store<root.IState> { }

export const store = configureStore(history);