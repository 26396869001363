import { Action } from 'redux';
import { actionsSet } from '../../../actions/index';
const prefix = 'SIGN_UP';

export const ACTIONS = {
    SIGN_UP: actionsSet(prefix, "")
};

export interface IOpenInitAction extends Action {
}

export interface ICloseDoneAction extends Action {
}

export const actions = {
    open: () => ({type: ACTIONS.SIGN_UP.INIT}) as IOpenInitAction,
    close: () => ({type: ACTIONS.SIGN_UP.DONE}) as ICloseDoneAction,
}
