import {IErrorResponse, IUserRead, IUserSettings} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'USER_SETTINGS_VIEW';

export const ACTIONS = {
    VIEW: actionsSet(prefix, ""),
};

export interface IUserSettingsInitAction extends Action {
    user: IUserRead
}

export interface IUserSettingsDoneAction extends Action {
    userSettings: IUserSettings
}

export const actions = {
    init: (user: IUserRead) => ({type: ACTIONS.VIEW.INIT, user}) as IUserSettingsInitAction,
    done: (userSettings: IUserSettings) => ({type: ACTIONS.VIEW.DONE, userSettings}) as IUserSettingsDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.VIEW.FAIL, error}) as IFailAction,
}
