import React from 'react';
import cn from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ISubscriptionItem {
    id: number;
    title: string;
    price: number;
    selected: boolean;
    onClick: (id:number)=>void;
    subtitle?: string;
}

class SubscriptionItem extends React.Component<ISubscriptionItem & WithTranslation> {

    render(){
        const { id, title, price, selected, subtitle, onClick, t } = this.props;

        return (<div className={cn("user-subscription-item", selected ? "user-subscription-item-selected":"user-subscription-item-unselected")} 
                            onClick={()=>{onClick(id)}}>
                    <div className="user-subscription-item-title">{title}</div>
                    <div className="user-subscription-item-price">{price} {t('rub')}</div>
                    {subtitle && <div className="user-subscription-item-subtitle">{subtitle}</div>}
                </div>);
    }
}

export default withTranslation()(SubscriptionItem);