import PasswordValidator from 'password-validator';
 
// Create a schema
const validator = new PasswordValidator();
 
// Add properties to it
validator
.is().min(6)                                    // Minimum length 6
.is().max(100)                                  // Maximum length 100
.has().not().spaces()                           // Should not have spaces
.is().not().oneOf(['123456','Password','Passw0rd', 'Password123']); // Blacklist these values

export default validator;