import React from 'react';
import './tosCheckbox.scss';

interface ITosCheckboxProps {
    onChecked: (checked: boolean) => void;
    hidden?: boolean
    title: any
}

interface ITosCheckboxState {
    checked: boolean;
}

class Index extends React.Component<ITosCheckboxProps, ITosCheckboxState> {

    constructor(props:any){
        super(props);

        this.state = {checked: false};
        this.handelChange = this.handelChange.bind(this);
        this.userAgreementClick = this.userAgreementClick.bind(this);
    }

    handelChange(e: any){
        const checked = !this.state.checked;
        this.setState({...this.state, checked: checked});
        this.props.onChecked(checked);
        e.stopPropagation();
    }

    userAgreementClick(event: any){
        event.stopPropagation();
    }

    render(){
        const {title} = this.props;
        return (<>
            <label className="main">{title}
                <input type="checkbox" onClick={this.handelChange}/>
                <span className="geekmark"></span>
            </label>
        </>);
    }
    
}

export default Index