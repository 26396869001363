import * as React from "react";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import ModalForm from "../../Landing/ModalForm/index";
import {Action} from "redux";
import * as actions from "../../../redux/actions/index";
import './style.scss'
import door from '../../../images/door.png'
import facebook from '../../../images/sign-facebook.png'
import google from '../../../images/sign-google.png'
import cn from "classnames";
import {IUserWrite} from "../../../api/user";
import {IErrorResponse} from "../../../api";
import {IState} from "../../../redux/reducers";
import {PasswordService} from "../../Services/PasswordService";
import InputContainer from "../../InputContainer";
import {Spinner} from "../../Spinner";
import SubmitButton from "../../Buttons/SubmitButton";
import TosCheckbox from "../../Checkbox/index";

interface IDispatchProps{
    closeSignUpForm:() => Action
    createAccount:(user: IUserWrite) => Action
    opensSignInForm:(isAfterConfirmEmail: boolean) => Action;
    signInFacebookUser:(token: string)=> Action;
    signInGoogleUser:(token: string)=> Action;
    clearSignUp:() => Action
    clearSignInFacebookUser:()=> Action;
    clearSignInGoogleUser:()=> Action;
}

interface ISignUpState {
    name: string;
    email: string;
    password: string;
    isAgreement: boolean;
    error: string | null;
    errorEmail: string | null
    errorPassword: string | null
}

interface IStateToProps{
    inProgress: boolean;
    inProgressFacebook: boolean;
    inProgressGoogle: boolean;
    error: IErrorResponse | null;
    errorFacebook: string | null;
    errorGoogle: string | null;
}

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        inProgress: state.user.create.inProgress,
        inProgressFacebook: state.externalAuth.facebook.signIn.inProgress,
        inProgressGoogle: state.externalAuth.google.signIn.inProgress,
        error: state.user.create.error,
        errorFacebook: state.externalAuth.facebook.signIn.error,
        errorGoogle: state.externalAuth.google.signIn.error,
    }
};

const mapDispatchToProps: IDispatchProps = {
    closeSignUpForm: actions.actions.modalForm.signUp.close,
    createAccount: actions.actions.users.create.init,
    opensSignInForm: actions.actions.modalForm.signIn.open,
    signInFacebookUser: actions.actions.externalAuth.facebook.signIn.init,
    signInGoogleUser: actions.actions.externalAuth.google.signIn.init,
    clearSignUp: actions.actions.users.create.clear,
    clearSignInFacebookUser: actions.actions.externalAuth.facebook.signIn.clear,
    clearSignInGoogleUser: actions.actions.externalAuth.google.signIn.clear
};

export class SignUpForm extends React.Component<WithTranslation & IDispatchProps & IStateToProps, ISignUpState> {
    public passwordService: PasswordService;
    constructor(props:any){
        super(props);
        this.state = {email: "", password: "", name: "", error: null, errorPassword: null, errorEmail: null, isAgreement: false}
        this.handleCloseForm = this.handleCloseForm.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleCreateAccount = this.handleCreateAccount.bind(this);
        this.handleCheckAgreement = this.handleCheckAgreement.bind(this);
        this.handleOpenSignInForm = this.handleOpenSignInForm.bind(this);
        this.handleFacebookSignUp = this.handleFacebookSignUp.bind(this);
        this.handleGoogleSignUp = this.handleGoogleSignUp.bind(this);
        this.passwordService = new PasswordService();
    }

    componentDidUpdate(prevProps: Readonly<WithTranslation & IDispatchProps & IStateToProps>){
        if (this.props.error && prevProps.error !== this.props.error){
            switch (this.props.error.code) {
                case 3: {
                    this.setState({...this.state, errorEmail: this.props.error.message})
                    break;
                }

                default: {
                    this.setState({...this.state, error: this.props.error.message})
                    break;
                }
            }
        }

        if (this.props.errorFacebook && prevProps.errorFacebook !== this.props.errorFacebook) {
            this.setState({...this.state, error: this.props.errorFacebook})
        }

        if (this.props.errorGoogle && prevProps.errorGoogle !== this.props.errorGoogle) {
            this.setState({...this.state, error: this.props.errorGoogle})
        }
    }

    handleCloseForm(){
        this.props.closeSignUpForm();
        this.props.clearSignUp();
        this.props.clearSignInFacebookUser();
        this.props.clearSignInGoogleUser();
    }

    handleChangeName(e: any){
        this.setState({...this.state, name: e})
    }

    handleChangeEmail(e: any){
        this.setState({...this.state, email: e})
    }

    handleChangePassword(e: any){
        this.setState({...this.state, password: e})
        setTimeout(() => {
            const error = this.passwordService.validatePassword(this.state.password);
            this.setState({...this.state, errorPassword: error});
            }, 1000)
    }

    handleCheckAgreement(e: any){
        this.setState({...this.state, isAgreement: e})
    }

    handleCreateAccount(){
        const {email, isAgreement, name, password} = this.state
        if (email && isAgreement && name && password) {
            const user: IUserWrite = {
                email: this.state.email,
                password: this.state.password,
                isAgreement: this.state.isAgreement,
                nickName:  this.state.name,
            }
            this.props.createAccount(user);
        }
    }

    handleOpenSignInForm(){
        this.props.opensSignInForm(false);
        this.props.closeSignUpForm();
    }

    handleFacebookSignUp(){
        // FB.login(() => {
        //     FB.getLoginStatus((response) => {
        //         if (response.status === 'connected') {
        //             this.props.signInFacebookUser(response.authResponse.accessToken)
        //         }
        //     })}, {scope: 'email'});
    }

    handleGoogleSignUp(){
        // gapi.load('auth2', () => {
        //     const auth2: any = this.init(gapi)

        //     auth2.signIn()
        //         .then(() => {
        //             this.props.signInGoogleUser(auth2.currentUser.get().Zi.access_token);
        //         });
        // });
    }

    init(gapis: any): any{
        return gapis.auth2.init({
            'apiKey': '1_A5dsa2BPqZCwf3qnmN5j9J',
            'client_id': '291201890790-kf0hnml86ntlgrmacfem38v9fl8glhal.apps.googleusercontent.com'
        });
    }

    isButtonActive(): boolean{
        const {email, password, name, isAgreement, errorPassword} = this.state
        if (email && email !== "" && password && password !== "" && name && name !== "" && isAgreement && errorPassword === null){
            return true
        }

        return false
    }

    isButtonSocialActive(): boolean{
        const {inProgressGoogle, inProgressFacebook} = this.props

        return inProgressGoogle || inProgressFacebook
    }


    isInProgressForm(): boolean {
        const {inProgress, inProgressFacebook, inProgressGoogle} = this.props;
        return inProgress || inProgressFacebook || inProgressGoogle;
    }

    renderSpinner() {
        let spinnerClass: string = "";
        const {inProgressFacebook, inProgressGoogle} = this.props
        if (inProgressGoogle || inProgressFacebook) {
            if (inProgressFacebook) {
                spinnerClass = "signFacebookSpinner"
            } else if (inProgressGoogle){
                spinnerClass = "signGoogleSpinner"
            }
            return <Spinner className={spinnerClass}/>;
        }
    }

    renderButton(){
        const {t, inProgress} = this.props
        return <div className="button-block-sign-up">
            <SubmitButton title={t('signUp.ok')} onSubmit={this.handleCreateAccount} disabled={!this.isButtonActive()} inProgress={inProgress}/>
        </div>
    }

    render(){
        const {t}  = this.props
        const {error, errorPassword, errorEmail}  = this.state
        return <ModalForm title={t('signUp.title')} closeForm={this.handleCloseForm} error={error} keysHandlers={this.handleCreateAccount}>
            <div className="sign-up">
                <div className="door">
                    <img src={door}/>
                </div>
                <div className={cn("information", this.isInProgressForm() ? "disabled" : "")}>
                    <h1>{t('signUp.title')}</h1>
                    <span className="offer">{t('signUp.tos.offer')}<a href="#" onClick={this.handleOpenSignInForm}> {t('signUp.tos.signIn')}</a></span>
                    <div className="signUp-input-block">
                        <InputContainer name="Name" title={t('signUp.name')} hidden={false} onChange={this.handleChangeName}/>
                        <InputContainer name="SignUpEmail"  title={t('signUp.email')} hidden={false} onChange={this.handleChangeEmail} error={errorEmail}/>
                        <InputContainer name="SignUpPassword"  title={t('signUp.password')} type="password" hidden={false} onChange={this.handleChangePassword} error={errorPassword}/>
                    </div>
                    <div className="signUp-checkbox-block">
                        <TosCheckbox onChecked={this.handleCheckAgreement} hidden={false} title={<span>{t('signUp.tos.agreed')} <a target="_blank" href='/userAgreement'> {t('signUp.tos.use')}</a> {t('signUp.tos.and')} <a target="_blank" href='/privacyPolicy'>{t('signUp.tos.policy')}</a></span>}/>
                        <TosCheckbox onChecked={() => {}} hidden={false} title={t('signUp.tos.keep')}/>
                    </div>
                    <div className="button-block">
                        {this.renderButton()}
                        <button title={t('signUp.end-to-end')} className="button-block-social"  disabled={this.isButtonSocialActive()}>
                            {this.renderSpinner()}
                            <span className="title">{t('signUp.end-to-end')}</span>
                            <div className="images">
                                <img src={facebook} className="signFacebook" onClick={this.handleFacebookSignUp}/>
                                {/* <img src={vk} className="signVK"/> */}
                                <img src={google} className="signGoogle" onClick={this.handleGoogleSignUp}/>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

        </ModalForm>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignUpForm));
