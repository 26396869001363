import React from 'react';
import { MovementMoveDown, MovementMoveUp, MovementRemove } from '../../icons';
import './style.scss';

interface IMovementPanelOwnProps {
    id: string|number;
    moveup: (id: string|number) => void;
    movedown: (id: string|number) => void;
    remove?: (id: string|number) => void;
}

export default class MovementPanel extends React.Component<IMovementPanelOwnProps> {

    constructor(props:any){
        super(props);

        this.handleMoveDown = this.handleMoveDown.bind(this);
        this.handleMoveUp = this.handleMoveUp.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    handleMoveDown(e:any){
        e.stopPropagation();
        const { id, movedown} = this.props;
        movedown(id);
    }

    handleMoveUp(e:any){
        e.stopPropagation();
        const { id, moveup} = this.props;
        moveup(id);
    }

    handleRemove(e:any){
        e.stopPropagation();
        const { id, remove} = this.props;
        remove && remove(id);
    }

    render(){
        const { remove } = this.props;
        return <div className='movement-panel'>
                    <div className='movement-panel-movedown' onMouseDown={this.handleMoveDown}><MovementMoveDown /></div>
                    <div className='movement-panel-moveup' onMouseDown={this.handleMoveUp}><MovementMoveUp /></div>
                    {remove && <div className='movement-panel-remove' onMouseDown={this.handleRemove}><MovementRemove /></div>}
                </div>
    }
}