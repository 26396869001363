import selectedBlocks from "../../models/selection";
import { store } from "../../store/configureStore";
import BaseBlock from "../blocks/base";
import * as d3 from "d3";
import { BlockType } from "../../models";
import * as actions from "../../redux/actions";

export enum Direction {
    UP="up",
    DOWN="down"
}

export default function moveFocusUpDowm(dir:Direction){
    
    const { editingTextBlockId } = store.getState().app;
    const size = selectedBlocks.getSelectedBlocksSize();
    if (size != 1 && !editingTextBlockId) return;

    let currBlockId = '';
    
    if (editingTextBlockId) {
        currBlockId = editingTextBlockId;
    }
    else if (size == 1) {
        currBlockId = selectedBlocks.getSelectedBlocksId()[0];
    }

    const currBlock = BaseBlock.getBlock(currBlockId);
    if(!currBlock) return;

    let nextBlockId=undefined;
    if((currBlock.type == BlockType.Element || currBlock.type == BlockType.Composite  || currBlock.type == BlockType.Fixed) && currBlock.parentId){
        const parent = BaseBlock.getBlock(currBlock.parentId);  
        if (!parent) return;

        const index = parent.children.indexOf(currBlock.id);
        if(dir== Direction.UP){
            if(index>0){
                nextBlockId = parent.children[index-1];
            }
            else{
                nextBlockId = parent.id;
            }
        }
        else { // DOWN
            if(index==parent.children.length-1){
                nextBlockId = parent.id;
                if(parent.parentId){
                    const parentParent = BaseBlock.getBlock(parent.parentId);                      
                    if(parentParent && parentParent.children.length > 1) {
                        const index = parentParent.children.indexOf(parent.id);
                        if(index+1<=parentParent.children.length-1)
                            nextBlockId = parentParent.children[index+1];
                    }
                    
                }
            }
            else{
                nextBlockId = parent.children[index+1];
            }
        }
    }
    else if(currBlock.type == BlockType.Section){

        if(dir==Direction.DOWN && currBlock.children.length>0){
            nextBlockId = currBlock.children[0];
        }
        else if(currBlock.parentId){
            const parent = BaseBlock.getBlock(currBlock.parentId);  
            if (!parent) return;
            const index = parent.children.indexOf(currBlock.id);

            if (dir == Direction.UP) {
                if (index == 0) {
                    nextBlockId = parent.id;
                }
                else {
                    nextBlockId = parent.children[index - 1];
                }
            }
            else{
                if(index==parent.children.length-1){
                    nextBlockId = parent.id;
                    if(parent.parentId){
                        const parentParent = BaseBlock.getBlock(parent.parentId);                      
                        if(parentParent && parentParent.children.length > 1) {
                            const index = parentParent.children.indexOf(parent.id);
                            if(index+1<=parentParent.children.length-1)
                                nextBlockId = parentParent.children[index+1];
                        }
                        
                    }
                }
                else{
                    nextBlockId = parent.children[index+1];
                }
            }
        }        
        
    }
    else if(currBlock.type == BlockType.Page){
        if(dir==Direction.DOWN && currBlock.children.length>0){
            nextBlockId = currBlock.children[0];
        }
    }
    
    if(!nextBlockId) return;
    
    const nextBlock = BaseBlock.getBlock(nextBlockId);
    if(nextBlock && nextBlock.type !== BlockType.Fixed){
        store.dispatch(actions.appEditText(nextBlockId));
    }
    else if (size == 1) {
        
        store.dispatch(actions.appEditText(undefined));
        const newSelectedBlock = d3.select(document.getElementById(nextBlockId)).select(".block-title-container").node() as any;
        
        const newEvent = new MouseEvent("mousedown", {
            view: window,
            bubbles: true,
            cancelable: true
          })
          newSelectedBlock.dispatchEvent(newEvent); 
    }    
}
