import { IErrorResponse, IUserWrite } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'TRIAL_CREATE';

export const ACTIONS = {
    SAVE: actionsSet(prefix, 'SAVE')
};

export interface ISaveInitAction extends Action {
    userId: string;
}

export interface ISaveDoneAction extends Action {
    item: IUserWrite;
}

export const actions = {
    init: (userId: string) => ({ type: ACTIONS.SAVE.INIT, userId }) as ISaveInitAction,
    done: (item: IUserWrite) => ({ type: ACTIONS.SAVE.DONE, item }) as ISaveDoneAction,
    fail: (error: IErrorResponse) => ({ type: ACTIONS.SAVE.FAIL, error }) as IFailAction,

}
