import { Action } from 'redux';
import { actionsSet } from '../../../actions/index';
const prefix = 'DROP_DOWN';

export const ACTIONS = {
    DROP_DOWN: actionsSet(prefix, "")
};

export interface IOpenInitAction extends Action {
}

export interface ICloseDoneAction extends Action {
}

export const actions = {
    open: () => ({type: ACTIONS.DROP_DOWN.INIT}) as IOpenInitAction,
    close:() => ({type: ACTIONS.DROP_DOWN.DONE}) as ICloseDoneAction
}
