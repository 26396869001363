import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import '../style.scss'
import { IProject } from "../../../../api/projects";
import { Action } from "redux";
import { IState } from "../../../../redux/reducers";
import * as actions from "../../../../redux/actions";
import { connect } from "react-redux";
import cn from 'classnames';
import { IErrorResponse } from "../../../../api";
import InputContainerDark from '../../../InputContainerDark';
import { CoverColorSelectionIcon } from '../../../../icons';
import { white, red, orange, gold, green, light_blue, blue, purple, pink, grey } from '../../../../consts';
import ModalForm from "../../../Landing/ModalForm";
import SubmitButton from "../../../Buttons/SubmitButton";
import {DefaultButton} from "../../../Buttons/DefaultButton";

interface IStateProps {
    id: string,
    view_project: IProject | null,
    view_inProgress: boolean,
    view_error: IErrorResponse | null,
    edit_project: IProject | null,
    edit_inProgress: boolean,
    edit_error: IErrorResponse | null,
    isOpen: boolean
    projectLink: string | null,
    linkShared: boolean;
}

interface IDispatchProps {
    save: (id: string, item: IProject, fromFrom: boolean) => Action;
    clear: () => Action;
    load: (id: string) => Action;
    close: () => Action;
    shareProject: (id: string) => Action;
    closeProject: (id: string) => Action;
}

interface ISettingsFormState {
    title: string;
    description: string;
    color: string;
    disabled: boolean;
    errorTitle?: string;
    linkShared: boolean;
}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        id: state.modalForm.projectSettings.id,
        view_project: state.projects.view.item,
        view_inProgress: state.projects.view.inProgress,
        view_error: state.projects.view.error,
        edit_project: state.projects.edit.item,
        edit_inProgress: state.projects.edit.inProgress,
        edit_error: state.projects.edit.error,
        isOpen: state.modalForm.projectSettings.isOpen,
        projectLink: state.presentationMode.share.item,
        linkShared: state.presentationMode.view.isPresentationMode
    };
};

const mapDispatchToProps: IDispatchProps = {
    save: actions.actions.projects.edit.init,
    clear: actions.actions.projects.view.clear,
    load: actions.actions.projects.view.init,
    close: actions.actions.modalForm.projectSettings.close,
    shareProject: actions.actions.presentationMode.share.init,
    closeProject: actions.actions.presentationMode.close.init
};

class ProjectSettingsModalCreate extends React.Component<WithTranslation & IStateProps & IDispatchProps, ISettingsFormState> {
    constructor(props: any) {
        super(props);

        this.state = {
            errorTitle: undefined,
            title: " ",
            description: "",
            color: white,
            disabled: false,
            linkShared: this.props.linkShared
        };
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.handleBlurTitle = this.handleBlurTitle.bind(this);
        this.validate = this.validate.bind(this);
        this.renderColorsPanel = this.renderColorsPanel.bind(this);
        this.shareProject = this.shareProject.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<WithTranslation & IStateProps & IDispatchProps>) {
        if (this.props && this.props.id && this.props.id !== prevProps.id) {
            this.props.load(this.props.id);
        }

        if (this.props && this.props.view_project && this.props.view_project !== prevProps.view_project) {
            const error = !this.props.view_project.title ? this.props.t('projectSettings.emptyFieldError') : '';
            this.setState({ ...this.state, title: this.props.view_project.title, errorTitle: error, color: this.props.view_project.color, description: this.props.view_project.description })
        }

        if (this.props && this.props.edit_inProgress !== prevProps.edit_inProgress) {
            this.setState({ ...this.state, disabled: this.props.edit_inProgress })
        }

        if (this.props && !this.props.edit_inProgress && this.props.edit_inProgress !== prevProps.edit_inProgress) {
            this.handleClose();
        }
    }

    shareProject() {
        const { shareProject, closeProject, id } = this.props;
        this.setState((state) => ({ linkShared: !state.linkShared }));
        setTimeout(() => {
            if (shareProject && this.state.linkShared) {
                shareProject(id);
            } else {
                closeProject(id);
            }
        }, 300);

    }

    handleClose() {
        this.setState({ ...this.state, title: " ", color: "", description: "", disabled: false })
        this.props.close();
    }

    handleChangeTitle(e: any) {
        this.setState({ ...this.state, title: e.target.value });
    }


    handleSubmit() {
        this.setState({ ...this.state, disabled: true })
        const { title, color, description } = this.state;
        const { view_project, save } = this.props;
        const newProject: IProject | null = view_project;
        if (newProject && title && color) {
            newProject.title = title;
            newProject.color = color;
            // TODO: выпилить, если не используется
            newProject.description = ' ';

            save(newProject.id, newProject, true);
        } else {
            this.setState({
                ...this.state,
                errorTitle: this.validate(this.state.title),
                disabled: false
            })
        }
    }

    handleBlurTitle() {
        const error = this.state.title === '' ? this.props.t('projectSettings.emptyFieldError') : '';
        this.setState({ ...this.state, errorTitle: error });
    }

    validate(description: string) {
        if (!description) {
            return this.props.t('projectSettings.emptyFieldError');
        }
        else {
            return '';
        }
    }

    renderColorsPanel() {
        const { color } = this.state;
        return (<>
            <div className="colors-panel-row">
                <div className={cn("colors-panel-row-color-element", "white")}
                    onClick={() => this.setState({ color: white }) as any}
                >
                    {color === white && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "red")}
                    onClick={() => this.setState({ color: red }) as any}>
                    {color === red && <CoverColorSelectionIcon />}

                </div>
                <div className={cn("colors-panel-row-color-element", "orange")}
                    onClick={() => this.setState({ color: orange }) as any}
                >
                    {color === orange && <CoverColorSelectionIcon />}
                </div>

                <div className={cn("colors-panel-row-color-element", "gold")}
                    onClick={() => this.setState({ color: gold }) as any}
                >
                    {color === gold && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "green")}
                    onClick={() => this.setState({ color: green }) as any}
                >
                    {color === green && <CoverColorSelectionIcon />}
                </div>
            </div>

            <div className="colors-panel-row">
                <div className={cn("colors-panel-row-color-element", "light-blue")}
                    onClick={() => this.setState({ color: light_blue }) as any}
                >
                    {color === light_blue && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "blue")}
                    onClick={() => this.setState({ color: blue }) as any}
                >
                    {color === blue && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "purple")}
                    onClick={() => this.setState({ color: purple }) as any}
                >
                    {color === purple && <CoverColorSelectionIcon />}
                </div>

                <div className={cn("colors-panel-row-color-element", "pink")}
                    onClick={() => this.setState({ color: pink }) as any}
                >
                    {color === pink && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "grey")}
                    onClick={() => this.setState({ color: grey }) as any}
                >
                    {color === grey && <CoverColorSelectionIcon />}
                </div>
            </div>
        </>

        );
    }
    render() {
        const { errorTitle } = this.state;

        const { t, edit_error, isOpen, edit_inProgress, view_inProgress, projectLink } = this.props;
        const { description, color, title, disabled } = this.state;
        
        if (isOpen) {
            return (
                <ModalForm title={''} closeForm={this.handleClose} inProgress={(edit_inProgress || view_inProgress || false)} classes="settings" keysHandlers={this.handleSubmit}>
                    <div className="popup-content">
                        <div className="popup-content-left-panel" >
                            <div className="popup-content-left-panel--info" style={{ background: !!color ? color : white }}>
                                <div className="popup-content-left-panel--info__name">
                                    {title}
                                </div>
                            </div>
                            <div className="popup-content-left-panel--color-picker">
                                {this.renderColorsPanel()}
                            </div>

                        </div>
                        <div className={cn("popup-content-right-panel", edit_inProgress ? "disabled" : "")}>
                            <div className="inputs-block">
                                <h1 className="inputs-block-heading">
                                    {t('projectSettings.header')}
                                </h1>
                                <InputContainerDark title={t('projectSettings.title')} value={title}
                                    error={errorTitle} onBlur={this.handleBlurTitle}
                                    onChange={this.handleChangeTitle} />

                                <div className="members">

                                    {/* <div className="members--icons">
                                    </div>
                                    <div className="members--add-button">
                                        <ProjectInviteIcon />
                                    </div>
                                    <div className="members--open-menu-button">Members</div> */}

                                </div>

                                <div className="share-link">
                                    <div className="share-link__switch">
                                        <label className="switch">
                                            <input type="checkbox" checked={this.state.linkShared} onChange={this.shareProject} />
                                            <span className="slider round"></span>
                                        </label>

                                    </div>
                                    <div className="project-link">{
                                        this.state.linkShared &&
                                            projectLink ?
                                            <a href={projectLink}>{projectLink}</a> :
                                            <> <div className="generate-link">Generate presentation link</div>  <div className="presentation-mode">Presentation mode</div></>}
                                    </div>
                                </div>


                                <div className="button-container">
                                    <div className="button-container-save">
                                        <SubmitButton title={t('projectSettings.save')} onSubmit={this.handleSubmit} disabled={edit_inProgress} inProgress={edit_inProgress}/>
                                    </div>

                                    <DefaultButton className="button-container-cancel" title={t('projectSettings.cancel')} disabled={edit_inProgress} onClick={this.handleClose} />
                                </div>
                            </div>
                        </div>
                        {edit_error ? <span className="form-error">{edit_error}</span> : <span className="form-error">{""}</span>}

                    </div>
                </ModalForm>);
        }

        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectSettingsModalCreate));
