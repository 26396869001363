import React from "react";
import './style.scss'
import cn from "classnames";

interface IProps {
    className?: string
}

export class Spinner extends React.Component<IProps> {
    render(){
        return <div className={cn("spinner", this.props.className)}><i className="loader"></i>
        </div>
    }
}