import {httpGet, httpPost} from '../common';

export interface ITariffs1 {
    basic: number;
    pro: number;
    studio: number;
}

export interface ITariffCurrency {
    type: string;
    tariffs: ITariffs1
}

export function getTariffs(cultureInfo: string) {
    return httpGet<ITariffs1>(`api/tariffs?cultureInfo=${cultureInfo}`, { authorize: false });
}
