import { BlockType, IBlock } from "../../models";
import BaseBlock from "./base";
import { BLOCK_PLACEHOLDER_ID } from "../../consts";


class PlaceholderBlock extends BaseBlock {   
    constructor(){
        super("placeholder", BlockType.Placeholder, [], BLOCK_PLACEHOLDER_ID);
    }    
}

export function createPlaceholderBlock(){    
    let placeholder = BaseBlock.getBlock(BLOCK_PLACEHOLDER_ID);
    if(!placeholder){
        placeholder = new PlaceholderBlock();
        BaseBlock.addBlock(placeholder);     
    }       
}

export function isPlaceholderBlock(){
    const placeholder = BaseBlock.getBlock(BLOCK_PLACEHOLDER_ID);
    if(placeholder && placeholder.parentId) return true;
    return false;
}

export function setPlaceholder(parentOfPlaceholder:IBlock, insertingIndex:number){
    resetPlaceholder();

    const placeholder = BaseBlock.getBlock(BLOCK_PLACEHOLDER_ID);
    if(!placeholder) return;

    BaseBlock.removeChild(parentOfPlaceholder, BLOCK_PLACEHOLDER_ID);
    BaseBlock.addChild(parentOfPlaceholder, BLOCK_PLACEHOLDER_ID, insertingIndex);
    placeholder.parentId = parentOfPlaceholder.id;
    
    BaseBlock.updateBlocks([parentOfPlaceholder, placeholder], true);
}

export function resetPlaceholder(){
    const placeholder = BaseBlock.getBlock(BLOCK_PLACEHOLDER_ID);

    if(!placeholder) return;
    if(!placeholder.parentId) return;    

    const parentPlaceholder = BaseBlock.getBlock(placeholder.parentId);

    placeholder.parentId = undefined;
    BaseBlock.updateBlock(placeholder, true);

    if(!parentPlaceholder) return;

    BaseBlock.removeChild(parentPlaceholder, BLOCK_PLACEHOLDER_ID);
    BaseBlock.updateBlock(parentPlaceholder, true);
}