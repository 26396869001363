import * as authAction from './create';
import * as updateAction from './update';
import * as viewAction from './view';
import * as currentUserAction from './currentUser';
import * as confirmAction from './confirm';
import * as updatePasswordAction from './updatePassword'

import * as currentUserTrialPeriod from './currentUserTrialPeriod';

export const ACTIONS = {
    create: authAction.ACTIONS,
    update: updateAction.ACTIONS,
    updatePasswordAction: updatePasswordAction.ACTIONS,
    view: viewAction.ACTIONS,
    confirm: confirmAction.ACTIONS,
    currentUserTrialPeriod: currentUserTrialPeriod.ACTIONS,
    currentUser: currentUserAction.ACTIONS
};

export const actions = {
    create: authAction.actions,
    update: updateAction.actions,
    updatePassword: updatePasswordAction.actions,
    view: viewAction.actions,
    confirm: confirmAction.actions,
    currentUserTrialPeriod: currentUserTrialPeriod.actions,
    currentUser: currentUserAction.actions
};
