import React from 'react';
import { SmallchekerIcon } from '../../../../icons';

interface IComboBoxItemStateOwn {
    checked: boolean;
}

interface IComboBoxItemPropsOwn {
    index: number;
    value: string;
    checked: boolean;
    multiSelect: boolean;
    select: (index:number, checked?: boolean) => void;
}

export default class ComboBoxItem extends React.Component<IComboBoxItemPropsOwn, IComboBoxItemStateOwn> {

    constructor(props:any){
        super(props);

        this.state={checked: props.checked};

        this.selectItem = this.selectItem.bind(this);
        this.renderCheckbox = this.renderCheckbox.bind(this);
    }

    selectItem(event:any){
        event.stopPropagation();

        const { index, select, multiSelect } = this.props;
        

        if(!multiSelect)
            select(index);
        else {
            const { checked } = this.props;
            select(index, !checked);
        }
    }

    renderCheckbox(){
        
        return <div className="wc-combobox-item-checkbox" onClick={this.selectItem}>
                    {this.props.checked && <SmallchekerIcon />}
               </div>
    }

    render(){
        const { value, multiSelect } = this.props;

        return <div className='wc-combobox-item' onClick={this.selectItem}>
                {multiSelect && this.renderCheckbox()}
                <div>{value}</div>
               </div>;
    }
}