import React from 'react';
import { IPoint } from '../../../../models';
import { IState } from '../../../../redux/reducers';
import { connect } from 'react-redux';
import './style.scss';

interface IWorkCanvasTooltipProps {
    coord?: IPoint;
    text?: string;
}

const mapStateToProps = (state: IState): IWorkCanvasTooltipProps => {
    return {
        coord: state.app.workCanvasTooltip.coord,
        text: state.app.workCanvasTooltip.text
    };
};

class WorkCanvasTooltip extends React.Component<IWorkCanvasTooltipProps> {

    render(){

        const { coord, text } = this.props;
        
        if(!coord) return <></>;

        return <div style={{left: coord.x+5, top: coord.y+5}} className='workcanvas-tooltip'>{text}</div>;
    }
}

export default connect(mapStateToProps)(WorkCanvasTooltip);