import selectedBlocks from "../../models/selection";
import { store } from "../../store/configureStore";
import BaseBlock from "../blocks/base";
import { BlockType, ElementBlockType } from "../../models";
import { createDefaultElementBlock } from "./createElementBlock";
import { createSectionBlock } from "./createSectionBlock";
import { Guid } from "guid-typescript";

export function createBlockByKeyClick(blockType: BlockType) {
    const { editingTextBlockId } = store.getState().app;
    const size = selectedBlocks.getSelectedBlocksSize();
    if (size != 1 && !editingTextBlockId) return;

    let targetBlockId = '';

    if (editingTextBlockId) {
        targetBlockId = editingTextBlockId;
    }
    else if (size == 1) {
        targetBlockId = selectedBlocks.getSelectedBlocksId()[0];
    }

    const b = BaseBlock.getBlock(targetBlockId);
    if (!b || b.type != BlockType.Element && b.type != BlockType.Section || !b.parentId) return;

    // Если текущий блок "секция", добавить нужно "элемент"
    if (b.type == BlockType.Section && blockType == BlockType.Element){
        const newEl = createDefaultElementBlock(b.subType ? b.subType : ElementBlockType.Text, undefined, b.id, "\u200b");        
        BaseBlock.addChild(b, newEl.id, 0);
        BaseBlock.updateBlock(b);
    }
    // Если текущий блок "элемент", добавить нужно "элемент"
    else if (b.type == BlockType.Element && blockType == BlockType.Element) {
        const parent = BaseBlock.getBlock(b.parentId);
        if (!parent) return;

        const index = parent.children.indexOf(b.id);
        const newEl = createDefaultElementBlock(b.subType ? b.subType : ElementBlockType.Text, undefined, parent.id, "\u200b");
        BaseBlock.addChild(parent, newEl.id, index + 1);
        BaseBlock.updateBlock(parent);
    }
    // Если добавить нужно "секцию" 
    else if (blockType == BlockType.Section) {

        const parent = BaseBlock.getBlock(b.parentId);
        if (!parent) return;

        if (parent.type == BlockType.Page) {
            const index = parent.children.indexOf(b.id);
            const newEl = createSectionBlock("", Guid.raw(), undefined,undefined, parent.id);
            BaseBlock.addBlock(newEl);
            BaseBlock.addChild(parent, newEl.id, index + 1);
            BaseBlock.updateBlock(parent);
        }
        else if (parent.type == BlockType.Section && parent.parentId) {
            const page = BaseBlock.getBlock(parent.parentId);
            if(!page) return;

            const index = page.children.indexOf(parent.id); // Определяем где секция находится на странице
            const newEl = createSectionBlock("\u200b", Guid.raw(), undefined, undefined, page.id);
            BaseBlock.addBlock(newEl);
            BaseBlock.addChild(page, newEl.id, index + 1);
            BaseBlock.updateBlock(page);
        }
    }
}