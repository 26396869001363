import React from 'react';
import ComboBoxItem from './ComboBoxItem';
import { DropDownerIcon } from '../../../../icons';
import './styles/combobox.scss';
import './styles/combobox-item.scss';

interface IComboBoxStateOwn {
    displayChildren: boolean;
    selectedIndex: number[];
    selectedValue: string;
}

interface IComboBoxPropsOwn {
    blockId: string;
    title: string;
    multiSelect: boolean;
    items: any[];
    selectedIndex: number[];
    changeSelection: (indexes: number[]) => void;
}

export default class ComboBox extends React.Component<IComboBoxPropsOwn, IComboBoxStateOwn> {

    constructor(props:any){
        super(props);
       
        this.renderItems = this.renderItems.bind(this);
        this.showHideChildren = this.showHideChildren.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.showHideChildren = this.showHideChildren.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
        this.getInitialState =this.getInitialState.bind(this);

        this.state = this.getInitialState();

    }

    componentDidMount(){
        this.setState(this.getInitialState());
    }

    componentDidUpdate(prevProps:any){
        if(this.props.blockId !== prevProps.blockId)
            this.setState(this.getInitialState());
    }

    getInitialState(){
        return {displayChildren: false, selectedValue: this.selectedValue(this.props.items, this.props.selectedIndex), selectedIndex: this.props.selectedIndex};;
    }

    selectedValue(items:string[], indexes:number[]){
        return indexes.map(v=>items[v]).join(' + ');
    }

    selectItem(index:number, checked?:boolean){
        const { items, multiSelect } = this.props;
        const { displayChildren, selectedIndex } = this.state;

        if(!multiSelect){
            this.setState((state) => { return { ...state, selectedValue: items[index], selectedIndex: [index], displayChildren: !displayChildren } });
            this.props.changeSelection([index]);
        }            
        else {
            
            // Логика для множественного выбора 
            let indexes = [...selectedIndex];
            if(checked && indexes.indexOf(index)<0)
                indexes = [...indexes, index];
            else {
                const removedIndex = indexes.indexOf(index);
                if(removedIndex >= 0 && indexes.length > 1)
                    indexes.splice(removedIndex, 1);                    
            }
                
            const value = this.selectedValue(items, indexes);
            this.setState((state) => { return { ...state, selectedValue: value, selectedIndex:indexes } });

            this.props.changeSelection(indexes);
        }
            
    }

    renderItems(){
        const { multiSelect, items } = this.props;
        const { selectedIndex } = this.state;
        
        return <>
                <div className='wc-combobox-items-divider'></div>
                <div className='wc-combobox-items'>                    
                    {items.map((v,i)=><ComboBoxItem key={i} value={v} multiSelect={multiSelect} index={i} select={this.selectItem} checked={selectedIndex.indexOf(i)>=0} />)}
                </div>
               </>
    }

    showHideChildren(event:any){
        const {displayChildren} = this.state;
        this.setState((state) => { return { ...state, displayChildren: !displayChildren } });
        event.stopPropagation();
    }

    mouseLeave(event:any){
        event.stopPropagation();
        const {displayChildren} = this.state;
        if(displayChildren)
            this.showHideChildren(event);
    }

    formatSelectedValue(value:string){
        if(value.length>16){
            return `${value.substring(0,15)}...`
        }

        return value;
    }

    render() {

        const { title, multiSelect } = this.props;
        const { displayChildren, selectedValue } = this.state;

        let style = {}
        if(multiSelect)
            style = {minWidth: "180px"};
        
        return <div className='wc-combobox'>
                 <div className='wc-combobox-title'>{title}</div>
                 <div className='wc-combobox-content' style={style} onMouseLeave={this.mouseLeave}>
                     <div className='wc-combobox-value' onClick={this.showHideChildren}>
                        {this.formatSelectedValue(selectedValue)} 
                        <div className='wc-combobox-icon'><DropDownerIcon/> </div>
                    </div>
                     {displayChildren && this.renderItems()}
                 </div>
               </div>
    }
}