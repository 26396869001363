import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/presentationMode/index';
import { closeProject } from "../../../api/presentationMode/index";
import { actions } from "../../actions/index";
import { ISaveInitAction } from "../../actions/presentationMode/close";
import { IErrorResponse } from "../../../api";

export function* sagaHandler(action: Action) {
    try {
        const { id } = action as ISaveInitAction;
        yield call(closeProject, id);

        yield put(actions.presentationMode.close.done());
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.presentationMode.close.fail(c))
    }
}

export function* closeSaga() {
    yield takeLatest(ACTIONS.close.CLOSE.INIT, sagaHandler);
}
