import React from "react";
import Video from "../Video";
import './style.scss'
import Radio from "./RadioButton";
import {TweenMax, Back} from "gsap/TweenMax";
import {DiamondFillIcon, DiamondIcon, DiamondTextureIcon} from "../../icons";
import vignette from '../../images/vignette.png'
import {withTranslation, WithTranslation} from "react-i18next";
import cn from "classnames";
import ScrollButton from "../Landing/ScrollButton";
import i18next from "i18next";

export interface ISrcVideo{
    number: string,
    path: any,
    isLoop: boolean,
    isAdditional: boolean,
    startBlock: number,
    endBlock: number,
    className: any
}

interface IProps {
    firstVideo: ISrcVideo
    secondVideo: ISrcVideo
    thirdVideo: ISrcVideo
}

interface IState {
    firstVideo: ISrcVideo,
    secondVideo: ISrcVideo,
    thirdVideo: ISrcVideo,
    isAnimate: boolean,
    mainText: string,
    firstText: string,
    secondText: string,
    thirdText: string,
    fourText: string,
    firstCheck: boolean,
    secondCheck: boolean,
    thirdCheck: boolean,
    fourCheck: boolean,
    scrollPos: number
    step: number
    active: number,
    optionalClass: string
}

export class VideoCarousel extends React.Component<IProps & WithTranslation, IState> {
    constructor(props:any){
        super(props);
        const {t} = this.props
        this.state = {scrollPos: 0, step: 0, firstVideo: {} as ISrcVideo, secondVideo: {} as ISrcVideo, thirdVideo: {} as ISrcVideo,
            mainText: t('videoCarousel.mainText.text'),
            firstText: t('videoCarousel.firstText.text'),
            secondText: t('videoCarousel.secondText.text'),
            thirdText: t('videoCarousel.thirdText.text'),
            fourText: t('videoCarousel.fourText.text'),
            active: 0, optionalClass: '', firstCheck: false, secondCheck: false, thirdCheck: false, fourCheck: false, isAnimate: false}
        this.handleBlurText = this.handleBlurText.bind(this);
        this.handleFocusText = this.handleFocusText.bind(this);
        this.handleWheel = this.handleWheel.bind(this);
        this.handleButtonScroll = this.handleButtonScroll.bind(this);
    }

    componentDidMount() {
        this.addEventListenerMouseWheel();
        this.setState({...this.state, firstVideo: this.props.firstVideo, secondVideo: this.props.secondVideo, thirdVideo: this.props.thirdVideo})
    }

    animateItem(duration: number, className: string) {
        let letter = document.getElementsByClassName(className)
        TweenMax.staggerFromTo(letter, duration, {
            opacity: 0
        }, {
            opacity: 2,
            ease: Back.easeOut.config(1.9)
        }, 0.03)
    }


    animateScreen(){
        const elements = document.getElementsByClassName("moveBlocks");
        const element = elements[0]
        TweenMax.from(element, 1, {top: '20vh', opacity: 1, delay: 0.01});
    }

    complexAnimate(duration: number, textClassName: string){
        this.animateScreen();
        this.animateItem(duration, textClassName)
    }

    handleRadioButton(e: number) {
        if (e == 1) {
            this.setState({...this.state, firstCheck: true, secondCheck: false, thirdCheck: false, fourCheck: false, step: e})
        } else if (e ==2) {
            this.setState({...this.state, firstCheck: false, secondCheck: true, thirdCheck: false, fourCheck: false, step: e})
        }else if (e ==3) {
            this.setState({...this.state, firstCheck: false, secondCheck: false, thirdCheck: true, fourCheck: false, step: e})
        }else if (e ==4) {
            this.setState({...this.state, firstCheck: false, secondCheck: false, thirdCheck: false, fourCheck: true, step: e})
        }

        this.detectStepEnd(e);
    }

    handleFocusText(e: any){
        this.setState({secondVideo: {...this.state.secondVideo, className: undefined, startBlock: 0, endBlock: 1}})
    }

    handleBlurText(e: any){
        if(this.state.secondVideo) {
            this.setState({secondVideo: {...this.state.secondVideo, startBlock: 1, endBlock: 2}})
        }
    }

    handleWheel(e: any) {
        this.removeEventListenerMouseWheel();
        const elements = document.getElementsByClassName("groupRadio");
        const element = elements[0]

        this.setState({...this.state, isAnimate: true})
        if (e.deltaY <  0){
            this.scrollStart(element, 0, 1000);
        }
        else {
            this.scrollEnd(element, element.clientHeight * 2, 1000);
        }
    }

    handleButtonScroll() {
        this.removeEventListenerMouseWheel();
        const elements = document.getElementsByClassName("groupRadio");
        const element = elements[0]
        this.setState({...this.state, isAnimate: true})
        this.scrollEnd(element, element.clientHeight * 2, 1000);
    }

    stepOneDownBehavior(newState: number){
        this.setState({thirdVideo: {...this.state.thirdVideo, startBlock: 0, endBlock: 0}, step: newState, firstCheck: true, secondCheck: false, thirdCheck: false, fourCheck: false})
        setTimeout(() => this.animateScreen(), 500)
        setTimeout(() => {this.addEventListenerMouseWheel()}, 1000);
    }

    stepTwoDownBehavior(newState: number){
        this.setState({thirdVideo: {...this.state.thirdVideo, startBlock: 0, endBlock: 2}, step: newState, firstCheck: false, secondCheck: true, thirdCheck: false, fourCheck: false})
        this.animateItem(1, 'hideText')
        setTimeout(() => this.addEventListenerMouseWheel(), 2000);
    }

    stepThreeDownBehavior(newState: number){
        this.setState({thirdVideo: {...this.state.thirdVideo, startBlock: 2, endBlock: 4}, step: newState, firstCheck: false, secondCheck: false, thirdCheck: true, fourCheck: false})
        this.complexAnimate(1, 'hideText')
        setTimeout(() => this.addEventListenerMouseWheel(), 2000);
    }

    stepFourDownBehavior(newState: number){
        this.setState({thirdVideo: {...this.state.thirdVideo, startBlock: 4, endBlock: 10}, step: newState, firstCheck: false, secondCheck: false, thirdCheck: false, fourCheck: true})
        this.animateScreen()
        setTimeout(() => {this.addEventListenerMouseWheel()}, 100);
    }

    stepOneUpBehavior(newStep: number){
        this.setState({
            thirdVideo: {...this.state.thirdVideo}, step: newStep, firstCheck: true, secondCheck: false, thirdCheck: false, fourCheck: false
        })
        setTimeout(() => {this.addEventListenerMouseWheel()}, 100);
    }

    stepThreeUpBehavior(newStep: number){
        this.setState({thirdVideo: {...this.state.thirdVideo, startBlock: 12, endBlock: 13}, step:  newStep, firstCheck: false, secondCheck: true, thirdCheck: false, fourCheck: false})
        this.animateScreen()
        setTimeout(() => this.addEventListenerMouseWheel(), 1000);
    }

    stepFourUpBehavior(newStep: number){
        this.setState({thirdVideo: {...this.state.thirdVideo, startBlock: -1, endBlock: 12}, step:  newStep, firstCheck: false, secondCheck: false, thirdCheck: true, fourCheck: false})
        this.animateScreen()
        setTimeout(() => this.addEventListenerMouseWheel(), 1000);
    }

    detectStepStart(element: any, to: any, duration: number){
        switch (this.state.step) {
            case 3:{
                this.stepThreeUpBehavior(2);
                return;
            }
            case 4:{
                this.stepFourUpBehavior(3);
                return;
            }
            default: {
                this.stepOneUpBehavior(0);
                this.animateScroll(element, to, duration, element.scrollTop-to);
                return;
            }
        }
    }

    detectStepEnd(newStep: number){
        switch (newStep) {
            case 1:{
                this.stepOneDownBehavior(newStep);
                return;
            }
            case 2:{
                this.stepTwoDownBehavior(newStep);
                return;
            }
            case 3:{
                this.stepThreeDownBehavior(  newStep);
                return;
            }
            case 4:{
                this.stepFourDownBehavior(newStep );
                return;
            }
            default: {
                setTimeout(() => {this.addEventListenerMouseWheel()}, 100);
                return;
            }
        }

    }

    scrollStart(element: any, to: any, duration: number){
        this.detectStepStart(element, to, duration);
        this.setState({...this.state, isAnimate: false})
    }

    scrollEnd(element: any, to: any, duration: number){
        const newState = this.state.step + 1;
        this.detectStepEnd(newState);
        this.animateScroll(element, to, duration, element.scrollTop-to);
        this.setState(prevState => ({...prevState, isAnimate: true}))
    }

    animateScroll(element: any, to: any, duration: number, change: number, currentTime: number = 0){
        const start = element.scrollTop
        const increment = 20
        currentTime += increment;
        let val = (t: number, b: number, c: number, d: number): any => {
            const step = c * increment / d;
            return step
        }

        let step = val(currentTime, start, change, duration);
        if (step  > 0 && element.scrollTop-step < 0) {
            step = element.scrollTop
            element.scrollTop -= step;
            return;
        }
        element.scrollTop -= step;

        if(currentTime < duration) {
            setTimeout(() => this.animateScroll(element, to, duration, change, currentTime), increment);
        }
    }

    render(){
        return (
            <div className="groupRadio" >
                <div className="videoCarousel">
                {this.renderVideo()}
                {this.renderScrollButton()}
                </div>

                {this.renderVignette()}
                {this.renderText()}
            </div>
        )
    }

    renderVideo() {
        const {firstVideo, secondVideo, thirdVideo} = this.state

        if (firstVideo.path && secondVideo.path && thirdVideo.path) {
        return (<><div className="videoContainer"><Video
                isLoop={firstVideo.isLoop}
                className={firstVideo.className}
                number={firstVideo.number}
                path={firstVideo.path}
                isAdditional={firstVideo.isAdditional}
                startBlock={firstVideo.startBlock}
                endBlock={firstVideo.endBlock}/></div>
            <div className="videoContainer"><Video
                isLoop={secondVideo.isLoop}
                className={secondVideo.className}
                number={secondVideo.number}
                path={secondVideo.path}
                isAdditional={secondVideo.isAdditional}
                startBlock={secondVideo.startBlock}
                endBlock={secondVideo.endBlock}/></div>
             <div className="videoContainer-down"><Video
                isLoop={thirdVideo.isLoop}
                className={thirdVideo.className}
                number={thirdVideo.number}
                path={thirdVideo.path}
                isAdditional={thirdVideo.isAdditional}
                startBlock={thirdVideo.startBlock}
                endBlock={thirdVideo.endBlock}/>
                 <div className="radioButton">
                     {this.renderButton()}
                 </div>
                 <div className={"moveBlocks"}>
                     {this.renderSecondTextOneBlock()}
                     {this.renderSecondTextTwoBlock()}
                     {this.renderSecondTextThreeBlock()}
                     {this.renderSecondTextFourBlock()}
                 </div>
             </div>
        </>)
        }
    }

    renderText() {
        const {step, mainText} = this.state
        const {t} = this.props

        if (step == 0) {
            return (<div className="firstScreen">
                <div className="activeText" onMouseOver={this.handleFocusText} onMouseLeave={this.handleBlurText}>
                    {t('videoCarousel.mainText.header1')}
                </div>
                <div className="visualText">{t('videoCarousel.mainText.header2')}</div>
                <div className="hideText">
                    {mainText}
                </div>
            </div>)
        }
    }

    renderSecondTextOneBlock() {
        const {step, firstText} = this.state
        const {t} = this.props

        if (step == 1) {
            return (<div className="secondScreen">
                <DiamondIcon/>
                <div className="activeText">{t('videoCarousel.firstText.header1')}</div>
                <div className="hideText">{firstText}</div>
            </div>)
        }
    }

    renderSecondTextTwoBlock() {
        let spans = [];
        let ind: number = 1;
        const {step, secondText} = this.state
        const {t} = this.props

        if (step == 2) {
            const itemActive: any = secondText
            // for (let i in itemActive) {
            //     ind= ind++;
            //     console.log(ind, itemActive)
            //     spans.push(<span key={ind} className={`hideText letter-${i}`}>{itemActive[i]}</span>)
            // }

            return (<div className="thirdScreen">
                <DiamondIcon/>
                <div className="activeText">{t('videoCarousel.secondText.header1')}</div>
                <div className="hideText">{t('videoCarousel.secondText.text_main')} {itemActive}</div>
            </div>)
        }
    }

    renderSecondTextThreeBlock() {
        const {step, thirdText} = this.state
        const {t} = this.props

        if (step == 3) {
            return (<div className="fourScreen">
                <DiamondFillIcon/>
                <div className="activeText">{t('videoCarousel.thirdText.header1')}</div>
                <div className="hideText">{thirdText}</div>
            </div>)
        }
    }

    renderSecondTextFourBlock() {
        const {step, fourText} = this.state
        const {t} = this.props

        if (step > 3) {
            return (<div className="fourScreen">
                <DiamondTextureIcon/>
                <div className="activeText">{t('videoCarousel.fourText.header1')}</div>
                <div className="hideText">{fourText}</div>
            </div>)
        }
    }

    renderButton() {
        return (<>
                <Radio onClick={this.handleRadioButton.bind(this, 1)} checked={this.state.firstCheck}/>
                <Radio onClick={this.handleRadioButton.bind(this, 2)} checked={this.state.secondCheck}/>
                <Radio onClick={this.handleRadioButton.bind(this, 3)} checked={this.state.thirdCheck}/>
                <Radio onClick={this.handleRadioButton.bind(this, 4)} checked={this.state.fourCheck}/>
            </>)
    }


    renderScrollButton(){
        return  <ScrollButton onclick={this.handleButtonScroll}/>
    }

    addEventListenerMouseWheel(){
        const elements = document.getElementsByClassName("groupRadio");
        const element = elements[0]
        element.addEventListener("mousewheel", this.handleWheel);
    }

    removeEventListenerMouseWheel(){
        const elements = document.getElementsByClassName("groupRadio");
        const element = elements[0]
        element.removeEventListener("mousewheel", this.handleWheel);
    }

    renderVignette(){
        return (<img className="vignette" src={vignette}/>)
    }
}

export default (withTranslation()(VideoCarousel));