import {ACTIONS } from '../../actions/userSecurity/resetPassword';
import { Action } from 'redux';
import {IErrorResponse, printErrorResponse} from '../../../api/index';
import { IFailAction } from '../../../actions';

export interface IState {
    isChange: boolean;
    inProgress: boolean;
    error: string | null;
}

export const initial: IState = {
    isChange: false,
    inProgress: false,
    error: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.RESET_PASSWORD.INIT:
            return {
                ...state,
                isChange: false,
                inProgress: true,
                error: null
            };

        case ACTIONS.RESET_PASSWORD.DONE:
        {
            return {
                ...state,
                isChange: true,
                inProgress: false,
                error: null
            };
        }

        case ACTIONS.RESET_PASSWORD.FAIL:
        {
            const { error } = action as IFailAction;
            return {
                ...state,
                inProgress: false,
                error: printErrorResponse(error)
            };
        }

        case ACTIONS.CLEAR:
        {
            return initial;
        }

        default:
            return state;
    }
}
