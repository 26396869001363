import * as createAction from './create';
import * as editAction from './edit';

export const ACTIONS = {
    create: createAction.ACTIONS,
    edit: editAction.ACTIONS
};

export const actions = {
    create: createAction.actions,
    edit: editAction.actions
};
