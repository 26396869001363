import {ACTIONS, IOpenInitAction} from '../../actions/modalForm/messageSendingForm';
import { Action } from 'redux';

export interface IState {
    isShow: boolean;
    isConfirmEmail: boolean;
    isForgetPassword: boolean;
}

export const initial: IState = {
    isShow: false,
    isConfirmEmail: false,
    isForgetPassword: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.MESSAGE_SENDING.INIT:
            const {isConfirmEmail, isForgetPassword} = action as IOpenInitAction
            return {
                ...state,
                isShow: true,
                isConfirmEmail: isConfirmEmail,
                isForgetPassword: isForgetPassword
            };

        case ACTIONS.MESSAGE_SENDING.DONE:
        {
            return initial;
        }
        default:
            return state;
    }
}
