import { put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/index';
import {actions} from "../../actions/index";
import {IErrorResponse, IUserSettings} from "../../../api/index";
import {IUserSettingsInitAction} from "../../actions/userSettings/view";

export function* sagaHandler(action: Action) {
    try {
        const { user } = action as IUserSettingsInitAction;
        const userSettings: IUserSettings = {
            language: user.language
        }

        yield put(actions.userSettings.view.done(userSettings));
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.userSettings.view.fail(c))
    }
}

export function* mappingUserSettingsSaga() {
    yield takeLatest(ACTIONS.userSettings.view.VIEW.INIT, sagaHandler);
}
