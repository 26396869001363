import React from 'react';
import './style.scss';
import DropdownMenuItem from '../DropDownMenuItem/DropdownMenuItem';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as H from 'history';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { IUserRead } from "../../../../api/user";
import { IState } from "../../../../redux/reducers";
import { actions } from "../../../../redux/actions";
import DropdownMenuDivider from '../../../UserSpace/ProjectDropdownMenu/DropdownMenuDivider';
import { Action } from 'redux';
import { IInvoice } from '../../../../api';
import cn from 'classnames';

interface IDropDownMenuState {
}

interface IStateToProps {
    user: IUserRead | null,
    isShow: boolean,

    lastUserInvoice: IInvoice | null;
    lastInvoiceInProgress: boolean;
}

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        user: state.currentUser.item,
        isShow: state.modalForm.dropDownMenu.isShow,
        lastUserInvoice: state.invoices.lastInvoice.item,
        lastInvoiceInProgress: state.invoices.lastInvoice.inProgress,
    };
}

interface IRouterProps {
    match: any;
    history: H.History;
    location: H.Location;
}

interface IDispatchProps {
    logout: () => void;
    hideMenu: () => void;
    fetchLastUserInvoice: (id: string) => Action;
}

const mapDispatchToProps: IDispatchProps = {
    logout: actions.auth.logout,
    hideMenu: actions.modalForm.dropDownMenu.close,
    fetchLastUserInvoice: actions.invoices.lastInvoice.fetch.init,
};

type IDropDownMenuProps = IRouterProps & WithTranslation & IDispatchProps & IStateToProps;
class DropDownMenu extends React.Component<IDropDownMenuProps, IDropDownMenuState> {
    constructor(props: IDropDownMenuProps) {
        super(props);
        this.state = { show: false, invertDropdownLogo: false };

        this.handleClick = this.handleClick.bind(this);
        this.handleHideMenu = this.handleHideMenu.bind(this);
    }

    componentDidMount() {
        const { user, fetchLastUserInvoice } = this.props;
        let userId;
        if (user && user.id) {
            userId = user.id.toString();
        }

        if (!!userId) {
            fetchLastUserInvoice(userId);
        }
    }

    handleClick() {
        const { logout } = this.props;
        if (logout) {
            logout();
        }
    }

    handleHideMenu() {
        this.props.hideMenu();
    }

    renderDropdownMenuItemPrices = () => {
        const { t, history, lastUserInvoice } = this.props;
        return (
            <div className={cn("user-profile-menu-item")}
                onClick={(e: any) => { e.stopPropagation(); history.push('/prices') }}>
                <div className={cn("user-profile-menu-item-title")} >
                    {t('user.dropdownmenu.yourPlan')}
                    <span className="your-plan-name" >
                        {lastUserInvoice && lastUserInvoice.tariff}
                    </span>
                </div>
            </div >
        );
    }

    render() {
        const { t, history, isShow, } = this.props;

        if (isShow) {
            return (<div className="user-menu" onMouseLeave={this.handleHideMenu}>
                <div className="user-menu-items">
                    <DropdownMenuItem title={t('user.dropdownmenu.dashboard')} onClick={(e: any) => { e.stopPropagation(); history.push('/dashboard') }} />

                    <DropdownMenuItem title={t('user.dropdownmenu.profile')} onClick={(e: any) => { e.stopPropagation(); history.push('/profile') }} />

                    {this.renderDropdownMenuItemPrices()}

                    <DropdownMenuDivider className={"user-menu-divider"} />

                    <DropdownMenuItem isCurrent={true} title={t('user.dropdownmenu.logout')} onClick={this.handleClick} />
                </div>
            </div>);
        }

        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DropDownMenu)));
