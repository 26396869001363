import { ACTIONS } from '../../actions/users/update';
import { Action } from 'redux';
import {IErrorResponse, IUserSettings} from '../../../api/index';
import { ISaveDoneAction } from "../../actions/userSettings/update";
import { IFailAction } from '../../../actions';

export interface IState {
    inProgress: boolean;
    error: IErrorResponse | null;
    item: IUserSettings | null;
}

export const initial: IState = {
    inProgress: false,
    error: null,
    item: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.SAVE.INIT:
            return {
                ...state,
                inProgress: true,
                error: null
            };

        case ACTIONS.SAVE.DONE:
            {
                const { item } = action as ISaveDoneAction;
                return {
                    ...state,
                    item,
                    inProgress: false,
                    error: null
                };
            }

        case ACTIONS.SAVE.FAIL:
            {
                const { error } = action as IFailAction;
                return {
                    ...state,
                    inProgress: false,
                    error: error
                };
            }
        case ACTIONS.SAVE.DONE:
            {
                const { error } = action as IFailAction;
                return {
                    ...state,
                    inProgress: false,
                    error: error
                };
            }
        
        default:
            return state;
    }
}
