import { Action } from 'redux';
import { actionsSet } from '../../../actions/index';
const prefix = 'FORGET_PASSWORD_FORM';

export const ACTIONS = {
    FORGET_PASSWORD_FORM: actionsSet(prefix, "")
};

export interface IOpenInitAction extends Action {
}

export interface ICloseDoneAction extends Action {
}

export const actions = {
    open: () => ({type: ACTIONS.FORGET_PASSWORD_FORM.INIT}) as IOpenInitAction,
    close: () => ({type: ACTIONS.FORGET_PASSWORD_FORM.DONE}) as ICloseDoneAction,
}
