export function simulateMouseDown(metaKey:boolean = false){
    const el = document.getElementById("work-canvas");
    if (el) {
        const newEvent = new MouseEvent("mousedown", {
            view: window,
            bubbles: true,
            cancelable: true,
            metaKey: metaKey // чтобы отличить фиктивный MouseDown от настоящего
          });
        el.dispatchEvent(newEvent); 
    }
}