import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/index';
import {forgetPassword} from "../../../api/userSecurity/index";
import {actions} from "../../actions/index";
import {IErrorResponse} from "../../../api/index";
import {IForgetPasswordInitAction} from "../../actions/userSecurity/forgetPassword";

export function* sagaHandler(action: Action) {
    try {
        const { userName } = action as IForgetPasswordInitAction;
        yield call(forgetPassword, userName);
        yield put(actions.userSecurity.forgetPassword.done());
        yield put(actions.modalForm.forgetPasswordForm.close());
        yield put(actions.modalForm.messageSendingForm.open(false, true))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.userSecurity.forgetPassword.fail(c))
    }
}

export function* forgetPasswordSaga() {
    yield takeLatest(ACTIONS.userSecurity.forgetPassword.FORGET_PASSWORD.INIT, sagaHandler);
}
