import { ACTIONS } from '../../actions/projects/delete';
import { Action } from 'redux';
import { IErrorResponse } from '../../../api/index';
import { ISaveInitAction } from "../../actions/projects/delete";
import { IFailAction } from '../../../actions';

export interface IState {
    inProgress: boolean;
    error: IErrorResponse | null;
    result: boolean;
    id: string
}

export const initial: IState = {
    id: "",
    inProgress: false,
    error: null,
    result: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.DELETE.INIT:
            const { id } = action as ISaveInitAction;
            return {
                ...state,
                id,
                inProgress: true,
                error: null
            };

        case ACTIONS.DELETE.DONE:
        {
            return {
                ...state,
                result: true,
                inProgress: false,
                error: null
            };
        }

        case ACTIONS.DELETE.FAIL:
        {
            const { error } = action as IFailAction;
            return {
                ...state,
                result: false,
                inProgress: false,
                error: error
            };
        }

        default:
            return state;
    }
}
