import React  from 'react';
import { FixedBlockExpandeIcon, FixedBlockCollapseIcon } from '../../icons';

interface IFixedBlockChildrenExpanderProps{
    collapsed:boolean;
    collapse: (e:any) => void;
}

export default function FixedBlockChildrenExpander(props: IFixedBlockChildrenExpanderProps){

    const {collapsed, collapse} = props;

    return <div className='block-title-expander' onClick={collapse}>
            {collapsed ? 
                <span><FixedBlockExpandeIcon/> expand</span> : 
                <span><FixedBlockCollapseIcon/> collapse</span> }  
        </div>
}