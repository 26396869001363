import { IPoint, IBlock } from "../../../models";

interface ISelectionSize{
    point: IPoint;
    width: number;
    height: number;
}

export function getSelectionSize(selection:string[]): ISelectionSize | undefined{

    if(document == null || selection.length == 0) return undefined;

    let x: number[] = [];
    let y: number[] = [];

    let w: number[] = [];
    let h: number[] = [];

    for(const id of selection){
       const domEl = document.getElementById(id);
       if(domEl==null) continue;

       const domElRect = domEl.getBoundingClientRect() as DOMRect;

       if(domElRect.x!==Infinity && domElRect.x!==-Infinity) x = [...x, domElRect.x];
       if(domElRect.y!==Infinity && domElRect.y!==-Infinity) y = [...y, domElRect.y];

       w = [...w, domElRect.right];
       h = [...h, domElRect.bottom];
    }

    if(x.length==0 || y.length==0) return undefined;
    
    const point = {x: Math.min(...x),y: Math.min(...y)}

    return {point, width:Math.max(...w) - point.x, height: Math.max(...h) - point.y};
}