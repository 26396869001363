import { combineReducers } from 'redux';
import * as view from '../userSettings/view';
import * as update from '../userSettings/update';

export interface IState {
    view: view.IState,
    update: update.IState
}

export const initial: IState = {
    view: view.initial,
    update: update.initial
}

export const reducer = combineReducers({
    view: view.reducer,
    update: update.reducer
});
