import { combineReducers } from 'redux';
import * as signIn from './signIn'


export interface IState {
    signIn: signIn.IState
}

export const initial: IState = {
    signIn: signIn.initial
}

export const reducer = combineReducers({
    signIn: signIn.reducer
});
