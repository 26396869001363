import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/users/currentUserTrialPeriod';
import { getUserTrialPeriod, } from "../../../api/user/index";
import { actions } from "../../actions/index";
import { IErrorResponse } from '../../../api';

export function* sagaHandler(action: Action) {
    try {
        const result: number = yield call(getUserTrialPeriod);
        yield put(actions.users.currentUserTrialPeriod.fetch.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.users.currentUserTrialPeriod.fetch.fail(c))
    }
}

export function* currentUserTrialPeriodSaga() {
    yield takeLatest(ACTIONS.FETCH.INIT, sagaHandler);
}
