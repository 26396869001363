import { Action } from "redux";
import { IBlock, IContent, ContentType, ContentStyle, IContentItem } from "../../models";
import { IPoint } from "../../models";

/*
 * action types
 */

export const BLOCK_MOVE = 'BLOCK_MOVE';
export const BLOCK_ADD = 'BLOCK_ADD';
export const BLOCKS_ADD = 'BLOCKS_ADD';
export const BLOCK_UPDATE = 'BLOCK_UPDATE';
export const BLOCK_UPDATE_VISUAL = 'BLOCK_UPDATE_VISUAL';
export const BLOCKS_REMOVE = 'BLOCKS_REMOVE';
export const BLOCK_REMOVE = 'BLOCK_REMOVE';
export const BLOCK_REMOVE_VISUAL = 'BLOCK_REMOVE_VISUAL';
export const BLOCK_RESET_SELECTION = 'BLOCK_RESET_SELECTION';
export const BLOCK_UPDATE_CONTENT_ITEMS = 'BLOCK_UPDATE_CONTENT_ITEMS';

/*
 * action creators
 */

 export interface IBlockMoveAction extends Action {
    id: string;
    newCoord: IPoint;
 }

 export interface IBlockAddAction extends Action {
    block: IBlock;
 }

 export interface IBlocksAddAction extends Action {
    blocks: IBlock[];
 }

 export interface IBlockRemoveAction extends Action {
    id: string;
 }

 export interface IBlocksRemoveAction extends Action {
    ids?: string[];
 }

 export interface IBlockResetSelectionAction extends Action {
     selection: string[];
 }

export interface IBlockUpdateContentItemsAction extends Action {
     id: string;
     items: IContentItem[][];
}


/*
 * action triggers
 */

export function blockMove(id:string, newCoord:IPoint) {
    return { type: BLOCK_MOVE, id, newCoord } as IBlockMoveAction;
}

export function blockAdd(block: IBlock) {
    return { type: BLOCK_ADD, block } as IBlockAddAction;
}

export function blocksAdd(blocks: IBlock[]) {
    return { type: BLOCKS_ADD, blocks } as IBlocksAddAction;
}

export function blockUpdate(block: IBlock, isVisual:boolean) {
    if(isVisual) return { type: BLOCK_UPDATE_VISUAL, block } as IBlockAddAction;
    return { type: BLOCK_UPDATE, block } as IBlockAddAction;
}

export function blockRemove(id: string, isVisual:boolean) {
    if(isVisual) return { type: BLOCK_REMOVE_VISUAL, id } as IBlockRemoveAction;
    return { type: BLOCK_REMOVE, id } as IBlockRemoveAction;
}

export function blocksRemove(ids?: string[]) {
    return { type: BLOCKS_REMOVE, ids } as IBlocksRemoveAction;
}

export function blockResetSelection(selection: string[]) {
    return { type: BLOCK_RESET_SELECTION, selection} as IBlockResetSelectionAction;
}

export function blockUpdateContentItems(id:string, items: any[]) {
    return { type: BLOCK_UPDATE_CONTENT_ITEMS, id, items} as IBlockUpdateContentItemsAction;
}

