export const MIN_HEIGHT_BLOCK = 30;
export const MIN_STEP = 15;
export const DEFAULT_BLOCK_WIDTH_1LVL: number = 330;
export const DEFAULT_BLOCK_WIDTH_2LVL: number = 540;
export const INPUT_LENGTH_1LVL = 33;

export const BLOCK_PLACEHOLDER_ID = "target-block-placeholder_ID";
export const white = '#b8b8b8';
export const red = '#ad3535';
export const orange = ' #b86a24';
export const gold = ' #bda52c';
export const green = '#418623';
export const light_blue = '#2384a4';
export const blue = '#3346a5';
export const purple = ' #7234b2';
export const pink = ' #ae386a';
export const grey = '#3b3b3b';