import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/trial/index';
import {actions} from "../../actions/index";
import {ISaveInitAction} from "../../actions/trial/edit";
import {IErrorResponse, IUserWrite} from "../../../api";
import {editTrial} from "../../../api/trial";
export function* sagaHandler(action: Action) {
    try {
        const { trial } = action as ISaveInitAction;
        const result: IUserWrite =  yield call(editTrial, trial);
        yield put(actions.trial.edit.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.trial.edit.fail(c))
    }
}

export function* editSaga() {
    yield takeLatest(ACTIONS.edit.SAVE.INIT, sagaHandler);
}
