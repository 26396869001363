import * as view from '../userSettings/view';
import * as update from '../userSettings/update';

export const ACTIONS = {
    view: view.ACTIONS,
    update: update.ACTIONS
};

export const actions = {
    view: view.actions,
    update: update.actions
};
