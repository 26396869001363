import { Action } from 'redux';
import * as ACTIONS from '../actions';
import { ElementBlockType, BlockType } from '../../models/block';
import { IPoint, DetailLevel } from '../../models';
import { MIN_STEP } from '../../consts';

interface IBlockCreatorTool {
    blockCreatorToolType: BlockType | ElementBlockType;
    templateId?: string;
}

interface IWorkCanvasTooltip {
    coord?: IPoint;
    text?: string;
}

export interface IState {
    workareaPosition: IPoint;
    workareaPositionDelta: IPoint;
    newPositionBlockPlaceHolder?: IPoint;
    blockCreatorTool: IBlockCreatorTool;
    editingTextBlockId?: string;
    catchedSelection: boolean;
    catchedSelectionMoveCancel: boolean;
    displayIdTypePopupMenu: string;
    closeAllPopupMenus: boolean;
    workCanvasTooltip: IWorkCanvasTooltip;
}

export const initial: IState = {
    workareaPosition: { x: 0, y: 0 },
    workareaPositionDelta: { x: 0, y: 0 },
    blockCreatorTool: { blockCreatorToolType: BlockType.SelectTool },
    editingTextBlockId: undefined,
    catchedSelection: false,
    catchedSelectionMoveCancel: false,
    displayIdTypePopupMenu: "",
    closeAllPopupMenus: false,
    workCanvasTooltip: {}
}

function calcDeltaOfStartPoint(position: IPoint) {
    return {
        x: position.x - Math.floor(position.x / MIN_STEP) * MIN_STEP,
        y: position.y - Math.floor(position.y / MIN_STEP) * MIN_STEP
    };
}

export const reducer = (state = initial, action: Action) => {

    switch (action.type) {

        case ACTIONS.APP_CHANGE_WORKAREA_POSITION:
            const { point } = action as ACTIONS.IAppChangeWorkareaPositionAction;

            return {
                ...state,
                workareaPosition: point,
                workareaPositionDelta: calcDeltaOfStartPoint(point)
            };

        case ACTIONS.APP_NEW_POSITION_BLOCK_PLACEHOLDER:
            const position = action as ACTIONS.IAppNewPositionBlockPlaceholderAction;

            return {
                ...state,
                newPositionBlockPlaceHolder: position.point
            };

        case ACTIONS.APP_SELECT_BLOCK_CREATOR_TOOL_TYPE:
            const { toolType, blockTemplateId } = action as ACTIONS.IAppSelectedBlockCreatorToolType;

            return {
                ...state,
                blockCreatorTool: {
                    blockCreatorToolType: toolType,
                    templateId: blockTemplateId
                }                
            };

        case ACTIONS.APP_EDIT_TEXT:
            const { editTextBlockId } = action as ACTIONS.IAppEditText;

            return {
                ...state,
                editingTextBlockId: editTextBlockId
            };

        case ACTIONS.APP_CATCHED_SELECTION:
            const { catchedSelection } = action as ACTIONS.IAppCatchedSelection;

            return {
                ...state,
                catchedSelection: catchedSelection
            };

        case ACTIONS.APP_CATCHED_SELECTION_MOVE_CANCEL:
            const { catchedSelectionMoveCancel } = action as ACTIONS.IAppCatchedSelectionMoveCancel;

            return {
                ...state,
                catchedSelectionMoveCancel: catchedSelectionMoveCancel
            };

        case ACTIONS.APP_DISPLAY_TYPE_POPUPMENU:
            const { displayId } = action as ACTIONS.IAppDisplayTypePopupMenu;

            return {
                ...state,
                displayIdTypePopupMenu: displayId
            };

        case ACTIONS.APP_CLOSE_ALL_POPUPMENUS:
            const { closeAllPopupMenus } = action as ACTIONS.IAppCloseAllPopupMenus;
            
            return {
                ...state,
                closeAllPopupMenus: closeAllPopupMenus
            };

            case ACTIONS.APP_WORKCANVAS_TOOLTIP:
                const { coord, text } = action as ACTIONS.IAppWorkCanvasTooltip;
                
                return {
                    ...state,
                    workCanvasTooltip: {coord, text}
                };

        default:
            return state;
    }
}