import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingPage from '../components/Landing/LandingPage';
import './App.scss';
import UserSpace from '../components/UserSpace';
import WorkCanvas from '../components.core/WorkCanvas';
import Feedback from '../components/Feedback';
import Prices from '../components/Prices';
import * as H from 'history';
import Heartbeat from "../components/HealthCheck";
import ActivationPage from "../components/Landing/ActivationPage";
import UserSubscription from '../components/UserSpace/UserSubscription';
import { Action } from "redux";
import * as actions from "../redux/actions";
import { IUserSettings } from "../api/user";
import { IState } from "../redux/reducers";
import { connect } from "react-redux";
import PrivacyPolicy from '../components/Legals/PrivacyPolicy';
import UserAgreement from '../components/Legals/UserAgreement';
import { mobileDeviceChecker } from './mobileDeviceChecker';
import {withRouter} from "react-router";

interface IAppProps {
    history: H.History;
}

interface IDispatchProps {
    saveUserSettings: (user: IUserSettings) => Action,
    getUserInfo: () => Action
}

interface IStateToProps {
    userSettings: IUserSettings | null,
    isAuthorized: boolean
}

const mapDispatchToProps: IDispatchProps = {
    saveUserSettings: actions.actions.userSettings.update.save.init,
    getUserInfo: actions.actions.users.currentUser.fetch.init
};

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        userSettings: state.userSettings.view.item,
        isAuthorized: state.auth.isAuthorized
    }
};

class App extends React.Component<IAppProps & IDispatchProps & IStateToProps> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        if (this.props.isAuthorized) {
            this.props.getUserInfo();
        }       
    }

    componentWillUnmount() {
        if (this.props.userSettings && this.props.userSettings !== null) {
            this.props.saveUserSettings(this.props.userSettings)
        }
    }

    getMobileBlankPage() {
        const MobileBlankPage = React.lazy(() => import('./MobileBlankPage/index'));
        return <React.Suspense fallback={<div>Loading...</div>}>
                    <MobileBlankPage/>
                </React.Suspense>;
    }
    
    desktopRender(){
        return (
            <>
                <Switch>
                    <Route exact={true} path={['/', '/resetPassword/:token']} component={LandingPage} />
                    <Route exact={true} path='/confirm' component={ActivationPage} />
                    <Route exact={true} path={["/tryitnow", '/project/:id/edit', '/presentation-mode/view/:projectId']} component={WorkCanvas} />
                    <Route path={['/dashboard', '/profile',]} component={UserSpace} />
                    <Route exact={true} path="/feedback" component={Feedback} />
                    <Route exact={true} path="/prices" component={Prices} />
                    <Route exact={true} path="/subscription" component={UserSubscription} />

                    <Route exact={true} path="/userAgreement" component={UserAgreement} />
                    <Route exact={true} path="/privacyPolicy" component={PrivacyPolicy} />

                </Switch>
                <Heartbeat />
            </>
        )
    }

    render() {

        return mobileDeviceChecker() ? this.getMobileBlankPage() : this.desktopRender();
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App) as any);