import React from 'react';
import './style.scss';
import { Spinner } from "../../Spinner";

interface ISubmitButtonProps {
    title: string
    classes?: string
    disabled?: boolean
    onSubmit: () => void
    inProgress?: boolean
    children?: any
}

export default class SubmitButton extends React.Component<ISubmitButtonProps> {

    constructor(props: any) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const { onSubmit } = this.props;
        if (onSubmit) {
            onSubmit();
        }
    }

    renderSpinner() {
        return this.props.inProgress ? <Spinner /> : null;
    }

    render() {
        const { title, disabled, classes, inProgress } = this.props;
        return (
            <div className={"submit-button"}>
                <input disabled={inProgress || disabled} className={classes ? classes : ""} type="button" value={title} onClick={this.onSubmit} />
                {this.renderSpinner()}
            </div>)
    }
}
