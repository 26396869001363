import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/projects/index';
import {deleteProjectsById, IProject, IProjectQuery} from "../../../api/projects/index";
import {actions} from "../../actions/index";
import {ISaveInitAction} from "../../actions/projects/delete";
import {IErrorResponse} from "../../../api";
import { deleteProject } from '../../../store/deleteProject';

export function* sagaHandler(action: Action) {
    try {
        const { id } = action as ISaveInitAction;
        const result: IProject =  yield call(deleteProjectsById, id);
        deleteProject(id);
        yield put(actions.projects.delete.done())
        const query: IProjectQuery | null = null;
        yield put(actions.projects.table.init(query));

    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.projects.delete.fail(c))
    }
}

export function* deleteSaga() {
    yield takeLatest(ACTIONS.delete.DELETE.INIT, sagaHandler);
}
