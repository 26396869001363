import { IErrorResponse } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';
import {IProject, IProjectQuery} from "../../../api/projects";

const prefix = 'PROJECTS';

export const ACTIONS = {
    VIEW: actionsSet(prefix, 'VIEW'),
    CLEAR:'PROJECTS_CLEAR'
};

export interface ISaveInitAction extends Action {
    id: string;
}

export interface ISaveDoneAction extends Action {
    item: IProject;
}

export const actions = {
    init: (id: string) => ({type: ACTIONS.VIEW.INIT, id}) as ISaveInitAction,
    done: (item: IProject) => ({type: ACTIONS.VIEW.DONE, item}) as ISaveDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.VIEW.FAIL, error}) as IFailAction,
    clear: () => ({type: ACTIONS.CLEAR})
}