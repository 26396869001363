import React from 'react';
import { MenuItem, BlockTypePopupMenu } from '../BlockTypePopupMenu';
import { InteractIcon, PopupIcon, FormIcon, TableIcon, GalleryIcon, TextIcon, TitleIcon, ListIcon, LinkIcon, ButtonIcon, ImageIcon } from '../../icons';
import { BlockType, IBlock, ElementBlockType } from '../../models';
import BaseBlock from '../../core/blocks/base';
import "./block-type-presenter.scss";
import { IState } from '../../redux/reducers';
import { connect } from 'react-redux';
import { Action } from 'redux';
import * as actions from '../../redux/actions'
import { getIconByType } from '../helpers/getIconByType';
import { getDefaultContent } from '../../core/blocks';

const width = 245;
const height = 350;

interface IBlockTypeMenuOwnProps {
  block: IBlock,
  change?(type: BlockType): (type: BlockType) => void
}

interface IBlockTypeMenuProps{
  displayIdPopupMenu: string;
}

const mapStateToProps = (state: IState): IBlockTypeMenuProps => {
  return {
    displayIdPopupMenu: state.app.displayIdTypePopupMenu
  };
};

interface IBlockTypeMenuOwnState {  
  position: string;
}

interface IDispatchProps {
  setDisplayIdPopupMenu: (displayId: string) => Action;
}

const mapDispatchToProps: IDispatchProps = {
  setDisplayIdPopupMenu: actions.appDisplayIdTypePopupMenu
};

type BlockTypeMenuProps = IBlockTypeMenuOwnProps & IBlockTypeMenuProps & IDispatchProps;

class BlockTypeMenu extends React.Component<BlockTypeMenuProps, IBlockTypeMenuOwnState>{
  constructor(props: BlockTypeMenuProps) {
    super(props);

    this.getMenuItems = this.getMenuItems.bind(this);
    this.selectBlockType = this.selectBlockType.bind(this);
    this.selectElementBlockType = this.selectElementBlockType.bind(this);
    this.showPopupMenu = this.showPopupMenu.bind(this);
    this.hidePopupMenu = this.hidePopupMenu.bind(this);

    this.state = { position: "default" };
  }

  showPopupMenu(event: any) {
    event.stopPropagation();

    if (this.props.displayIdPopupMenu==this.props.block.id) {
      this.setState({ ...this.state, position: "default" });
      this.props.setDisplayIdPopupMenu("");
      return;
    }

    let position = "default";
    if (event.clientX > width) {
      if(event.clientY > height/2 && event.clientY >  window.screen.availHeight - height/2){
        position = "default";
      }
      else if (event.clientY < height) {
        position = "right-down";
      }
      else  if (event.clientY >  window.screen.availHeight - height){
        position = "right-up";
      }
    }
    else if (event.clientX < window.screen.availWidth - width) {
      if(event.clientY > height/2 && event.clientY > window.screen.availHeight - height/2){
        position = "default";
      }
      else if (event.clientY < height) {
        position = "left-down";
      }
      else if (event.clientY >  window.screen.availHeight - height) {
        position = "left-up";
      }
    }
    this.props.setDisplayIdPopupMenu(this.props.block.id);
    this.setState({ ...this.state, position: position });
  }

  hidePopupMenu() {
    this.props.setDisplayIdPopupMenu("");
  }

  selectBlockType(event: any, newType: BlockType) {
    event.stopPropagation();
    const { block } = this.props;
    BaseBlock.hover(block, false);
    block.type = newType;
    block.subType = undefined;

    BaseBlock.updateBlock(block);
    this.hidePopupMenu();
  }

  selectElementBlockType(event: any, newSubType: ElementBlockType) {
    event.stopPropagation();
    const { block } = this.props;
    BaseBlock.hover(block, false);
    block.subType = newSubType;

    const defaultContent = getDefaultContent(newSubType);
    if (block.content && defaultContent) {
      block.content.comps = defaultContent.comps;
      block.content.lines = defaultContent.lines;
      block.content.style = defaultContent.style;
      block.content.type = defaultContent.type;
    }

    BaseBlock.updateBlock(block);
    this.hidePopupMenu();
  }

  getMenuItems() {
    return (<>
      <MenuItem icon={<TextIcon />} title="Text" onMouseDown={(event: any) => this.selectElementBlockType(event, ElementBlockType.Text)} />
      <MenuItem icon={<ListIcon />} title="List" onMouseDown={(event: any) => this.selectElementBlockType(event, ElementBlockType.List)} />
      <MenuItem icon={<LinkIcon />} title="Link" onMouseDown={(event: any) => this.selectElementBlockType(event, ElementBlockType.Link)} />
      <MenuItem icon={<ImageIcon />} title="Image" onMouseDown={(event: any) => this.selectElementBlockType(event, ElementBlockType.Image)} />
      <MenuItem icon={<GalleryIcon />} title="Gallery" onMouseDown={(event: any) => this.selectElementBlockType(event, ElementBlockType.Gallery)} />
      {/* <MenuItem icon={<TableIcon />} title="Table" onMouseDown={(event: any) => this.selectElementBlockType(event, ElementBlockType.Table)} />
      <MenuItem icon={<FormIcon />} title="Form" onMouseDown={(event: any) => this.selectElementBlockType(event, ElementBlockType.Form)} /> */}
    </>);
  }

  
  render() {
    const { position } = this.state;
    const { block, displayIdPopupMenu } = this.props;
    
    if (block.type === BlockType.Page)
      return <div className="block-type-icon" style={{visibility:block.isSelected?"hidden":"visible"}}>{getIconByType(this.props.block)}</div>
    if (block.type === BlockType.TemplateComposite || block.type === BlockType.TemplateFixed)
      return <div className="block-type-icon">{getIconByType(this.props.block)}</div>
    if (block.type === BlockType.Composite || block.type === BlockType.Fixed)
      return <div className="composite-block-type-icon">{getIconByType(this.props.block)}</div>
    if (block.type === BlockType.Section)
      return <></>;

    return (
      <BlockTypePopupMenu items={this.getMenuItems()} displayPopupMenu={displayIdPopupMenu == block.id} showPopupMenu={this.showPopupMenu} position={position}>
        {getIconByType(this.props.block)}
      </BlockTypePopupMenu>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockTypeMenu);