import { IErrorResponse, IUserQuery } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';
import { IInvoice } from '../../../api/invoices';

const prefix = 'INVOICE_CREATE';

export const ACTIONS = {
    SAVE: actionsSet(prefix, 'SAVE')
};

export interface ISaveInitAction extends Action {
    item: IInvoice;
}

export interface ISaveDoneAction extends Action {
    item: IInvoice;
}

export const actions = {
    save: {
        init: (item: IInvoice) => ({ type: ACTIONS.SAVE.INIT, item }) as ISaveInitAction,
        done: (item: IInvoice) => ({ type: ACTIONS.SAVE.DONE, item }) as ISaveDoneAction,
        fail: (error: IErrorResponse) => ({ type: ACTIONS.SAVE.FAIL, error }) as IFailAction
    }
}