import { BlockType } from "../../models";
import { MIN_STEP, MIN_HEIGHT_BLOCK, BLOCK_PLACEHOLDER_ID } from "../../consts";
import BaseBlock from "./base";

export function blockHeight(targetBlockId:string, withChildren:boolean = false, withPageTitle:boolean=true){
    const targetBlock = BaseBlock.getBlock(targetBlockId);

    let height = 0;

    if(!targetBlock || targetBlock.type == BlockType.Placeholder) return height;

    height = MIN_HEIGHT_BLOCK;

    if(targetBlock.type == BlockType.Page || targetBlock.type == BlockType.TemplateFixed) {
        height += MIN_STEP;
        if(!withPageTitle){
            height-= 3*MIN_STEP;
        }
    }
    else if (targetBlock.type === BlockType.Section || targetBlock.type === BlockType.TemplateComposite || targetBlock.type === BlockType.Fixed) {
        height += MIN_STEP;
        if(targetBlock.children.filter(id=>id!=BLOCK_PLACEHOLDER_ID).length==0 || targetBlock.type === BlockType.Fixed)
            height += MIN_STEP;
    }
    else if (targetBlock.type == BlockType.Element || targetBlock.type === BlockType.Composite) {

        if(targetBlock.parentId){
            const parentBlock = BaseBlock.getBlock(targetBlock.parentId);
            if(parentBlock 
                    && (parentBlock.type == BlockType.Section || parentBlock.type == BlockType.TemplateComposite || parentBlock.type == BlockType.Composite)){
                const indexInChildren = parentBlock.children.filter(id=>id!=BLOCK_PLACEHOLDER_ID).indexOf(targetBlockId);
                if(parentBlock.children.filter(id=>id!=BLOCK_PLACEHOLDER_ID).length - 1 == indexInChildren){ // последний Element в секции
                    height += MIN_STEP;
                }
            }
            else { // самостоятельный Element 
                height += 2 * MIN_STEP;
            }  
        }
        else { // самостоятельный Element 
            height += 2 * MIN_STEP;
        } 
    }    

    if(withChildren && targetBlock.type !== BlockType.Composite && targetBlock.type !== BlockType.Fixed){
        for(const b of targetBlock.children.filter(id=>id!=BLOCK_PLACEHOLDER_ID)){
            height += blockHeight(b, withChildren);
        }
    }

    return height;
}