import { ElementBlockType, BlockType, IPoint, IBlock } from "../../models";
import { store } from '../../store/configureStore';
import { appSelectBlockCreaterToolType } from "../../redux/actions";
import { MIN_STEP } from "../../consts";
import { createDefaultPageBlock } from "./createPageBlock";
import { createDefaultSectionBlock } from "./createSectionBlock";
import { createDefaultElementBlock } from "./createElementBlock";
import { getCreatorTool, getWorkareaPositionDelta } from "../../redux/selectors/app";
import { createDefaultCompositeBlock } from "./createCompositeBlock";
import { createDefaultFixedBlock } from "./createFixedBlock";

export default class BlockCreatorTool {

    selectTool(creationTool: BlockType | ElementBlockType, templateId?: string){
        store.dispatch(appSelectBlockCreaterToolType(creationTool, templateId));
    }

    createNewBlockBySelectionTool(creationPoint?: IPoint){

        const workareaPositionDelta = getWorkareaPositionDelta(store.getState());
        const {blockCreatorToolType, templateId} = getCreatorTool(store.getState());
        
        let coord = undefined;
        if(creationPoint)
            coord = { 
            x: Math.floor(creationPoint.x / MIN_STEP) * MIN_STEP + workareaPositionDelta.x, 
            y: Math.floor(creationPoint.y / MIN_STEP) * MIN_STEP + workareaPositionDelta.y };
        
        switch(blockCreatorToolType){
            case BlockType.Page:
                return createDefaultPageBlock(coord);
               
            case BlockType.Section:
                return createDefaultSectionBlock(coord);
            
            case BlockType.Composite:
                const compsiteBlock = createDefaultCompositeBlock(templateId, coord);
                if(compsiteBlock) return compsiteBlock;
                
                return [];

            case BlockType.Fixed:
                const fixedBlock = createDefaultFixedBlock(templateId);
                if (fixedBlock) return fixedBlock;

                return [];
                
            case ElementBlockType.Text:
                    return [createDefaultElementBlock(blockCreatorToolType, coord, undefined, "Some text")];            
            case ElementBlockType.List:
                    return [createDefaultElementBlock(blockCreatorToolType, coord, undefined, "List of …")];
            case ElementBlockType.Link:
                    return [createDefaultElementBlock(blockCreatorToolType, coord, undefined, "Link")];
            case ElementBlockType.Image:
                    return [createDefaultElementBlock(blockCreatorToolType, coord, undefined, "imageName.jpg")];
            case ElementBlockType.Gallery:
                    return [createDefaultElementBlock(blockCreatorToolType, coord, undefined, "Gallery of …")];
            case ElementBlockType.Table:
                    return [createDefaultElementBlock(blockCreatorToolType, coord, undefined, "Table title")];
            case ElementBlockType.Form:
                    return [createDefaultElementBlock(blockCreatorToolType, coord, undefined, "Form name")];
            case ElementBlockType.External:
                    return [createDefaultElementBlock(blockCreatorToolType, coord, undefined, "External source")];
                
        }
        return[];
    }

}