import {httpPost, httpPut} from "../common";
import {IChangePassword} from "../user";

export function forgetPassword(username: string) {
    return httpPost<any>(`api/userSecurity/forgetPassword`, { username }, { authorize: false });
}

export function resetPassword(token: string, password: string) {
    return httpPost<any>(`/api/userSecurity/resetPassword?token=${token}`, { password }, { authorize: false });
}

export function changePassword(passwords: IChangePassword) {
    return httpPut<any>(`/api/userSecurity/changePassword`, passwords, { authorize: true });
}

export function confirnEmail(code: string) {
    return httpPost<any>(`api/userSecurity/confirm?code=${code}`, {}, { authorize: false });
}
