import React from 'react';
import { IBlock, BlockType } from '../../models';
import * as d3 from 'd3';
import './styles/block-dl-title.scss';
import FixedBlockChildrenExpander from './FixedBlockChildrenExpander';

interface IComponentOwnState {
    value: string;    
}

interface IBlockTitleProps {
    block: IBlock;
    className: string;
    style: any;
    isPresentationMode: boolean;
    collapsed: boolean;
    collapse: (e:any) => void;
}

export default class BlockTitle extends React.Component<IBlockTitleProps, IComponentOwnState> {

    private component: any;

    constructor(props: any) {
        super(props);
        this.state = {value:""};
        this.onTitleChange = this.onTitleChange.bind(this);
        this.inputAutoResize = this.inputAutoResize.bind(this);        
    }

    componentDidMount() {
        this.setState({...this.state, value: this.props.block.title})
        this.inputAutoResize(this.props.block.title);
        if(this.props.block.title==""){
            const inputNode = this.component.select("input").node();
            inputNode.focus();
            inputNode.setSelectionRange(0, 0);
        }        
    }

    onTitleChange(e: any) {
        let value = e && e.target && e.target.value;

        if (e && e.value && !this.props.isPresentationMode) {
            value = e.value
        }

        this.setState({...this.state, value});
        this.inputAutoResize(value);
    }

    inputAutoResize(value: string) {
        this.component.select("input").style("width", ((value.length + 1) * 8) + "px");
    }

    render(){

        const { block, className, style, isPresentationMode } = this.props;
        let newStyle = {height: {...style.height}, padding: {...style.padding}};
        if(block.type === BlockType.Fixed && !this.props.collapsed){
            newStyle.height.height = 40;
            newStyle.padding.paddingTop = 5;
        }            

        return <div className={className} style={{...newStyle.height, ...newStyle.padding}} ref={handle => (this.component = d3.select(handle))}>

                <input disabled={isPresentationMode || block.type === BlockType.Fixed} value={this.state.value} onChange={this.onTitleChange} />

                {block.type === BlockType.Fixed && <FixedBlockChildrenExpander collapsed={this.props.collapsed} collapse={this.props.collapse} />}
            
               </div>;
    }
}