import { BlockType, ElementBlockType } from "../../../models";

export function getCursorClassName(creationTool?: BlockType | ElementBlockType){

      
    switch(creationTool){
        case BlockType.HandTool:
            return "cursor-hand-tool";

        case BlockType.SelectTool:
            return "cursor-select-tool";
            
        case BlockType.Page:
            return "cursor-page-tool";
      
        case BlockType.Section:
            return "cursor-section"

        case BlockType.Composite:
        case BlockType.Fixed:
            return "cursor-element-tool";

        case ElementBlockType.Text:
            return "cursor-text";
        case ElementBlockType.Table:
            return "cursor-table";
        case ElementBlockType.List:
            return "cursor-list";
        case ElementBlockType.Link:
            return "cursor-link";
        case ElementBlockType.Image:
            return "cursor-image";
        case ElementBlockType.Gallery:
            return "cursor-gallery";
        case ElementBlockType.Form:
            return "cursor-form";
        case ElementBlockType.External:
            return "cursor-external";
    }

    return "cursor-default";
}