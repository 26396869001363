import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/users/index';
import {actions} from "../../actions/index";
import {IErrorResponse} from "../../../api";
import {IConfirmInitAction} from "../../actions/users/confirm";
import {push} from "react-router-redux";
import {confirnEmail} from "../../../api/userSecurity";

export function* sagaHandler(action: Action) {
    try {
        const { token } = action as IConfirmInitAction;
        yield call(confirnEmail, token);
        yield put(push(`/`));
        yield put(actions.modalForm.signIn.open(true));
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.users.confirm.fail(c))
    }
}

export function* confirmSaga() {
    yield takeLatest(ACTIONS.confirm.CONFIRM.INIT, sagaHandler);
}
