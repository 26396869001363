import { IPoint, IBlock, ElementBlockType, BlockType } from "../../models";
import BaseBlock from "../blocks/base";
import TemplateFixedBlock from "../blocks/template-fixed";
import { createDefaultElementBlock } from "./createElementBlock";

export function createDefaultTemplateFixedBlock(title:string="Your new fixed block"){
    const newTemplate = new TemplateFixedBlock(title, undefined);
    BaseBlock.addBlocks([newTemplate]);
    return newTemplate as IBlock;
}

export function createTemplateFixedBlockWithChild(title?:string){
    const templateBlock = createDefaultTemplateFixedBlock(title);
    const child = createDefaultElementBlock(ElementBlockType.Text, undefined, templateBlock.id, "Some element")
    BaseBlock.addChild(templateBlock, child.id, 0);
    BaseBlock.updateBlock(templateBlock, true);

    return templateBlock;
}

export function createTemplateFixedBlock(id:string, title:string, children:string[], parentId?:string, position?:IPoint){
    const template = new TemplateFixedBlock(title, id, children);
    template.coord = position;
    template.parentId = parentId;
    return template as IBlock;
}