import { combineReducers } from 'redux';
import * as facebook from './facebook'
import * as google from './google'

export interface IState {
    facebook: facebook.IState,
    google: google.IState
}

export const initial: IState = {
    facebook: facebook.initial,
    google: google.initial
}

export const reducer = combineReducers({
    facebook: facebook.reducer,
    google: google.reducer
});
