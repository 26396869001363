import React from 'react';
import { IState } from '../../redux/reducers';
import { connect } from 'react-redux';
import { getSelectionSize } from './helpers/getSelectionSize';
import cn from 'classnames';
import './styles/selection.scss';
import { BlockType, ElementBlockType, MoveDirection, ITargetParentPosition, IPoint, IBlock } from '../../models';
import { Action } from 'redux';
import * as actions from '../../redux/actions'
import selectedBlocks  from '../../models/selection';
import { BlocksMovements } from '../../core/blocks-movements';
import { getCreatorToolType } from '../../redux/selectors/app';

interface ISelectionPresenterOwnProps {
    selection: string[];    
}

interface ISelectionPresenterProps {    
    creationTool: BlockType | ElementBlockType;
    placeHolderVisible: boolean;
    appEditTextRegime: boolean;
    catchedSelection: boolean;
    isPresentationMode: boolean;
}

const mapStateToProps = (state: IState): ISelectionPresenterProps => {
    return {        
        creationTool: getCreatorToolType(state),
        placeHolderVisible: state.app.newPositionBlockPlaceHolder ? true : false,
        appEditTextRegime: state.app.editingTextBlockId ? true : false,
        catchedSelection: state.app.catchedSelection,
        isPresentationMode: state.presentationMode.view.isPresentationMode
    };
};

interface IDispatchProps {
    newPositionBlockPlaceHolder: (point?: IPoint) => Action;
    catchedSelectionUpdate:(catched: boolean) => Action;
}

const mapDispatchToProps: IDispatchProps = {
    newPositionBlockPlaceHolder: actions.appNewPositionBlockPlaceHolder,
    catchedSelectionUpdate: actions.appCatchedSelection
};

class SelectionPresenter extends React.Component<ISelectionPresenterProps & ISelectionPresenterOwnProps & IDispatchProps> {

    private blocksMovements: BlocksMovements;

    constructor(props: any) {
        super(props);
        this.blocksMovements = new BlocksMovements(selectedBlocks);
        this.catchSelection = this.catchSelection.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.catchedSelectedBlocks = this.catchedSelectedBlocks.bind(this);
        this.state = {catchedSelection: false}
    }

    simulateCatch(){
         // Эмитируем захват. Необходимо, чтобы отработать ситуацию для перемещения одного блока
         const el = document.getElementById("selection");
         if (el) {
             const newEvent = new MouseEvent("mousedown", {
                 view: window,
                 bubbles: true,
                 cancelable: true
               })
             el.dispatchEvent(newEvent); 
         }
    }

    componentDidMount() {
        this.simulateCatch();
    }

    onClick(event: any) {
        event.stopPropagation();
        const el = document.elementsFromPoint(event.pageX, event.pageY)[1];
        if (el) {
            const newEvent = new MouseEvent("mousedown", event)
            el.dispatchEvent(newEvent); // Прокидываем событие нижележащим элементам (по z-index). Формально selection и блоки находятся на одном уровне
        }
    }

    onMouseDown(event:any){
        event.stopPropagation();
        this.catchSelection();        
    }

    catchedSelectedBlocks(catched: boolean){
        this.props.catchedSelectionUpdate(catched);
    }

    catchSelection() {
        const { creationTool, isPresentationMode} = this.props;
        if (creationTool == BlockType.SelectTool && !isPresentationMode) {
            this.blocksMovements.subscribeNecessaryMovementEvents("mouseup", this.catchedSelectedBlocks);           
        }
    }

    render() {
        const { selection, placeHolderVisible, appEditTextRegime, catchedSelection } = this.props;

        const selectionSize = getSelectionSize(selection);

        if (!selectionSize || placeHolderVisible || catchedSelection) return (<></>);

        let style = {
            left: selectionSize.point.x,
            top: selectionSize.point.y,
            width: selectionSize.width,
            height: selectionSize.height
        };

        const classNames = cn('selection', selection.length == 1 ? 'selection-transparent' : '', appEditTextRegime ? 'selection-pointer-events-none': '')

        return (<div id="selection" className={classNames}
            style={style}
            onMouseDown={this.onMouseDown}
            onClick={this.onClick}>

        </div>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionPresenter);