import React from "react";
import './style.scss';

interface IProps {
    onClick?: () => void
    checked: boolean
}

export default class Radio extends React.Component<IProps>{
    constructor(props:any){
        super(props);
        this.state = {disabled: false}
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        if (this.props.onClick)
        this.props.onClick();
    }

    render(){
        return <label className="radioButton-container">
            <input type="radio" name="radio" checked={this.props.checked} onChange={this.handleClick}/>
            <span className="checkmark"></span>
        </label>
    }
}