import React from 'react';

export default class PrivacyPolicy_RU extends React.Component {

    render(){

        return <div id="ua_wrapper">
                    <h1>User Agreement Vizls LLC</h1>
                    <h6>Last published: 06 October 2019</h6>
                    <h2>1. Miscellaneous</h2>
                    <p className="li-dot1">1.1.</p> <p className="li-item1">Pursuant to provisions of Articles 437 and 438 of the Civil Code of the Russian Federation these Terms and Conditions of Use (the “User Agreement”) are the offer from Vizls LLC, 129336, Moscow, Startovaya street, bld. 39, app. 81 (the “Administration”) that applies to any and all users joining this User Agreement and acting in their own interests or on behalf of a person such user represents (the “User”) on the following terms and conditions:</p>
                    <p className="li-dot1">1.2.</p> <p className="li-item1">With registration by completing the Registration special form available at <a href="http://vizls.com">https://vizls.com</a> the User  unconditionally and irrevocably agrees with (accepts) this User Agreement and gives its consent to its terms and conditions with no exceptions.</p>
                    <p className="li-dot1">1.3.</p> <p className="li-item1">This User Agreement comes into force from the date the User gives its consent to Clause 1.2. of this User Agreement.</p>
                    <p className="li-dot1">1.4.</p> <p className="li-item1">Definitions:</p>
                    <p className="li-item1">The following terms and definitions are used in this document and with respect to relationship between the parties to the User Agreement arising from or connected with this document:</p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Platform refers to software package of the Administration available at <a href="https://vizls.com">https://vizls.com</a>. </p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Profile (Account) refers to unique information about the User that is located on the Platform and explicitly identifies it. </p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Dashboard refers to the Platform’s part which helps the User to use the Platform’s tools.</p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Project refers to the end product resulting from the use of the Platform by the User.</p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Tariff refers to an amount of consideration paid for a certain scope of rights and services provided to the User pursuant to this User Agreement.</p>
                    
                    <h2>2. User Registration. User Profile </h2>
                    <p className="li-dot1">2.1.</p> <p className="li-item1">To use the Platform the User completes a registration procedure to create the User’s unique Profile to access the Dashboard.</p>
                    <p className="li-dot1">2.2.</p> <p className="li-item1">The User registers at <a href="https://vizls.com">https://vizls.com</a> by completing the Registration Form by entering its login (email) and a password. Upon registration the User may also indicate a name (nickname) and (or) upload an image (a photo) for its Profile (an avatar). Such name (a nickname) and such image may be included in materials published by the User while using the Platform’s services.</p>
                    <p className="li-item1">When the User inserts its photo as an image the User is aware and agrees that the Administration has the right to publish and further use the User’s image in the Platform’s services.</p>
                    <p className="li-dot1">2.3.</p> <p className="li-item1">The Administration reserves the right at any time to require the User to validate the details indicated for its Profile registration with respect to the Platform’s services (such as a bank card connected to its profile, if applicable) and containing User-related information  as well as other information with respect to use of services on the Platform that is provided to the User after it gets authorized for the Platform’s services  by entering its login and password.</p>
                    <p className="li-item1">To verify the User information the Administration has the right to request confirming documents (in particular, ID documents); a failure to submit them may be deemed by the Administration, at its sole discretion, as false information being provided; the Administration has the right, at its discretion, to deactivate or delete the User’s Profile and (or) deny the User its services (or its certain functions).</p>
                    <p className="li-dot1">2.4.</p> <p className="li-item1">All activities occurred in the Dashboard are deemed as accomplished by the User itself.</p>
                    <p className="li-dot1">2.5.</p> <p className="li-item1">The User is entirely responsible for: </p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">Security (including resiliency to guessing) of its Profile access tools (login and password) that the User selected and indicated for registration,</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">Adverse implications in case of a loss and/or transmission by the User of its Profile access tools to third parties.</p>
                    <p className="li-dot1">2.6.</p> <p className="li-item1">The User itself protects confidentiality of its Profile access tools. The Administration recommends that the User  should not deliver its Profile access tools to any third parties. If such delivery takes place the User familiarizes third parties with this User Agreement but the User is still liable for all their actions.</p>
                    
                    <p className="li-dot1">2.7.</p> <p className="li-item1">The Administration has the right to immediately deactivate the User’s Profile if: </p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">any breach by the User of this User Agreement is detected;</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">any violation by the User of Russian Federation legislative requirements is detected;</p>
                    <p className="li-dot1 sub">c.</p> <p className="li-item2">any claim is received from third parties with respect to infringement by the User of their rights;</p>
                    <p className="li-dot1 sub">d.</p> <p className="li-item2">any respective demands are received from state authorities;</p>
                    <p className="li-dot1 sub">e.</p> <p className="li-item2">in other cases as stipulated by this Agreement.</p>
                    <p className="li-dot1">2.8.</p> <p className="li-item1">Deactivation means the User’s inability to access the Profile on the Platform.</p>
                    <p className="li-dot1">2.9.</p> <p className="li-item1">The User has the obligation to take all efforts to remedy any detected violations.</p>
                    <p className="li-dot1">2.10.</p> <p className="li-item1">If deactivation takes place following a claim from third parties with respect to violation by the User of their rights the User has the right to continue using the Platform only if the Administration receives a written notification from a claim initiator that any dispute is resolved and a claim from third parties with respect to rights infringement is withdrawn.</p>
                    <p className="li-dot1">2.11.</p> <p className="li-item1">If any claims from state authorities including any demands (notifications, complaints) are received from the Federal Service for Supervision over Information Technologies, Communications and Mass Media (Roskomnadzor) the Administration deactivates the Profile of the infringer. Further, the Account will be reactivate only if the Administration receives an official notification from the above mentioned state authorities including Roskomnadzor that no claims and complaints exist against the User which Account is deactivated as well as against Projects created (posted) by such User.</p>
                    <p className="li-dot1">2.12.</p> <p className="li-item1">The Administration has the right to restore the User’s access to its Profile in case of its hacking, loss or change of a login/ password when the following conditions are satisfied all at once:</p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">The Profile is accessed on a Tariff-paid basis,</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">The User has accomplished all required actions as prescribed by the instruction on how to restore access if such is requested by the User. </p>
                    
                    <h2>3. Scope </h2>
                    
                    <p className="li-dot1">3.1.</p> <p className="li-item1">The Administration provides a non-exclusive license to use the Platform.</p>
                    <p className="li-dot1">3.2.</p> <p className="li-item1">The Administration provides Users with access to services including tools to prototype user interfaces, structure information, place, store and distribute its output. All Platform services existing as of the current moment including their development and / or addition of new services are regulated by this User Agreement.</p>
                    <p className="li-dot1">3.3.</p> <p className="li-item1">The User uses the Platform pursuant to this User Agreement and the Tariff it selects and pays a consideration amount unless otherwise is stipulated by the Tariff.</p>
                    <h2>4. General provisions on use of the Platform services</h2>
                    <p className="li-dot1">4.1.</p> <p className="li-item1">The User has the right to use the Platform pursuant to this User Agreement and legislation effective in the Russian Federation.</p>
                    <p className="li-dot1">4.2.</p> <p className="li-item1">By starting to use the Platform the User accepts the User Agreement in its entirety without any reservations and exceptions. If the User does not agree with any provisions of the User Agreement the User has no right to use the Platform.</p>
                    <p className="li-dot1">4.3.</p> <p className="li-item1">The User assumes all risks related to use of the Platform and services.</p>
                    <p className="li-dot1">4.4.</p> <p className="li-item1">The User itself is liable to third parties for its actions connected with the Platform’s use including if such action results in infringement of rights and legal interests of third parties as well as for compliance with legislation while using the Platform.</p>
                    <p className="li-dot1">4.5.</p> <p className="li-item1">The Platform and services are delivered on an “as is” and “as available” basis.</p>
                    <p className="li-dot1">4.6.</p> <p className="li-item1">The User has the right to use available functionalities of the Platform at its discretion and not violating this User Agreement.</p>
                    <p className="li-dot1">4.7.</p> <p className="li-item1">The User has no right to:</p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">make any attempt to avoid technical restrictions set by the Platform;</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">decompile, disassemble, decode and effect other actions with the Platform’s source code;</p>
                    <p className="li-dot1 sub">c.</p> <p className="li-item2">use the Platform to publish, distribute, store information that:</p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">Is illegal, malicious, threatening, slanderous, inciting to violence with respect to any person or a group of persons, cruel treatment of animals, encourages to commit illegal actions or explains how to use explosive substances and other weapons, violates common decency and moral and ethical norms, contains offensive language, promotes hate and/or discrimination as well as contains negative and critical comments relating to religion, politics, race, ethnic or gender features, personal traits, aptitudes, sexual orientation and personal appearance of third parties, includes offences targeting specific persons and organizations; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">May be interpreted as propaganda of certain political and religious views, nonstandard sexual orientation, violence, drug use, alcohol and tobacco smoke;</p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">Violates rights of persons under minimum age; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">Infringes rights of third persons to results of intellectual activities and equivalent identifications, rights to information that is a commercial secret, causes damage to honour, dignity and business reputation, violates national and international legislation;</p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">Contains data not authorized to be disclosed; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">Contains malicious software (viruses, worms, Trojan and other computer codes, files or programmes) intended to violate, destroy or restrict functionalities of any hardware or telecommunications equipment (or their portions) to gain an unauthorized access, to gain access to commercial software by presenting serial numbers, logins, passwords, programmes for their generation and other tools to obtain an unsanctioned access to paid Internet resources and to insert links to the above mentioned information; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">Is a spam, i.e. unsolicited messages of commercial and non-commercial messages in the form of (including but not limited to): advertisement of goods which circulation is prohibited or limited by the Russian legislation; messages of religious and / or religious and mystic content encouraging to further distribute such messages (chain letters); lists of third parties’ email addresses; multilevel marketing; internet-based jobs and online businesses; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">Violates the Russian legislation and international law norms; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">Can be used to illegally collect, store and process third party persona data;</p>
                    <p className="li-dot1 sub">d.</p> <p className="li-item2">impersonate any person or a representative of a company and / or association without due authorization to do so including employees of the Administration, moderators, website owners as well as use other forms and methods of unlawful representation of other persons in Internet and mislead users and the Administration with respect to attributes and features of any subjects or objects</p>
                    <p className="li-dot1 sub">e.</p> <p className="li-item2">interfere with the operating capability of the Platform including by placing elements that would impede information exchange on a real time basis, etc.;</p>
                    <p className="li-dot1 sub">f.</p> <p className="li-item2">insert links to resources which content run contrary to the Russian legislation and international law norms;</p>
                    <p className="li-dot1 sub">g.</p> <p className="li-item2">distribute false information about its affiliation with the Administration and/or its partners;</p>
                    <p className="li-dot1 sub">h.</p> <p className="li-item2">facilitate actions to violate restrictions and prohibitions set forth by this User Agreement and international law norms;</p>
                    <p className="li-dot1 sub">i.</p> <p className="li-item2">collect and store third party personal data unless permitted to do so;</p>
                    <p className="li-dot1 sub">j.</p> <p className="li-item2">otherwise violate legislative requirements including international law norms.</p>
                    <p className="li-dot1">4.8.</p> <p className="li-item1">The Administration has the right to restrict use of services for all Users or for certain categories of Users including access to some functionalities, a content storage period, etc.</p>
                    <p className="li-dot1">4.9.</p> <p className="li-item1">To protect the User’s equipment and its own equipment the Administration has the right to restrict receipt of messages / requests from Users when such messages / requests contain malware or rogue programmes or when the Platform’s automatic screening or antiviral protection software detect such malware or rogue programmes in such messages. The User is notified and agrees that the Platform has the right to analyze and investigate such malware and rogue programmes for such purpose to enhance the Platform’s automatic screening and antiviral protection software.</p>
                    <p className="li-dot1">4.10.</p> <p className="li-item1">The Administration has the right to deliver information messages to Users with the help of the Platform’s services. In accordance with Part 1 of Article 18 of the Federal Law “On Advertisement” the User hereby gives it consent to receive messages with advertising content. The User has the right to opt out such messages with advertising content via respective functionalities of the service for which and as part of which the User receives advertising messages.</p>
                    <p className="li-dot1">4.11.</p> <p className="li-item1">The User hereby consents that the Platform notifies other Users about the User’s public activities including placement of new messages and other activities occurred on the Platform.</p>
                    
                    <h2>5. Intellectual Property</h2>
                    <p className="li-dot1">5.1.</p> <p className="li-item1">The Administration owns the rights to the Platform and all its constituent elements: the source code, design database and know-how.</p>
                    <p className="li-dot1">5.2.</p> <p className="li-item1">All tools available in connection with the Platform’s services as well as any content placed in the Platform’s services are objects of exclusive rights of the Administration, Users and other right owners.</p>
                    <p className="li-dot1">5.3.</p> <p className="li-item1">Use of content and any other elements of the Platform’s services is possible only to the extent of the functionality available for a service. No elements of the Platform’s services are used otherwise without a prior permission from the owner. Such use refers to, but not limited to, duplication, copying, processing, distribution in any form, a frame presentation, etc. unless exceptions are explicitly stipulated by the Russian legislation or terms and conditions of use of the Platform’s respective service.</p>
                    
                    <h2>6. Rights of the Administration</h2>
                    <p>The Administration has the right to:</p>
                    <p className="li-dot1">6.1.</p> <p className="li-item1"> Provide advisory and technical support to the User.</p>
                    <p className="li-dot1">6.2.</p> <p className="li-item1">Change, modify and upgrade the Platform without any consent from and notification to the User.</p>
                    <p className="li-dot1">6.3.</p> <p className="li-item1">Impose any limits on the use of the Platform.</p>
                    <p className="li-dot1">6.4.</p> <p className="li-item1">Delete the User’s Profile when the following conditions are satisfied all at once: by time of such deletion the User is subscribed to the unpaid Tariff for six calendar months or more and does not use the Platform’s services.</p>
                    <p className="li-dot1">6.5.</p> <p className="li-item1">Deliver communications, notifications, requests, advertising and informational messages to the User. </p>
                    <p className="li-dot1">6.6.</p> <p className="li-item1">Provide information about webinars, video and other Platform-related information to the User.</p>
                    <p className="li-dot1">6.7.</p> <p className="li-item1">Receive access to the Dashboard of the User to monitor the Platform’s operation.</p>
                    <p className="li-dot1">6.8.</p> <p className="li-item1">Perform maintenance resulting in suspension of the Platform’s operation.</p>
                    <p className="li-dot1">6.9.</p> <p className="li-item1">The Administration has the right to lock the Project containing implicit advertisement of goods and services prohibited by the Russian legislation and international law norms.</p>
                    <p className="li-dot1">6.0.</p> <p className="li-item1">In the event of multiple locks of Projects or refusal to terminate any violation the Administration has the right, at its sole discretion, to delete the Profile together with all Projects and other information.</p>
                    
                    <h2>7. Tariffs</h2>
                    <p className="li-dot1">7.1.</p> <p className="li-item1">Services of the Platform are provided to the User both free of charge (the unpaid Tariff) and on the paid basis.</p>
                    <p className="li-item1">The list of Tariffs is available at <a href="https://vizls.com/prices">https://vizls.com/prices</a>. </p>
                    <p className="li-dot1">7.2.</p> <p className="li-item1">Tariffs may be changed by the Administration at its sole discretion. No adjustment will be made to the cost of the period that has been already paid for.</p>
                    <p className="li-dot1">7.3.</p> <p className="li-item1">After registration the User has the right to use the Platform free of charge. </p>
                    <p className="li-dot1">7.4.</p> <p className="li-item1">The User may switch to the paid Tariff by making the payment with one of the methods suggested by the Platform’s functionality. The User may switch between the paid Tariffs when the new Tariff is paid in its entirety.</p>
                    <p className="li-dot1">7.5.</p> <p className="li-item1">The Tariff is fully (100%) pre-paid.</p>
                    <p className="li-dot1">7.6.</p> <p className="li-item1">A payment date is a date when money is credited to the settlement account of the Administration.</p>
                    <p className="li-dot1">7.7.</p> <p className="li-item1">Payment obligations are not fulfilled if money is returned per requirement from a payment processor.</p>
                    <p className="li-dot1">7.8.</p> <p className="li-item1">A tariff amount includes a license fee (95%) and the cost of services (5%). A license fee is not subject to VAT in accordance with sub-clause 26 of Clause  2 of Article 149 of the Russian Tax Code.</p>
                    <p className="li-dot1">7.9.</p> <p className="li-item1">The Administration does not issue invoices to persons who are not VAT payers and tax payers released from responsibilities with respect to tax calculation and payment.</p>
                    <p className="li-dot1">7.10.</p> <p className="li-item1">If the User subscribes to the paid Tariff, its failure to use services does release the User from the obligation to pay for them.</p>
                    <p className="li-dot1">7.11.</p> <p className="li-item1">Upon expiration the Tariff is automatically renewed for a period equivalent to the previous one.</p> 
                    <p className="li-dot1">7.12.</p> <p className="li-item1">o make a payment the User may link its bank card to its Profile (the Linked Card). For the purpose of this User Agreement any bank card linked to the Profile of the User is defined as the Linked Card. The Administration has the right to debit from any Linked Card a consideration amount per Tariffs.</p>
                    <p className="li-item1">By entering the Linked Card details and continuing to use the Linked Card the User confirms and represents that the User has indicated the authentic and complete details of the valid bank card issued in the User’s name, that the User complies with the rules of international payment systems and requirements of an emitting bank that issued the Linked Card.</p>
                    <p className="li-dot1">7.13.</p> <p className="li-item1">By making the first payment of the Tariff the User provides the Administration and its partners with its consent to store information about its bank card and agrees that the Administration is authorized to debit money from the bank card to:</p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">Implement the Autopay function to debit payments stipulated by this User Agreement and the Tariffs without further authorization;</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">Repay the User’s debt under this User Agreement.</p>
                    <p className="li-dot1">7.14.</p> <p className="li-item1">If the User subscribes to the paid Tariff the Administration debits the respective amount from the User’s bank card in accordance with valid Tariffs on a payment date.</p>
                    <p className="li-dot1">7.15.</p> <p className="li-item1">The User has the right to cancel the Autopay through the Dashboard or by notifying the Administration about its refusal to renew the paid Tariff by email <a href="mailto:support@vizls.com">support@vizls.com</a> not later than three (3) business days prior to its expiration date. In this case the User is automatically switched to the unpaid Tariff.</p>
                    <p className="li-dot1">7.16.</p> <p className="li-item1">When switching from the paid Tariff to the unpaid Tariff the User reconciles its Dashboard with the terms and conditions on which the unpaid Tariff is provided.</p>
                    <h2>8. Information confidentiality and privacy</h2>
                    <p className="li-dot1">8.1.</p> <p className="li-item1">Relationship between the User and the Administration with respect to all information obtained by the Administration about the User in the course of the Platform use as well as personal data processing and protection are subject to the <a href='/privacyPolicy'>Privacy Policy</a>.</p>
                    <p className="li-dot1">8.2.</p> <p className="li-item1">If the User processes third parties’ personal data the User itself is responsible for taking proper measures to protect personal data pursuant to Federal Law No. 152-FZ dated 27 July 2006 “On personal data” and the General Data Protection Regulation (GDPR) and other laws and bylaws including with respect to relevant permissions and placement of required documents and information on the Website.</p>
                    
                    <h2>9. Guarantees and liabilities</h2>
                    <p className="li-dot1">9.1.</p> <p className="li-item1">The Administration does not provide the User with any explicit or implicit warranties with respect to the Platform and services and does not also guarantee (including but not limited to) that the Platform’s services match expectations and objectives of the User; that information is accurate and complete and does not guarantee  productivity, systemic integration, absence of errors, absence of viruses, lawfulness of use in any locations outside the Russian Federation.</p>
                    <p className="li-dot1">9.2.</p> <p className="li-item1">The User itself evaluates risks and entirely assumes responsibility for its actions in connection with the use of the Platform. The Administration is not liable for and does not pay any direct or indirect damages inflicted to the User and third parties as a result of use and inability to use of the Platform.</p>
                    <p className="li-dot1">9.3.</p> <p className="li-item1">The Administration is not liable for:</p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">inability to use the Platform for causes beyond the control of the Administration;</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">any actions and / or non-actions of service providers, services, networks, software or hardware;</p>
                    <p className="li-dot1 sub">c.</p> <p className="li-item2">misrepresentation, change or loss of the Project;</p>
                    <p className="li-dot1 sub">d.</p> <p className="li-item2">reliability of the login and /or password of the User;</p>
                    <p className="li-dot1 sub">e.</p> <p className="li-item2">unsanctioned and / or unlawful use by third parties of the login and / or password of the User;</p>
                    <p className="li-dot1 sub">f.</p> <p className="li-item2">any damage that may be caused by any devices and information carriers and / or software of the User due to use of the Platform and / or services.</p>
                    <p className="li-dot1">9.4.</p> <p className="li-item1">The Administration is not liable for any direct and indirect lost profits of the User. </p>
                    <p className="li-dot1">9.5.</p> <p className="li-item1">The liability of the Administration under the User Agreement does not exceed the amount of the Tariff paid by the User. </p>
                    <p className="li-dot1">9.6.</p> <p className="li-item1">If any complaints, claims or demands are issued against the Administration with respect to violation of third party rights related to breaches of the User’s guarantees, the User has the obligation to settle them and pay damages to the Administration, if any.</p>
                    <p className="li-dot1">9.7.</p> <p className="li-item1">All comments and / or complaints related to the operation of the Platform are submitted with the feedback form: <a href="https://vizls.com/feedback">https://vizls.com/feedback</a>.</p>
                    
                    <h2>10. Effective Period</h2>
                    <p className="li-dot1">10.1.</p> <p className="li-item1">This User Agreement takes effect from time the User accepts its terms and conditions and continues until the User deletes its Profile for reasons stipulated by Clause 10.2 of the Agreement.</p>
                    <p className="li-dot1">10.2.</p> <p className="li-item1">The Profile may be deleted:</p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">per request from the User,</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">for reasons stipulated by clauses 2.3., 6.4, 6.10 of this User Agreement.</p>
                    <p className="li-dot1">10.3.</p> <p className="li-item1">If the Profile with the activated paid Tariff is deleted, the license component of the consideration is not refunded. The User has the right to demand return of payment made for services over period past the date when the Profile is deleted. </p>
                    
                    <h2>11. Resolution of Disputes</h2>
                    <p className="li-dot1">11.1.</p> <p className="li-item1">Any disputes and controversies between the parties to the User Agreement in connection with or arising out of this User Agreement are settled by negotiations.</p>
                    <p className="li-dot1">11.2.</p> <p className="li-item1">If the parties to the User Agreement fail to come to an agreement, any disputes and controversies are resolved through a complaint procedure. Time to respond to a complaint is 30 days from the date it is received by the respective Party.</p>
                    <p className="li-dot1">11.3.</p> <p className="li-item1">If it is impossible to reach an agreement a dispute may be referred to court to be resolved in accordance with court jurisdiction rules stipulated by the Russian legislation.</p>
                    <p className="li-dot1">11.4.</p> <p className="li-item1">The Russian law is the governing law.</p>
                    
                    <h2>12. Concluding Provisions</h2>
                    <p className="li-dot1">12.1.</p> <p className="li-item1">This User Agreement may be altered and supplemented by the Administration at any time with no special notification to the User. The new wording of this User Agreement takes effect from time it is published on the Platform for public access at <a href="https://vizls.com">https://vizls.com</a>, unless otherwise is stipulated by the new wording of the User Agreement.</p>
                    <p className="li-dot1">12.2.</p> <p className="li-item1">If the Administration makes any amendments to the User Agreement with which the User does not agree the User discontinues the use of the Platform.</p>
                    <p className="li-dot1">12.3.</p> <p className="li-item1">By continuing the use of the Platform after any amendments and / or additions are made to this User Agreement the User accepts and agrees with such amendments and / or additions.</p>
                    <p className="li-dot1">12.4.</p> <p className="li-item1">This User Agreement is governed and construed in accordance with the Russian Federation legislation. Any matters not covered by this User Agreement are settled in accordance with the Russian Federation legislation.</p>
                    <p className="li-dot1">12.5.</p> <p className="li-item1">If any provisions of this User Agreement are recognized null and void in accordance with the Russian Federation legislation, the other provisions remain in full force and effect, and the User Agreement is fulfilled by the Parties in its entirety with such provision being excluded.</p>
                    <p className="li-dot1">12.6.</p> <p className="li-item1">Nothing in this User Agreement is understood to initiate between the User and the Administration any agency relations, partnership, joint venture, personal employment or any other relationship not directly stipulated by this User Agreement.</p>
                    <p className="li-dot1">12.7.</p> <p className="li-item1">This User Agreement is prepared in the Russian language; and upon request the User may be provided with its English translation for information purpose only. In case of any discrepancies between the Russian text and the text in any other language, the Russian text prevails.</p>
                </div>;
    }
}