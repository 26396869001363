import { IPoint } from "../../models";
import { store } from '../../store/configureStore';
import { appWorkareaPositionChange } from "../../redux/actions";
import { MIN_STEP } from "../../consts";

const minMouseDelta = MIN_STEP;

/*
 * Класс реализующий логику перемещения холста
 */
class CanvasMovementTool {

    private lastMousePosition: IPoint;
    private isChangeStartPoint: boolean;

    constructor() {
        this.lastMousePosition = { x: 0, y: 0 };
        this.isChangeStartPoint = false;
        this.onChangeStartPoint = this.onChangeStartPoint.bind(this);
        this.catchWorkarea = this.catchWorkarea.bind(this);
        this.dropWorkarea = this.dropWorkarea.bind(this);
        this.getCanvasPositionDelta = this.getCanvasPositionDelta.bind(this);
    }

    catchWorkarea(targetClassName: string) {
        if (!!targetClassName && targetClassName !== "" && ((targetClassName as any).animVal !== "")) {
            if (!targetClassName.includes("work-canvas")) {
                return;
            }
        }
        this.isChangeStartPoint = true;
    }

    dropWorkarea() {
        this.isChangeStartPoint = false;
        this.lastMousePosition = { x: 0, y: 0 };
    }

    getCanvasPositionDelta(){
        const { workareaPosition } = store.getState().app;
        return {
            x: workareaPosition.x - Math.floor(workareaPosition.x / MIN_STEP) * MIN_STEP, 
            y: workareaPosition.y - Math.floor(workareaPosition.y / MIN_STEP) * MIN_STEP
        };
    }

    getCanvasPosition(){
        const { workareaPosition } = store.getState().app;
        return {
            x: workareaPosition.x, 
            y: workareaPosition.y
        };
    }
    
    onChangeStartPoint(mousePosition:IPoint) {
        
        if (this.isChangeStartPoint) {

            const { workareaPosition } = store.getState().app;

            if (this.lastMousePosition.x === 0 && this.lastMousePosition.y === 0) {
                this.lastMousePosition = { x: mousePosition.x, y: mousePosition.y };                
            }
            const newPosition = { 
                x: mousePosition.x - this.lastMousePosition.x, 
                y: mousePosition.y - this.lastMousePosition.y 
            };
            this.lastMousePosition = { x: mousePosition.x, y: mousePosition.y };
            
            workareaPosition.x+=newPosition.x;
            workareaPosition.y+=newPosition.y;
            store.dispatch(appWorkareaPositionChange({ ...workareaPosition}));                
        }
    }

}

const canvasMovementTool = new CanvasMovementTool();
export default canvasMovementTool;