import { IErrorResponse} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';
import {IProject} from "../../../api/projects";

const prefix = 'PROJECTS_EDIT';

export const ACTIONS = {
    SAVE: actionsSet(prefix, 'SAVE')
};

export interface ISaveInitAction extends Action {
    id: string;
    item: IProject;
    fromForm: boolean;
}

export interface ISaveDoneAction extends Action {
    item: IProject;
}

export const actions = {
    init: (id: string, item: IProject, fromForm: boolean = false) => ({type: ACTIONS.SAVE.INIT, id, item, fromForm}) as ISaveInitAction,
    done: (item: IProject) => ({type: ACTIONS.SAVE.DONE, item}) as ISaveDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.SAVE.FAIL, error}) as IFailAction,

}
