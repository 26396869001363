import * as invoicesAction from './invoices';
import * as lastInvoice from './lastInvoice';
import * as tariff from './tariff';

export const ACTIONS = {
    CREATE: invoicesAction.ACTIONS,
    LAST_INVOICE: lastInvoice.ACTIONS,
    TARIFF: tariff.ACTIONS
};

export const actions = {
    create: invoicesAction.actions,
    lastInvoice: lastInvoice.actions,
    tariff: tariff.actions
};
