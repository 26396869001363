import { combineReducers } from 'redux';
import * as healthCheck from './healthCheck';

export interface IState {
    healthCheck: healthCheck.IState
}

export const initial: IState = {
    healthCheck: healthCheck.initial
}

export const reducer = combineReducers({
    healthCheck: healthCheck.reducer
});