import {IPoint, IBlock, BlockType, IContent} from "../../models";
import PageBlock from "../blocks/page";
import BaseBlock from "../blocks/base";
import {createDefaultSectionBlock} from "./createSectionBlock";
import { createTemplateFixedBlockWithChild } from "./createTemplateFixedBlock";
import { createDefaultFixedBlock } from "./createFixedBlock";

export function createDefaultPageBlock(position?:IPoint){
    const newPage = new PageBlock("Page N", position, undefined);
    const newSection = createDefaultSectionBlock(undefined, newPage.id);
    
    let templateHeader = getDefaultTemplateFixed("Header");
    if(!templateHeader)
        templateHeader = createTemplateFixedBlockWithChild("Header");
    
    let templateFooter = getDefaultTemplateFixed("Footer");
    if(!templateFooter)
        templateFooter = createTemplateFixedBlockWithChild("Footer");

    const header = createDefaultFixedBlock(templateHeader.id, newPage.id);
    const footer = createDefaultFixedBlock(templateFooter.id, newPage.id);
    
    if(header) BaseBlock.addChild(newPage, header[0].id, 0);
    BaseBlock.addChild(newPage, newSection[0].id, 1);
    if(footer) BaseBlock.addChild(newPage, footer[0].id, 2);

    BaseBlock.addBlock(newPage);
    
    if(header && footer)
    return [newPage as IBlock, ...newSection, header[0], footer[0]];
}

export function createPageBlock(title: string, id:string, content?: IContent, position?:IPoint, children: string[] = []){
    const emptyPoint: IPoint = {x: 0, y: 0}
    const newPage =  new PageBlock(title, position!== undefined ? position : emptyPoint, id, children);
    newPage.content = content;
    return newPage;
}

function getDefaultTemplateFixed(title:string){
    const res = BaseBlock.getBlocks().filter(b=>b.type === BlockType.TemplateFixed && b.title === title);
    if(res.length>0)
        return res[0];
}

