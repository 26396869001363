import { HotKeys, KeyMap } from 'react-hotkeys';
import React from "react";
import * as actions from '../../../redux/actions';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { BlockType, ElementBlockType, IPoint, DetailLevel } from '../../../models';
import { IState } from '../../../redux/reducers';
import selectedBlocks  from '../../../models/selection';
import { changeSelectedElementSubType, removeSeletedFixedTemplates, resetPlaceholder } from '../../../core/blocks';
import { createBlockByKeyClick } from '../../../core/creators/createBlockByKeyClick';
import canvasBlockCreator from './canvasBlockCreator';
import moveFocusUpDown, { Direction } from '../../../core/move-focus';
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { store } from '../../../store/configureStore';
import { getIsCatchedSelection, getCreatorToolType } from '../../../redux/selectors/app';
import { appCatchedSelectionMoveCancel } from '../../../redux/actions';
import BaseBlock from '../../../core/blocks/base';
import removeBlocks from '../../../core/remover/removeBlocks';
import newBlockCreate from '../../../models/creation';
import { resetTargetBlocks } from '../../../core/styles-reset';

interface IHotKeysOwnProps {
    detailLevel: DetailLevel;
}

interface IHotKeysProps {
    creationTool: BlockType | ElementBlockType;    
    appEditTextRegime: boolean;
}

const mapStateToProps = (state: IState): IHotKeysProps => {
    return {
        creationTool: getCreatorToolType(state),
        appEditTextRegime: state.app.editingTextBlockId ? true : false
    };
};

interface IDispatchHotKeysProps {
    appSelectBlockCreaterToolType: (toolType: BlockType | ElementBlockType) => Action;
    appNewPositionBlockPlaceHolder: (position?: IPoint) => Action;
    setAppEditTextRegime: (blockId?: string) => Action;
    undoChangesBlocks: () => Action;
    redoChangesBlocks: () => Action;
}

const mapDispatchToProps: IDispatchHotKeysProps = {
    appSelectBlockCreaterToolType: actions.appSelectBlockCreaterToolType,
    appNewPositionBlockPlaceHolder: actions.appNewPositionBlockPlaceHolder,
    setAppEditTextRegime: actions.appEditText,
    undoChangesBlocks: UndoActionCreators.undo,
    redoChangesBlocks: UndoActionCreators.redo
};

class WorkCanvasHotKeys extends React.Component<IHotKeysOwnProps & IDispatchHotKeysProps & IHotKeysProps> {

    private lastSelectedTool: any;
    private keysMap: KeyMap;
    private keysHandlers: { [key: string]: (keyEvent?: KeyboardEvent | undefined) => void; };

    constructor(props: any) {
        super(props);

        this.keysMap = {};
        this.keysHandlers = {};

        this.escapeHotKey = this.escapeHotKey.bind(this);
        this.grabbingTool = this.grabbingTool.bind(this);
        this.grabbingToolReset = this.grabbingToolReset.bind(this);
        this.deleteSelectedBlocks = this.deleteSelectedBlocks.bind(this);

        this.pageTool = this.pageTool.bind(this);
        this.sectionTool = this.sectionTool.bind(this);
        this.elementTextTool = this.elementTextTool.bind(this);

        this.selectTextSubType = this.selectTextSubType.bind(this);
        this.selectListSubType = this.selectListSubType.bind(this);
        this.selectLinkSubType = this.selectLinkSubType.bind(this);
        this.selectImageSubType = this.selectImageSubType.bind(this);
        this.elementTextTool = this.elementTextTool.bind(this);
        this.elementTextTool = this.elementTextTool.bind(this);
        this.elementTextTool = this.elementTextTool.bind(this);
        this.elementTextTool = this.elementTextTool.bind(this);

        this.appendNewElementBlock = this.appendNewElementBlock.bind(this);
        this.appendNewSectionBlock = this.appendNewSectionBlock.bind(this);

        this.moveFocusUp = this.moveFocusUp.bind(this);
        this.moveFocusDown = this.moveFocusDown.bind(this);

        this.undoChangesBlocks = this.undoChangesBlocks.bind(this);
        this.redoChangesBlocks = this.redoChangesBlocks.bind(this);

        this.init.bind(this)();
    }

    init() {
        this.keysMap = {
            "resetSelectedTool": ['esc','v'],
            'pageTool': 'a',
            'sectionTool': 's',
            'elementTextTool': 'd',
            'selectTextSubType': '0',            
            'selectListSubType': '1',
            'selectLinkSubType': '2',           
            'selectImageSubType': '3',            
            'appendNewElementBlock': 'enter',
            'appendNewSectionBlock': ['ctrl+enter', 'command+enter'],
            'deleteSelectedBlocks': ['del', 'backspace'],
            'grabbingTool': {sequence: 'space', action: 'keydown'},
            'grabbingToolReset': {sequence: 'space', action: 'keyup'},
            'moveFocusUp': ['ctrl+up', 'command+up', 'Command+up'],
            'moveFocusDown': ['ctrl+down', 'command+down'],
            'undoChangeBlocks': ['ctrl+z', 'command+z'],
            'redoChangeBlocks': ['ctrl+shift+z', 'command+shift+z'],
        };
        this.keysHandlers = {
            'resetSelectedTool': this.escapeHotKey,
            'pageTool': this.pageTool,
            'sectionTool': this.sectionTool,
            'elementTextTool': this.elementTextTool,
            'selectTextSubType': this.selectTextSubType,
            'selectListSubType': this.selectListSubType,
            'selectLinkSubType': this.selectLinkSubType,
            'selectImageSubType': this.selectImageSubType,
            'appendNewElementBlock': this.appendNewElementBlock,
            'appendNewSectionBlock': this.appendNewSectionBlock,
            'deleteSelectedBlocks': this.deleteSelectedBlocks,
            'grabbingTool': this.grabbingTool,
            'grabbingToolReset': this.grabbingToolReset,
            'moveFocusUp': this.moveFocusUp,
            'moveFocusDown': this.moveFocusDown,
            'undoChangeBlocks': this.undoChangesBlocks,
            'redoChangeBlocks': this.redoChangesBlocks
        };
    }

    undoChangesBlocks(){
        canvasBlockCreator.resetPreCreation();
        this.props.undoChangesBlocks();
        canvasBlockCreator.preCreationBlock();
    }

    redoChangesBlocks(){        
        canvasBlockCreator.resetPreCreation();
        this.props.redoChangesBlocks();
        canvasBlockCreator.preCreationBlock();
    }

    deleteSelectedBlocks(){       
        if(this.props.appEditTextRegime || this.props.detailLevel == 2) return;

        const blockCr = newBlockCreate.getLeadBlock();
        if(blockCr && (blockCr.type === BlockType.Composite || blockCr.type === BlockType.Fixed)){
            newBlockCreate.reset();
            this.props.appSelectBlockCreaterToolType(BlockType.SelectTool);
        } 
        
        const blocksToRemove = removeSeletedFixedTemplates();
        
        removeBlocks(selectedBlocks.getBlocks());
        selectedBlocks.reset();
        removeBlocks([...BaseBlock.getBlocks().filter(b=>b.isSelected), ...blocksToRemove]);
    }

    escapeHotKey(){
        // Отмена перемещения
        if(getIsCatchedSelection(store.getState())) {
            store.dispatch(appCatchedSelectionMoveCancel(true));
        }

        // Сброс инструмента создания блоков 
        this.props.appSelectBlockCreaterToolType(BlockType.SelectTool);
        this.props.appNewPositionBlockPlaceHolder(undefined);
        
        // Сброс выделения
        selectedBlocks.reset();
        BaseBlock.resetSelection(BaseBlock.getBlocks().filter(b=>b.isSelected).map(b=>b.id));
        
        resetPlaceholder();
        resetTargetBlocks();

        this.props.setAppEditTextRegime(undefined);
    }

    pageTool(){
        if(this.props.appEditTextRegime || this.props.detailLevel == 2) return;
        this.props.appSelectBlockCreaterToolType(BlockType.Page);
        this.props.appNewPositionBlockPlaceHolder(undefined);        
        canvasBlockCreator.preCreationBlock();
    }

    sectionTool(){
        if(this.props.appEditTextRegime || this.props.detailLevel == 2) return;
        this.props.appSelectBlockCreaterToolType(BlockType.Section);
        this.props.appNewPositionBlockPlaceHolder(undefined);
        canvasBlockCreator.preCreationBlock();
    }

    elementTextTool(){
        if(this.props.appEditTextRegime || this.props.detailLevel == 2) return;
        this.props.appSelectBlockCreaterToolType(ElementBlockType.Text);
        this.props.appNewPositionBlockPlaceHolder(undefined);
        canvasBlockCreator.preCreationBlock();
    }

    selectTextSubType(){
        if(this.props.appEditTextRegime || this.props.detailLevel == 2) return;
        changeSelectedElementSubType(ElementBlockType.Text);
    }

    selectListSubType(){
        if(this.props.appEditTextRegime || this.props.detailLevel == 2) return;
        changeSelectedElementSubType(ElementBlockType.List);
    }

    selectLinkSubType(){
        if(this.props.appEditTextRegime || this.props.detailLevel == 2) return;
        changeSelectedElementSubType(ElementBlockType.Link);
    }

    selectImageSubType(){
        if(this.props.appEditTextRegime || this.props.detailLevel == 2) return;
        changeSelectedElementSubType(ElementBlockType.Image);
    }

    appendNewElementBlock(){
        if(this.props.detailLevel == 2) return;
        createBlockByKeyClick(BlockType.Element);
    }

    appendNewSectionBlock(){
        if(this.props.detailLevel == 2) return;
        createBlockByKeyClick(BlockType.Section);
    }

    grabbingTool(){               
        if(this.props.creationTool!=BlockType.HandTool) this.lastSelectedTool = this.props.creationTool;
        this.props.appSelectBlockCreaterToolType(BlockType.HandTool);
    }

    grabbingToolReset(){
        this.props.appSelectBlockCreaterToolType(this.lastSelectedTool);
    }

    moveFocusUp(){
        if(this.props.detailLevel == 2) return;
        moveFocusUpDown(Direction.UP);
    }

    moveFocusDown(){
        if(this.props.detailLevel == 2) return;
        moveFocusUpDown(Direction.DOWN);
    }

    render() {
        
        return (
            <HotKeys keyMap={this.keysMap} handlers={this.keysHandlers}>
                {this.props.children}
            </HotKeys>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkCanvasHotKeys);
