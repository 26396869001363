import { IBlock, BlockType, IPoint, ElementBlockType, MoveDirection, TargetPosition, ITargetParentPosition, IContent, ContentType, ContentStyle, ContentComponentType, IContentItem, ContentTextDisplayedRows } from "../../models";
import { Guid } from "guid-typescript";
import { store } from '../../store/configureStore';
import { blockMove, blockRemove, blockAdd, blockUpdate, blockResetSelection, blocksAdd, blocksRemove, blockUpdateContentItems } from "../../redux/actions";
import { comparerBlockType } from "../comparer";
import selectedBlocks  from '../../models/selection';
import * as blocksSelectors from "../../redux/selectors/blocks";
import { BLOCK_PLACEHOLDER_ID } from "../../consts";

export function getHighestBlock(id:string): IBlock | undefined {
    const block = BaseBlock.getBlock(id);
    if(block && block.parentId){
        return getHighestBlock(block.parentId);
    }
    return block;
}

export default abstract class BaseBlock implements IBlock {
    id: string;
    title: string;
    readonly type: BlockType;
    readonly subType?: ElementBlockType;
    parentId?: string;
    coord?: IPoint;
    children: string[];
    private static readonly appStore: any = store;

    isSelected: boolean;
    isTargetBlock: boolean;
    isHover: boolean;

    templateId?: string;

    content?: IContent;

    constructor(title: string, type: BlockType, children: string[], id?:string, subType?: ElementBlockType, coord?: IPoint, parentId?: string, templateId?: string, content?:IContent) {
        this.id = id == undefined ? Guid.raw() : id;
        this.title = title;
        this.type = type;
        this.subType = subType;
        this.coord = coord;
        this.parentId = parentId;
        this.children = children;

        this.isSelected = false;
        this.isTargetBlock = false;
        this.isHover = false;

        this.templateId = templateId;

        this.content = content;
    }

    static remove(block:IBlock, isVisual:boolean=false){  
        // Перед удалением сбрасываем все стилистические свойства
        BaseBlock.updateBlock({...block, isSelected:false, isHover:false, isTargetBlock:false}, true);     
        for(const id of block.children){            
            const b = BaseBlock.getBlock(id);            
            if(b){
                // b.parentId = undefined;
                BaseBlock.remove(b, isVisual);
            } 
        }        
        BaseBlock.removeBlock(block.id, isVisual);

        // Подчищаем родителя
        if(block.parentId){
            const parent = BaseBlock.getBlock(block.parentId);
            if(parent){
                BaseBlock.removeChild(parent, block.id);                
                BaseBlock.updateBlock({...parent, isSelected:false, isHover:false, isTargetBlock:false}, true);
            }
        }
    }

    static removeChild(block:IBlock, childId: string) {
        if (!block.children) return;
        const index = block.children.indexOf(childId);
        if (index >= 0) {
            block.children.splice(index, 1);
        }
    }

    static addChild(block:IBlock, childId: string, index: number) {
        if (!block.children) return;
        block.children.splice(index, 0, childId);
    }

    static addBlock(block: IBlock) {
        BaseBlock.appStore.dispatch(blockAdd(block));
    }

    static addBlocks(blocks: IBlock[]) {
        BaseBlock.appStore.dispatch(blocksAdd(blocks));
    }

    static getBlock(id: string): IBlock | undefined {
        const block = blocksSelectors.getById(BaseBlock.appStore.getState(), id);
        if(block)
            return {...block, children: [...block.children]};
    }

    static getBlocks(): IBlock[] {
        const blocks = blocksSelectors.getAllPresentBlocks(BaseBlock.appStore.getState());
        return blocks.map(b=> {
            return {...b, children: [...b.children]}
        });
    }
    
    static resetSelection(selection: string[]=[]){
        if(selection.length == 0){
            selection = [...selectedBlocks.getSelectedBlocksId()];
            selectedBlocks.reset();
        }   
            
        BaseBlock.appStore.dispatch(blockResetSelection(selection));
    }

    static updateTitle(block:IBlock, newTitle: string) {
        BaseBlock.updateBlock({...block, title: newTitle});
    }

    static updateContentStyle(block:IBlock, style?:ContentStyle){
        if(block.content){
            block.content.style = style;
            BaseBlock.updateBlock(block);
        }
    }

    static updateContentDisplayedLines(block:IBlock, lines?:ContentTextDisplayedRows){
        if(block.content){
            block.content.lines = lines;
            BaseBlock.updateBlock(block);
        }
    }

    static updateContentType(block:IBlock, type?: ContentType){
        if(block.content){
            block.content.type = type;
            BaseBlock.updateBlock(block);
        }
    }

    
    static updateContentComps(block:IBlock, comps: ContentComponentType[]){
        if(block.content){
            block.content.comps = [...comps];
            BaseBlock.updateBlock(block);
        }
    }

    static updateContentItems(blockId: string, items:IContentItem[][]){
        BaseBlock.appStore.dispatch(blockUpdateContentItems(blockId, items)); 
    }

    static updateBlock(b: IBlock, isVisual:boolean = false) {
        BaseBlock.appStore.dispatch(blockUpdate(b, isVisual));        
    }

    static updateBlocks(someBlocks: IBlock[], isVisual:boolean = false) {
        someBlocks.map(b=>BaseBlock.updateBlock(b, isVisual));        
    }

    static removeBlock(id:string, isVisual:boolean) {
        BaseBlock.appStore.dispatch(blockRemove(id,isVisual));
    }

    static removeBlocks(ids?:string[]) {

        if(!ids)
            BaseBlock.appStore.dispatch(blocksRemove());

        else if(ids.length > 0)
            BaseBlock.appStore.dispatch(blocksRemove(ids));
    }

    static select(block:IBlock, value: boolean, addToSelection:boolean=true): void {   
        if(addToSelection && value){
            selectedBlocks.add(block.id);
        }     
        const factBlock = BaseBlock.getBlock(block.id);
        if(factBlock) {
            factBlock.isHover = false;        
            factBlock.isSelected = value;
            BaseBlock.updateBlock(factBlock, true);
        }        
    }

    static targetBlock(block:IBlock, value: boolean): void {
        block.isTargetBlock = value;
        block.isHover = false;
        BaseBlock.updateBlock(block, true);
    }

    static hover(block:IBlock, value: boolean): void {
        block.isHover = value;
        BaseBlock.updateBlock(block, true);
    }

    // Определяем позицию вставки
    // id - целевое место вставки - ЦМВ
    static getTargetPosition(block: IBlock, direction: MoveDirection, id: string): TargetPosition {

        // Если ЦМВ совпадает с текущим => все остается "как есть"
        if (block.id === id) return undefined;

        const getBlock = BaseBlock.getBlock;

        const targetBlock = getBlock(id);
        
        if (!targetBlock || targetBlock 
            && (comparerBlockType(targetBlock.type, block.type) === -1 && !targetBlock.parentId // Если ЦМВ меньше по вложенности и не имеет родителя
            || comparerBlockType(targetBlock.type, block.type) === 0 && !targetBlock.parentId)) // Если ЦМВ равно по вложенности и не имеет родителя
                return undefined;                                                             // => все остается "как есть"

        if (targetBlock.coord) {                                   // Если ЦМВ имеет координаты
            return { block: targetBlock, indexOfChildren: 0 };     // => вставка осуществляется в начало
        }

        if (direction == MoveDirection.Top) {                                                       // Навели на ВЕРХНЮЮ часть ЦМВ 
           
            if(targetBlock.parentId){
                const newPosition = BaseBlock.getParentOfTargetBlock(targetBlock.id, targetBlock.parentId) as ITargetParentPosition; 
                if(comparerBlockType(newPosition.block.type, block.type) <= 0) return undefined;  
                return { ...newPosition, indexOfChildren: newPosition.indexOfChildren > 0 ? newPosition.indexOfChildren - 1:newPosition.indexOfChildren};
            }                                    
            else {
                return {block: targetBlock, indexOfChildren: 0}
            }                                                          
                               
        }
        else if (direction == MoveDirection.Bottom) {                                               // Навели на НИЖНЮЮ часть ЦМВ 

            if (comparerBlockType(targetBlock.type, block.type) === 1) {
                return { block: targetBlock, indexOfChildren: 0 };
            }
            if (targetBlock.parentId && comparerBlockType(targetBlock.type, block.type) === 0) {     // ЦМВ имеет родитея и типы совпадают
                const newPosition = BaseBlock.getParentOfTargetBlock(targetBlock.id, targetBlock.parentId) as ITargetParentPosition;
                if (comparerBlockType(newPosition.block.type, block.type) <= 0) return undefined;
                return { ...newPosition, indexOfChildren: newPosition.indexOfChildren + 1 };
            }
            if (targetBlock.parentId && comparerBlockType(targetBlock.type, block.type) === -1) { // ЦМВ имеет родителя и тип ЦМВ меньше текущего типа
                const parentTargetBlock = getBlock(targetBlock.parentId);                     //   
                //
                if (parentTargetBlock && parentTargetBlock.parentId) {                            //
                    const parentParentTargetBlock = getBlock(parentTargetBlock.parentId);     // Осуществляем переопределение ЦМВ
                    if (parentParentTargetBlock && parentParentTargetBlock.children && comparerBlockType(parentParentTargetBlock.type, block.type) > 0) // ЦМВ = родитель ЦМВ
                        return { block: parentParentTargetBlock, indexOfChildren: parentParentTargetBlock.children.length };
                }
                else if (parentTargetBlock && parentTargetBlock.children && comparerBlockType(parentTargetBlock.type, block.type) > 0) {
                    return { block: parentTargetBlock, indexOfChildren: parentTargetBlock.children.length };
                }
            }
            if (targetBlock.parentId) {
                const newPosition = BaseBlock.getParentOfTargetBlock(targetBlock.id, targetBlock.parentId) as ITargetParentPosition;
                if (comparerBlockType(newPosition.block.type, block.type) <= 0) return undefined;
                return { ...newPosition, indexOfChildren: newPosition.indexOfChildren };
            }
        }
        else if (direction == MoveDirection.Baseline) {

            if (targetBlock.parentId) {

                const parentTargetBlock = getBlock(targetBlock.parentId);
                
                if (parentTargetBlock && parentTargetBlock.parentId) {

                    const newPosition = BaseBlock.getParentOfTargetBlock(parentTargetBlock.id, parentTargetBlock.parentId) as ITargetParentPosition;
                    if(comparerBlockType(newPosition.block.type, block.type) <= 0) return undefined;
                    return { ...newPosition, indexOfChildren: newPosition.indexOfChildren + 1 };
                }
                else if (parentTargetBlock && comparerBlockType(parentTargetBlock.type, block.type) > 0)
                    return { block: parentTargetBlock, indexOfChildren: parentTargetBlock.children.length };
            }
        }

        return undefined;
    }

    /*
     * Определяем место вставки и позицию ребенка
     */
    private static getParentOfTargetBlock(targetBlockId: string, targetParentId: string): TargetPosition {
        const targetParent = BaseBlock.getBlock(targetParentId);

        if (!targetParent) return undefined;

        const targetBlockIndex = targetParent.children.indexOf(targetBlockId);
        
        return { block: targetParent, indexOfChildren: targetBlockIndex };
    }

    static move(block:IBlock, position: TargetPosition): boolean {
        if (!position) return false;

        if (block.coord && (<IPoint>position).x && (<IPoint>position).y){
            const newCoord = position as IPoint;
            if(block.coord.x === newCoord.x && block.coord.y === newCoord.y)
                return false;
        }
        
        BaseBlock.updateBlock({...block, isSelected:false, isHover:false, isTargetBlock:false}, true);

        if ((<IPoint>position).x && (<IPoint>position).y) {
            
            // Сначала меняем координату
            if(!block.coord) {
                BaseBlock.updateBlock({...block, coord: position as IPoint});
            }
            else BaseBlock.appStore.dispatch(blockMove(block.id, position as IPoint));

            // Подчищаем родителя
            if (block.parentId) {
                const parent = BaseBlock.getBlock(block.parentId);
                if(parent){
                    const c = parent.children.indexOf(block.id);
                    parent.children.splice(c, 1);
                    block.parentId = undefined;
                    BaseBlock.updateBlocks([{...block, coord: position as IPoint}, {...parent, isSelected:false, isHover:false, isTargetBlock:false}], true);
                }               
            } 

            return true;
        }

        const target = <ITargetParentPosition>position;

        // Подчищаем целевой блок
        BaseBlock.updateBlock({
            ...target.block,
            children: [...target.block.children.filter(c => c != BLOCK_PLACEHOLDER_ID)],
            isSelected: false, isHover: false, isTargetBlock: false
        }, true);
        
        // Фиксируем состояние перемещаемого блока, если он был на холсте или у него был родитель
        let isRealBlock = false;
        if(block.coord || block.parentId) {
            isRealBlock = true;
            let childrenCopy:string[] = [];
            if(block.children) childrenCopy = [...block.children]
            BaseBlock.updateBlock({...block, children: childrenCopy, isSelected:false, isHover:false, isTargetBlock:false});
        }    
        
        const copyOfBlock = {...block, children: [...block.children]};
        let copyOfBlocks = [{...copyOfBlock, children: [...copyOfBlock.children]}];
        for(const b of copyOfBlock.children.map(c=>BaseBlock.getBlock(c))){
            if(b!=undefined)
                copyOfBlocks = [...copyOfBlocks, b];
        }

        BaseBlock.remove(block,true);

        copyOfBlocks[0].parentId = target.block.id;
        copyOfBlocks[0].coord = undefined;    
                       
        BaseBlock.removeChild(target.block, copyOfBlock.id);
        BaseBlock.addChild(target.block, copyOfBlock.id, target.indexOfChildren);
        copyOfBlocks.map(b=>BaseBlock.addBlock(b));

        if(isRealBlock) BaseBlock.updateBlock(target.block, true);
        else BaseBlock.updateBlock(target.block);

        return true;
    }

}