import React from "react";
import './style.scss'
import cn from "classnames";

interface IProps {
    path: string,
    isAdditional: boolean,
    isLoop: boolean,
    number: string,
    startBlock: number,
    endBlock: number,
    className?: any,
}

interface IState {
    className?: string
}

export default class Video extends React.Component<IProps, IState> {
    constructor(props:any){
        super(props);
        this.state = {className: undefined}
        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.setCurrentTime = this.setCurrentTime.bind(this);
        this.handleTimeUpdate = this.handleTimeUpdate.bind(this)
    }

    componentDidMount(){
        this.setState({...this.state, className: this.props.className})
        if (this.props.number && this.props.isAdditional){
            const vid = document.getElementById(this.props.number);
            if (vid !== null) {
                vid.onended = () => {
                    this.setState({...this.state, className: "hideVideo"})
                }

                vid.onplay = () => {
                    this.setState({...this.state, className: undefined})
                }
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.startBlock !== this.props.startBlock || prevProps.endBlock !== this.props.endBlock) {
            this.handlePlayTo(this.props.number);
        }
    }

    play(c: any){
        c.play();
    }

    pause(c: any){
        c.pause();
    }

    setCurrentTime(c: any, currentTime: number){
        c.currentTime = currentTime;
    }

    getCurrentTime(c: any): number{
        return c.currentTime;
    }

    handlePause(number: string){
        const vid = document.getElementById(number);
        if (vid !== null) {
            this.pause(vid);
        }
    }

    handlePlay(number: string){
        const vid = document.getElementById(number);
        if (vid !== null) {
            this.play(vid);
        }
    }

    handlePlayTo(number: string) {
        const video = document.getElementById(number);
        if (this.props.startBlock < 0 && this.props.endBlock < 0){
            return;
        }

        const startBlock = this.props.startBlock >=0 ? this.props.startBlock : 12 - (this.getCurrentTime(video)-4)/3
        if (video !== null) {
            this.setCurrentTime(video, startBlock)
            this.play(video);
        }
    }

    handleTimeUpdate(e: any){
        if (!this.props.isLoop) {
            const {endBlock} = this.props
            let time = e.target.currentTime;
            if (time >= endBlock - 0.1) {
                e.target.pause()
                e.target.currentTime = endBlock;
            }
        }
    }

    render(){
        return (<>
            {this.renderVideo()}
        </>);
    }

    renderVideo(){
        const className = this.state.className
        return <video id={this.props.number} className={cn("video", className)} autoPlay={true} muted loop={this.props.isLoop} preload="auto" onTimeUpdate={this.handleTimeUpdate}>
            <source src={this.props.path} type="video/mp4" /> Your browser does not support the video tag. I suggest you upgrade your browser.
        </video>
    }
}