import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/projects/index';
import { editProject, IProject, IProjectQuery} from "../../../api/projects/index";
import {actions} from "../../actions/index";
import {ISaveInitAction} from "../../actions/projects/edit";
import {IErrorResponse} from "../../../api";
import {IState} from "../../reducers/healthCheck/healthCheck";
import {store} from "../../../store/configureStore";
export function* sagaHandler(action: Action) {
    try {
        const { id, item, fromForm } = action as ISaveInitAction;
        const result: IState = store.getState().healthCheck.healthCheck.isPing;
        
        if (result) {
            const savedProject: IProject = yield call(editProject, id, item);
            yield put(actions.projects.edit.done(savedProject));     
            
            if(fromForm) {
                const query: IProjectQuery | null = null;
                yield put(actions.projects.table.init(query));
            }            

        } else {
            yield put(actions.projects.edit.done(item));            
        }

    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.projects.edit.fail(c))
    }
}

export function* editSaga() {
    yield takeLatest(ACTIONS.edit.SAVE.INIT, sagaHandler);
}
