import { IProject } from "../../../api/projects";
import { store } from '../../../store/configureStore';
import { blockAdd } from "../../../redux/actions";
import { actions as viewActions } from "../../../redux/actions/projects/view";
import { actions as presentationModeActions } from "../../../redux/actions/presentationMode/view";
import { actions as viewSettingsActions } from "../../../redux/actions/projectSettings/view";
import { actions as saveSettingsActions } from "../../../redux/actions/projectSettings/create";
import { actions as editTrialActions } from "../../../redux/actions/trial/edit";
import { actions as userTrialPeriodActions } from "../../../redux/actions/users/currentUserTrialPeriod";
import { createPageBlock } from "../../../core/creators/createPageBlock";
import { createSectionBlock } from "../../../core/creators/createSectionBlock";
import { createElementBlock } from "../../../core/creators/createElementBlock";
import { IBlock } from "../../../models";
import { ITrial } from "../../../api/trial";
import packageJson from '../../../../package.json';
import {IProjectSettings} from "../../../api/projectSettings";
import BaseBlock from "../../../core/blocks/base";
import { createCompositeBlock } from "../../../core/creators/createCompositeBlock";
import { createTemplateCompositeBlock } from "../../../core/creators/createTemplateCompositeBlock";
import {IPersistedProjectState, IPersistedState} from "../../../store/persistedStore";
import {createFixedBlock} from "../../../core/creators/createFixedBlock";
import { createTemplateFixedBlock } from "../../../core/creators/createTemplateFixedBlock";

export function updateBlocks(project: IProject) {
    BaseBlock.removeBlocks();
    const blocks = getBlocks(project);
    blocks.map(b => store.dispatch(blockAdd(b)));
}

function getBlocks(project: IProject): IBlock[] {
    const blocks: IBlock[] = JSON.parse(project.content);
    return blocks ? fillBlocks(blocks) : [];
}

function fillBlocks(blocks: IBlock[]): IBlock[] {
    return blocks && blocks.map(x => {
        switch (x.type) {
            case "page": {
                return createPageBlock(x.title, x.id, x.content, x.coord, x.children);
            }
            case "section": {
                return createSectionBlock(x.title, x.id, x.content, x.coord, x.parentId, x.children);
            }
            case "template_composite": {
                return createTemplateCompositeBlock(x.id, x.title, x.children, x.content, x.parentId, x.coord);
            }
            case "template_fixed": {
                return createTemplateFixedBlock(x.id, x.title, x.children, x.parentId, x.coord);
            }
            case "composite": {
                return createCompositeBlock(x.title, x.id, x.content, x.coord, x.parentId, x.children, x.templateId);
            }
            case "element": {
                return createElementBlock(x.title, x.id, x.content, x.subType, x.coord, x.parentId, x.children, x.templateId);
            }
            case "fixed": {
                const block = createFixedBlock(x.id, x.title, x.content, x.parentId, x.templateId);
                return block;
            }
            case "placeholder": {
                const block = BaseBlock.getBlock(x.id)
                return block ? block : createElementBlock(x.title, x.id, x.content, x.subType, x.coord, x.parentId, x.children);
            }
            default: {
                return createElementBlock(x.title, x.id, x.content, x.subType, x.coord, x.parentId, x.children);
            }
        }

    });
}

export function loadProject(id: string) {
    store.dispatch(viewActions.init(id));
}

export function loadStorageProject(projectId: string): IProject | null {
    const stateJson = localStorage.getItem(`VizlsApp_${projectId}`);
    if (!stateJson) {
        return null;
    }
    const state = JSON.parse(stateJson) as IPersistedProjectState;

    if (state.project == null) {
        return null;
    }

    const project: IProject = state.project;
    return project;
}


export function loadPresentationMode(id: string) {
    store.dispatch(presentationModeActions.init(id));
}

export function loadProjectSettings(id: string) {
    store.dispatch(viewSettingsActions.init(id));
}

export function saveProjectSettings(projectSettings: IProjectSettings) {
    store.dispatch(saveSettingsActions.init(projectSettings));
}

export function updateTrial(trial: ITrial): any {
    store.dispatch(editTrialActions.init(trial));
    store.dispatch(userTrialPeriodActions.fetch.init())
}

export function periodCounter() {
    const periodTrialSend = packageJson.periodTrialSend;
    if (periodTrialSend) {
        const timerId = setInterval(() => {
            const trial: ITrial = {
                period: 1
            }

            updateTrial(trial);
        }, periodTrialSend)

        return timerId;
    }
}