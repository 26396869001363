import { all, fork } from "redux-saga/effects";
import { viewSaga } from "./view";
import { shareSaga } from "./share";
import { closeSaga } from "./close";

export function* presentationModeSaga() {
    yield all([
        fork(viewSaga),
        fork(shareSaga),
        fork(closeSaga),
    ]);
}
