import { actions } from '../redux/actions';
import idbApp from "../indexeddb";
import { APP_STATE, store } from './configureStore';
import { IPersistedState } from './persistedStore';

export const loadStateAsync = async () => {

    const stateJson = await idbApp.get(APP_STATE)

    try {
        if (!stateJson) return
        const state = JSON.parse(stateJson) as IPersistedState;

        const currentAuth = {
            accessToken: state.auth.accessToken ? state.auth.accessToken : '',
            authenticationScheme: state.auth.authenticationScheme ? state.auth.authenticationScheme : '',
            refreshToken: state.auth.refreshToken ? state.auth.refreshToken : '',
            isAuthorized: !!state.auth.accessToken,
            expiresIn: 10000
        }
        store.dispatch(actions.auth.login.done(currentAuth));

        if (state.currentUser) {
            store.dispatch(actions.users.currentUser.fetch.done(state.currentUser));
        }
    }
    catch (ex) {
        console.log(ex);
    }
}