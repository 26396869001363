import React from 'react';
import './style.scss';
import DropdownMenuItem from './DropdownMenuItem';
import { withTranslation, WithTranslation } from 'react-i18next';
import DropdownMenuDivider from './DropdownMenuDivider';
import { ProjectItemMenuIcon } from '../../../icons';

interface IProjectDropdownMenuOwnProps {
    show: boolean;
    handleSettingsClick: (e: number) => void;
    handleEditClick: (e: number) => void;
    handleDeleteClick: (e: number) => void;
    hideMenu: (e: any) => void;
}

type ProjectDropDownMenuProps = WithTranslation & IProjectDropdownMenuOwnProps;

class ProjectDropdownMenu extends React.Component<ProjectDropDownMenuProps> {
    constructor(props: ProjectDropDownMenuProps) {
        super(props);

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleSettingsClick = this.handleSettingsClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    handleDeleteClick(e: any) {
        e.stopPropagation();
        this.props.handleDeleteClick(e);        
    }

    handleEditClick(e: any) {
        e.stopPropagation();
        this.props.handleEditClick(e);
    }

    handleSettingsClick(e: any) {
        e.stopPropagation();
        this.props.handleSettingsClick(e);
    }

    render() {
        const { t, show, hideMenu } = this.props;

        return (<div className="project-profile-menu">
            <div className="project-profile-menu-dropdown">
                <ProjectItemMenuIcon />
            </div>

            {show && <div className="project-profile-menu-items" onMouseLeave={hideMenu}>

                <DropdownMenuItem title={t('dashBoard.dropdownmenu.openProject')} onClick={this.handleEditClick} />

                <DropdownMenuItem title={t('dashBoard.dropdownmenu.editSettings')} onClick={this.handleSettingsClick} />

                {/* TODO: сделать members */}
                {/* <DropdownMenuItem title={t('dashBoard.dropdownmenu.members')} onClick={() => { }} /> */}

                <DropdownMenuDivider className="project-profile-menu-divider" />
                <DropdownMenuItem title={t('dashBoard.dropdownmenu.delete')} onClick={this.handleDeleteClick} />
            </div>}
        </div>);
    }
}

export default withTranslation()(ProjectDropdownMenu);
