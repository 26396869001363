import { IErrorResponse } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'HEALTH_CHECK';

export const ACTIONS = {
    HEALTH_CHECK: actionsSet(prefix, "")
};

export interface IHealthCheckInitAction extends Action {
}

export interface IHealthCheckDoneAction extends Action {
    isPing: boolean
}

export const actions = {
    init: () => ({type: ACTIONS.HEALTH_CHECK.INIT}) as IHealthCheckInitAction,
    done: (isPing: boolean) => ({type: ACTIONS.HEALTH_CHECK.DONE, isPing}) as IHealthCheckDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.HEALTH_CHECK.FAIL, error}) as IFailAction
}
