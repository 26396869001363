import {httpDelete, httpGet, httpPost, httpPut, IBaseRead, IPageResult} from '../common';
import * as queryString from "query-string";

export interface IProject  extends IBaseRead  {
    id: string;
    title: string;
    description: string;
    color: string;
    content: string;
    version: number;
    isActive: boolean
}

export interface IProjectQuery {
    title: string | null;
    description: string | null;
}

export function createProject(project: IProject) {
    return httpPost<IProject>('/api/project', project, { authorize: true });
}

export function editProject(id: string, project: IProject) {
    return httpPut<IProject>(`/api/project/${id}`, project, { authorize: true });
}

export function getProjects(projectQuery: IProjectQuery | null) {
    const query = projectQuery === undefined || projectQuery === null ? "" : '?' + queryString.stringify(projectQuery);
    return httpGet<IPageResult<IProject[]>>(`/api/project${query}`, { authorize: true });
}

export function getProjectsById(projectId: string) {
    return httpGet<IProject>(`/api/project/${projectId}`, { authorize: true });
}

export function deleteProjectsById(projectId: string) {
    return httpDelete(`/api/project/${projectId}`, { authorize: true });
}

