import React from 'react';
import cn from "classnames";

interface IProps {
    className: any
}

export default class DropdownMenuDivider extends React.Component<IProps> {

    render() {
        return (<div className={cn(this.props.className)}></div>);
    }
}
