import React from 'react';
import cn from 'classnames';
import { IBlock, ContentStyle, IContent, ElementBlockType, IContentItem } from '../../../../../models';
import { AddItemList } from '../../../../../icons';
import BaseBlock from '../../../../../core/blocks/base';
import { getDefaultContentItems } from '../../../../../core/blocks';
import GalleryItem from './GalleryItem';
import '../../styles/block-content-gallery.scss';
import './style.scss';


interface IGalleryOwnProps {
    blockId: string;
    isPresentationMode: boolean;
    content: IContent;
    classNames: string;
    updateValue: (value:IContentItem[][]) => void;
    selectBlock: () => void;    
}


export default class Gallery extends React.Component<IGalleryOwnProps> {

    constructor(props:any){
        super(props);

        this.galleryWrapper = this.galleryWrapper.bind(this);
        this.galleryItems = this.galleryItems.bind(this);
        this.addListItem = this.addListItem.bind(this);
        this.updateItem = this.updateItem.bind(this);
    }

    updateItem(items:IContentItem[][], item:IContentItem[], itemIndex: number){
        const newArr = [...items.map(arr=>arr.slice())];
        newArr[itemIndex] = [...item];
        this.props.updateValue(newArr);
    }

    galleryWrapper(){
        const { content, classNames } = this.props;
        let galleryDefaultStyle = 'gallery-style-none';
        if(content.style === ContentStyle.LegendOn)
            galleryDefaultStyle = 'gallery-style-square'
        
        return <ul className={cn(galleryDefaultStyle, classNames)}>
                    {this.galleryItems()}
               </ul>;
    }

    galleryItems(){
        const { content, isPresentationMode } = this.props;        

        return content
                    .items.map((v,i)=><GalleryItem key={i} isPresentationMode={isPresentationMode} 
                                        legendOn={content.style === ContentStyle.LegendOn}
                                        items={v} index={i} selectBlock={this.props.selectBlock} 
                                        updateValue={(v,i)=>this.updateItem(content.items,v,i)} />);
    }

    addListItem(event:any){
        event.stopPropagation();

        const { blockId, content } = this.props;;  

        const newItems = [...content.items.map(arr=>arr.slice()), getDefaultContentItems()];           
        BaseBlock.updateContentItems(blockId, newItems);

    }

    render(){

        const { isPresentationMode } = this.props;

        return <div>
                    {this.galleryWrapper()}       

                    {!isPresentationMode &&
                        <div className='gallery-add-item' onClick={this.addListItem}>
                            <AddItemList /> add item
                        </div>}                         
               </div>;
    }
}