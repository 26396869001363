import * as React from "react";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {IState} from "../../redux/reducers";
import SignUpForm from "./SignUpForm";
import SignInForm from "./SignInForm";
import ConfirmForm from "./MessageSendingForm";
import ForgerPasswordForm from "./ForgetPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";

interface IStateToProps{
    isShowSignInForm: boolean,
    isShowSignUpForm: boolean,
    isShowMessageSendingForm: boolean,
    isShowForgetPasswordForm: boolean,
    isShowResetPasswordForm: boolean,
    isAuthorized: boolean
}

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        isShowSignInForm: state.modalForm.signInForm.isShow,
        isShowSignUpForm: state.modalForm.signUpForm.isShow,
        isShowMessageSendingForm: state.modalForm.messageSendingForm.isShow,
        isShowForgetPasswordForm: state.modalForm.forgetPasswordForm.isShow,
        isShowResetPasswordForm: state.modalForm.resetPasswordForm.isShow,
        isAuthorized: state.auth.isAuthorized
    }
};

interface IStateProps {
    email: string;
    password: string;
    error: string | null;
}

class SignForm extends React.Component<WithTranslation & IStateToProps, IStateProps> {
    constructor(props:any){
        super(props);
    }

    renderSignUpForm(){
        if (this.props.isShowSignUpForm) {
            return <SignUpForm/>
        }
    }

    renderSignInForm(){
        if (this.props.isShowSignInForm) {
            return <SignInForm/>
        }
    }

    renderConfirmForm(){
        if (this.props.isShowMessageSendingForm) {
            return <ConfirmForm/>
        }
    }

    renderForgetForm(){
        if (this.props.isShowForgetPasswordForm) {
            return <ForgerPasswordForm/>
        }
    }

    renderResetPasswordForm(){
        if (this.props.isShowResetPasswordForm) {
            return <ResetPasswordForm/>
        }
    }

    render(){
        return (<div>
            {this.renderSignUpForm()}
            {this.renderSignInForm()}
            {this.renderConfirmForm()}
            {this.renderForgetForm()}
            {this.renderResetPasswordForm()}
        </div>);
    }
}

export default connect(mapStateToProps)(withTranslation()(SignForm));
