import { combineReducers } from 'redux';
import * as tarrifs from './tarrifs';

export interface IState {
    view: tarrifs.IState
}

export const initial: IState = {
    view: tarrifs.initial
}

export const reducer = combineReducers({
    view: tarrifs.reducer
});
