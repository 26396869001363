import React from 'react';
import {Action} from "redux";
import {IState} from "../../../redux/reducers";
import * as actions from "../../../redux/actions";
import {connect} from "react-redux";
import * as H from 'history';

interface IStateProps {
    isConfirm?: boolean;
    error: string | null;
    inProgress: boolean;
}

interface IDispatchProps {
    confirm: (token: string) => Action;
}

interface IRouterProps {
    history: H.History;
    location: H.Location & {
        search: {
            token?: string
        }
    };
}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        isConfirm: state.user.confirm.isConfirm,
        inProgress: state.user.confirm.inProgress,
        error: state.user.confirm.error
    };
};

const mapDispatchToProps: IDispatchProps = {
    confirm: actions.actions.users.confirm.init
};

class ActivationPage extends React.Component<IStateProps & IDispatchProps & IRouterProps, {}> {

    constructor(props:any){
        super(props);
    }

    componentDidMount() {
        if(this.props.location.search) {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const token = params.get('token');
            if(this.props.confirm && token){
                this.props.confirm(token);
            }
        }
    }

    render() {
        return (<div />);
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ActivationPage);