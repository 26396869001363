import {httpGet, httpPost, IBaseRead} from '../common';

export interface IInvoice extends IBaseRead {
    id?: string;
    userId: string;
    tariff: Tariff;
    expirationDate?: Date;
    paymentDate?: Date;
    status: InvoiceStatus;
    paymentValue: number;
}

export interface IInvoiceWithStatus {
    invoice: IInvoice;
    status: SubscriptionExpirationStatus;
}

export enum SubscriptionExpirationStatus {
    EXPIRED = "EXPIRED",
    ACTIVE = "ACTIVE"
}

export enum InvoiceStatus {
    NEW = "NEW",
    PAID = "PAID",
}

export enum Tariff {
    BASIC = "BASIC",
    PRO = "PRO",
    STUDIO = "STUDIO",
}

export interface ITariffSettings {
    tariff: Tariff;
    count_projects: number
}

export function postInvoices(item: IInvoice) {
    return httpPost<IInvoice>('/api/invoices', item, { authorize: true });
}

export function getLastInvoice(userId: string) {
    return httpGet<IInvoice>(`/api/invoices/lastInvoices/${userId}`, { authorize: true });
}

export function getTariff() {
    return httpGet<ITariffSettings>(`/api/invoices/tariff`, { authorize: true });
}
