import { all, fork } from "redux-saga/effects";
import {resetPasswordSaga} from "../userSecurity/resetPassword";
import {forgetPasswordSaga} from "../userSecurity/forgetPassword";
import {changePasswordSaga} from "../userSecurity/changePassword";

export function* userSecuritySaga() {
    yield all([
        fork(resetPasswordSaga),
        fork(forgetPasswordSaga),
        fork(changePasswordSaga)
    ]);
}