import React from 'react';
import './style.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import Discount from './Discount';
import Footer from "../Footer";
import * as H from 'history';
import { withRouter } from 'react-router';
import { IState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Header from "../Header";
import HeaderAuth from "../Header/HeaderAuth";
import SignForm from "../SignForm";
import SubmitButton from "../Buttons/SubmitButton";
import { Payment } from '../payment/payment';
import { IAppSettings } from '../../api/appSettings';
import { IUserRead, IInvoice, ITariffCurrency, InvoiceStatus, Tariff } from '../../api';
import { Action } from 'redux';
import { actions } from '../../redux/actions';
import { YourPlanIcon } from '../../icons';
import i18next from "i18next";

interface IStateProps {
    isAuthorized: boolean

    tariffCurrency: ITariffCurrency | null,
    user: IUserRead | null,
    trialPeriod?: number | null,
    appSettings?: IAppSettings | null;
    inProgress: boolean;
    error: string | null;
    lastUserInvoice: IInvoice | null;
    newUserInvoice: IInvoice | null;
    newInvoiceInProgress: boolean;

}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        isAuthorized: state.auth.isAuthorized,
        appSettings: state.appSettings.view.item,
        user: state.currentUser.item,
        tariffCurrency: state.tariffs.view.item,
        trialPeriod: state.currentUserTrialPeriod.period,
        inProgress: state.tariffs.view.inProgress,
        error: state.tariffs.view.error,
        lastUserInvoice: state.invoices.lastInvoice.item,
        newUserInvoice: state.invoices.create.item,
        newInvoiceInProgress: state.invoices.create.inProgress,
    };
};

interface IDispatchProps {
    fetchTariffs: (cultureInfo: string) => Action;
    fetchCurrentUser: () => Action;
    fetchCurrentUserTrialPeriod: (id: string) => Action;
    fetchAppSettings: () => Action;
    saveInvoice: (item: IInvoice) => Action;
    fetchLastUserInvoice: (id: string) => Action;
    showSignInForm: (isAfterConfirmEmail: boolean) => Action;
}

const mapDispatchStateToProps: IDispatchProps = {
    fetchAppSettings: actions.appSettings.view.fetch.init,
    fetchTariffs: actions.tariffs.view.fetch.init,
    fetchCurrentUser: actions.users.currentUser.fetch.init,
    fetchCurrentUserTrialPeriod: actions.users.currentUserTrialPeriod.fetch.init,
    saveInvoice: actions.invoices.create.save.init,
    fetchLastUserInvoice: actions.invoices.lastInvoice.fetch.init,
    showSignInForm: actions.modalForm.signIn.open
}

interface IRouterProps {
    match: any;
    history: H.History;
    location: H.Location;
}

interface IPricesState {
    selectedPlan: number;
    isProModalShow: boolean;
    isStudioModalShow: boolean;
    isShowBuyButton: boolean;
}

type IProps = WithTranslation & IRouterProps & IStateProps & IDispatchProps;

class Prices extends React.Component<IProps, IPricesState> {
    constructor(props: any) {
        super(props);

        this.state = {
            selectedPlan: 0,
            isProModalShow: false,
            isStudioModalShow: false,
            isShowBuyButton: true
        }
    }

    componentDidMount() {
        const { fetchTariffs, fetchLastUserInvoice, fetchAppSettings, user } = this.props;
        fetchTariffs(i18next.languages[0]);

        let userId;
        if (user && user.id) {
            fetchAppSettings();
            userId = user.id.toString();
        }

        if (!!userId) {
            fetchLastUserInvoice(userId);
        }
    }

    renderHeader() {
        if (this.props.isAuthorized) {
            return (<HeaderAuth />)
        } else {
            return (<Header />)
        }
    }

    renderSignForm() {
        return <SignForm />
    }

    buy = (selectedPlan: any) => {

        const { tariffCurrency, isAuthorized, user, saveInvoice, fetchLastUserInvoice } = this.props;
        const pricePro: number = tariffCurrency && tariffCurrency.tariffs.pro || 0;
        const PriceStudio: number = tariffCurrency && tariffCurrency.tariffs.studio || 0;
        if (isAuthorized) {
            let userId: string = '';
            if (user && user.id) {
                userId = user.id.toString();
            }

            const item: IInvoice = {
                userId,
                tariff: selectedPlan,
                status: InvoiceStatus.NEW,
                paymentValue: selectedPlan === Tariff.PRO ? pricePro : PriceStudio
            }

            saveInvoice(item);

            if (!!userId) {
                fetchLastUserInvoice(userId);
            }

            if (selectedPlan === Tariff.PRO) {
                this.setState({ isProModalShow: true });
            } else if (selectedPlan === Tariff.STUDIO) {
                this.setState({ isStudioModalShow: true });
            }
        } else {
            this.props.showSignInForm(false);
        }
    }

    render() {
        const { t, lastUserInvoice } = this.props;
        return (
            <>
                {this.renderHeader()}

                {this.renderSignForm()}

                <div className="prices legal-scroll">
                    <div className="prices__table">

                        <div className="row u-border-bottom-0" >
                            <div className="prices__table__price-col-name first-col">
                                {t('prices.pricesTable.th.prices')}
                            </div>
                            <div className="prices__table__basic-col-name sec-col">
                                {t('prices.pricesTable.th.basic')}
                            </div>
                            <div className="prices__table__pro-col-name third-col">
                                {t('prices.pricesTable.th.pro')}
                            </div>
                            <div className="prices__table__studio-col-name fourth-col">
                                {t('prices.pricesTable.th.studio')}
                            </div>
                        </div>

                        <div className="t-body">

                            <div className="row u-border-bottom-0 row-height-big">
                                <div className="first-col">
                                </div>
                                <div className="sec-col">
                                    <div className="free">
                                        {t('prices.pricesTable.tarrifs.basicPrice')}
                                    </div>
                                </div>
                                <div className="third-col">
                                    <div className="right-positioned">
                                        <div className="costs">
                                            <div className="costs__lined-throught">
                                                {t('prices.pricesTable.tarrifs.proPriceBefore')}
                                            </div>
                                            <div className="costs__now">
                                                {t('prices.pricesTable.tarrifs.proPriceAfter')}
                                            </div>
                                        </div>
                                        <div className="right-positioned">
                                            <Discount discountValue="75" />
                                        </div>
                                    </div>
                                </div>
                                <div className="fourth-col">
                                    <div className="right-positioned">
                                        <div className="costs">
                                            <div className="costs__lined-throught">
                                                {t('prices.pricesTable.tarrifs.studioPriceBefore')}
                                            </div>
                                            <div className="costs__now">
                                                {t('prices.pricesTable.tarrifs.studioPriceAfter')}
                                            </div>
                                        </div>
                                        <div className="right-positioned">
                                            <Discount discountValue="75" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="first-col">
                                    {t('prices.pricesTable.projectCount')}
                                </div>
                                <div className="sec-col">
                                    1  {t('prices.pricesTable.project')}
                                </div>
                                <div className="third-col">
                                    3 {t('prices.pricesTable.activeProjects')}
                                </div>
                                <div className="fourth-col">
                                    10 {t('prices.pricesTable.activeProjects')}
                                </div>
                            </div>

                            <div className="row">
                                <div className="first-col">
                                    {t('prices.pricesTable.projectArchive')}
                                </div>
                                <div className="sec-col">
                                    {t('prices.pricesTable.no')}
                                </div>
                                <div className="third-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="fourth-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                            </div>

                            <div className="row">

                                <div className="first-col">
                                    {t('prices.pricesTable.fixedAndCustomElements')}
                                </div>
                                <div className="sec-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="third-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="fourth-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                            </div>

                            <div className="row">

                                <div className="first-col">
                                    {t('prices.pricesTable.levelOfDetail')}
                                </div>
                                <div className="sec-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="third-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="fourth-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                            </div>

                            <div className="row">

                                <div className="first-col">
                                    {t('prices.pricesTable.presentationMode')}
                                </div>
                                <div className="sec-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="third-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="fourth-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                            </div>

                            <div className="row">
                                <div className="first-col">
                                    {t('prices.pricesTable.exportToFigma')}
                                </div>
                                <div className="sec-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="third-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="fourth-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                            </div>

                            <div className="row">
                                <div className="first-col">
                                    {t('prices.pricesTable.customProjectSettings')}
                                </div>
                                <div className="sec-col">
                                    &mdash;
                            </div>
                                <div className="third-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="fourth-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                            </div>

                            <div className="row">
                                <div className="first-col">
                                    {t('prices.pricesTable.teamWork')}
                                </div>
                                <div className="sec-col">
                                    &mdash;
                            </div>
                                <div className="third-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                                <div className="fourth-col">
                                    {t('prices.pricesTable.yes')}
                                </div>
                            </div>

                            <div className="row">
                                <div className="first-col">
                                    {t('prices.pricesTable.annotationsLevel')}
                                </div>
                                <div className="sec-col">
                                    &mdash;
                            </div>
                                <div className="third-col">
                                    {t('prices.pricesTable.comingSoon')}
                                </div>
                                <div className="fourth-col">
                                    {t('prices.pricesTable.comingSoon')}
                                </div>
                            </div>

                            <div className="row">
                                <div className="first-col">
                                    {t('prices.pricesTable.connections')}
                                </div>
                                <div className="sec-col">
                                    {t('prices.pricesTable.comingSoon')}
                                </div>
                                <div className="third-col">
                                    {t('prices.pricesTable.comingSoon')}
                                </div>
                                <div className="fourth-col">
                                    {t('prices.pricesTable.comingSoon')}
                                </div>
                            </div>
                        </div>

                        <div className="row u-border-bottom-0 right-align  row-height-big">
                            <div className="first-col" >
                            </div>
                            <div className="sec-col margin-top-small">
                                <div className="width-auto">
                                    {lastUserInvoice &&
                                        lastUserInvoice.tariff === Tariff.BASIC ?
                                        this.renderYourPlanDiv()
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                            <div className="third-col margin-top-small">
                                <div className="width-auto">
                                    {lastUserInvoice &&
                                        (lastUserInvoice.tariff === Tariff.PRO ?
                                            this.renderYourPlanDiv()
                                            :
                                            lastUserInvoice && lastUserInvoice.tariff !== Tariff.STUDIO
                                                ? <SubmitButton title={t('prices.pricesTable.upgradeButton')} classes="u-short-button"
                                                    onSubmit={() => this.buy(Tariff.PRO)} />
                                                : '')}

                                </div>

                            </div>
                            <div className="fourth-col margin-top-small"  >
                                <div className="width-auto">
                                    {lastUserInvoice &&
                                        lastUserInvoice.tariff === Tariff.STUDIO ?
                                        this.renderYourPlanDiv()
                                        :
                                        <SubmitButton title={t('prices.pricesTable.upgradeButton')} classes="u-short-button"
                                            onSubmit={() => this.buy(Tariff.STUDIO)} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                {this.state.isProModalShow && this.renderPaymentModal(Tariff.PRO)}
                {this.state.isStudioModalShow && this.renderPaymentModal(Tariff.STUDIO)}

                <Footer />
            </>
        );
    }

    renderPaymentModal = (curTarrif: any) => {
        const { appSettings, t, newInvoiceInProgress } = this.props;

        if (appSettings && newInvoiceInProgress === false) {
            return <Payment payment={this.setPayment(curTarrif)}/>
        }

        return null
    }

    setPayment = (curTarrif: any) => {
        const { appSettings, tariffCurrency, user, newUserInvoice, t } = this.props;
        const pricePro: number = tariffCurrency && tariffCurrency.tariffs.pro || 0;
        const priceStudio: number = tariffCurrency && tariffCurrency.tariffs.studio || 0;

        return {
            terminalkey: appSettings && appSettings.terminalId || '',
            amount: curTarrif === Tariff.PRO ? pricePro : priceStudio,
            description: curTarrif === Tariff.PRO ? t('prices.tariffProPaymentInfo') : t('prices.tariffStudioPaymentInfo'),
            order: (newUserInvoice && newUserInvoice.id) || '',
            currency: tariffCurrency ? tariffCurrency.type : "USD",
            name: user && user.name || '',
            email: user && user.email || ''
        };
    }

    renderYourPlanDiv = () => {
        const { t } = this.props;
        return (
            <div className="your-tariff-plan">
                < span className="your-tariff-plan__label" >
                    {t('prices.pricesTable.yourPlan')}
                </span >

                < span className="your-tariff-plan__icon">
                    <YourPlanIcon />
                </span >

            </div>);
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchStateToProps)(withTranslation()(Prices)));
