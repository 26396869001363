import { IErrorResponse, IUserQuery } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';
import { IFeedbackQuery } from '../../../api/feedback';

const prefix = 'SEND_FEEDBACK';

export const ACTIONS = {
    SEND: actionsSet(prefix, 'SEND')
};

export interface ISaveInitAction extends Action {
    item: IFeedbackQuery;
}

export interface ISaveDoneAction extends Action {
}

export const actions = {
    send: {
        init: (item: IFeedbackQuery) => ({ type: ACTIONS.SEND.INIT, item }) as ISaveInitAction,
        done: () => ({ type: ACTIONS.SEND.DONE }) as ISaveDoneAction,
        fail: (error: IErrorResponse) => ({ type: ACTIONS.SEND.FAIL, error }) as IFailAction
    }
}