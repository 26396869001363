import React from 'react';

export default class PrivacyPolicy_EN extends React.Component {

    render(){
        return <div id="pp_wrapper">
                    <h1>Privacy Policy Vizls LLC</h1>
                    <h6>Last published: 06 October 2019</h6>
                    <h2>1. Privacy Policy Scope</h2>
                    <p>This Privacy Policy (the “Policy”) is applied to all information about the User including personal data as defined by the Russian Federation legislation (the “Personal Data”)  that Vizls LLC (the “Company”) may obtain about the User when the User utilizes the software of the Company located at <a href="https://vizls.com">https://vizls.com</a>  (the “Platform”) and in the course of fulfillment of the Terms of Use made with the User in connection with the use of the Platform. </p>
          
                    <h2>2. Who Processes Information</h2>
                    <p>To make available the Platform and its Services the User’s Personal Data is be collected and utilized by Limited Liability Company Vizls, a legal entity established in the accordance with the Russian Federation legislation with the registered address at: 129336, Moscow, Startovaya street, bld. 39, app. 81. </p>
        
                    <h2>3. Privacy Policy Purpose</h2>
                    <p>It is the top priority for the Company to protect the Personal Data and Confidentiality of the User. When the User uses the Platform the Company protects and processes Personal Data in strict compliance with effective legislation.</p>
                    <p>To fulfill our obligations to protect the User’s Personal Data we intend to explicitly outline the following aspects in this Policy:</p>
                    <p className="li-dot">a.</p> <p className="li-item">Why and how the Company uses (“processes”) the Personal Data when the User utilizers the Platform;</p>
                    <p className="li-dot">b.</p> <p className="li-item">Role and obligations of the Company as a legal entity making a decision on why and how to process the Personal Data;</p>
                    <p className="li-dot">c.</p> <p className="li-item last-item">Rights of the User with respect to the Personal Data processing.</p>
                    
                    <h2>4. What personal data the Company collects</h2>
                    <p>The Company may collect the following categories of the Personal Data about the User in connection with the Platform Use:</p>
                    <p className="li-dot">I.</p> <p className="li-item">Personal Data provided for registration (Profile set-upn) such as a nickname and the User’s email address;</p>
                    <p className="li-dot">II.</p> <p className="li-item">PC-based data (HTTP titles, IP address, cookie files, browser identifier, hardware and software details);</p>
                    <p className="li-dot">III.</p> <p className="li-item last-item">Date and time when the Platform is accessed.</p>
                    <p>Also, the Company may use cookie files to collect the Personal Data and link such Personal Data with the User’s device and web browser (See Section 11 of this Policy).</p>
                    <p>The Company does not collect information defined as personal data in accordance with Federal Law No. 152-FZ dated 27 July 2006 “On personal data” (“Law No. 1520FZ”) but if the User provides such information on its own initiative the Company processes such personal data for the purpose to fulfill the Terms of Use as required by Law No. 152-FZ.</p>
                    <p>The Company does not collect sensitive Personal Data (relating to race, political views, health and biometrics data). </p>
                    <p>The Company does not gather data in order to compose the profile of the User to the extent which may significantly affect its right and freedoms in accordance with effective legislation.</p>
                    
                    <h2>5. Legal basis for the Personal Data Processing</h2>
                    <p>The Company has no right to process the Personal data without a sufficient legal basis. Therefore, the Company processes the Personal Data only if:</p>
                    <p className="li-dot">I.</p> <p className="li-item">Such processing is required to perform the Company’s contract obligations to the User including to ensure the full functionality of the Platform (e.g., after registration the User is able to use the Platform);</p>
                    <p className="li-dot">II.</p> <p className="li-item">Such processing is needed to comply with legislative requirements;</p>
                    <p className="li-dot">III.</p> <p className="li-item last-item">It is stipulated by law, processing is required to safeguard legal interests of the Company if such processing does not significantly affect the User’s interests, its fundamental rights and freedoms. Please note that in the course of Personal Data processing for the given reason the Company always seeks to maintain the balance between its legal interest and confidentiality protection.</p>
                    <p>The Company processes the Personal Data to safeguard its legal interests, e.g., in the following cases:</p>
                    <p className="li-dot">a.</p> <p className="li-item">to better understand how the User interacts with the Platform;</p>
                    <p className="li-dot">b.</p> <p className="li-item">to refine, modify, personalize or otherwise improve the Platform in the interests of all Users;</p>
                    <p className="li-dot">c.</p> <p className="li-item last-item">in some certain cases we may request a separate consent from the user to the Personal Data processing.</p>
                    <p>The Company always processes the Personal Data for certain purposes and to the extent that relates to fulfilment of such purposes. So, we process the Personal Data with the following purposes:</p>
                    <p className="li-dot">I.</p> <p className="li-item">to provide the User with access to the Platform;</p>
                    <p className="li-dot">II.</p> <p className="li-item">to provide the User with access to the Profile;</p>
                    <p className="li-dot">III.</p> <p className="li-item">to communicate with the User to deliver notifications, requests and information related to the operation of the Platform, fulfill the Terms of Use made with the user and to process its request;</p>
                    <p className="li-dot">IV.</p> <p className="li-item">to deliver advertising messages and proposals unless the User refuses to receive such messages;</p>
                    <p className="li-dot">V.</p> <p className="li-item last-item"> to protect the rights of the User and the rights of the Company.</p>
                    
                    <h2>6. Personal Data Protection</h2>
                    <p>In most cases the Personal Data is processed in an automatic mode without access of any employees of the Company to it. If such access is required it can be provided only to those employees of the Company who need it to perform their tasks. In order to protect and safeguard data confidentiality all employees complies with internal rules and procedures related to the Personal Data processing. They also observe all technical and organizational security measures that are in place to protect the Personal Data.</p>
                    <p>The Company have introduced sufficient technical and organizational measures to protect the Personal Data from unsanctioned, accidental or unlawful destruction, loss, change unfair use, disclosure or access and other illegal methods of processing. These security measures have been implemented based on the state-of-the-art hardware, implementation cost, risks associated with processing and nature of the Personal Data. </p>
                    
                    <h2>7. Third Party Access to Personal Data</h2>
                    <p>The Company can disclose the Personal Data to third parties only to fulfill the purposes stipulated by Section 5 of this Policy. Such third parties may include:</p>
                    <p className="li-dot">I.</p> <p className="li-item">state and / or international regulatory authorities, law-enforcement bodies, central or local executive power bodies, other official or state authorities or courts to which the Company is obliged to provide information upon respective request as legally required;
                    </p>
                    <p className="li-dot">II.</p> <p className="li-item">third parties if the User has provided its consent to disclose the Personal Data or if Personal Data provision is required to provide the User with the relevant Service or to fulfill a certain contract or agreement made with the User;</p>
                    <p className="li-dot">III.</p> <p className="li-item last-item">any other third parties in order to legally protect the Company or third parties if the User violates the Terms of Use, this Policy or in the case of such violation threat.</p>
                    
                    <h2>8. Where Personal Data is Stored and Processed</h2>
                    <p>The Personal Data related to the category of personal data as defined by Law No. 152-FZ is stored in the Russian Federation. The Company records, classifies, collects, stores, specifies (updates, changes), and retrieves personal data of Russian Federation citizens using databases located in the territory of the Russian Federation.</p>
                    <p>Other Personal Data may be stored and processed by the Company both in the territory of the Russian Federation and abroad.</p>
                    <h2>9. How Long We Will Keep the Personal Data</h2>
                    <p>The Company stores the Personal Data for such a period of time that is required to fulfill the purpose for which such data is collected or to comply with legislative requirements and regulatory acts.</p>
                    <p>If the User wishes any Personal Data to be deleted from the Company’s databases, the User  may itself delete the relative Personal Data via its Profile and submit the relevant request to the Company (if applicable). It is understood that if such Personal Data as a login and (or) password is removed the User’s Profile will be deleted.</p>
                    <h2>10. User’s Rights</h2>
                    <h3>10.1. The User has the right to access its Personal Data processed by the Company as required by this Policy.</h3>
                    <p>If the User believes that any information stored by the Company with respect to the User is wrong or incomplete the User may access its Profile to correct itself the Personal Data.</p>
                    <p>The User has the right to:</p>
                    <p className="li-dot dot">•</p> <p className="li-item">Require the Personal Data to be deleted and (or) withdraw its consent to personal data processing;</p>
                    <p className="li-dot dot">•</p> <p className="li-item">Demand that Personal Data processing be restricted;</p>
                    <p className="li-dot dot">•</p> <p className="li-item last-item">Object to Personal Data processing if stipulated by legislation.</p>
                    <p>The Company fulfills such requests pursuant to effective legislation.</p>
                    <p className="last-item">In cases set forth by legislation the User may have other rights not listed above.</p>
                    <h3>10.2 The User exercises its rights in the following way:</h3>
                    <p>In order to exercise the above mentioned rights the User accesses its Profile or contacts the Company if a special interface tool is missing (please see Section 13 of this Policy).</p>
                    <p>If the User is not satisfied with how its Personal Data is processed by the Company, the User has the right to submit a complaint. If the User disagrees with the Company’s response, the User has the right to proceed its complaint with competent authorities.</p>
                    <h2>11. How We Use Cookies and Other Similar Technologies on the Platform</h2>
                    <h3>11.1 What a cookie is and why it is used by the Company</h3>
                    <p>Cookie is a small text file stored on the device you use to access the Platform. It contains data that is collected from the User’s device and sent back to the Platform with each next visit to record activities and preferences of the User over time.</p>
                    <p>The Platform uses the following cookie types:</p>
                    <p className="li-dot dot">•</p> <p className="li-item">Strictly required cookies / technical cookies are needed for the Platform’s operation and to provide Services to you; they also enable the Company to identify the User’s hardware and software including a browser type;</p>
                    <p className="li-dot dot">•</p> <p className="li-item">Statistical / analytical cookies enable to identify users, calculate their amount and gather information about operations on the Platform including visited websites and content the User receives;</p>
                    <p className="li-dot dot">•</p> <p className="li-item">Technical cookies enable to collect information about how users interact with the Platform that makes it possible to detect errors and test new functions to increase the productivity of the Platform and its Services;</p>
                    <p className="li-dot dot">•</p> <p className="li-item">Functional cookies enable to provide certain functions to simplify the use of the Platform by the User, e.g. remembering the User’s preferences (such as a language and location);</p>
                    <p className="li-dot dot">•</p> <p className="li-item last-item">(Third party) tracking cookies / advertising cookies record information about users, traffic sources, visited websites and advertisements displayed for you and which you click to get to the advertised page. Such cookies enable to display advertisements that may be interesting to you on the basis of the analysis of the Personal Data collected about you. They are also used for statistical and research purposes.</p>
                    <h3>11.2 How long cookies are stored on the User’s device:</h3>
                    <p className="last-item">The Company uses information contained by cookies only for the above mentioned purposes following which the remembered data will be stored at your device over the period that may be defined by a cookie type but not exceeding the period required to achieve their goals after which they will be deleted from your system automatically.</p>
                    <h3>11.3 Who else has access to information contained by a cookie:</h3>
                    <p>The Personal Data gathered by cookies stored at the User’s device may be disclosed or available to the Company and / or third parties listed in Section 7 of this Policy. </p>
                    <p>At the first visit to the Platform a consent may be requested from the User to cookie use. If after the User approves cookie use and wishes to change its decision, the User can delete cookies stored in its browser (commonly, in its settings, please consult the manual on the browser or the Platform requesting it from the developer). Afterwards, a pop-up window may be displayed requesting for the User’s consent when the User is able to make another choice. If the User refuses cookies, it may result in unavailability of some Platform’s functions to the User that will impact the possibility to use the Platform. The User may also change its browser settings to accept or decline by default all cookies or cookies from certain sites including the Company’s platform. </p>
                    <h2>12. Policy Update</h2>
                    <p>This Policy may be modified. The Company has the right to make amendments to the Policy at its discretion including but not limited to cases that relate to legislative changes and also when respective alterations are connected with changes in the Platform’s operation.</p>
                    <p>The Company is obliged not to make significant changes, not to impose additional encumbrances or restrictions on users’ rights stipulated by this Policy without relevant notification. Such notification may be displayed on the Platform (e.g. by a popup window or a banner) before such changes take effect or may be delivered to the User by means of other communication channels (e.g., by email).</p>
                    <h2>13. Feedback</h2>
                    <p>The Company welcomes any questions and proposals from the User relating to implementation and modifications of this Policy. The User may also submit its requests with respect to exercise of its rights or complaints about Personal Data inappropriateness or processing unlawfulness to <a href="mailto:feedback@vizls.com">feedback@vizls.com</a>.</p>
        </div>
    }
}