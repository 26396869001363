import * as React from "react";
import {Action} from "redux";
import * as actions from "../../redux/actions";
import {ActionCreators} from "redux-undo";
import {connect} from "react-redux";
import canvasBlockCreator from "../../components.core/WorkCanvas/helpers/canvasBlockCreator";

interface IDispatchProps {
    ping:() => Action
}

const mapDispatchToProps: IDispatchProps = {
    ping: actions.actions.healthCheck.healthCheck.init,
};

interface IState {
    intervalId: any
}

export class HealthCheck extends React.Component<IDispatchProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = { intervalId: 0 }
    }

    componentDidMount(){
        const interval= setInterval(() => this.props.ping(), 30000)
        this.setState({ ...this.state, intervalId: interval})
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId)
    }

    render(){
        return <div></div>
    }
}

export default connect(null, mapDispatchToProps)(HealthCheck)