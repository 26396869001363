import {IUserWrite, IErrorResponse, IUserRead} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'CURRENT_USER';

export const ACTIONS = {
    FETCH: actionsSet(prefix, 'FETCH'),
    CLEAR: actionsSet(prefix, 'CLEAR')
};

export interface IFetchDoneAction extends Action {
    item: IUserRead;
}

export const actions = {
    fetch: {
        init: () => ({type: ACTIONS.FETCH.INIT }),
        done: (item: IUserRead) => ({type: ACTIONS.FETCH.DONE, item }) as IFetchDoneAction,
        fail: (error: IErrorResponse) => ({type: ACTIONS.FETCH.FAIL, error }) as IFailAction,
    },
    clear: {
        init: () => ({type: ACTIONS.CLEAR.INIT }),
        done: () => ({type: ACTIONS.CLEAR.DONE }),
        fail: () => ({type: ACTIONS.CLEAR.FAIL }),
    }
}
