import { Action } from "redux";
import {ACTIONS, ISignInGoogleDoneAction} from "../../../actions/external-auth/google/signIn";
import {printErrorResponse} from "../../../../api";
import { IFailAction } from "../../../../actions";

export interface IState {
    isAuthorized: boolean;
    inProgress: boolean;
    error: string | null;
    accessToken: string | null;
    authenticationScheme: string | null;
    refreshToken: string | null;
}

export const initial: IState = {
    isAuthorized: false,
    inProgress: false,
    error: null,
    accessToken: null,
    authenticationScheme: null,
    refreshToken: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.SIGN_IN_GOOGLE.INIT:
            return {
                ...state,
                isAuthorized: false,
                inProgress: true,
                error: null
            };

        case ACTIONS.SIGN_IN_GOOGLE.DONE:
            const { result } = action as ISignInGoogleDoneAction;
            return {
                ...state,
                isAuthorized: true,
                inProgress: false,
                accessToken: result.accessToken,
                refreshToken: result.refreshToken,
                authenticationScheme: result.authenticationScheme,
                error: null
            };

        case ACTIONS.SIGN_IN_GOOGLE.FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                isAuthorized: false,
                inProgress: false,
                error: printErrorResponse(error)
            };

        case ACTIONS.CLEAR_GOOGLE:
            return initial;

        default:
            return state;
    }
}