import {IErrorResponse, IUserWrite} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'USERS_CREATE';

export const ACTIONS = {
    SAVE: actionsSet(prefix, 'SAVE'),
    CLEAR: "USERS_CREATE_CLEAR"
};

export interface ISaveInitAction extends Action {
    item: IUserWrite;
}

export interface ISaveDoneAction extends Action {
    item: IUserWrite;
}

export const actions = {
    init: (item: IUserWrite) => ({type: ACTIONS.SAVE.INIT, item}) as ISaveInitAction,
    done: (item: IUserWrite) => ({type: ACTIONS.SAVE.DONE, item}) as ISaveDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.SAVE.FAIL, error}) as IFailAction,
    clear: () => ({type: ACTIONS.CLEAR})
}
