import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS, actions, IFetchInitAction } from '../../actions/invoices/lastInvoice';
import { IErrorResponse, IInvoice, getLastInvoice, } from '../../../api';
import {push} from "react-router-redux";

export function* sagaHandler(action: Action) {
    try {
        const { userId } = action as IFetchInitAction;

        const result: IInvoice = yield call(getLastInvoice, userId);
        yield put(actions.fetch.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.fetch.fail(c))
    }
}

export function* lastInvoiceSaga() {
    yield takeLatest(ACTIONS.FETCH.INIT, sagaHandler);
}
