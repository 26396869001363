import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import {ACTIONS, actions} from "../../actions/index";
import { ISaveInitAction } from "../../actions/userSettings/update";
import {IErrorResponse, IUserRead} from "../../../api";
import {updateUserSettings} from "../../../api/userSettings";

export function* sagaHandler(action: Action) {
    try {
        const { item } = action as ISaveInitAction;
        const result: IUserRead = yield call(updateUserSettings, item);

        yield put(actions.users.update.save.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.users.update.save.fail(c))
    }
}

export function* updateSettingsSaga() {
    yield takeLatest(ACTIONS.userSettings.update.SAVE.INIT, sagaHandler);
}
