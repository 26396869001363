import { Action } from 'redux';
import { actionsSet } from '../../../actions/index';
const prefix = 'PROJECT_SETTINGS';

export const ACTIONS = {
    MODAL_FORM: actionsSet(prefix, "EDIT")
};

export interface IOpenInitAction extends Action {
    id: string
}

export interface IConfirmDoneAction extends Action {
}

export const actions = {
    open: (id: string) => ({type: ACTIONS.MODAL_FORM.INIT, id}) as IOpenInitAction,
    close: () => ({type: ACTIONS.MODAL_FORM.DONE}) as IConfirmDoneAction
}
