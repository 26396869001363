import {BlockType, ElementBlockType, DetailLevel} from "../../../models";
import newBlockCreate from "../../../models/creation";
import BlockCreatorTool from "../../../core/creators";
import {BlocksMovements} from "../../../core/blocks-movements";
import { store } from "../../../store/configureStore";

class CanvasBlockCreator {
    private blockCreatorTool: BlockCreatorTool;
    private blocksMovements: BlocksMovements;
    private currDetailLevel: DetailLevel;

    constructor() {
        this.blockCreatorTool = new BlockCreatorTool();
        this.blocksMovements = new BlocksMovements(newBlockCreate);
        this.currDetailLevel = 1;
    }

    changeCreatorTool(event:any, creationTool: BlockType | ElementBlockType, templateId?: string){
        
        event.stopPropagation();

        if(this.isDetailLevel(creationTool)) return;
        
        this.blockCreatorTool.selectTool(creationTool, templateId);
        if(creationTool!==BlockType.SelectTool && creationTool!==BlockType.HandTool) {
            this.preCreationBlock();
        }
        else {
            this.blocksMovements.unsubscribeNecessaryMovementEvents();
        }
    }

    preCreationBlock(){
        newBlockCreate.reset();

        if(this.isDetailLevel()) return;

        const newBlock = this.blockCreatorTool.createNewBlockBySelectionTool();
        if(newBlock) {
            newBlockCreate.setLeadBlock(newBlock[0], newBlock.filter((b,i)=>i>0));
        }

        this.blocksMovements.subscribeNecessaryMovementEvents("mousedown");
    }

    resetPreCreation(){
        newBlockCreate.reset();
    }

    getCreationLeadBlock(){
        return newBlockCreate.getLeadBlock();
    }

    getBlocksMovementsInstance(){
        return this.blocksMovements;
    }

    setDetailLevel(l:DetailLevel){
        this.currDetailLevel = l;
    }

    private isDetailLevel(creationTool?: BlockType | ElementBlockType){
        if(this.currDetailLevel === 2 && (creationTool !== BlockType.HandTool || !creationTool)) return true;

        return false;
    }
}

const canvasBlockCreator = new CanvasBlockCreator();
export default canvasBlockCreator;
