import { IErrorResponse } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'PRESENTATION_MODE';

export const ACTIONS = {
    CLOSE: actionsSet(prefix, 'CLOSE')
};

export interface ISaveInitAction extends Action {
    id: string;
}

export interface ISaveDoneAction extends Action {
}

export const actions = {
    init: (id: string) => ({type: ACTIONS.CLOSE.INIT, id}) as ISaveInitAction,
    done: () => ({type: ACTIONS.CLOSE.DONE}) as ISaveDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.CLOSE.FAIL, error}) as IFailAction
}