import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import {ACTIONS, ISignInGoogleInitAction} from "../../../actions/external-auth/google/signIn";
import {signInGoogle} from "../../../../api/external-auth/facebook";
import {actions} from "../../../actions/external-auth/google";
import {actions as actionsAuth} from "../../../actions";
import {IAuthResult} from "../../../../api/auth";
import {IErrorResponse} from "../../../../api";
import {push} from "react-router-redux";

function* signInHandler(action: Action) {
    try {
        const { token } = action as ISignInGoogleInitAction;
        const result: IAuthResult =  yield call(signInGoogle, token);
        yield put(actions.signIn.done(result));
        yield put(actionsAuth.auth.login.done(result))
        yield put(actionsAuth.users.currentUser.fetch.init());
        yield put(actionsAuth.modalForm.signIn.close());
        yield put(push(`/dashboard`));
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.signIn.fail(c))
    }
}

export function* signInGoogleSaga() {
    yield takeLatest(ACTIONS.SIGN_IN_GOOGLE.INIT, signInHandler);
}
