import React from 'react';
import * as d3 from 'd3';
import cn from 'classnames';
import './style.scss';

interface IDefaultTextAreaProps {
    value: string;
    onFocus: (e:any) => void;
    onBlur: (e:any) => void;
    onChange: (e:any) => void;
    classNames?: string;
    disabled: boolean;
    displayedRows?: number;
    setTotalRows?: (rows: number) => void;
    onKeyDown?:(e:any) => void;
}

export default class ResizableTextArea extends React.Component<IDefaultTextAreaProps> {

    private component: any;

    constructor(props:any){
        super(props);

        this.onMouseDown = this.onMouseDown.bind(this);
        this.resize = this.resize.bind(this);
    }
    
    componentDidMount(){
        this.resize();
    }

    componentDidUpdate(prevProps:any){
        this.resize();
    }

    resize () {
        if(!this.props.displayedRows){
            this.component.style('height', 'auto');
            this.component.style('height', this.component.property('scrollHeight')+'px');            
        }        
        else {
            this.component.style('height', null);
        }

        const { setTotalRows } = this.props;
        if(setTotalRows) {
            let compStyles = window.getComputedStyle(this.component.node());
            const lht = +compStyles.getPropertyValue('line-height').replace('px','');
            const lines = Math.floor(this.component.property('scrollHeight') / lht);
            setTotalRows(lines);
        }    
    }

    onMouseDown(event:any){
        this.component.node().focus();
        event.stopPropagation();
    }

    render(){
        const { value, disabled, classNames, onChange, onFocus, onBlur, displayedRows, onKeyDown } = this.props;

        let rows = 1;
        if(displayedRows)
            rows = displayedRows;

        return  <textarea className={cn('resizable-textarea', classNames)} rows={rows} ref={handle => (this.component = d3.select(handle))}
                    value={value} onChange={onChange}
                    onKeyDown={onKeyDown}
                    disabled={disabled} onFocus={onFocus} onBlur={onBlur}
                    onMouseDown={this.onMouseDown} />
    }
}