import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS, actions, ISaveInitAction } from '../../actions/feedback/feedback';
import { IFeedbackQuery, postFeedback } from '../../../api/feedback';
import { IErrorResponse } from '../../../api';
 
export function* sagaHandler(action: Action) {
    try {
        const { item } = action as ISaveInitAction;

        const result: IFeedbackQuery = yield call(postFeedback, item);
        yield put(actions.send.done())
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.send.fail(c))
    }
}

export function* sendSaga() {
    yield takeLatest(ACTIONS.SEND.INIT, sagaHandler);
}
