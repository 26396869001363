import {httpPut} from '../common';
import {IUserRead} from "../user";

export interface IUserSettings {
    language: string;
}

export function updateUserSettings(user: IUserSettings) {
    return httpPut<IUserRead>('/api/userSettings', user, { authorize: true });
}



