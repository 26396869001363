import { combineReducers } from 'redux';
import * as appSettings from './appSettings'

export interface IState {
    view: appSettings.IState,
}

export const initial: IState = {
    view: appSettings.initial,
}

export const reducer = combineReducers({
    view: appSettings.reducer,
});
