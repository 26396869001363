import React from 'react';
import './styles/user-bloсks-editor.scss'
import cn from 'classnames';
import * as blockPresenterHelpers from '../../../BlockPresenter/helpers'
import { IBlock, BlockType } from "../../../../models";
import { IState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import { CompositeIcon, FixedIcon } from '../../../../icons';
import { createTemplateCompositeBlockWithChild } from '../../../../core/creators/createTemplateCompositeBlock';
import { getBlocks } from '../../../../redux/selectors/blocks';
import { blockHeight } from '../../../../core/blocks';
import { Action } from 'redux';
import * as actions from '../../../../redux/actions'
import BaseBlock from '../../../../core/blocks/base';
import selectedBlocks from '../../../../models/selection';
import { createTemplateFixedBlockWithChild } from '../../../../core/creators/createTemplateFixedBlock';
import canvasMovementTool from '../../../../core/common/canvasMovementTool';

const filtersTypes: BlockType[] = [BlockType.TemplateComposite, BlockType.TemplateFixed];
const getFilteredBloks = getBlocks(filtersTypes);

enum MenuTab {
    FixedBlocks = "FixedBlocks",
    CompositeBlocks = "CompositeBlocks",
    TemplateEditor = "TemplateEditor",
}

interface IUserBloсksDashboardOwnState {
    selectedTab: MenuTab;
}
interface IUserBloсksDashboardOwnProps {
    isCollapse: boolean,
    onCollapse: () => void
}

interface IUserBloсksDashboardProps {
    blocks: IBlock[];
}

const mapStateToProps = (state: IState): IUserBloсksDashboardProps => {
    return {
        blocks: getFilteredBloks(state),
    };
};

interface IDispatchProps {
    hideElementTypePopupMenu: (displayId: string) => Action;
}

const mapDispatchToProps: IDispatchProps = {
    hideElementTypePopupMenu: actions.appDisplayIdTypePopupMenu
};

class UserBloсksEditor extends React.Component<IUserBloсksDashboardProps & IUserBloсksDashboardOwnProps & IDispatchProps, IUserBloсksDashboardOwnState> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedTab: MenuTab.FixedBlocks
        }
        this.handleCollapseClick = this.handleCollapseClick.bind(this);
        this.switchTab = this.switchTab.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    handleCollapseClick() {
        this.props.onCollapse();
    }

    AddBlock = (selectedTab: MenuTab) => {
        if(selectedTab === MenuTab.CompositeBlocks)
            createTemplateCompositeBlockWithChild();
        else if(selectedTab === MenuTab.FixedBlocks)
            createTemplateFixedBlockWithChild();
    }

    renderBlocks = (selectedTab: MenuTab) => {
        let blockType = BlockType.TemplateComposite;
        if(selectedTab === MenuTab.FixedBlocks){
            blockType = BlockType.TemplateFixed;
        }

        const canvasPos = canvasMovementTool.getCanvasPosition();
        const { blocks } = this.props;
        let y_pos = 0;
        return blocks.filter(b=>b.type === blockType).map(b => {
            b.coord = {x: 0 - canvasPos.x, y: y_pos - canvasPos.y};
            const comp = blockPresenterHelpers.blockPresenter(b)
            y_pos += (blockHeight(b.id, true) + 30);
            return comp;
        });
    }

    renderTab = (selectedTab: MenuTab) => {
        return (
            <>
                <div className={cn("tabs--content", "blocks-scroll")}>                    
                    {this.renderBlocks(selectedTab)}
                </div>
                {this.renderAddButton(selectedTab)}                
            </>);

    }

    renderAddButton = (selectedTab: MenuTab) => {

        if(selectedTab === MenuTab.CompositeBlocks)
            return (<div className="add-compound-component">
                        <div onClick={() => this.AddBlock(selectedTab)} className="add-compound-component--btn">
                            <CompositeIcon />
                            Add composite block
                        </div >
                    </div>)
        else if(selectedTab === MenuTab.FixedBlocks)
            return (<div className="add-compound-component">
                        <div onClick={() => this.AddBlock(selectedTab)} className="add-compound-component--btn">
                            <FixedIcon />
                            Add fixed block
                        </div >
                    </div>)
    }

    switchTab(newTab:MenuTab){
        const { isCollapse } = this.props;
        const { selectedTab } = this.state;
        if(!isCollapse && selectedTab == newTab) this.props.onCollapse();
        else if(isCollapse) this.props.onCollapse();

        this.setState((state) => { return { ...state, selectedTab: newTab } });
    }

    onClick(event: any) {
        if (event.target.className.includes && (event.target.className.includes("user-blocks-editor") || event.target.className.includes("tabs"))) {
            this.props.hideElementTypePopupMenu("");
            if (selectedBlocks.getSelectedBlocksSize() === 0)
                BaseBlock.resetSelection(BaseBlock.getBlocks().filter(b => b.isSelected).map(b => b.id));
        }
    }

    render() {
        
        const { isCollapse } = this.props;
        const { selectedTab } = this.state;

        return (
            <div className={cn("user-blocks-editor-wrapper", isCollapse?"user-blocks-editor-wrapper--collapsed":"")} onMouseDown={this.onClick}>
                <div className={cn("user-blocks-editor", isCollapse ? "user-blocks-editor--collapse" : "user-blocks-editor--expand")}>
                    {this.renderTab(selectedTab)}                    
                </div >
                <div className={cn("tabs", isCollapse ? "tabs--collapsed" : "")}>
                    <div className={cn("tabs--title--1", !isCollapse && selectedTab === MenuTab.FixedBlocks ? "tabs--selected" : "")} onClick={() => this.switchTab(MenuTab.FixedBlocks)}>
                        Fixed Blocks
                    </div>
                    {/* <div className={cn("tabs--title--2", !isCollapse && selectedTab === MenuTab.CompositeBlocks ? "tabs--selected" : "")} onClick={() => this.switchTab(MenuTab.CompositeBlocks)}>
                        Composite Blocks
                    </div>
                    <div className={cn("tabs--title--3", !isCollapse && selectedTab === MenuTab.TemplateEditor ? "tabs--selected" : "")} onClick={() => this.switchTab(MenuTab.TemplateEditor)}>
                        Template Editor
                    </div> */}
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBloсksEditor);
