import React from 'react';
import { IBlock, BlockType } from "../../../models";
import BlockPresenter from "..";
import BaseBlock from '../../../core/blocks/base';

 /**
  * Generate block as React Component
  * @param props
  * @returns Generated React Component
  */
export function blockPresenter(b?: IBlock, parentId?: string) {

    // Обработка специфических случаев
    // Placeholder
    if(b && b.type == BlockType.Placeholder && b.parentId && parentId){
      return <div key="target-block-placeholder" id="target-block-placeholder" className="target-block-placeholder"></div>
    }

    // Template
    if(b && b.type == BlockType.TemplateComposite){
      return renderBlockPresenter(b, b.id);
    }

    // Обработка стандартных случаев: блок на холсте, или внутри другого блока
    if (b && b.coord) {
        return renderBlockPresenter(b, b.id);
    }
    
    if (b && parentId) {
        return renderBlockPresenter(b, parentId);
    }    
}

function renderBlockPresenter(b: IBlock, parentId?: string){
  return <BlockPresenter key={b.id} block={b}>
            {b.children && b.children.map(id => blockPresenter(BaseBlock.getBlock(id), parentId))}
         </BlockPresenter>
}