import React from 'react';
import './style.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import DashboardItem from './DashboardItem';
import { IProject, IProjectQuery } from "../../../api/projects";
import { Action } from "redux";
import { IState } from "../../../redux/reducers";
import * as actions from "../../../redux/actions";
import { connect } from "react-redux";
import ProjectSettingsModalCreate from "../ProjectSettingsModal/create";
import ProjectSettingsModalEdit from "../ProjectSettingsModal/edit";
import { withRouter } from "react-router";
import * as H from 'history';
import { Spinner } from "../../Spinner";
import { IErrorResponse, IPageResult, ITariffSettings, IUserSettings } from "../../../api";
import { ProjectCreateButtonIcon } from '../../../icons';
import TrialExpireWarningTip from "../../TrialExpireWarningTip";

interface IDispatchProps {
    showProjects: (params: IProjectQuery | null) => Action
    openSettings: (id: string) => Action,
    openCreate: () => Action,
    deleteProject: (id: string) => Action,
    saveUserSettings: (user: IUserSettings) => Action,
    getTariffSettings: () => Action
}

interface IProjectsState {
    params: IProjectQuery | null,
    projects: IProject[] | null,
    deletedProjectId: string | null
}

interface IStateProps {
    projects: IPageResult<IProject[]> | null,
    createdProject: IProject | null,
    create_inProgress: boolean,
    updatedProjects: IProject | null,
    inProgress: boolean,
    error: string | null,
    open: boolean,
    delete_project: boolean,
    delete_inProgress: boolean,
    delete_error: IErrorResponse | null,
    userSettings: IUserSettings | null,
    tariffSettings: ITariffSettings | null,
}

interface IRouterProps {
    history: H.History;
}

type IProps = IStateProps & IDispatchProps & IRouterProps & WithTranslation;

const mapStateToProps = (state: IState): IStateProps => {
    return {
        projects: state.projects.table.item,
        createdProject: state.projects.create.item,
        create_inProgress: state.projects.create.inProgress,
        updatedProjects: state.projects.edit.item,
        inProgress: state.projects.table.inProgress,
        error: state.projects.table.error,
        open: state.modalForm.projectSettings.isOpen,
        delete_project: state.projects.delete.result,
        delete_inProgress: state.projects.delete.inProgress,
        delete_error: state.projects.delete.error,
        userSettings: state.userSettings.view.item,
        tariffSettings: state.invoices.tariff.item
    };
};

const mapDispatchToProps: IDispatchProps = {
    showProjects: actions.actions.projects.table.init,
    openSettings: actions.actions.modalForm.projectSettings.open,
    openCreate: actions.actions.modalForm.projectSettingsCreate.open,
    deleteProject: actions.actions.projects.delete.init,
    saveUserSettings: actions.actions.userSettings.update.save.init,
    getTariffSettings: actions.actions.invoices.tariff.fetch.init
};

class UserDashboard extends React.Component<IProps, IProjectsState> {

    constructor(props: IProps) {
        super(props);
        this.state = { params: null, projects: null, deletedProjectId: null };
        this.handleCreateClick = this.handleCreateClick.bind(this)
    }

    componentDidMount() {
        const query: IProjectQuery | null = null;
        this.props.showProjects(query);
        this.props.getTariffSettings();
    }

    componentDidUpdate(prevProps: Readonly<WithTranslation & IStateProps & IDispatchProps>) {
        if (this.props && this.props.projects && this.props.projects !== prevProps.projects) {
            this.setState({ ...this.state, projects: this.props.projects.items })
        }
    }

    handleSettingsClick(e: any) {
        this.props.openSettings(e);
    }

    handleCreateClick() {
        const { tariffSettings, projects } = this.props;
        if (tariffSettings !== null && projects !== null && tariffSettings.count_projects >= projects.totalCount + 1) {
            this.props.openCreate();
        } else {
            this.props.history.push('/prices')
        }
    }

    handleEditClick(e: any) {
        if (this.props.history) {
            this.props.history.push(`/project/${e}/edit`)
        }
    }

    handleDeleteClick(e: any) {
        if (this.props.deleteProject && e) {
            this.props.deleteProject(e)
            this.setState({ ...this.state, deletedProjectId: e })
        }
    }

    renderTrial() {
        const { projects, tariffSettings } = this.props
        if (projects && tariffSettings && projects.totalCount > tariffSettings.count_projects) {
            const activeProjects: IProject[] = projects.items.filter(x => x.isActive)
            return <TrialExpireWarningTip projects={activeProjects} />
        }
    }


    render() {

        const { t } = this.props;

        return (<div className="dashboard-container">
            <div className="dashboard">
                <div className="dashboard-title-block">
                    <div className="dashboard-projects-title">{t('dashBoard.projects')}</div>
                    {/* <div className="dashboard-projects-slash">/</div>
                    <div className="dashboard-projects-info">{t('dashBoard.archiveProjects')}</div> */}
                </div>
                <ProjectSettingsModalCreate />
                <ProjectSettingsModalEdit />
                <div className="dashboard-projects">
                    {/* <DefaultButton title={t('dashBoard.button')} className="dashboard-projects-new-button" /> */}

                    <div className="dashboard-projects-new-button" >
                        <div className="content">
                            <ProjectCreateButtonIcon onClick={this.handleCreateClick} />
                            <div className="title">
                                {t('dashBoard.button')}
                            </div>
                        </div>
                    </div>

                    {this.renderProjects()}
                </div>
            </div>
            <div className="trial">
                {this.renderTrial()}
            </div>
        </div>);
    }


    renderProjects() {
        const { projects } = this.state;
        const { inProgress } = this.props;
        if (inProgress) {
            return <Spinner className="" />
        }

        if (projects === null) {
            return null;
        }

        const items = projects.map(x => {
            return <DashboardItem key={x.id} name={x.title} lastEditTime={x.last_changed ? x.last_changed : new Date(Date.now())} backgroundColor={x.color}
                handleSettingsClick={this.handleSettingsClick.bind(this, x.id)}
                handleEditClick={this.handleEditClick.bind(this, x.id)}
                handleDeleteClick={this.handleDeleteClick.bind(this, x.id)} />
        })

        return <>
            {items}
        </>
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserDashboard)) as any);