import React from "react";
import '../sidebar.scss'
import canvasBlockCreator from "../../../helpers/canvasBlockCreator";
import {BlockType, ElementBlockType} from "../../../../../models";
import {WithTranslation, withTranslation} from "react-i18next";
import BaseBlock from "../../../../../core/blocks/base";

interface IProps {
    children: any,
    isSelect?: boolean,
    isCollapse: boolean,
    blockType: BlockType | ElementBlockType,
    className?: any
}

export class SideBarItem extends React.Component<IProps & WithTranslation> {
   
    constructor(props: any) {
        super(props);
        this.handleMouseDownClick = this.handleMouseDownClick.bind(this);
    }

    handleMouseDownClick(event:any) {
        BaseBlock.resetSelection();
        canvasBlockCreator.changeCreatorTool(event, this.props.blockType);
        BaseBlock.resetSelection(BaseBlock.getBlocks().filter(b=>b.isSelected).map(b=>b.id));
    }

    render() {
        const {children, isCollapse, t, blockType, isSelect} = this.props;

        return <li key={blockType} className={isSelect ? "selectBlock" : "nonSelectBlock"} onMouseDown={(event:any)=>this.handleMouseDownClick(event)}>
            <div className="item">
                {children}
                <div hidden={isCollapse} >
                    {t(`blocks.${blockType.charAt(0).toLowerCase() + blockType.slice(1)}`)}
                </div>
            </div>
        </li>
    }
}

export default withTranslation()(SideBarItem);
