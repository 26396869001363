import React from 'react';
import './sidebar.scss'
import cn from 'classnames';
import {
    CollapseIcon,
    ExpandIcon,
    ExternalkIcon,
    FormIcon,
    GalleryIcon,
    HandIcon,
    ImageIcon,
    LinkIcon,
    ListIcon,
    PageIcon,
    PopupIcon,
    SectionIcon,
    SelectIcon,
    TableIcon,
    TextIcon
} from "../../../../icons/block-types";
import MenuItem from "./SideBarItem";
import {BlockType, ElementBlockType, DetailLevel} from "../../../../models";
import {IState} from "../../../../redux/reducers";
import {connect} from "react-redux";
import { getCreatorToolType } from '../../../../redux/selectors/app';
import DetailLevelSwitcher from '../DetailLevelSwitcher';
interface IPropsPanel {
    isCollapse: boolean,
    isPresentationMode: boolean,
    detailLevel: number,
    setDetailLevel:(event:any, level: DetailLevel) => void,
    onCollapse:() => void
}

interface IStateToProps {
    selectBlock:  BlockType | ElementBlockType
}

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        selectBlock: getCreatorToolType(state)
    };
};

export class SideBar extends React.Component<IStateToProps & IPropsPanel> {
    constructor(props: any) {
        super(props);
        this.state = {isCollapse: false}
        this.handleCollapseClick = this.handleCollapseClick.bind(this);
    }

    handleCollapseClick() {
        this.props.onCollapse();
    }

    renderCollapse() {
        if (this.props.isCollapse) {
            return <div className="itemCollapse" onClick={this.handleCollapseClick}>
                    <ExpandIcon/>
            </div>
        } else {
            return <div className="itemCollapse" onClick={this.handleCollapseClick}>
                    <CollapseIcon/>
            </div>
        }
    }

    renderMenuBlocks() {
        const {selectBlock, isCollapse, isPresentationMode, detailLevel} = this.props;
        if (!isPresentationMode && detailLevel !== 2) {
            return  <>
                    <div className= {isCollapse ? "separator-collapse" : "separator" }/>

                    <MenuItem isCollapse={isCollapse} blockType={BlockType.Page} className={"item"} isSelect={selectBlock == BlockType.Page}>
                        <PageIcon/>
                    </MenuItem>

                    <MenuItem isCollapse={isCollapse} blockType={BlockType.Section} className={"item"} isSelect={selectBlock == BlockType.Section}>
                        <SectionIcon/>
                    </MenuItem>

                    <MenuItem isCollapse={isCollapse} blockType={ElementBlockType.Text} className={"item"} isSelect={selectBlock == ElementBlockType.Text}>
                        <TextIcon/>
                    </MenuItem>          

                    <MenuItem isCollapse={isCollapse} blockType={ElementBlockType.List} className={"item"} isSelect={selectBlock == ElementBlockType.List}>
                        <ListIcon/>
                    </MenuItem>

                    <MenuItem isCollapse={isCollapse} blockType={ElementBlockType.Link} className={"item"} isSelect={selectBlock == ElementBlockType.Link}>
                        <LinkIcon/>
                    </MenuItem>

                    <MenuItem isCollapse={isCollapse} blockType={ElementBlockType.Image} className={"item"} isSelect={selectBlock == ElementBlockType.Image}>
                        <ImageIcon/>
                    </MenuItem>

                    <MenuItem isCollapse={isCollapse} blockType={ElementBlockType.Gallery} className={"item"} isSelect={selectBlock == ElementBlockType.Gallery}>
                        <GalleryIcon/>
                    </MenuItem>

                    {/* <MenuItem isCollapse={isCollapse} blockType={ElementBlockType.Table} className={"item"} isSelect={selectBlock == ElementBlockType.Table}>
                        <TableIcon/>
                    </MenuItem>

                    <MenuItem isCollapse={isCollapse} blockType={ElementBlockType.Form} className={"item"} isSelect={selectBlock == ElementBlockType.Form}>
                        <FormIcon/>
                    </MenuItem>

                    <MenuItem isCollapse={isCollapse} blockType={ElementBlockType.External} className={"item"} isSelect={selectBlock == ElementBlockType.External}>
                        <ExternalkIcon/>
                    </MenuItem> */}
                    
                </>
        } else {
            return null
        }
    }

    render() {
        const {selectBlock, isCollapse} = this.props;
        return <div className={cn("rightpanel",isCollapse ? "collapse" : "expand")}>
            {this.renderCollapse()}
            <ul>
                <MenuItem isCollapse={isCollapse} blockType={BlockType.SelectTool} className={"item"} isSelect={selectBlock == BlockType.SelectTool}>
                    <SelectIcon/>
                </MenuItem>

                <MenuItem isCollapse={isCollapse} blockType={BlockType.HandTool} className={"item"} isSelect={selectBlock == BlockType.HandTool}>
                    <HandIcon/>
                </MenuItem>

                {this.renderMenuBlocks()}

                <DetailLevelSwitcher detailLevel={this.props.detailLevel} setDetailLevel={this.props.setDetailLevel} isCollapse={this.props.isCollapse}/>
            </ul>
        </div>
    }
}

export default connect(mapStateToProps)(SideBar);