import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/presentationMode/index';
import { getProjectsById} from "../../../api/presentationMode/index";
import {actions} from "../../actions/index";
import {ISaveInitAction} from "../../actions/presentationMode/view";
import {IErrorResponse} from "../../../api";
import {IProject} from "../../../api/projects";

export function* sagaHandler(action: Action) {
    try {
        const { id } = action as ISaveInitAction;
        const result: IProject =  yield call(getProjectsById, id);
        yield put(actions.presentationMode.view.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.presentationMode.view.fail(c))
    }
}

export function* viewSaga() {
    yield takeLatest(ACTIONS.view.VIEW.INIT, sagaHandler);
}
