import React from 'react';
import cn from 'classnames';
import * as d3 from "d3";
import { Action } from 'redux';
import { connect } from 'react-redux';
import "./styles/block-title.scss";
import BaseBlock, { getHighestBlock } from '../../core/blocks/base';
import selectedBlocks  from '../../models/selection';
import { IBlock, BlockType, IPoint} from '../../models';
import * as actions from '../../redux/actions'
import BlockTypePresenter from '../BlockTypePresenter';
import AddElementToCompositeButton from './AddCompositeButton';
import { IState } from '../../redux/reducers';
import BlockMovementPanel from './BlockMovementPanel';
import { INPUT_LENGTH_1LVL } from '../../consts';

interface IBlockTitleOwnState{
    value: string
    hovered: boolean;
    tooltipOnHover: boolean;
}
interface IBlockTitleProps {
    block: IBlock;
    isPresentationMode: boolean;
    className: string;
    style: any;
    onMouseOver: () => void;
    onMouseOut: () => void;
    onMouseDown: (event: any) => void;
}

interface IStateProps {
    editingTextBlockId?: string;     
}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        editingTextBlockId: state.app.editingTextBlockId
    };
};

interface IDispatchProps {
    setAppEditTextRegime: (blockId?: string) => Action;
    setDisplayIdPopupMenu: (displayId: string) => Action;
    appWorkCanvasTooltip: (coord?:IPoint, text?:string) => Action;
}

const mapDispatchToProps: IDispatchProps = {
    setAppEditTextRegime: actions.appEditText,
    setDisplayIdPopupMenu: actions.appDisplayIdTypePopupMenu,
    appWorkCanvasTooltip: actions.appWorkCanvasTooltip
};

class BlockTitle extends React.Component<IBlockTitleProps & IDispatchProps & IStateProps, IBlockTitleOwnState> {

    private component: any;

    constructor(props: any) {
        super(props);
        this.state = {value:"", hovered: false, tooltipOnHover: false};
        this.onTitleChange = this.onTitleChange.bind(this);
        this.inputAutoResize = this.inputAutoResize.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    componentDidMount() {
        
        let title = this.props.block.title;
        if(title === "\u200b"){
            const inputNode = this.component.select("input").node();
            inputNode.focus();
            inputNode.setSelectionRange(0, 0);
            title = '';
        }   
        
        this.setState({...this.state, value: title});
    }

    componentDidUpdate(prevProps:any){
        if(this.props.block.title !== prevProps.block.title)
            this.setState({...this.state, value: this.props.block.title});

        if(this.props.block.type === BlockType.Fixed && this.state.value !== this.props.block.title){
            this.setState({...this.state, value: this.props.block.title});
        }
        this.inputAutoResize(this.state.value);
        
        if(prevProps.editingTextBlockId !== this.props.editingTextBlockId && this.props.block.id === this.props.editingTextBlockId) {            
            const inputNode = this.component.select("input").node();
            inputNode.focus();
            inputNode.setSelectionRange(inputNode.value.length, inputNode.value.length);
        }
        
        if(this.state.value.length >= INPUT_LENGTH_1LVL && !this.state.tooltipOnHover){
            this.setState(state => {return {...state, tooltipOnHover: true }});
        }
    }

    onTitleChange(e: any) {
        let value = e && e.target && e.target.value;

        if (e && e.value && !this.props.isPresentationMode) {
            value = e.value
        }

        this.setState({...this.state, value});
        this.inputAutoResize(value);
    }

    inputAutoResize(value: string) {
        this.component.select(".block-title-edit > input").style("width", ((value.length + 1) * 8) + "px");
    }


    onFocus() {        
        this.props.setAppEditTextRegime(this.props.block.id);
        
        const { block } = this.props;
        const highest = getHighestBlock(block.id);
        if(highest && highest.type===BlockType.TemplateComposite) return;

        if (window!=null && window.getSelection) {
            const sel = window.getSelection();
            if (sel) { 
                sel.empty();
            } 
        }      
        BaseBlock.resetSelection(); 
        selectedBlocks.reset();          
        BaseBlock.select(this.props.block, true);
    }

    onBlur(e: any) {
        let value = e && e.target && e.target.value;

        if (e && e.value && !this.props.isPresentationMode) {
            value = e.value
        }
        BaseBlock.updateTitle(this.props.block, value);
        this.props.setAppEditTextRegime(undefined);
    }

    onMouseDown(event:any){
        this.component.select("input").node().focus();
        event.stopPropagation();
    }

    computeTopBottomBaseline(height:number, paddingBottomExist:boolean){
        if(height==45 && paddingBottomExist){
            return {
                top: {top: 0, height: "33%" },
                bottom: {top: "33%", height: "33%"},
                baseline: {top: "66%", height: "34%"}
            };
        }
        else return {
            top: {top: 0, height: "50%"},
            bottom: {top: "50%", height: "50%"}
        };
    }

    onKeyDown(e:any){
        if(e.ctrlKey && (e.keyCode=='38' || e.keyCode=='40')){
            e.preventDefault();
            return false;
        }
    }

    handleMouseEnter(e:any){
        this.setState(state => {return {...state, hovered: true }});
    }

    handleMouseLeave(e:any){
        this.setState(state => {return {...state, hovered: false }});
    }

    handleMouseMove(e:any){
        e.stopPropagation();
        
        if(this.state.tooltipOnHover){
            const ne = e.nativeEvent;
            this.props.appWorkCanvasTooltip({x:ne.pageX, y:ne.pageY}, this.state.value); 
        }               
    }

    render(){

        const {block, className, style, onMouseOver, onMouseOut, onMouseDown, isPresentationMode} = this.props;

        const highest = getHighestBlock(block.id);

        let showMovementPanel = false;
        if(highest && highest.type !== block.type && (highest.type === BlockType.TemplateComposite || highest.type === BlockType.TemplateFixed)){
            showMovementPanel=true;
        }

        const topBottomBaseline = this.computeTopBottomBaseline(style.height.height, style.padding && style.padding.paddingBottom);

        return (<div className={`${block.type}-block-title-container`} onMouseDown={()=>this.props.setDisplayIdPopupMenu("")} onMouseMove={this.handleMouseMove} onMouseOut={onMouseOut} onMouseOver={onMouseOver} ref={handle => (this.component = d3.select(handle))}>
                                        
                    <div className={"block-title-container"} onMouseDown={onMouseDown} style={style.height} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                        
                        <div id={`top_${block.id}`} className="block-title-top" style={topBottomBaseline.top}/>
                        <div id={`bottom_${block.id}`} className="block-title-bottom" style={topBottomBaseline.bottom}/>
                        {topBottomBaseline.baseline && 
                        <div id={`baseline_${block.id}`} className="block-title-baseline" style={topBottomBaseline.baseline}/> }

                        <div className={cn(className, "block-title-edit")} style={style.padding}>
                            <BlockTypePresenter block={block}/>

                            <input disabled={isPresentationMode || block.type === BlockType.Fixed} value={this.state.value} onChange={this.onTitleChange} 
                                    onMouseDown={this.onMouseDown} onFocus={this.onFocus} onBlur={this.onBlur} onKeyDown={this.onKeyDown} />

                            {block.type === BlockType.TemplateComposite && <AddElementToCompositeButton target={block}/>}
                        </div>

                        {this.state.hovered && showMovementPanel && <BlockMovementPanel block={block} />}

                    </div>                    

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockTitle);
