import {httpGet, httpPost, httpPut} from '../common';
import {IUserWrite} from "../user";

export interface ITrial {
    period: number
}

export function createTrial(userId: string) {
    return httpPost<IUserWrite>('/api/user/subscription/trial', userId, { authorize: true });
}

export function editTrial(trial: ITrial) {
    const period: number = trial.period;
    return httpPut<IUserWrite>(`/api/user/subscription/trial`, {period},{ authorize: true });
}{}
