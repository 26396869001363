import * as createAction from './create';
import * as editAction from './edit';
import * as viewAction from './view';
import * as tableAction from './table';
import * as deleteAction from './delete';

export const ACTIONS = {
    create: createAction.ACTIONS,
    edit: editAction.ACTIONS,
    view: viewAction.ACTIONS,
    table: tableAction.ACTIONS,
    delete: deleteAction.ACTIONS
};

export const actions = {
    create: createAction.actions,
    edit: editAction.actions,
    view: viewAction.actions,
    table: tableAction.actions,
    delete: deleteAction.actions
};
