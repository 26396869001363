import React from 'react';
import './style.scss';
import { DiscountHelpIcon } from '../../../icons';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IDiscountProps {
    discountValue: string;
}
type IProps = IDiscountProps & WithTranslation;

class Discount extends React.Component<IProps> {

    render() {
        const { t, discountValue } = this.props;
        return (
            <div className="discount">

                <div className="discount__question-icon">
                    <DiscountHelpIcon />
                </div>

                <div className="discount__value">
                    {discountValue}% &nbsp;
                    {t('discount.name')}
                </div>
            </div>
        );
    }
}

export default (withTranslation()(Discount));
