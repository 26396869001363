import React from 'react';
import './modalForm.scss';
import { DialogClose } from '../../../icons';
import cn from 'classnames';
import { Spinner } from "../../Spinner";
import {HotKeys, KeyMap} from "react-hotkeys";

interface IModalFormProps {
    title: string;
    closeForm: () => void;
    keysHandlers:()=> void;
    classes?: string;
    inProgress?: boolean
    error?: string | null
}

export default class ModalForm extends React.Component<IModalFormProps> {
    private keysMap: KeyMap;
    private keysHandlers: { [key: string]: (keyEvent?: KeyboardEvent | undefined) => void; };

    init() {
        this.keysMap = {
            'Ok': 'enter',
        };
        this.keysHandlers = {
            'Ok': this.props.keysHandlers
        };
    }

    constructor(props: IModalFormProps) {
        super(props);
        this.keysMap = {};
        this.keysHandlers = {};
        this.init();

        this.click = this.click.bind(this);
    }

    componentDidMount(){
        const element = document.getElementById("popup-container");
        if (element) {
            element.focus();
        }
    }

    click(event: any) {
        if (event.target.id === "popupwrap")
            this.props.closeForm();
    }

    renderError(){
        const {error} = this.props
        if (error){
            return <div className="form-error">
                <span>{error}</span>
            </div>
        }
    }

    render() {

        const { title, classes, inProgress } = this.props;

        return (
            <HotKeys keyMap={this.keysMap} handlers={this.keysHandlers}>
                    <div id="popup-container" onClick={this.click}>
                    <div className="popupwrap">
                        <div className={cn("popup", classes)}>
                            <>
                                <div className="close-icon" onClick={this.props.closeForm}>
                                    <DialogClose />
                                </div>
                            </>
                            {this.props.children}
                            {inProgress && <Spinner />}
                        </div>
                        {this.renderError()}
                    </div>
                </div>
            </HotKeys>);
    }
}
