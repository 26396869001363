import { combineReducers } from 'redux';
import * as view from './view';
import * as share from './share';
import * as close from './close';

export interface IState {
    view: view.IState;
    share: share.IState;
    close: close.IState;

}

export const initial: IState = {
    view: view.initial,
    share: share.initial,
    close: close.initial,
}

export const reducer = combineReducers({
    view: view.reducer,
    share: share.reducer,
    close: close.reducer,
});
