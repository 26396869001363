import React from 'react';
import ResizableTextArea from '../ResizableTextArea';
import '../../styles/block-content-text.scss';
import './style.scss';
import { ContentTextDisplayedRows } from '../../../../../models';
import { simulateMouseDown } from '../../../../WorkCanvas/helpers/simulateMouseDown';

interface ICollapsedTextAreaState {
    collapsed: boolean;
    value: string;
    displayedRows?: ContentTextDisplayedRows;
    totalRows: number;
}

interface ICollapsedTextAreaProps {
    value: string;
    classNames: string;
    displayedRows?: ContentTextDisplayedRows;
    isPresentationMode: boolean;
    updateValue: (value: string) => void;
    selectBlock: () => void;
}

export default class CollapsedTextArea extends React.Component<ICollapsedTextAreaProps, ICollapsedTextAreaState> {

    constructor(props: any) {
        super(props);

        this.state = { collapsed: false, value: '', displayedRows: props.displayedRows, totalRows: 1 };

        this.handleChange = this.handleChange.bind(this);
        this.collapse = this.collapse.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.setTotalRowsCount = this.setTotalRowsCount.bind(this);
        this.checkCollapse = this.checkCollapse.bind(this);
    }

    onFocus(e: any) {
        this.props.selectBlock(); // активируем блок
        this.setState((state) => { return { ...state, displayedRows: 'All' } });
    }

    onBlur(e: any) {
        this.props.updateValue(this.state.value);
        this.setState((state) => { return { ...state, displayedRows: this.props.displayedRows } });
    }

    setTotalRowsCount(rows: number) {
        if (this.state.totalRows !== rows)
            this.setState({ ...this.state, totalRows: rows });
    }

    handleChange(e: any) {
        let value = e && e.target && e.target.value;

        this.setState({ ...this.state, value: value });

        this.props.updateValue(value);
    }

    componentDidMount() {
        this.setState({ ...this.state, collapsed: true, value: this.props.value, displayedRows: this.props.displayedRows });       
    }
    
    componentDidUpdate(prevProps: any, prevState: any) {
        
        if (prevState.collapsed !== this.state.collapsed || this.props.classNames !== prevProps.classNames) {
            simulateMouseDown(true);
        }

        if (this.props.value !== prevProps.value){
            this.setState((state) => { return { ...state, collapsed: this.checkCollapse(), value: this.props.value } });   
            
            simulateMouseDown(true);
        }            

        if (this.props.displayedRows !== prevProps.displayedRows && prevState.displayedRows !== this.props.displayedRows) {
            this.setState((state) => { return { ...state, collapsed: this.checkCollapse(), displayedRows: this.props.displayedRows } });            
        }

        if(this.state.displayedRows !== prevState.displayedRows || prevState.totalRows !== this.state.totalRows)
            simulateMouseDown(true);
    }

    checkCollapse(){
        const collapsed = this.props.displayedRows!=undefined 
            && this.props.displayedRows!=='All' 
            && this.state.totalRows > this.props.displayedRows;
        
        return collapsed;
    }

    collapse(event: any) {
        event.stopPropagation();
        const { collapsed } = this.state;
        if (!collapsed) {
            this.setState({ ...this.state, collapsed: true, displayedRows: this.props.displayedRows });
        }
        else {
            this.setState({ ...this.state, collapsed: false, displayedRows: undefined });
        }
    }

    displayedRowsNumber(displayedRows?: ContentTextDisplayedRows) {
        if (!displayedRows || displayedRows === 'All') return undefined;

        return Math.min(this.state.totalRows, displayedRows);
    }

    render() {

        const { isPresentationMode, classNames } = this.props;
        const rows = this.displayedRowsNumber(this.state.displayedRows)
        
        return <div className='expanded-textarea'>

            <ResizableTextArea classNames={classNames}
                value={this.state.value} onChange={this.handleChange}
                setTotalRows={this.setTotalRowsCount}
                displayedRows={rows}
                disabled={isPresentationMode} onFocus={this.onFocus} onBlur={this.onBlur} />

            {this.props.displayedRows && this.state.displayedRows!=='All' && this.state.totalRows > this.props.displayedRows &&
                <div className='textarea-expander' onClick={this.collapse}>
                    {this.state.collapsed ? 'expand' : 'collapse'}
                </div>
            }
        </div>;
    }
}