import { combineReducers } from 'redux';
import * as view from './view';
import * as create from './create';

export interface IState {
    view: view.IState,
    create: create.IState
}

export const initial: IState = {
    view: view.initial,
    create: create.initial
}

export const reducer = combineReducers({
    view: view.reducer,
    create: create.reducer
});
