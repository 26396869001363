import { ACTIONS } from '../../actions/users/confirm';
import { Action } from 'redux';
import { printErrorResponse } from '../../../api/index';
import { IFailAction } from '../../../actions';

export interface IState {
    isConfirm: boolean;
    inProgress: boolean;
    error: string | null;
}

export const initial: IState = {
    isConfirm: false,
    inProgress: false,
    error: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.CONFIRM.INIT:
            return {
                ...state,
                isConfirm: false,
                inProgress: true,
                error: null
            };

        case ACTIONS.CONFIRM.DONE:
        {
            return {
                ...state,
                isConfirm: true,
                inProgress: false,
                error: null
            };
        }

        case ACTIONS.CONFIRM.FAIL:
        {
            const { error } = action as IFailAction;
            return {
                ...state,
                inProgress: false,
                error: printErrorResponse(error)
            };
        }

        default:
            return state;
    }
}
