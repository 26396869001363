import * as create from './create';
import * as view from './view';

export const ACTIONS = {
    VIEW: view.ACTIONS,
    CREATE: create.ACTIONS

};

export const actions = {
    view: view.actions,
    create: create.actions,
};
