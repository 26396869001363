import {ACTIONS} from '../../actions/modalForm/resetPasswordForm';
import { Action } from 'redux';

export interface IState {
    isShow: boolean;
}

export const initial: IState = {
    isShow: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.RESET_PASSWORD_FORM.INIT:
            return {
                ...state,
                isShow: true
            };

        case ACTIONS.RESET_PASSWORD_FORM.DONE:
        {
            return initial;
        }
        default:
            return state;
    }
}
