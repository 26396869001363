import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/projects/index';
import { getProjectsById, IProject} from "../../../api/projects/index";
import {actions} from "../../actions/index";
import {ISaveInitAction} from "../../actions/projects/view";
import {IErrorResponse} from "../../../api";

export function* sagaHandler(action: Action) {
    try {
        const { id } = action as ISaveInitAction;
        const result: IProject =  yield call(getProjectsById, id);
        yield put(actions.projects.view.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.projects.view.fail(c))
    }
}

export function* viewSaga() {
    yield takeLatest(ACTIONS.view.VIEW.INIT, sagaHandler);
}
