import { all, fork } from "redux-saga/effects";
import {viewSaga} from "./view";
import {tableSaga} from "./table";
import {createSaga} from "./create";
import {editSaga} from "./edit";
import {deleteSaga} from "./delete";

export function* projectSaga() {
    yield all([
        fork(tableSaga),
        fork(viewSaga),
        fork(createSaga),
        fork(editSaga),
        fork(deleteSaga)
    ]);
}