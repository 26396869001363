import { httpPost } from "../common";

export enum FeedbackType {
    Bug = "Bug",
    Question = "Question",
    Suggestion = "Suggestion",
    Other = "Other",
}

export interface IFeedbackQuery {
    email?: string;
    type: FeedbackType;
    message: string;
}

export function postFeedback(item: IFeedbackQuery) {
    return httpPost('/api/feedback', item, { authorize: false });
}