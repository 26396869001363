import * as auth from './auth';
import * as users from './users';
import * as tariffs from './tariffs';
import * as invoices from './invoices';
import * as projects from './projects';
import * as modalForm from './modalForm';
import * as appSettings from './appSettings';
import * as trial from './trial';
import * as projectSettings from './projectSettings';
import * as presentationMode from './presentationMode';
import * as feedback from './feedback';
import * as externalAuth from './external-auth';
import * as healthCheck from './healthCheck';
import * as userSecurity from './userSecurity';
import * as userSettings from './userSettings';

export * from './blocks';
export * from './relations';
export * from './app';

export const ACTIONS = {
    auth: auth.ACTIONS,
    users: users.ACTIONS,
    appSettings: appSettings.ACTIONS,
    projects: projects.ACTIONS,
    invoices: invoices.ACTIONS,
    tariffs: tariffs.ACTIONS,
    modalForm: modalForm.ACTIONS,
    trial: trial.ACTIONS,
    projectSettings: projectSettings.ACTIONS,
    presentationMode: presentationMode.ACTIONS,
    externalAuth: externalAuth.ACTIONS,
    feedback: feedback.ACTIONS,
    healthCheck: healthCheck.ACTIONS,
    userSecurity: userSecurity.ACTIONS,
    userSettings: userSettings.ACTIONS
};

export const actions = {
    auth: auth.actions,
    users: users.actions,
    appSettings: appSettings.actions,
    projects: projects.actions,
    invoices: invoices.actions,
    tariffs: tariffs.actions,
    modalForm: modalForm.actions,
    trial: trial.actions,
    projectSettings: projectSettings.actions,
    presentationMode: presentationMode.actions,
    externalAuth: externalAuth.actions,
    feedback: feedback.actions,
    healthCheck: healthCheck.actions,
    userSecurity: userSecurity.actions,
    userSettings: userSettings.actions
};
