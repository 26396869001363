import * as d3 from "d3";
import { MIN_STEP } from "../../consts";
import { resetPlaceholder, setPlaceholder, blockHeight } from "../blocks";
import { resetTargetBlocks } from "../styles-reset";
import { MoveDirection, ITargetParentPosition, IPoint, IBlock, TargetPosition, BlockType } from "../../models";
import { store } from '../../store/configureStore';
import * as actions from '../../redux/actions'
import BaseBlock, { getHighestBlock } from "../blocks/base";
import canvasMovementTool from '../../core/common/canvasMovementTool';
import { getIsCatchedSelectionMoveCancel } from "../../redux/selectors/app";
import { appCatchedSelectionMoveCancel } from "../../redux/actions";

export interface IBlockToMove {    
    getLeadBlock():IBlock | undefined;
    isFewBlocksToMove(): boolean;
    move(newPosition: TargetPosition):void;
    reset():void;
}

export class BlocksMovements {

    private lastOverTopBottom: MoveDirection;
    private lastOverTopBottomId: string;
    private blockToMove:IBlockToMove;

    constructor(blockToMove:IBlockToMove){
        this.lastOverTopBottomId = "";
        this.lastOverTopBottom = MoveDirection.Undef;

        this.movingDone = this.movingDone.bind(this);
        this.handleMouseMoveCanvas = this.handleMouseMoveCanvas.bind(this);
        this.handleMouseOverTop = this.handleMouseOverTop.bind(this);
        this.handleMouseOverBottom = this.handleMouseOverBottom.bind(this);
        this.handleMouseOverBaseline = this.handleMouseOverBaseline.bind(this);
        this.subscribeNecessaryMovementEvents = this.subscribeNecessaryMovementEvents.bind(this);
        this.unsubscribeNecessaryMovementEvents = this.unsubscribeNecessaryMovementEvents.bind(this);
        this.blockToMove = blockToMove;
        this.computeNewPosition = this.computeNewPosition.bind(this);
        this.movingDoneProcessing = this.movingDoneProcessing.bind(this);
    }
    
    private startPosition?:IPoint = undefined;
    private catchedSelectedBlocks?: (catched: boolean) => void;
    private movingDoneEvent: string = "";
    subscribeNecessaryMovementEvents(movingDoneEvent:string="mouseup", catchedSelectedBlocks?: (catched: boolean)=> void) {     
        this.startPosition = undefined;   
        this.movingDoneEvent = movingDoneEvent;
        this.catchedSelectedBlocks = catchedSelectedBlocks;
        this.unsubscribeNecessaryMovementEvents();        
        
        d3.select(".work-canvas").on("mousemove", this.handleMouseMoveCanvas);
        d3.select(".work-canvas").on(movingDoneEvent, this.movingDone);
        d3.selectAll(".block-title-top").style("z-index", 100);
        d3.selectAll(".block-title-bottom").style("z-index", 100);
        d3.selectAll(".block-title-baseline").style("z-index", 100);
        d3.selectAll(".block-title-top").on("mouseover", this.handleMouseOverTop);
        d3.selectAll(".block-title-bottom").on("mouseover", this.handleMouseOverBottom);
        d3.selectAll(".block-title-baseline").on("mouseover", this.handleMouseOverBaseline);
    }

    unsubscribeNecessaryMovementEvents() {
        this.lastOverTopBottom = MoveDirection.Undef;
        d3.select(".work-canvas").on("mousemove", null);
        d3.select(".work-canvas").on(this.movingDoneEvent, null);
        d3.selectAll(".block-title-top").style("z-index", null);
        d3.selectAll(".block-title-bottom").style("z-index", null);
        d3.selectAll(".block-title-baseline").style("z-index", null);
        d3.selectAll(".block-title-top").on("mouseover", null);
        d3.selectAll(".block-title-bottom").on("mouseover", null);
        d3.selectAll(".block-title-baseline").on("mouseover", null);
    }

    private handleMouseOverTop() {
        this.lastOverTopBottom = MoveDirection.Top;
        this.lastOverTopBottomId = d3.event.target.id.substring(4, d3.event.target.id.length);
    }

    private handleMouseOverBottom() {        
        this.lastOverTopBottom = MoveDirection.Bottom;
        this.lastOverTopBottomId = d3.event.target.id.substring(7, d3.event.target.id.length);
    }

    private handleMouseOverBaseline() {
        this.lastOverTopBottom = MoveDirection.Baseline;
        this.lastOverTopBottomId = d3.event.target.id.substring(9, d3.event.target.id.length);
    }

    private computeNewPosition(x:number, y:number){

        const positionDelta = canvasMovementTool.getCanvasPositionDelta();
        const canvasCoord = canvasMovementTool.getCanvasPosition();
        
        let coord = {
            x: Math.floor(x / MIN_STEP) * MIN_STEP + positionDelta.x - canvasCoord.x, 
            y: Math.floor(y / MIN_STEP) * MIN_STEP + positionDelta.y - canvasCoord.y
        };

        const leadBlock = this.blockToMove.getLeadBlock();
        if (!this.blockToMove.isFewBlocksToMove() && leadBlock && this.startPosition && leadBlock.coord) {
            const delta = {
                x: coord.x - this.startPosition.x + Math.floor(canvasCoord.x / MIN_STEP) * MIN_STEP,
                y: coord.y - this.startPosition.y + Math.floor(canvasCoord.y / MIN_STEP) * MIN_STEP
            };
            coord = { x: leadBlock.coord.x + delta.x, y: leadBlock.coord.y + delta.y };
        }
        
        return coord;
    }

    private handleMouseMoveCanvas() {
        
        if(!this.startPosition){            
            this.startPosition = {
                x: Math.floor(d3.event.x / MIN_STEP) * MIN_STEP, 
                y: Math.floor(d3.event.y / MIN_STEP) * MIN_STEP
            };
        }

        if(d3.event.target.className.includes && d3.event.target.className.includes("work-canvas")){
            this.lastOverTopBottom = MoveDirection.Undef;
        }
        if (d3.event.path && d3.event.path[4].className.includes("rightpanel")) return;
        
        if(this.catchedSelectedBlocks) this.catchedSelectedBlocks(true);
        // При движение по компоненту убираем placeholder
        if (this.lastOverTopBottom != MoveDirection.Undef) { 
            store.dispatch(actions.appNewPositionBlockPlaceHolder(undefined));

            const selectionLeadBlock =  this.blockToMove.getLeadBlock();
            if(!selectionLeadBlock) return;

            const targetPosition = BaseBlock.getTargetPosition(selectionLeadBlock, this.lastOverTopBottom, this.lastOverTopBottomId) as ITargetParentPosition;
            if (targetPosition) {  
                resetTargetBlocks();     
                BaseBlock.targetBlock(targetPosition.block, true);
                setPlaceholder(targetPosition.block, targetPosition.indexOfChildren)
            }
            else { // если предполагаемое место вставки совпадает с добавляемым блоком
                const targetBlock = getHighestBlock(this.lastOverTopBottomId);
                if (targetBlock && targetBlock.coord) {
                    const heightOfBlock = blockHeight(targetBlock.id, true, true);
                    const y = targetBlock.coord && targetBlock.coord.y;
                    const coord = this.computeNewPosition(d3.event.x, y + heightOfBlock + 3 * MIN_STEP);
                    store.dispatch(actions.appNewPositionBlockPlaceHolder(coord));
                }
            }
            return;
        }
         
        resetPlaceholder();
        resetTargetBlocks();
        
        if (d3.event.target.className.includes && d3.event.target.className.includes("work-canvas") || d3.event.path[2] && d3.event.path[2].className.includes("work-canvas")) {
            
            this.moveCanvas(d3.event.x, d3.event.y, d3.event.target.className);
                           
            const coord = this.computeNewPosition(d3.event.x, d3.event.y);
            store.dispatch(actions.appNewPositionBlockPlaceHolder(coord));
        }
    }

    /*
     * Передвигаем рабочую область если курсор с захваченным элементом находится у границ рабочей области
     */
    private moveCanvas(x:number, y:number, targetClassName:string){
        if(x<1 || x>document.body.clientWidth-MIN_STEP || y<50 || y>document.body.clientHeight-MIN_STEP){
            let dx = 0;
            let dy = 0;
            const { workareaPosition } = store.getState().app;

            if(x<1)
                dx = 2; 
            else if(x>document.body.clientWidth-MIN_STEP)
                dx=-2;
            
            if(y<60)
                dy = 2;
            else if(y>document.body.clientHeight-MIN_STEP)
                dy = -2;
            
            workareaPosition.x += dx*MIN_STEP;
            workareaPosition.y += dy*MIN_STEP;
            canvasMovementTool.catchWorkarea(targetClassName);
            canvasMovementTool.onChangeStartPoint({x:workareaPosition.x, y:workareaPosition.y});
            canvasMovementTool.onChangeStartPoint({x:workareaPosition.x+dx*MIN_STEP, y:workareaPosition.y+dy*MIN_STEP});
            canvasMovementTool.dropWorkarea();
        }
    }

    private movingDone() {    

        if (d3.event.path && ((d3.event.path[2].className && d3.event.path[2].className.includes("user-blocks-editor")) 
                            || (d3.event.path[4].className && d3.event.path[4].className.includes("rightpanel"))
                            // || (d3.event.path[6].className && d3.event.path[6].className.includes("user-blocks-editor"))                            
                            )) {
                                resetPlaceholder();
                                resetTargetBlocks();
                                return;
                            }
        
        this.movingDoneProcessing();        
    }

    private movingDoneProcessing(){
        if(this.catchedSelectedBlocks) {
            this.catchedSelectedBlocks(false);
            if(getIsCatchedSelectionMoveCancel(store.getState())) {
                store.dispatch(appCatchedSelectionMoveCancel(false));
                this.unsubscribeNecessaryMovementEvents();
                d3.event.stopPropagation();
                return;
            }
        }

        store.dispatch(actions.appNewPositionBlockPlaceHolder(undefined));

        const { editingTextBlockId } = store.getState().app;

        // Append block to another
        let targetPosition: any = {}
        const leadBlock =  this.blockToMove.getLeadBlock();
        if (this.lastOverTopBottom != MoveDirection.Undef && leadBlock && !editingTextBlockId) {
            targetPosition = BaseBlock.getTargetPosition(leadBlock, this.lastOverTopBottom, this.lastOverTopBottomId) as ITargetParentPosition;
            
            if (targetPosition) {                
                
                this.blockToMove.move(targetPosition); 
                resetPlaceholder();
                resetTargetBlocks();
                BaseBlock.resetSelection();
                this.unsubscribeNecessaryMovementEvents();
                return;
            }
        }

        resetPlaceholder();
        resetTargetBlocks();
          
        if ((leadBlock && leadBlock.type !== BlockType.Fixed && d3.event.target.className.includes && (d3.event.target.className.includes("work-canvas") 
                || d3.event.target.className.includes("block-placeholder"))) 
                || !targetPosition) {
            
            let coord = {x:0, y:0};   
            if(!targetPosition) { 
                // если предполагаемое место вставки совпадает с добавляемым блоком
                const targetBlock = getHighestBlock(this.lastOverTopBottomId);
                if(targetBlock && targetBlock.coord) {
                    const heightOfBlock = blockHeight(targetBlock.id, true, true);
                    const y = targetBlock.coord && targetBlock.coord.y;
                    coord = this.computeNewPosition(d3.event.x, y + heightOfBlock + 3*MIN_STEP);
                }                
            }
            else {
                // Move block
                coord = this.computeNewPosition(d3.event.x, d3.event.y);
            }
            
            
            if(leadBlock && leadBlock.type === BlockType.Page){ 
                coord.y = coord.y - 3*MIN_STEP;
            }
            

            this.blockToMove.move(coord);
        }
        this.unsubscribeNecessaryMovementEvents();
        d3.event.stopPropagation();
    }

}