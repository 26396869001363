import {ACTIONS} from '../../actions/modalForm/projectSettingsCreate';
import { Action } from 'redux';

export interface IState {
    isOpen: boolean;
}

export const initial: IState = {
    isOpen: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.MODAL_FORM.INIT:
            return {
                ...state,
                isOpen: true
            };

        case ACTIONS.MODAL_FORM.DONE:
        {
            return {
                ...state,
                isOpen: false
            };
        }
        default:
            return state;
    }
}
