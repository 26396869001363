import * as projectSettingsAction from './projectSettingsEdit';
import * as projectSettingsCreateAction from './projectSettingsCreate';
import * as signIn from './signInForm';
import * as signUpForm from './signUpForm';
import * as messageSendingForm from './messageSendingForm';
import * as resetPasswordForm from './resetPasswordForm';
import * as forgetPasswordForm from './forgetPasswordForm';
import * as dropDownMenu from './dropDownMenu';

export const ACTIONS = {
    projectSettings: projectSettingsAction.ACTIONS,
    projectSettingsCreate: projectSettingsCreateAction.ACTIONS,
    signIn: signIn.ACTIONS,
    signUp: signUpForm.ACTIONS,
    messageSendingForm: messageSendingForm.ACTIONS,
    resetPasswordForm: resetPasswordForm.ACTIONS,
    forgetPasswordForm: forgetPasswordForm.ACTIONS,
    dropDownMenu: dropDownMenu.ACTIONS
};

export const actions = {
    projectSettings: projectSettingsAction.actions,
    projectSettingsCreate: projectSettingsCreateAction.actions,
    signIn: signIn.actions,
    signUp: signUpForm.actions,
    messageSendingForm: messageSendingForm.actions,
    resetPasswordForm: resetPasswordForm.actions,
    forgetPasswordForm: forgetPasswordForm.actions,
    dropDownMenu: dropDownMenu.actions
};
