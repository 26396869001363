import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ru from "./locales/ru.json";
import en from "./locales/en.json";

i18n
    .use(LanguageDetector)
    .init({
        interpolation: { escapeValue: false },
        fallbackLng: 'en',
        // debug: true,
        resources: {
            en: {
                translation: en        
            },
            ru: {
                translation: ru
            },
        },
        // react i18next special options (optional)
        // react: {
        //     wait: true,
        //     bindI18n: 'languageChanged loaded',
        //     bindStore: 'added removed',
        //     nsMode: 'default',
        // },
    });

export default i18n;
