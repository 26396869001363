import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/users/index';
import {getUserByToken, IUserRead} from "../../../api/user/index";
import {actions} from "../../actions/index";
import { IErrorResponse } from '../../../api';

export function* sagaHandler(action: Action) {
    try {
        const result: IUserRead =  yield call(getUserByToken);
        yield put(actions.users.currentUser.fetch.done(result));
        yield put(actions.userSettings.view.init(result));
    } catch (e) {
        yield put(actions.users.currentUser.fetch.fail(e as IErrorResponse))
    }
}

export function* currentUserSaga() {
    yield takeLatest(ACTIONS.currentUser.FETCH.INIT, sagaHandler);
}
