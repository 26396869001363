import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import UserDashboard from './UserDashboard';
import './style.scss';
import UserProfile from './UserProfile';
import * as H from 'history';
import { Action } from 'redux';
import * as actions from '../../redux/actions';
import { connect } from 'react-redux';
import HeaderAuthProfile from "../Header/HeaderAuthProfile";
import Footer from "../Footer";

interface IDispatchProps {
    closePopupMenus: (close: boolean) => Action;
}

const mapDispatchToProps: IDispatchProps = {
    closePopupMenus: actions.appCloseAllPopupMenus
}

interface IRouterProps {
    match: any;
    history: H.History;
    location: H.Location;
}

class UserSpace extends React.Component<IRouterProps & WithTranslation & IDispatchProps> {

    constructor(props:any){
        super(props);
        this.handleDownClick = this.handleDownClick.bind(this);
        this.handleUpClick = this.handleUpClick.bind(this);
    }

    handleDownClick(event:any){
        this.props.closePopupMenus(true);
    }

    handleUpClick(event:any){
        this.props.closePopupMenus(false);
    }

    render() {
        const { history, t } = this.props;

        return (<div className="user-space" onMouseDown={this.handleDownClick} onMouseUp={this.handleUpClick}>
            <HeaderAuthProfile />

            <div className="user-space-content legal-scroll">
                <Switch>
                    <Route exact={true} path="/dashboard" component={UserDashboard} />
                    <Route exact={true} path="/profile" component={UserProfile} />
                </Switch>
            </div>

            <Footer/>
        </div>)
    }
}

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(UserSpace))as any);
