import React from 'react';
import { IBlock, ElementBlockType, BlockType } from '../../models';
import selectedBlocks  from '../../models/selection';
import cn from 'classnames';
import './styles/block.scss';
import './styles/block-page.scss';
import './styles/block-section.scss';
import './styles/block-element.scss';
import './styles/block-template-composite.scss';
import './styles/block-template-fixed.scss';
import './styles/block-composite.scss';
import './styles/block-fixed.scss';
import BlockTitle from './BlockTitle';
import { isPlaceholderBlock, updateCompositeBlockChildren, dissemblyCompositeBlock } from '../../core/blocks';
import { IState } from '../../redux/reducers';
import { connect } from 'react-redux';
import BaseBlock, { getHighestBlock } from '../../core/blocks/base';
import { getCreatorToolType } from '../../redux/selectors/app';
import canvasBlockCreator from "../WorkCanvas/helpers/canvasBlockCreator";
import { BLOCK_PLACEHOLDER_ID } from '../../consts';
import { computeStyle } from './helpers';
import commandKey from '../helpers/commandKey';
import canvasMovementTool from '../../core/common/canvasMovementTool';

interface IBlockPresenterOwnProps {
    block: IBlock;    
}

interface IBlockPresenterProps {
    placeHolderVisible: boolean;
    isPresentationMode: boolean;
    catchedSelection: boolean;
    creationTool: BlockType | ElementBlockType;
    template?: IBlock;
}

const mapStateToProps = (state: IState, props:IBlockPresenterOwnProps): IBlockPresenterProps => {

    return {
        placeHolderVisible: state.app.newPositionBlockPlaceHolder ? true : false,
        catchedSelection: state.app.catchedSelection,
        creationTool: getCreatorToolType(state),
        isPresentationMode: state.presentationMode.view.isPresentationMode,
        template: props.block.templateId ? BaseBlock.getBlock(props.block.templateId) : undefined
    };
};

class BlockPresenter extends React.Component<IBlockPresenterOwnProps & IBlockPresenterProps> {

    constructor(props: any) {
        super(props);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);

        this.selectBlock = this.selectBlock.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.isHidden = this.isHidden.bind(this);
    }

    componentDidUpdate(prevProps: IBlockPresenterProps & IBlockPresenterOwnProps) {
        const { block } = this.props;

        // При изменения шаблона -> меняем блоки от которых он зависит
        if(block.type === BlockType.Element && this.props.template){
            if (prevProps.template && prevProps.template.subType !== this.props.template.subType) {
                block.subType = this.props.template.subType;
                BaseBlock.updateBlock(block, true);
            }
        }    
        else if(block.type === BlockType.Fixed && this.props.template) {
            if (prevProps.template && prevProps.template.title !== this.props.template.title) {
                block.title = this.props.template.title;
                BaseBlock.updateBlock(block, true);
            }
        }        
        else if(block.type === BlockType.Composite && this.props.template && this.props.template.children.length>0){
            if (prevProps.block.children.length !== this.props.template.children.filter(id=>id!==BLOCK_PLACEHOLDER_ID).length) {
                updateCompositeBlockChildren(block, this.props.template.children.filter(id=>id!==BLOCK_PLACEHOLDER_ID));
            }
        }      
        else if(block.type === BlockType.Composite && (this.props.template && this.props.template.children.length===0 || !this.props.template)){
            dissemblyCompositeBlock(block, this.props.template);
        }      
    }

    onMouseOver() {
        const { block } = this.props;
        BaseBlock.hover(block, true);
    }

    onMouseOut() {
        const { block } = this.props;
        BaseBlock.hover(block, false);
    }

    onMouseDown(event: any) {
        this.selectBlock(event);
        event.stopPropagation();
    }

    selectBlock(event: any) {
        const { creationTool, block } = this.props;
        const highest = getHighestBlock(block.id);
        if (creationTool == BlockType.SelectTool 
                        && highest && highest.type !== BlockType.TemplateComposite
                                   && highest.type !== BlockType.TemplateFixed) {        
            if (!event.ctrlKey && !commandKey(event)) {
                BaseBlock.resetSelection();
                selectedBlocks.reset();                
            }
            BaseBlock.select(block, true);
        }
        else if(creationTool == BlockType.SelectTool 
                && highest && (highest.type === BlockType.TemplateComposite || highest.type === BlockType.TemplateFixed)){
            // Выделенная логика для управления выделением BlockType.Template
            BaseBlock.resetSelection(BaseBlock.getBlocks().filter(b=>b.isSelected).map(b=>b.id));
            const isSelected = block.isSelected;
            BaseBlock.select(block, !isSelected, false);
            if(!isSelected && (block.type === BlockType.TemplateComposite || block.type === BlockType.TemplateFixed)){
                canvasBlockCreator.changeCreatorTool(event, this.selectBlockTypeByTemplate(block.type), block.id);
            }
            else {
                BaseBlock.resetSelection();
            }
        }
    }

    selectBlockTypeByTemplate(template:BlockType){
        if(template === BlockType.TemplateComposite)
            return BlockType.Composite;
        return BlockType.Fixed;
    }

    isHidden(){
        const {block}  =this.props;
        if(block.type === BlockType.Element && block.templateId){
            return true;
        }

        return false;
    }

    render() {
        
        const { block, children, placeHolderVisible, catchedSelection, isPresentationMode } = this.props;
        
        let style: any = {};

        if (block.coord) {
            const delta = canvasMovementTool.getCanvasPosition();
            style = { ...style, left: block.coord.x + delta.x, top: block.coord.y + delta.y };
        }

        const classNames = cn(block.coord ? "block" : '',
            block.isHover && !isPlaceholderBlock() ? "default-hover-outline" : '',
            block.isSelected && block.type === BlockType.TemplateComposite ? "default-select-outline" : '',
            block.isSelected && !placeHolderVisible ? "default-select-outline" : '',
            block.isSelected && (placeHolderVisible || catchedSelection) ? "block-moving-selection" : '',
            block.isTargetBlock ? "target-block-outline" : '',
            `${block.type}-block`);

        const titleClassNames = cn(`${block.type}-block-title`,block.type === BlockType.Page && block.isSelected ? 'page-block-selected' : '')

        return (<div id={block.id} style={style} className={classNames} hidden={this.isHidden()}>

            <BlockTitle block={block} className={titleClassNames} style={computeStyle(style, block)} isPresentationMode={isPresentationMode}
                onMouseDown={this.onMouseDown}
                onMouseOut={this.onMouseOut}
                onMouseOver={this.onMouseOver} />

            {block.type!==BlockType.Fixed && children}

        </div>);
    }
}

export default connect(mapStateToProps)(BlockPresenter);
