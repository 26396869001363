import React from 'react';
import { AddCompositeIcon } from '../../icons';
import './styles/add-child-to-composite.scss';
import BaseBlock from '../../core/blocks/base';
import { IBlock, ElementBlockType } from '../../models';
import { createDefaultElementBlock } from '../../core/creators/createElementBlock';
import { resetPlaceholder } from '../../core/blocks';
import { resetTargetBlocks } from '../../core/styles-reset';

interface IAddElementToCompositeButtonProps {
    target: IBlock
}

export default class AddElementToCompositeButton extends React.Component<IAddElementToCompositeButtonProps> {

    constructor(props:any){
        super(props);
        this.onMouseDown = this.onMouseDown.bind(this);
    }

    onMouseDown(event:any){
        resetPlaceholder();
        resetTargetBlocks();

        const { target } = this.props;
        const newElBlock = createDefaultElementBlock(ElementBlockType.Text, undefined, target.id);
        BaseBlock.addChild(target,newElBlock.id, target.children.length);

        event.stopPropagation();
    }

    render(){
        return <div className="add-child-to-composite-button" onMouseDown={this.onMouseDown}><AddCompositeIcon/></div>
    }
}