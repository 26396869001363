import {ACTIONS, IOpenInitAction} from '../../actions/modalForm/projectSettingsEdit';
import { Action } from 'redux';

export interface IState {
    id: string;
    isOpen: boolean;
}

export const initial: IState = {
    id: "",
    isOpen: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.MODAL_FORM.INIT:
            const { id } = action as IOpenInitAction;
            return {
                ...state,
                id,
                isOpen: true
            };

        case ACTIONS.MODAL_FORM.DONE:
        {
            return initial;
        }
        default:
            return state;
    }
}
