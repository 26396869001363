import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/users/index';
import {IUserRead, updateUser} from "../../../api/user/index";
import { actions } from "../../actions/index";
import { ISaveInitAction } from "../../actions/users/update";
import { IErrorResponse } from "../../../api";

export function* sagaHandler(action: Action) {
    try {
        const { id, item } = action as ISaveInitAction;
        const result: IUserRead = yield call(updateUser, id,  item);

        yield put(actions.users.update.save.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.users.update.save.fail(c))
    }
}

export function* updateSaga() {
    yield takeLatest(ACTIONS.update.SAVE.INIT, sagaHandler);
}
