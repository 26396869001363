import React, { SyntheticEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
// todo: import i18n from '../../../i18n'; i18n.language - для локализации формы оплаты
import './style.scss';
import SubscriptionItem from './SubscriptionItem';
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers';
import {ITariffCurrency, IInvoice, Tariff, InvoiceStatus, IUserRead} from '../../../api';
import { Action } from 'redux';
import { actions } from '../../../redux/actions';
import ModalForm from '../../Landing/ModalForm';
import SubmitButton from '../../Buttons/SubmitButton';
import { Payment } from './../../payment/payment';
import { IAppSettings } from '../../../api/appSettings';
import i18next from "i18next";

interface IStateProps {
    tariffCurrency: ITariffCurrency | null,
    user: IUserRead | null,
    trialPeriod?: number | null,
    appSettings?: IAppSettings | null;
    inProgress: boolean;
    error: string | null;
    lastUserInvoice: IInvoice | null;
    lastInvoiceInProgress: boolean;

}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        appSettings: state.appSettings.view.item,
        user: state.currentUser.item,
        tariffCurrency: state.tariffs.view.item,
        trialPeriod: state.currentUserTrialPeriod.period,
        inProgress: state.tariffs.view.inProgress,
        error: state.tariffs.view.error,
        lastUserInvoice: state.invoices.lastInvoice.item,
        lastInvoiceInProgress: state.invoices.lastInvoice.inProgress,

    };
}

interface IDispatchProps {
    fetchTariffs: (cultureInfo: string) => Action;
    fetchCurrentUser: () => Action;
    fetchCurrentUserTrialPeriod: (id: string) => Action;
    fetchAppSettings: () => Action;
    saveInvoice: (item: IInvoice) => Action;
    fetchLastUserInvoice: (id: string) => Action;
}

const mapDispatchStateToProps: IDispatchProps = {
    fetchAppSettings: actions.appSettings.view.fetch.init,
    fetchTariffs: actions.tariffs.view.fetch.init,
    fetchCurrentUser: actions.users.currentUser.fetch.init,
    fetchCurrentUserTrialPeriod: actions.users.currentUserTrialPeriod.fetch.init,
    saveInvoice: actions.invoices.create.save.init,
    fetchLastUserInvoice: actions.invoices.lastInvoice.fetch.init
}

interface IUserSubscriptionState {
    selectedPlan: number;
    isShowModal: boolean;
    isShowBuyButton: boolean;
}

class UserSubscription extends React.Component<WithTranslation & IStateProps & IDispatchProps, IUserSubscriptionState> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedPlan: 0,
            isShowModal: false,
            isShowBuyButton: true
        }
        this.selectPlan = this.selectPlan.bind(this);
    }

    componentDidMount() {
        const { fetchTariffs, fetchCurrentUserTrialPeriod, fetchAppSettings, user } = this.props;
        fetchTariffs(i18next.languages[0]);
        if (user && user.id) {
            fetchAppSettings();
            fetchCurrentUserTrialPeriod(user.id);
        }
    }

    selectPlan(id: number) {
        this.setState((state) => {
            return {
                ...state,
                selectedPlan: id
            };
        });
    }

    buy = () => {
        const { tariffCurrency, user, saveInvoice, fetchLastUserInvoice } = this.props;
        const { selectedPlan } = this.state;
        const pricePro: number = tariffCurrency && tariffCurrency.tariffs.pro || 0;
        const priceStudio: number = tariffCurrency && tariffCurrency.tariffs.studio || 0;

        let userId: string = '';
        if (user && user.id) {
            userId = user.id.toString();
        }

        const item: IInvoice = {
            userId,
            tariff: selectedPlan === 1 ? Tariff.PRO : Tariff.STUDIO,
            status: InvoiceStatus.NEW,
            paymentValue: selectedPlan === 1 ? pricePro : priceStudio
        }

        saveInvoice(item);

        if (!!userId) {
            fetchLastUserInvoice(userId);
        }
        this.setState({ isShowModal: true });
    }
    onSubmitModal = () => {
        this.setState({ isShowModal: false, });
    }

    render() {
        const { t, tariffCurrency, user, trialPeriod = 0, appSettings, lastInvoiceInProgress } = this.props;
        const { selectedPlan, isShowModal, isShowBuyButton } = this.state;
        const pricePro: number = tariffCurrency && tariffCurrency.tariffs.pro || 0;
        const priceStudio: number = tariffCurrency && tariffCurrency.tariffs.studio || 0;

        let trilaTimeHours: number = 0;
        let trilaTimeMinutes: number = 0;

        if (trialPeriod) {
            trilaTimeHours = Math.trunc((trialPeriod) / 60);
            trilaTimeMinutes = (trialPeriod) % 60;
        }
       
        if (!isShowModal) {
            return (<div className="user-layout">
                <div className="user-layout-main">
                    <div className="user-layout-main-title">{t('user.subscription.title')}: {t('user.subscription.trial')}</div>
                    <div className="user-layout-main-subtitle">{`${trilaTimeHours} h ${trilaTimeMinutes} m ${t('user.subscription.trial-info')}`}</div>

                    <div className="user-layout-inline user-subscription-items">
                        {trialPeriod !== 0 && <SubscriptionItem id={0} title={t('user.subscription.trial')} selected={selectedPlan == 0} price={0} onClick={this.selectPlan} />}
                        <SubscriptionItem id={1} title={t('user.subscription.monthly')} selected={selectedPlan === 1} price={pricePro} onClick={this.selectPlan} />
                        <SubscriptionItem id={2} title={t('user.subscription.yearly')} selected={selectedPlan === 2} price={priceStudio}
                            subtitle={`${t('user.subscription.yearly-saving')} ${500} ${t('rub')}`} onClick={this.selectPlan} />
                    </div>

                    {selectedPlan != 0 && isShowBuyButton &&
                        <button type={'submit'} onClick={this.buy} className="user-subscription-button">
                            {t('user.subscription.buy')}
                        </button>
                    }
                </div>
            </div>)
        } else {
            const { lastUserInvoice, } = this.props;

            return (
                <ModalForm title={t('user.subscription.modalForm.title')} closeForm={this.onSubmitModal} classes="user-subscription-modal-form" keysHandlers={this.onSubmitModal}>
                    {appSettings && lastInvoiceInProgress === false? <Payment
                        payment={{
                            terminalkey: appSettings.terminalId || '',
                            currency: tariffCurrency ? tariffCurrency.type : "USD",
                            amount: this.state.selectedPlan === 1 ? pricePro : priceStudio,
                            description: this.state.selectedPlan === 1 ? 'Оплата месячная подписки' : 'Оплата годовой подписки',
                            order: (lastUserInvoice && lastUserInvoice.id) || '',
                            name: '' || '',
                            phone: user && user.email || '',
                        }}> {selectedPlan != 0 && isShowBuyButton
                            && <button type={'submit'} className="user-subscription-button">{t('user.subscription.pay')}</button>}
                    </Payment>:<img className="spinner"      title="loading..."/>}

                    {lastUserInvoice && lastUserInvoice.status === InvoiceStatus.PAID &&
                        <SubmitButton title={t('user.subscription.modalForm.ok')} disabled={isShowBuyButton} onSubmit={this.onSubmitModal} />
                    } </ModalForm>);
        }
    }
}

export default connect(mapStateToProps, mapDispatchStateToProps)(withTranslation()(UserSubscription));
