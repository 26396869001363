import * as React from "react";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import ModalForm from "../../Landing/ModalForm/index";
import {Action} from "redux";
import * as actions from "../../../redux/actions/index";
import './style.scss'
import door from '../../../images/door@273.png'
import {IState} from "../../../redux/reducers";
import SubmitButton from "../../Buttons/SubmitButton";

interface IStateToProps{
    isConfirmEmail: boolean,
    isForgetPassword: boolean
}

interface IDispatchProps{
    closeConfirmForm:() => Action
}

const mapDispatchToProps: IDispatchProps = {
    closeConfirmForm: actions.actions.modalForm.messageSendingForm.close
};

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        isConfirmEmail: state.modalForm.messageSendingForm.isConfirmEmail,
        isForgetPassword: state.modalForm.messageSendingForm.isForgetPassword
    }
};

export class MessageSendingForm extends React.Component<WithTranslation & IDispatchProps & IStateToProps> {
    constructor(props:any){
        super(props);
        this.handleCloseForm = this.handleCloseForm.bind(this);
    }

    handleCloseForm(){
        this.props.closeConfirmForm()
    }

    render(){
        const {t, isConfirmEmail} = this.props;
        return <ModalForm title= {isConfirmEmail ? t('messageSendingForm.confirm.title') : t('messageSendingForm.forget.title')} closeForm={this.handleCloseForm} classes="confirm" keysHandlers={this.handleCloseForm}>
            <div className="messageSendingForm">
                <div className="door">
                    <img src={door}/>
                </div>
                <div className="information">
                    <h1>{isConfirmEmail ? t('messageSendingForm.confirm.title') : t('messageSendingForm.forget.title')}</h1>
                    <div className="offer">
                        <span className="offer-span">{t('messageSendingForm.offer')}</span>
                        <span className="offer-span">{isConfirmEmail ? t('messageSendingForm.confirm.offerConfirm') : t('messageSendingForm.forget.offerConfirm')}</span>
                    </div>
                    <div className="button-block">
                        <div className="button-block-messageSendingForm">
                            <SubmitButton title={t('messageSendingForm.ok')} onSubmit={this.handleCloseForm}/>
                        </div>
                    </div>
                </div>
            </div>
        </ModalForm>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MessageSendingForm));
