import { IUserWrite, IErrorResponse } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'USERS_VIEW';

export const ACTIONS = {
    FETCH: actionsSet(prefix, 'FETCH')
};

export interface IFetchInitAction extends Action {
    id: string;
}

export interface IFetchDoneAction extends Action {
    item: IUserWrite;
}

export const actions = {
    fetch: {
        init: (id: string) => ({type: ACTIONS.FETCH.INIT, id }) as IFetchInitAction,
        done: (item: IUserWrite) => ({type: ACTIONS.FETCH.DONE, item }) as IFetchDoneAction,
        fail: (error: IErrorResponse) => ({type: ACTIONS.FETCH.FAIL, error }) as IFailAction,
    },
}
