import React from 'react';
import ResizableTextArea from '../ResizableTextArea';
import { IContentItem, ContentComponentType } from '../../../../../models';
import { ImageIcon, TextIcon } from '../../../../../icons';
import cn from 'classnames';

interface IGalleryItemOwnState {
    values: string[];
}

interface IGalleryItemOwnProps {
    index: number;
    items: IContentItem[];
    legendOn: boolean;
    isPresentationMode: boolean;
    updateValue: (value:IContentItem[], index: number) => void;
    selectBlock: () => void;
}

export default class GalleryItem extends React.Component<IGalleryItemOwnProps, IGalleryItemOwnState> {

    constructor(props:any){
        super(props);

        this.state = {values:[]};
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.imageComponent = this.imageComponent.bind(this);        
        this.legendComponent = this.legendComponent.bind(this);
    }

    componentDidMount() {
        this.setState({ ...this.state, values: this.props.items.map(_=>_.value) });
    }

    onFocus(e:any, index:number){
        this.props.selectBlock(); // активируем блок
    }

    onBlur(e:any, index:number){        
        const itemsCopy = [...this.props.items];
        itemsCopy[index] = {isDefault:false, value: this.state.values[index]};
        this.props.updateValue(itemsCopy, this.props.index);
    }    

    handleChange(e:any, index:number) {
        let value = e && e.target && e.target.value;
        const copyValues = [...this.state.values];
        copyValues[index] = value;
        this.setState({...this.state, values: copyValues});

        if(this.props.items[index].isDefault) {
            const itemsCopy = [...this.props.items];
            itemsCopy[index] = {isDefault:false, value: value};
            this.props.updateValue(itemsCopy, this.props.index);
        }            
    }

    imageComponent(){
        const i = 0;
        const { isPresentationMode, items } = this.props;
        const style = items[i].isDefault ? 'content-gallery-item-default': '';
        return <div className={cn(style, 'gallery-item-image')}>
                    <ImageIcon />
                    <ResizableTextArea value={this.state.values[i]} onChange={(e:any) => this.handleChange(e, i)} disabled={isPresentationMode} onFocus={(e:any) => this.onFocus(e, i)} onBlur={(e:any) => this.onBlur(e, i)} />
               </div>;
    }

    legendComponent(){
        const i = 2;
        const { isPresentationMode, items } = this.props;
        const style = items[i].isDefault ? 'content-gallery-item-default': '';
        return <div className={cn(style, 'gallery-item-legend')}>
                    <TextIcon />
                    <ResizableTextArea value={this.state.values[i]} onChange={(e:any) => this.handleChange(e, i)} disabled={isPresentationMode} onFocus={(e:any) => this.onFocus(e, i)} onBlur={(e:any) => this.onBlur(e, i)} />
               </div>;
    }

    render(){
        const { legendOn } = this.props;
        
        return <li>
                    {this.imageComponent()}
                    {legendOn && this.legendComponent()}
               </li>;
    }
}