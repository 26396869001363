import React from "react";
import {Action} from "redux";
import {IState} from "../../redux/reducers";
import * as actions from "../../redux/actions";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import './style.scss'
import {DefaultButton} from "../Buttons/DefaultButton";

interface IProps {
    id: string
}

interface IStateProps {
    projectLink: string | null,
    linkShared: boolean;
}

interface IDispatchProps {
    shareProject: (id: string) => Action;
    closeProject: (id: string) => Action;
}

interface ISettingsFormState {
    linkShared: boolean;
    projectLink: string | null;
}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        projectLink: state.presentationMode.share.item,
        linkShared: state.presentationMode.view.isPresentationMode
    };
};

const mapDispatchToProps: IDispatchProps = {
    shareProject: actions.actions.presentationMode.share.init,
    closeProject: actions.actions.presentationMode.close.init
};

export class ShareLink extends React.Component<WithTranslation & IStateProps & IDispatchProps & IProps, ISettingsFormState> {
    constructor(props: any) {
        super(props);

        this.state = {
            linkShared: this.props.linkShared,
            projectLink: ""
        };

        this.shareProject = this.shareProject.bind(this);
        this.copyLink = this.copyLink.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<WithTranslation & IStateProps & IDispatchProps & IProps>){
        if (this.props && this.props.projectLink !== prevProps.projectLink){
            this.setState({...this.state, projectLink: this.props.projectLink ? this.props.projectLink.toString().substring(0, 20) : null})
        }
    }

    shareProject() {
        const { shareProject, closeProject, id } = this.props;
        this.setState((state) => ({ linkShared: !state.linkShared }));
        setTimeout(() => {
            if (shareProject && this.state.linkShared) {
                shareProject(id);
            } else {
                closeProject(id);
            }
        }, 300);

    }

    copyLink(e:any) {
        e.stopPropagation();
       
        var textArea = document.createElement("textarea");
        textArea.value = this.props.projectLink ? this.props.projectLink : '';
        textArea.style.position="fixed";  //avoid scrolling to bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    }

    render(){
        const {projectLink } = this.props;
        return (<div className="share-link">
            <label className="switch">
                <input type="checkbox" checked={this.state.linkShared} onChange={this.shareProject} />
                <span className="slider round"></span>
            </label>

            <div className="project-link">{
                this.state.linkShared &&
                projectLink ?
                    <a href={projectLink}>{this.state.projectLink}...</a> :
                    <> <div className="generate-link">Generate presentation link</div> </>}
            </div>

            <DefaultButton className={"share-copy-button"} title="copy" onClick={this.copyLink}/>
        </div>)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShareLink));
