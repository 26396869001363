import { Action } from 'redux';
import { actionsSet } from '../../../actions/index';
const prefix = 'MESSAGE_SENDING';

export const ACTIONS = {
    MESSAGE_SENDING: actionsSet(prefix, "")
};

export interface IOpenInitAction extends Action {
    isConfirmEmail: boolean,
    isForgetPassword: boolean
}

export interface ICloseDoneAction extends Action {
}

export const actions = {
    open: (isConfirmEmail: boolean, isForgetPassword: boolean) => ({type: ACTIONS.MESSAGE_SENDING.INIT, isConfirmEmail, isForgetPassword}) as IOpenInitAction,
    close: () => ({type: ACTIONS.MESSAGE_SENDING.DONE}) as ICloseDoneAction,
}
