import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/users/index';
import {createUser, IUserWrite} from "../../../api/user/index";
import {actions} from "../../actions/index";
import {ISaveInitAction} from "../../actions/users/create";
import {IErrorResponse} from "../../../api";

export function* sagaHandler(action: Action) {
    try {
        const { item } = action as ISaveInitAction;
        const result: IUserWrite =  yield call(createUser, item);
        yield put(actions.users.create.done(result))
        yield put(actions.modalForm.signUp.close())
        yield put(actions.modalForm.messageSendingForm.open(true, false))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.users.create.fail(c))
    }
}

export function* viewSaga() {
    yield takeLatest(ACTIONS.create.SAVE.INIT, sagaHandler);
}
