import React from 'react';
import { IBlock } from '../../models';
import MovementPanel from '../MovementPanel';
import BaseBlock from '../../core/blocks/base';

interface IBlockMovementPanel {
    block:IBlock;
}

export default class BlockMovementPanel extends React.Component<IBlockMovementPanel> {

    constructor(props:any){
        super(props);

        this.removeBlock = this.removeBlock.bind(this);
        this.moveItemUp = this.moveItemUp.bind(this);
        this.moveItemDown = this.moveItemDown.bind(this);
    }

    moveItemUp(id:number|string){
        if(typeof id === 'number') return;

        const {block} = this.props;

        if(!block.parentId) return;

        const parent = BaseBlock.getBlock(block.parentId);
        if(!parent) return;

        const currInx = parent.children.indexOf(block.id);
        if(currInx === 0) return;

        BaseBlock.removeChild(parent,block.id);
        BaseBlock.addChild(parent, block.id, currInx-1);
        BaseBlock.updateBlock(parent);
    }

    moveItemDown(id:number|string){
        if(typeof id === 'number') return;

        const {block} = this.props;

        if(!block.parentId) return;

        const parent = BaseBlock.getBlock(block.parentId);
        if(!parent) return;

        const currInx = parent.children.indexOf(block.id);
        if(currInx === parent.children.length - 1) return;

        BaseBlock.removeChild(parent,block.id);
        BaseBlock.addChild(parent, block.id, currInx+1);
        BaseBlock.updateBlock(parent);
    }

    removeBlock(id:number|string){
        if(typeof id === 'number') return;

        const {block} = this.props;

        if(!block.parentId) return;

        BaseBlock.removeBlock(id, false);
    }

    render(){

        const {block} = this.props;

        return <MovementPanel id={block.id} movedown={this.moveItemDown} moveup={this.moveItemUp} remove={this.removeBlock} />
    }
}