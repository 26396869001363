import {IErrorResponse, IUserRead, IUserSettings} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'USER_SETTINGS_UPDATE';

export const ACTIONS = {
    SAVE: actionsSet(prefix, 'SAVE'),
};

export interface ISaveInitAction extends Action {
    item: IUserSettings;
}

export interface ISaveDoneAction extends Action {
    item: IUserRead;
}

export const actions = {
    save: {
        init: (item: IUserSettings) => ({ type: ACTIONS.SAVE.INIT, item }) as ISaveInitAction,
        done: (item: IUserRead) => ({ type: ACTIONS.SAVE.DONE, item }) as ISaveDoneAction,
        fail: (error: IErrorResponse) => ({ type: ACTIONS.SAVE.FAIL, error }) as IFailAction,
    },
}
