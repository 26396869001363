import React from 'react';

export default class PrivacyPolicy_RU extends React.Component {

    render(){

        return <div id="ua_wrapper">
                    <h1>Пользовательское соглашение ООО “Визлс”</h1>
                    <h6>Дата публикации: 06 октября 2019</h6>
                    <h2>1. Общие положения</h2>
                    <p className="li-dot1">1.1.</p> <p className="li-item1">Настоящее пользовательское соглашение (далее - Соглашение) в соответствии с положениями ст. 437 и 438 ГК РФ является офертой ООО “Визлс”, 129336, Москва, ул. Стартовая, дом 39, кв. 81 (далее - Администрация), и адресовано любому пользователю присоединившемуся к настоящему Соглашению в собственном интересе либо выступающему от имени и в интересах представляемого им лица (далее – Пользователю), на изложенных ниже условиях.</p>
                    <p className="li-dot1">1.2.</p> <p className="li-item1">Регистрация в специальной форме “Регистрация”, размещенной по адресу: <a href="http://vizls.com">https://vizls.com</a>, является безусловным и безоговорочным принятием (акцептом) Пользователем условий Соглашения и выражением согласия со всеми его условиями без каких-либо исключений. </p>
                    <p className="li-dot1">1.3.</p> <p className="li-item1">Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в порядке, предусмотренном в п. 1.2. настоящего Соглашения.</p>
                    <p className="li-dot1">1.4.</p> <p className="li-item1">Термины и определения:</p>
                    <p className="li-item1">В настоящем документе и вытекающих или связанным с ним отношениях cторон Соглашения применяются следующие термины и определения:</p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Платформа - программный комплекс Администрации, расположенный по адресу <a href="https://vizls.com">https://vizls.com</a>. </p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Учетная запись (Аккаунт) - уникальная информация о Пользователе на Платформе, которая однозначно его идентифицирует. </p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Личный кабинет - раздел Платформы, с помощью которого Пользователь может использовать функционал Платформы. </p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Проект - результат использования Платформы Пользователем.</p>
                    <p className="li-dot1 dot sub">•</p> <p className="li-item1">Тариф — размер вознаграждения за определенный объем предоставляемых Пользователю прав и услуг в соответствии с условиями Соглашения.</p>
                    
                    <h2>2. Регистрация Пользователя. Учетная запись Пользователя</h2>
                    <p className="li-dot1">2.1.</p> <p className="li-item1">Для использования Платформы Пользователю необходимо пройти процедуру регистрации, в результате которой для Пользователя будет создана уникальная Учетная запись для доступа в Личный кабинет. </p>
                    <p className="li-dot1">2.2.</p> <p className="li-item1">Пользователь проходит регистрацию по адресу <a href="https://vizls.com">https://vizls.com</a>, используя форму “Регистрация”, указывая логин (адрес электронной почты) и пароль. При регистрации Пользователь также может указать имя (ник) и (или) загрузить изображение (фотографию) для Учетной записи (аватар). Имя (ник) и изображение могут сопровождать опубликованные Пользователем материалы в рамках использования сервисов Платформы.</p>
                    <p className="li-item1">В случае использования в качестве изображения фотографии Пользователя, Пользователь осознает и соглашается с тем, что Администрация вправе проводить опубликование и дальнейшее использование изображения Пользователя в сервисах Платформы.</p>
                    <p className="li-dot1">2.3.</p> <p className="li-item1">Администрация оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных, указанных при регистрации в Учетной записи Пользователя на сервисах Платформы (в том числе данных Привязанной карты, если применимо) и содержащих информацию о Пользователе, а также иную информацию, связанную с использованием сервисов Платформы, доступную Пользователю после авторизации с использованием логина и пароля Пользователя на сервисах Платформы. </p>
                    <p className="li-item1">Для целей проверки заявленных Пользователем данных Администрация вправе запросить подтверждающие документы (в частности - документы, удостоверяющие личность), непредоставление которых, по усмотрению Администрации, может быть приравнено к предоставлению недостоверной информации, Администрация имеет право по своему усмотрению заблокировать либо удалить Учетную запись Пользователя и (или) отказать Пользователю в использовании своих сервисов (либо их отдельных функций).</p>
                    <p className="li-dot1">2.4.</p> <p className="li-item1">Все действия, совершаемые в Личном кабинете, считаются совершенными Пользователем лично. </p>
                    <p className="li-dot1">2.5.</p> <p className="li-item1">Пользователь самостоятельно несет ответственность за:</p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">безопасность (в том числе устойчивость к угадыванию) выбранных им средств для доступа к Учетной записи (логин и пароль), которые Пользователь указал во время регистрации,</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">неблагоприятные последствия в случае утери и/или передачи Пользователем средств для доступа к учетной записи третьим лицам. </p>
                    <p className="li-dot1">2.6.</p> <p className="li-item1">Пользователь самостоятельно обеспечивает конфиденциальность средств для доступа к Учетной записи. Администрация не рекомендует Пользователю передавать третьим лицам средства для доступа к Учетной записи. В случае передачи данных Учетной записи Пользователь должен ознакомить третьих лиц с  настоящим Соглашением, при этом Пользователь несет всю ответственность за их действия.</p>
                    <p className="li-dot1">2.7.</p> <p className="li-item1">Администрация вправе незамедлительно осуществить блокировку Учетной записи Пользователя в случае: </p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">выявления нарушения Пользователем положений Соглашения;</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">выявления нарушения Пользователем положений действующего законодательства Российской Федерации;</p>
                    <p className="li-dot1 sub">c.</p> <p className="li-item2">получения от третьих лиц претензии о нарушении Пользователем их прав;</p>
                    <p className="li-dot1 sub">d.</p> <p className="li-item2">получения соответствующих требований от государственных органов;</p>
                    <p className="li-dot1 sub">e.</p> <p className="li-item2">в иных случаях, предусмотренных настоящим Соглашением.</p>
                    <p className="li-dot1">2.8.</p> <p className="li-item1">Блокировка означает невозможность Пользователя осуществить вход в Учетную запись Платформы.</p>
                    <p className="li-dot1">2.9.</p> <p className="li-item1">Пользователь обязуется самостоятельно предпринять все действия для устранения выявленных нарушений.</p>
                    <p className="li-dot1">2.10.</p> <p className="li-item1">В случае блокировки в связи с получением от третьих лиц претензии о нарушении Пользователем их прав, Пользователь вправе использовать в дальнейшем Платформу только при условии, что Администрация получила письменное уведомление от составителя жалобы, что спорная ситуация урегулирована и претензия со стороны третьих лиц о нарушении прав отсутствует. </p>
                    <p className="li-dot1">2.11.</p> <p className="li-item1">В случае получения требований от государственных органов, в том числе требований (уведомлений, претензий) Роскомнадзора, Администрация производит блокировку Учетной записи Пользователя, допустившего нарушение. При этом разблокировка Аккаунта не производится без получения Администрацией официального уведомления от вышеуказанных государственных органов, в том числе Роскомнадзора, об отсутствии каких-либо претензий и требований к Пользователю, чей Аккаунт был заблокирован, а равно и к Проектам, созданным (размещенным) указанным Пользователем.</p>
                    <p className="li-dot1">2.12.</p> <p className="li-item1">Администрация вправе возвращать Пользователю доступ к Учетной записи в случае его взлома, утери или смены логина/пароля при соблюдении следующих условий одновременно: </p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">Учетная запись использовалась на условиях платного Тарифа,</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">Пользователь выполнил все обязательные действия, предусмотренные инструкцией по возврату доступа, предоставляемой по запросу Пользователя. </p>
                    
                    <h2>3. Предмет соглашения</h2>
                    
                    <p className="li-dot1">3.1.</p> <p className="li-item1">Администрация предоставляет неисключительную лицензию на использование Платформы.</p>
                    <p className="li-dot1">3.2.</p> <p className="li-item1">Администрация предлагает Пользователям доступ к сервисам, включающим в себя средства протипирования пользовательских интерфейсов, структурирования информации, размещения, хранения и распространения результатов работы. Все существующие на данный момент сервисы Платформы, а также любое развитие их и/или добавление новых является предметом настоящего Соглашения.</p>
                    <p className="li-dot1">3.3.</p> <p className="li-item1">Пользователь использует Платформу согласно условиям Соглашения и выбранного Тарифа, а также уплачивает вознаграждение, если иное не предусмотрено Тарифом.</p>
                    <h2>4. Общие положения об использовании сервисов Платформы</h2>
                    <p className="li-dot1">4.1.</p> <p className="li-item1">Пользователь вправе использовать Платформу согласно настоящему Соглашению и действующему законодательству Российской Федерации. </p>
                    <p className="li-dot1">4.2.</p> <p className="li-item1">Начиная использовать Платформу, Пользователь считается принявшим настоящие Условия в полном объеме, без каких-либо оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения, Пользователь не вправе использовать Платформу.</p>
                    <p className="li-dot1">4.3.</p> <p className="li-item1">Пользователь принимает на себя все риски, связанные с использованием Платформы и услуг. </p>
                    <p className="li-dot1">4.4.</p> <p className="li-item1">Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием Платформы, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства при использовании Платформы.</p>
                    <p className="li-dot1">4.5.</p> <p className="li-item1">Платформа и услуги предоставляются на условиях «как есть».</p>
                    <p className="li-dot1">4.6.</p> <p className="li-item1">Пользователь вправе использовать предоставляемый функционал платформы по своему усмотрению, не нарушая условий настоящего Соглашения.</p>
                    <p className="li-dot1">4.7.</p> <p className="li-item1">Пользователь не вправе:</p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">предпринимать попытки обойти технические ограничения, установленные Платформой;</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">декомпилировать, дизассемблировать, дешифровать и производить иные действия с исходным кодом Платформы;</p>
                    <p className="li-dot1 sub">c.</p> <p className="li-item2">использовать Платформу для публикации, распространения, хранения, информации, которая:</p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">является незаконной, вредоносной, угрожающей, клеветнической, подстрекающей к насилию над каким-либо лицом или группой лиц, либо к бесчеловечному обращению с животными, призывает к совершению противоправной деятельности, в том числе разъясняет порядок применения взрывчатых веществ и иного оружия, нарушает общепринятые правила приличия и морально-этические нормы, содержит ненормативную лексику, пропагандирует ненависть и/или дискриминацию, а также содержит негативные и критические высказывания, касающиеся религии, политики, расовых, этнических, гендерных признаков, личных качеств, способностей, сексуальной ориентации и внешнего вида третьих лиц, содержит оскорбления в адрес конкретных лиц или организаций; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">может быть воспринята как пропаганда определенных политических и религиозных взглядов, нетрадиционной сексуальной ориентации, насилия, употребления наркотических средств, алкоголя и табакокурения;</p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">нарушает права несовершеннолетних лиц; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">нарушает права третьих лиц на результаты интеллектуальной деятельности и приравненные к ним средства индивидуализации, права на информацию, составляющую коммерческую тайну, наносящего вред чести, деловой репутации и достоинству третьих лиц, нарушающего национальное и международное законодательство;</p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">содержит не разрешенную к разглашению информацию; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">содержит вредоносное программное обеспечение (вирусы, черви, трояны или другие компьютерные коды, файлы или программы), предназначенное для нарушения, уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования (их частей), для осуществления несанкционированного доступа, для получения доступа к коммерческим программным продуктам, путем предоставления серийных номеров логинов, паролей, программ для их генерации и прочих средств для получения несанкционированного доступа к платным ресурсам, а также размещения ссылок на вышеуказанную информацию; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">представляет собой Спам, т.е. рассылку без согласия получателя сообщений коммерческого и некоммерческого характера в форме (включая, но не ограничиваясь): рекламы товаров, оборот которых запрещен или ограничен согласно законодательству РФ; сообщений социального и/или религиозно-мистического содержания с призывом о дальнейшем распространении таких сообщений («писем счастья»); списков чужих адресов электронной почты; схем «пирамид», многоуровневого (сетевого) маркетинга (MLM); реферальных ссылок; систем Интернет-заработка и онлайн-бизнесов.; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">нарушает законодательство Российской Федерации, а также нормы международного права; </p>
                    <p className="li-dot1 dot sub2">•</p> <p className="li-item3">может использоваться для незаконного сбора, хранения и обработки персональных данных других лиц;</p>
                    <p className="li-dot1 sub">d.</p> <p className="li-item2">выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников Администрации, за модераторов, за владельца сайта, а также применять любые другие формы и способы незаконного представительства других лиц в сети, а также вводить пользователей или Администрацию в заблуждение относительно свойств и характеристик каких-либо субъектов или объектов;</p>
                    <p className="li-dot1 sub">e.</p> <p className="li-item2">нарушать работоспособность Платформы, в том числе размещая элементы, затрудняющие обмен информацией в реальном времени и т.п.;</p>
                    <p className="li-dot1 sub">f.</p> <p className="li-item2">размещать ссылки на ресурсы, содержание которых противоречит действующему законодательству РФ и нормам международного права;</p>
                    <p className="li-dot1 sub">g.</p> <p className="li-item2">распространять не соответствующие действительности сведения относительно своей причастности к Администрации и/или ее партнерам;</p>
                    <p className="li-dot1 sub">h.</p> <p className="li-item2">содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением, а также на нарушение норм действующего законодательства;</p>
                    <p className="li-dot1 sub">i.</p> <p className="li-item2">несанкционированно собирать и хранить персональные данные других лиц;</p>
                    <p className="li-dot1 sub">j.</p> <p className="li-item2">другим образом нарушать нормы законодательства, в том числе нормы международного права.</p>
                    <p className="li-dot1">4.8.</p> <p className="li-item1">Администрация вправе устанавливать ограничения в использовании сервисов для всех Пользователей либо для отдельных категорий Пользователей, в том числе: наличие/отсутствие отдельных функций сервиса, срок хранения контента и т.д. </p>
                    <p className="li-dot1">4.9.</p> <p className="li-item1">Администрация в целях защиты оборудования Пользователя и собственного оборудования вправе ограничить прием сообщений/запросов Пользователей, когда такие сообщения/запросы содержат вредоносные программы или код, либо когда автоматические средства фильтрации и антивирусной защиты Платформы определили наличие таких вредоносных программ или кодов в указанных сообщениях. Пользователь уведомлен и согласен с тем, что Платформа в указанных целях вправе осуществлять анализ и исследование таких вредоносных программ и кодов для улучшения качества работы автоматических средств фильтрации и антивирусной защиты Платформы.</p>
                    <p className="li-dot1">4.10.</p> <p className="li-item1">Администрация вправе посылать Пользователям информационные сообщения. Используя сервисы Платформы, Пользователь также в соответствии с ч. 1 ст. 18 Федерального закона «О рекламе» дает свое согласие на получение сообщений рекламного характера. Пользователь вправе отказаться от получения сообщений рекламного характера путем использования соответствующего функционала того сервиса, в рамках которого или в связи с которым пользователем были получены сообщения рекламного характера.</p>
                    <p className="li-dot1">4.11.</p> <p className="li-item1">Пользователь выражает согласие Платформе на оповещение других Пользователей о публичных действиях Пользователя, в том числе о размещении им новых сообщений, а также иной активности, совершенной им в рамках Платформы.</p>
                    
                    <h2>5. Интеллектуальная собственность</h2>
                    <p className="li-dot1">5.1.</p> <p className="li-item1">Администрация является правообладателем Платформы и ее всех составных частей: исходный код, дизайн, базы данных, ноу-хау.</p>
                    <p className="li-dot1">5.2.</p> <p className="li-item1">Все объекты, доступные при помощи сервисов Платформы, а также любой контент, размещенный на сервисах Платформы, являются объектами исключительных прав Администрации, Пользователей и других правообладателей.</p>
                    <p className="li-dot1">5.3.</p> <p className="li-item1">Использование контента, а также каких-либо иных элементов сервисов Платформы возможно только в рамках функционала, предлагаемого тем или иным сервисом. Никакие элементы содержания сервисов Платформы не могут быть использованы иным образом без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе: воспроизведение, копирование, переработка, распространение на любой основе, отображение во фрейме и т.д. Исключение составляют случаи, прямо предусмотренные законодательством РФ или условиями использования того или иного сервиса Платформы.</p>
                    
                    <h2>6. Права Администрации</h2>
                    <p>Администрация вправе:</p>
                    <p className="li-dot1">6.1.</p> <p className="li-item1"> оказывать консультационную и техническую поддержку Пользователю.</p>
                    <p className="li-dot1">6.2.</p> <p className="li-item1">Изменять, дорабатывать и обновлять Платформу без согласия и уведомления Пользователя.</p>
                    <p className="li-dot1">6.3.</p> <p className="li-item1">Устанавливать любые ограничения на использование Платформы. </p>
                    <p className="li-dot1">6.4.</p> <p className="li-item1">Удалять Учетную запись Пользователя при выполнении следующих условий одновременно: к моменту удаления в течение шести и более календарных месяцев Пользователь  находится на бесплатном Тарифе и не использует сервисы Платформы.</p>
                    <p className="li-dot1">6.5.</p> <p className="li-item1">Направлять Пользователю сообщения, уведомления, запросы, сведения рекламного и информационного характера. </p>
                    <p className="li-dot1">6.6.</p> <p className="li-item1">Направлять Пользователю информацию о вебинарах, видео и другую информацию о Платформе.</p>
                    <p className="li-dot1">6.7.</p> <p className="li-item1">Получать доступ к Личному кабинету Пользователя для мониторинга работы Платформы.</p>
                    <p className="li-dot1">6.8.</p> <p className="li-item1">Проводить профилактические работы, влекущие приостановление работы Платформы.</p>
                    <p className="li-dot1">6.9.</p> <p className="li-item1">заблокировать Проект, содержащий неявную рекламу товаров и услуг, запрещенных законодательством Российской Федерации, а также нормами международного права.</p>
                    <p className="li-dot1">6.0.</p> <p className="li-item1">В случае отказа прекратить нарушение или неоднократных блокировок Проектов в одностороннем порядке удалить Учетную запись со всеми Проектами и другими данными.</p>
                    
                    <h2>7. Тарифы</h2>
                    <p className="li-dot1">7.1.</p> <p className="li-item1">Сервисы Платформы предоставляются Пользователю как на безвозмездной основе (бесплатный Тариф), так и на возмездной основе.</p>
                    <p className="li-item1">Перечень Тарифов доступен по ссылке <a href="https://vizls.com/prices">https://vizls.com/prices</a>. </p>
                    <p className="li-dot1">7.2.</p> <p className="li-item1">Тарифы могут изменяться по одностороннему решению Администрации. Стоимость оплаченного периода при этом изменению не подлежит. </p>
                    <p className="li-dot1">7.3.</p> <p className="li-item1">После регистрации Пользователь вправе использовать Платформу на бесплатном Тарифе.</p>
                    <p className="li-dot1">7.4.</p> <p className="li-item1">Для перехода на платный Тариф Пользователь оплачивает его способами, предложенными функционалом Платформы. Переход между платными Тарифами возможен в случае оплаты стоимости нового Тарифа в полном объеме.</p>
                    <p className="li-dot1">7.5.</p> <p className="li-item1">Оплата Тарифа осуществляется на условиях cтопроцентной предоплаты. </p>
                    <p className="li-dot1">7.6.</p> <p className="li-item1">Датой оплаты является дата зачисления денежных средств на расчетный счет Администрации.</p>
                    <p className="li-dot1">7.7.</p> <p className="li-item1">Обязательства по оплате считаются неисполненными в случае возврата денежных средств по требованию платежной организации.</p>
                    <p className="li-dot1">7.8.</p> <p className="li-item1">Размер тарифа складывается из лицензионного вознаграждения (95%) и стоимости услуг (5%). Лицензионное вознаграждение не облагается НДС на основании п.п. 26 п. 2 статьи 149 Налогового Кодекса Российской Федерации.</p>
                    <p className="li-dot1">7.9.</p> <p className="li-item1">Администрация не составляет счета-фактуры лицам, не являющимся налогоплательщиками налога на добавленную стоимость, и налогоплательщикам, освобожденным от исполнения обязанностей налогоплательщика, связанных с исчислением и уплатой налога.</p>
                    <p className="li-dot1">7.10.</p> <p className="li-item1">При выборе платного Тарифа неиспользование Пользователем услуг не освобождает Пользователя от их оплаты.</p>
                    <p className="li-dot1">7.11.</p> <p className="li-item1">По истечении срока действия Тарифа он автоматически продлевается на период, аналогичный предыдущему.</p> 
                    <p className="li-dot1">7.12.</p> <p className="li-item1">В целях получения возможности оплаты Пользователь может привязать свою банковскую карту в своей Учетной записи (далее - Привязанная карта). Для целей настоящего Соглашения любая банковская карта, привязанная к Учетной записи Пользователя, считается Привязанной картой. Администрация вправе списать сумму, составляющую вознаграждение согласно Тарифам, с любой из Привязанных карт.</p>
                    <p className="li-item1">При указании данных Привязанной карты и дальнейшем использовании Привязанной карты Пользователь подтверждает и гарантирует указание им достоверной и полной информации о действительной банковской карте, выданной на его имя; соблюдение им правил международных платежных систем и требований банка-эмитента, выпустившего Привязанную карту.</p>
                    <p className="li-dot1">7.13.</p> <p className="li-item1">При первой оплате Тарифа Пользователь дает Администрации и её партнёрам согласие на хранение информации о его платежной карте и соглашается с тем, что Администрация уполномочена списывать денежные средства с платежной карты для: </p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">реализации функции «Автоплатеж» в целях списания в безакцептном порядке платежей, предусмотренных Соглашением и Тарифами;</p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">погашения задолженности Пользователя по Соглашению. </p>
                    <p className="li-dot1">7.14.</p> <p className="li-item1">При использовании Пользователем платного Тарифа Администрация осуществляет списание соответствующей суммы с платежной карты Пользователя согласно действующим Тарифам в дату платежа.</p>
                    <p className="li-dot1">7.15.</p> <p className="li-item1">Пользователь вправе отказаться от использования функциии «Автоплатеж» через Личный кабинет Пользователя или путем уведомления Администрации  об отказе от продления платного Тарифа по электронной почте <a href="mailto:support@vizls.com">support@vizls.com</a> не позднее чем за 3 (три) рабочих дня до окончания срока его действия. При этом Пользователь автоматически переходит на бесплатный Тариф.</p>
                    <p className="li-dot1">7.16.</p> <p className="li-item1">При переходе с платного Тарифа на бесплатный Тариф Пользователь обязан привести Личный кабинет в соответствии с условиями, на которых предоставляется бесплатный Тариф.</p>
                    <h2>8. Конфиденциальность и приватность информации</h2>
                    <p className="li-dot1">8.1.</p> <p className="li-item1">Взаимоотношения Пользователя и Администрации в отношении всей информации, которую Администрация получает о Пользователе во время использования им Платформы, а также порядок обработки и защиты персональных данных регламентируется <a href='/privacyPolicy'>Политикой конфиденциальности</a>.</p>
                    <p className="li-dot1">8.2.</p> <p className="li-item1">В случае осуществления Пользователем обработки персональных данных третьих лиц Пользователь самостоятельно несет ответственность за соблюдение надлежащих мер по защите персональных данных согласно требованиям Федерального закона от 27 июля 2006 г. N 152-ФЗ "О персональных данных", и Общеевропейского регламента о персональных данных (General Data Protection Regulation, GDPR) и иных законов и подзаконных актов, в том числе в части получения соответствующих разрешений, размещения на Сайте необходимых документов и информации. </p>
                    
                    <h2>9. Гарантии и ответственность</h2>
                    <p className="li-dot1">9.1.</p> <p className="li-item1">Администрация не дает Пользователю никаких явно выраженных или предполагаемых гарантий в отношении Платформы и услуг, в том числе, не гарантирует (включая, но не ограничиваясь): соответствие сервисов Платформы целям и ожиданиям Пользователя, бесперебойную и безошибочную работу Платформы, точность, полноту информации, производительность, системную интеграцию, отсутствие ошибок, отсутствие вирусов, законность использования на любых территориях за пределами Российской Федерации. </p>
                    <p className="li-dot1">9.2.</p> <p className="li-item1">Пользователь должен самостоятельно оценивать риски и самостоятельно нести всю ответственность за свои действия, связанные с использованием Платформы. Администрация не несет ответственности и не возмещает никакой ущерб, прямой или косвенный, причиненный Пользователю или третьим лицам в результате использования или невозможности использования Платформы.</p>
                    <p className="li-dot1">9.3.</p> <p className="li-item1">Администрация не несет ответственности за: </p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">невозможность использования Платформы по причинам, не зависящим от Администрации; </p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">любые действия и/или бездействия поставщиков услуг, сервисов, сетей, программного обеспечения или оборудования; </p>
                    <p className="li-dot1 sub">c.</p> <p className="li-item2">искажение, изменение, утрату Проекта; </p>
                    <p className="li-dot1 sub">d.</p> <p className="li-item2">безопасность логина и/или пароля Пользователя; </p>
                    <p className="li-dot1 sub">e.</p> <p className="li-item2">несанкционированное и/или неправомерное использования третьими лицами логина и/или пароля Пользователя;</p>
                    <p className="li-dot1 sub">f.</p> <p className="li-item2">ущерб, который может быть нанесен любым устройствам и носителям информации и/или программному обеспечению Пользователя в результате использования Платформы и/или услуг.</p>
                    <p className="li-dot1">9.4.</p> <p className="li-item1">Администрация не несет ответственности за прямую или косвенную упущенную выгоду Пользователя.</p>
                    <p className="li-dot1">9.5.</p> <p className="li-item1">Ответственность Администрации по Соглашению не может превышать стоимости оплаченного Пользователем Тарифа.</p>
                    <p className="li-dot1">9.6.</p> <p className="li-item1">В случае предъявления Администрации претензий, требований и/или исков о нарушении прав третьих лиц, связанных с нарушением данных Пользователем гарантий, Пользователь принимает на себя обязательства по их урегулированию и возмещению ущерба Администрации в случае его возникновения. </p>
                    <p className="li-dot1">9.7.</p> <p className="li-item1">Все замечания и/или претензии, связанные с работой Платформы, должны направляться через форму обратной связи: <a href="https://vizls.com/feedback">https://vizls.com/feedback</a>.</p>
                    
                    <h2>10. Срок Соглашения</h2>
                    <p className="li-dot1">10.1.</p> <p className="li-item1">Соглашение действует с момент принятия Пользователем его условий и до момента удаления Учетной записи Пользователя по основаниям, предусмотренным п. 10.2 Соглашения.</p>
                    <p className="li-dot1">10.2.</p> <p className="li-item1">Учетная запись может быть удалена:</p>
                    <p className="li-dot1 sub">a.</p> <p className="li-item2">по заявке Пользователя, </p>
                    <p className="li-dot1 sub">b.</p> <p className="li-item2">по основаниям, предусмотренным пунктами 2.3., 6.4, 6.10 Соглашения</p>
                    <p className="li-dot1">10.3.</p> <p className="li-item1">В случае удаления Учетной записи с действующим платным Тарифом, лицензионная часть вознаграждения возврату не подлежит. Пользователь вправе потребовать возврата стоимости услуг за оплаченный период, который следует за датой удаления Учетной записи. </p>
                    
                    <h2>11. Разрешение споров и разногласий</h2>
                    <p className="li-dot1">11.1.</p> <p className="li-item1">Все споры или разногласия, возникающие между сторонами Соглашения в связи с исполнением Соглашения, разрешаются ими путем переговоров. </p>
                    <p className="li-dot1">11.2.</p> <p className="li-item1">При недостижении согласия между сторонами Соглашения, споры и разногласия подлежат разрешению в претензионном порядке. Срок ответа на претензию — 30 дней с момента ее получения соответствующей стороной.</p>
                    <p className="li-dot1">11.3.</p> <p className="li-item1">При невозможности прийти к соглашению спор может быть передан на разрешение суда в соответствии с правилами подсудности и подведомственности, установленными законодательством Российской Федерации.</p>
                    <p className="li-dot1">11.4.</p> <p className="li-item1">Применимым правом является право Российской Федерации. </p>
                    
                    <h2>12. Заключительные положения</h2>
                    <p className="li-dot1">12.1.</p> <p className="li-item1">Соглашение может быть изменено или дополнено Администрацией в любое время без какого-либо специального уведомления Пользователя.  Новая редакция Соглашения вступает в силу с момента ее размещения для общего доступа на Платформе по адресу <a href="https://vizls.com">https://vizls.com</a>, если иное не предусмотрено новой редакцией Соглашения. </p>
                    <p className="li-dot1">12.2.</p> <p className="li-item1">В случае если Администрацией были внесены какие-либо изменения в настоящее Соглашение, с которыми Пользователь не согласен, он обязан прекратить использование Платформы.</p>
                    <p className="li-dot1">12.3.</p> <p className="li-item1">Продолжение использования Платформы после внесения изменений и/или дополнений в Соглашение означает принятие и согласие Пользователя с такими изменениями и/или дополнениями. </p>
                    <p className="li-dot1">12.4.</p> <p className="li-item1">Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством РФ. </p>
                    <p className="li-dot1">12.5.</p> <p className="li-item1">В случае, если какое-либо из положений Соглашения окажется ничтожным в соответствии с законодательством Российской Федерации, остальные положения останутся в силе, а Соглашение будет исполняться Сторонами в полном объеме без учета такого положения. </p>
                    <p className="li-dot1">12.6.</p> <p className="li-item1">Ничто в Соглашении не может пониматься как установление между Пользователем и Администрацией агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.</p>
                    <p className="li-dot1">12.7.</p> <p className="li-item1">Настоящее Соглашение составлено на русском языке и может быть предоставлено Пользователю для ознакомления на английском языке. В случае расхождения русскоязычной версии Соглашения и версии Соглашения на ином языке, применяются положения русскоязычной версии Соглашения. </p>
                </div>
    }
}