import { IState } from "../reducers";

export function getCreatorToolType(state: IState) {
    return state.app.blockCreatorTool.blockCreatorToolType;
}

export function getCreatorTool(state: IState) {
    return state.app.blockCreatorTool;
}

export function getWorkareaPositionDelta(state: IState) {
    return state.app.workareaPositionDelta;
}

export function getIsCatchedSelection(state: IState) {
    return state.app.catchedSelection;
}

export function getIsCatchedSelectionMoveCancel(state: IState) {
    return state.app.catchedSelectionMoveCancel;
}