import { Action } from 'redux';
import { actionsSet } from '../../../actions/index';
const prefix = 'SIGN_IN_FORM';

export const ACTIONS = {
    SIGN_IN_FORM: actionsSet(prefix, "")
};

export interface IOpenInitAction extends Action {
    isAfterConfirmEmail: boolean
}

export interface IConfirmDoneAction extends Action {
}

export const actions = {
    open: ( isAfterConfirmEmail: boolean) => ({type: ACTIONS.SIGN_IN_FORM.INIT, isAfterConfirmEmail}) as IOpenInitAction,
    close:() => ({type: ACTIONS.SIGN_IN_FORM.DONE}) as IConfirmDoneAction
}
