import { IBlock, BlockType, ElementBlockType } from "../../../../models";

export default function getContentClassName(block:IBlock){
    let className = '';

    if(!block.content) return className;

    let style = ''
    if(block.type === BlockType.Element && block.subType){
        className = block.subType.toLowerCase();
        if(block.subType === ElementBlockType.Text && block.content.items[0][2].isDefault)
            style='-default';
        else if(block.subType === ElementBlockType.Link){
            if(block.content.items[0][3].isDefault)
                style='-default';
            else
                style=`-${block.content.type}-${block.content.style}`
        } 
        else if(block.subType === ElementBlockType.Image){
            if(block.content.items[0][0].isDefault)
                style='-default';
        } 
        else if(block.subType === ElementBlockType.List) {
            if(block.content.items[0][2].isDefault)
                style='-default';
            else
                style=`-${block.content.style}`
        }
        else if(block.subType === ElementBlockType.Gallery){
            style='';
        }           
        else style = (block.content.style ? `-${block.content.style}`: '');
    }        
    else if(block.type === BlockType.Section || block.type === BlockType.Composite){
        className = block.type.toLowerCase();
        if(block.content.items[0][0].isDefault)
            style='-default';
        else style = (block.content.style ? `-${block.content.style}`: '');
    }
         
    className = 'content-' + className + style;
    
    return className;
}

