import { combineReducers } from 'redux';
import * as invoices from './invoices';
import * as lastInvoice from './lastInvoice';
import * as tariff from './tariff';


export interface IState {
    create: invoices.IState,
    lastInvoice: lastInvoice.IState,
    tariff: tariff.IState
}

export const initial: IState = {
    create: invoices.initial,
    lastInvoice: lastInvoice.initial,
    tariff: tariff.initial
}

export const reducer = combineReducers({
    create: invoices.reducer,
    lastInvoice: lastInvoice.reducer,
    tariff: tariff.reducer
});
