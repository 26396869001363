import { combineReducers } from 'redux';
import * as blocks from './blocks';
import * as app from './app';
import * as auth from './auth';
import * as user from './users'
import * as projects from './projects'
import * as modalForm from './modalForm'
import * as currentUser from './users/currentUser';
import * as currentUserTrialPeriod from './users/currentUserTrialPeriod';
import * as appSettings from './appSettings';
import * as tariffs from './tariffs';
import * as invoices from './invoices';
import * as trial from './trial';
import * as projectSettings from './projectSettings';
import * as presentationMode from './presentationMode';
import * as externalAuth from './external-auth';
import * as feedback from './feedback';
import * as healthCheck from './healthCheck';
import * as userSecurity from './userSecurity';
import * as userSettings from './userSettings';

export interface IState {
    app: app.IState,
    blocks: blocks.IState,
    auth: auth.IState,
    externalAuth: externalAuth.IState,
    currentUser: currentUser.IState,
    currentUserTrialPeriod: currentUserTrialPeriod.IState,
    user: user.IState,
    invoices: invoices.IState,
    projects: projects.IState,
    modalForm: modalForm.IState,
    appSettings: appSettings.IState,
    projectSettings: projectSettings.IState,
    presentationMode: presentationMode.IState
    trial: trial.IState,
    tariffs: tariffs.IState,
    feedback: feedback.IState,
    healthCheck: healthCheck.IState,
    userSecurity: userSecurity.IState,
    userSettings: userSettings.IState
}

export const initial: IState = {
    app: app.initial,
    auth: auth.initial,
    externalAuth: externalAuth.initial,
    currentUser: currentUser.initial,
    blocks: blocks.initialState,
    currentUserTrialPeriod: currentUserTrialPeriod.initial,
    user: user.initial,
    invoices: invoices.initial,
    projects: projects.initial,
    presentationMode: presentationMode.initial,
    modalForm: modalForm.initial,
    appSettings: appSettings.initial,
    trial: trial.initial,
    tariffs: tariffs.initial,
    projectSettings: projectSettings.initial,
    feedback: feedback.initial,
    healthCheck: healthCheck.initial,
    userSecurity: userSecurity.initial,
    userSettings: userSettings.initial
}

export const appReducer = combineReducers({
    auth: auth.reducer,
    app: app.reducer,
    externalAuth: externalAuth.reducer,
    blocks: blocks.reducer,
    currentUser: currentUser.reducer,
    currentUserTrialPeriod: currentUserTrialPeriod.reducer,
    user: user.reducer,
    invoices: invoices.reducer,
    tariffs: tariffs.reducer,
    projects: projects.reducer,
    presentationMode: presentationMode.reducer,
    appSettings: appSettings.reducer,
    modalForm: modalForm.reducer,
    trial: trial.reducer,
    projectSettings: projectSettings.reducer,
    feedback: feedback.reducer,
    healthCheck: healthCheck.reducer,
    userSecurity: userSecurity.reducer,
    userSettings: userSettings.reducer
})
