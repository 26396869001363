import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/presentationMode/index';
import { shareProject } from "../../../api/presentationMode/index";
import { actions } from "../../actions/index";
import { ISaveInitAction } from "../../actions/presentationMode/view";
import { IErrorResponse } from "../../../api";

export function* sagaHandler(action: Action) {
    try {
        const { id } = action as ISaveInitAction;
        const {link} = yield call(shareProject, id);

        yield put(actions.presentationMode.share.done(link))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.presentationMode.share.fail(c))
    }
}

export function* shareSaga() {
    yield takeLatest(ACTIONS.share.SHARE.INIT, sagaHandler);
}
