import React from 'react';
import cn from 'classnames';
import { IBlock, ContentStyle, IContent, ElementBlockType, IContentItem } from '../../../../../models';
import SimpleListItem from './SimpleListItem';
import CompositeListItem from './CompositeListItem';
import { AddItemList } from '../../../../../icons';
import '../../styles/block-content-list.scss';
import './style.scss';
import BaseBlock from '../../../../../core/blocks/base';
import { getDefaultContentItems } from '../../../../../core/blocks';
import { simulateMouseDown } from '../../../../WorkCanvas/helpers/simulateMouseDown';

interface IStyledListOwnProps {
    blockId: string;
    isPresentationMode: boolean;
    content: IContent;
    classNames: string;
    updateValue: (value:IContentItem[][]) => void;
    selectBlock: () => void;    
}


export default class StyledList extends React.Component<IStyledListOwnProps> {

    constructor(props:any){
        super(props);

        this.listWrapper = this.listWrapper.bind(this);
        this.listItems = this.listItems.bind(this);
        this.addListItem = this.addListItem.bind(this);
        this.updateSimpleItem = this.updateSimpleItem.bind(this);
        this.updateCompositeItem = this.updateCompositeItem.bind(this);
        this.moveItemUp = this.moveItemUp.bind(this);
        this.moveItemDown = this.moveItemDown.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.classNames !== prevProps.classNames)
            simulateMouseDown(false);
    }

    updateSimpleItem(items:IContentItem[][],item:IContentItem, itemIndex: number){
        const newArr = [...items.map(arr=>arr.slice())];
        newArr[itemIndex][2] = {...item};
        this.props.updateValue(newArr);
    }

    updateCompositeItem(items:IContentItem[][], item:IContentItem[], itemIndex: number){
        const newArr = [...items.map(arr=>arr.slice())];
        newArr[itemIndex] = [...item];
        this.props.updateValue(newArr);
    }

    listWrapper(){
        const { content, classNames } = this.props;
        if(content.style === ContentStyle.Ordered)
            return <ol className={cn('styled-list-ordered', classNames)}>
                        {this.listItems()}
                   </ol>;
        
        return <ul className={cn('styled-list-unordered', classNames)}>
                    {this.listItems()}
               </ul>;
    }

    listItems(){
        const { content, isPresentationMode } = this.props;        

        if(content.style !== ContentStyle.Composite)
            return content
                        .items.map((v,i)=><SimpleListItem key={i} isPresentationMode={isPresentationMode} 
                                            itemsMoreThan1={content.items.length > 1}
                                            movedown={this.moveItemDown} moveup={this.moveItemUp} remove={this.removeItem}
                                            item={v[2]} index={i} selectBlock={this.props.selectBlock} 
                                            updateValue={(v,i)=>this.updateSimpleItem(content.items,v,i)} />);


        if(!content.comps) return;
        const comps = content.comps;

        return content
                    .items.map((v,i)=><CompositeListItem key={i} isPresentationMode={isPresentationMode} 
                                        itemsMoreThan1={content.items.length > 1}
                                        movedown={this.moveItemDown} moveup={this.moveItemUp} remove={this.removeItem}
                                        types={comps} items={[...v]} index={i} selectBlock={this.props.selectBlock} 
                                        updateValue={(v,i)=>this.updateCompositeItem(content.items,v,i)} />);
    }

    addListItem(event:any){
        event.stopPropagation();

        const { blockId, content } = this.props;;  

        const newItems = [...content.items.map(arr=>arr.slice()), getDefaultContentItems()];           
        BaseBlock.updateContentItems(blockId, newItems);

    }

    moveItemUp(itemIndex:number|string){
        if(typeof itemIndex === 'string' || itemIndex-1 < 0) return;

        const { blockId, content } = this.props; 
        const item = [...content.items[itemIndex]];
        const newItems = [...content.items.map(arr=>arr.slice())];   
        
        newItems.splice(itemIndex - 1, 0, item);
        BaseBlock.updateContentItems(blockId, newItems.filter((v,i)=>i!==itemIndex + 1));
    }

    moveItemDown(itemIndex:number|string){
        const { blockId, content } = this.props; 
        if(typeof itemIndex === 'string' || itemIndex+1 > content.items.length) return;

        const item = [...content.items[itemIndex]];        
        const newItems = [...content.items.map(arr=>arr.slice())];   
        
        newItems.splice(itemIndex + 2, 0, item);
        BaseBlock.updateContentItems(blockId, newItems.filter((v,i)=>i!==itemIndex));
    }

    removeItem(itemIndex:number|string){
        const { blockId, content } = this.props;        
        BaseBlock.updateContentItems(blockId, content.items.filter((v,i)=>i!==itemIndex));
    }

    render(){
        
        const { isPresentationMode } = this.props;

        return <div className='styled-list'>
                    {this.listWrapper()}       

                    {!isPresentationMode &&
                        <div className='styled-list-add-item' onClick={this.addListItem}>
                            <AddItemList /> add item
                        </div>}                         
               </div>;
    }
}