import React from 'react';
import { IBlock, BlockType, ContentType } from '../../models';
import { IState } from '../../redux/reducers';
import { connect } from 'react-redux';
import BlockTitle from './BlockTitle';
import cn from 'classnames';
import './styles/block-dl.scss';
import './styles/block-dl-page.scss';
import './styles/block-dl-section.scss';
import './styles/block-dl-element.scss';
import './styles/block-dl-composite.scss';
import './styles/block-dl-fixed.scss';
import { computeStyle } from '../BlockPresenter/helpers';
import BaseBlock from '../../core/blocks/base';
import { Action } from 'redux';
import * as actions from '../../redux/actions'
import SectionSlider from './BlockContent/items/SectionSlider';
import BlockContainer from './BlockContainer';
import canvasMovementTool from '../../core/common/canvasMovementTool';

interface IComponentOwnState {
    collapsed: boolean;
}

interface IComponentOwnProps {
    block: IBlock;    
    childrenIndex: number;
}

interface IComponentProps {
    isPresentationMode: boolean;
}

const mapStateToProps = (state: IState): IComponentProps => {
    return {
        isPresentationMode: state.presentationMode.view.isPresentationMode
    };
};

interface IDispatchProps {
    setAppEditTextRegime: (blockId?: string) => Action;
}

const mapDispatchToProps: IDispatchProps = {
    setAppEditTextRegime: actions.appEditText
};

class BlockPresenterDetailLevel extends React.Component<IComponentOwnProps & IComponentProps & IDispatchProps, IComponentOwnState> {

    constructor(props:any){
        super(props);
        this.state = {collapsed: true}
        this.selectBlock = this.selectBlock.bind(this);
        this.collapseChildren = this.collapseChildren.bind(this);
    }

    selectBlock() {
        
        const { block } = this.props;
        
        BaseBlock.resetSelection();
        this.props.setAppEditTextRegime(block.id);
        BaseBlock.select(this.props.block, true);
        
    }

    collapseChildren(event: any) {
        event.stopPropagation();
        const { collapsed } = this.state;
        if (!collapsed) {
            this.setState({ ...this.state, collapsed: true });
        }
        else {
            this.setState({...this.state, collapsed: false });
        }       
    }

    renderBlockContent(block: IBlock, titleClassNames: string, isPresentationMode: boolean, childrenIndex:number) {
        
        if (block.type === BlockType.Page || block.type === BlockType.Fixed)
            return <BlockTitle block={block} className={titleClassNames} 
                               style={computeStyle({}, block)} isPresentationMode={isPresentationMode} 
                               collapsed={this.state.collapsed} collapse={this.collapseChildren} />
        else return <BlockContainer block={block} childrenIndex={childrenIndex} isPresentationMode={isPresentationMode} selectBlock={this.selectBlock} />        
    }

    renderDefault(block:IBlock, isPresentationMode: boolean, children: React.ReactNode, childrenIndex:number){
        const titleClassNames = cn(`${block.type}-block-dl-title`, 'block-dl-title');
        return <>
                {this.renderBlockContent(block, titleClassNames, isPresentationMode, childrenIndex)}

                {block.type === BlockType.Fixed && !this.state.collapsed && children}
                {block.type !== BlockType.Fixed && children}
                </>
    }

    renderComposite(block:IBlock, isPresentationMode: boolean, children: React.ReactNode, childrenIndex:number){

        if(block.type === BlockType.Section && block.content && block.content.type === ContentType.Slider){
            return <>
                        <div className='block-dl-slider'>
                            {this.renderDefault(block, isPresentationMode, children, childrenIndex)}
                        </div>
                        
                        <SectionSlider />
                   </>;
        }

        return this.renderDefault(block, isPresentationMode, children, childrenIndex);

    }

    render(){

        const { block, children, isPresentationMode, childrenIndex } = this.props;

        let style={};
        if (block.coord) {
            const delta = canvasMovementTool.getCanvasPosition();
            style = { left: block.coord.x + delta.x, top: block.coord.y + delta.y };
        }

        const classNames = cn(block.coord ? "block-dl" : '', `${block.type}-block-dl`);
       

        return <div id={block.id} className={classNames} style={style}>

                    {this.renderComposite(block, isPresentationMode, children, childrenIndex)}
                    
               </div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockPresenterDetailLevel);