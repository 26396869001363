import React from 'react';
import { ErrorInputIcon } from '../../icons';
import cn from 'classnames';
import Tooltip from '../Tooltip';
import './style.scss';

type InputType = "text" | "password";

interface IInputContainerProps {
    title: string;
    error?: string | React.ReactNode;
    onChange:  (e: any) => void;
    type?: InputType;
    name?: string;
    value?: string;
    onBlur?:  (e: any) => void;
    classes?: string;
    hidden?: boolean;
}

interface IInputContainerState {
    showLabelTooltip: boolean;
}

export default class InputContainer extends React.Component<IInputContainerProps, IInputContainerState> {
    
    constructor(props: any) {
        super(props);

        this.state = { showLabelTooltip: false };
        this.onChange = this.onChange.bind(this);
        
    }

    onChange(e: any) {
        const value = e.target.value;
        const { onChange } = this.props;
        onChange(value);
    }

    render() {
        const {title, error, onBlur, type, classes, hidden, name} = this.props;

        return (<div className={cn("input-container", classes)} hidden={hidden}>
                    <input autoFocus={true} className="text-input" type={type ? type : "text"} onChange={this.onChange} defaultValue={this.props.value} autoComplete="off" required  onBlur={onBlur} name={name}/>

                            <label className={cn("label", error?"label-error":"")}>
                                {title}
                                <div className="tooltip-container">


                                    {error &&
                                    <Tooltip message={error} position="top" displayTooltip={true}>
                                        <ErrorInputIcon/>
                                    </Tooltip>}

                                </div>
                            </label>
                            
                </div>);
    }
}