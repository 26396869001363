import {IErrorResponse, ITariffSettings} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'TARIFF';

export const ACTIONS = {
    FETCH: actionsSet(prefix, 'GET')
};

export interface IFetchInitAction extends Action {
}

export interface IFetchDoneAction extends Action {
    item: ITariffSettings;
}

export const actions = {
    fetch: {
        init: () => ({ type: ACTIONS.FETCH.INIT }) as IFetchInitAction,
        done: (item: ITariffSettings) => ({ type: ACTIONS.FETCH.DONE, item }) as IFetchDoneAction,
        fail: (error: IErrorResponse) => ({ type: ACTIONS.FETCH.FAIL, error }) as IFailAction
    }
}