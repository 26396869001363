import { combineReducers } from 'redux';
import * as create from './create';
import * as edit from './edit';

export interface IState {
    create: create.IState,
    edit: edit.IState
}

export const initial: IState = {
    create: create.initial,
    edit: edit.initial
}

export const reducer = combineReducers({
    create: create.reducer,
    edit: edit.reducer
});
