import React from 'react';
import cn from 'classnames';
import './style.scss';

interface IDropdownMenuItemProps {
    title: string;
    onClick: (e:any) => void;
    subtitle?: string;
    isCurrent?: Boolean;
}

export default class DropdownMenuItem extends React.Component<IDropdownMenuItemProps> {
    render() {
        const { title, subtitle, onClick, isCurrent } = this.props;

        return (<div
            className={cn("user-profile-menu-item")} onMouseDown={onClick}>
            <div className={cn("user-profile-menu-item-title", isCurrent ? "user-profile-menu-item-title__current" : '')} >{title}</div>
            {subtitle && <div className="user-profile-menu-item-subtitle">{subtitle}</div>}
        </div >);
    }
}
