import { IProject } from "../../../api/projects";
import { store } from '../../../store/configureStore';
import { actions } from "../../../redux/actions/projects/edit";
import { BlockType, IBlock } from "../../../models";
import { getBlocks } from "../../../redux/selectors/blocks";

const filtersTypes: BlockType[] = [BlockType.Element, BlockType.Section, BlockType.Page, BlockType.Composite, BlockType.Fixed, BlockType.TemplateComposite, BlockType.TemplateFixed];

export function saveProject(project: IProject | null) {

    const blocks = getBlocks(filtersTypes)(store.getState());
    
    if (project == null || blocks.length == 0 || !project.isActive) {
        return;
    }
    clearBlocksBeforeSave(blocks);
    const content = JSON.stringify([...blocks]);
    const newProject: IProject | null = project;
    if (newProject) {
        newProject.content = content;
        newProject.version = Date.now();
        const projectId = newProject.id;
        store.dispatch(actions.init(projectId, newProject));
    }
}

function clearBlocksBeforeSave(blocks: IBlock[]) {
    blocks.forEach( b => {b.children = b.children.filter(id => id.indexOf("target-block-placeholder_ID")<0)});
}