import React, { SyntheticEvent } from 'react';
import './style.scss';
import * as H from 'history';
import {RouteComponentProps, withRouter} from 'react-router';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IUserRead, ITariffSettings } from '../../api';
import { IState } from '../../redux/reducers';
import { Action } from 'redux';
import { actions } from '../../redux/actions';
import {IProject} from "../../api/projects";

interface IStateProps {
  user: IUserRead | null,
  tariff: ITariffSettings | null,
  inProgress: boolean;
  error: string | null;
  isAuthorized: boolean;
}

interface IProps extends RouteComponentProps<any>{
    projects: IProject[] | null
}

const mapStateToProps = (state: IState): IStateProps => {
  return {
    // status: state.invoices.status.item,
    user: state.currentUser.item,
    tariff: state.invoices.tariff.item,
    inProgress: state.invoices.create.inProgress,
    error: state.tariffs.view.error,
    isAuthorized: state.auth.isAuthorized
  };
}

interface IDispatchProps {
  fetchCurrentUser: () => Action;
  // fetchSubsctiptionStatus: (id: string) => Action;
  fetchCurrentUserTrialPeriod: (id: string) => Action;
  // fetchTariff: () => Action;
}

const mapDispatchStateToProps: IDispatchProps = {
  fetchCurrentUser: actions.users.currentUser.fetch.init,
  // fetchSubsctiptionStatus: actions.invoices.status.fetch.init,
  fetchCurrentUserTrialPeriod: actions.users.currentUserTrialPeriod.fetch.init,
}

export interface ITrialExpireWarningTipProps {
  trilaTimeHours?: number;
  trilaTimeMinutes?: number;
  onClick?: (e: SyntheticEvent) => void;
  // fetchTariff: actions.invoices.tariff.fetch.init
}

interface IRouterProps {
  match: any;
  history: H.History;
  location: H.Location;
}
type ComponentProps = WithTranslation & IProps & IRouterProps & IDispatchProps & IStateProps;

class TrialExpireWarningTip extends React.Component<ComponentProps> {

  constructor(props: ComponentProps){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  /*static getDerivedStateFromProps(nextProps: ComponentProps, prevState: any) {
    if (nextProps.user) {
      if (nextProps.user !== prevState.user) {
        const { user, 
          // fetchSubsctiptionStatus,
           fetchCurrentUserTrialPeriod } = nextProps;

        if (user && user.id) {
          // fetchSubsctiptionStatus(user.id);
          fetchCurrentUserTrialPeriod(user.id);
        }
        return { user: nextProps.user };
      }
    }
    return null;
  }*/

  componentDidMount(){
    this.props.fetchCurrentUser();
    // this.props.fetchTariff();
  }

  handleClick(e: SyntheticEvent){
      e.preventDefault();
      this.props.history.push(`/prices`);
  }

  render() {
    const { tariff, projects } = this.props;
    if (tariff && projects) {
        const projectNames = projects.map(x => {return x.title})
        switch (tariff.tariff) {
            case "PRO": {
              return this.renderPro(projectNames);
            }
            default: {
                return this.renderBaseTariff(projectNames);
            }
        }
    }
  }

  renderBaseTariff(projects: string[]) {
      const { t, tariff, user, inProgress, isAuthorized } = this.props;
      return (
          <div className="text-box">
            <span className="text">
              {t('tariffTrip.base.description')} {projects.join(',')}
            </span>
              <input type="submit" onClick={this.handleClick} className="btn"
                     value={`${t('tariffTrip.base.buyButton')}`}/>
          </div>
      )
  }


  renderPro(projects: string[]){
    const { t, tariff, user, inProgress, isAuthorized } = this.props;

    return ( <div className="text-box">
            <span className="text">
              {t('tariffTrip.pro.description')} {projects.join(',')}
            </span>
        <input type="submit" onClick={this.handleClick} className="btn" value={`${t('tariffTrip.pro.buyButton')}`} />
    </div>)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchStateToProps)(withTranslation()(TrialExpireWarningTip)));
