import React from 'react';
import { IBlock, IPoint, BlockType } from '../../../models';
import './styles/block-content.scss';
import { blockContentItem } from './blockContentItem';
import { Action } from 'redux';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import BlockContentDivider from './BlockContentDivider';

interface IDispatchProps{
    appWorkCanvasTooltip: (coord?:IPoint, text?:string) => Action;
}

const mapDispatchToProps: IDispatchProps = {
    appWorkCanvasTooltip: actions.appWorkCanvasTooltip
};

interface IBlockContentOwnProps {
    isPresentationMode: boolean;
    block: IBlock;
    selectBlock: () => void;
    childrenIndex: number;
}

class BlockContent extends React.Component<IBlockContentOwnProps & IDispatchProps> {

    constructor(props:any){
        super(props);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    handleMouseMove(e:any){
        e.stopPropagation();
        
        const ne = e.nativeEvent;

        this.props.appWorkCanvasTooltip({x:ne.pageX, y:ne.pageY}, this.props.block.title);        
    }

    render(){
        
        const { block, isPresentationMode, selectBlock, childrenIndex } = this.props;
        
        return <div className={'block-content'} onMouseMove={this.handleMouseMove}>
                    {block.type === BlockType.Element && childrenIndex > 0 && <BlockContentDivider/>}
                    {blockContentItem(block, isPresentationMode, selectBlock)}
               </div>
    }
}

export default connect(null, mapDispatchToProps)(BlockContent);