import { BlockType, IPoint, IBlock, ContentStyle, ContentType, IContent } from "../../models";
import BaseBlock from "./base";
import { createElementBlockByTemplate } from "../creators/createElementBlock";
import { MIN_STEP } from "../../consts";
import { blockHeight } from ".";

function defaultContent(): IContent {
    return {
        style: ContentStyle.Small,
        type: ContentType.Normal,
        items: [[{isDefault: true, value:'Some section'}]]
    }
}

export default class CompositeBlock extends BaseBlock {
    constructor(title:string, templateId?:string, children: string[]=[], coord?:IPoint, id?:string, parentId?:string){
        super(title, BlockType.Composite, children, id, undefined, coord, parentId, templateId, defaultContent());
    }
}

export function updateCompositeBlockChildren(block:IBlock, templates: string[]){
    let newChildren = [...block.children];
    let currUsedBlocks = [...BaseBlock.getBlocks().filter(b=>block.children.indexOf(b.id)>=0).map(b=>b)];
    let currUsedTemplatesId = currUsedBlocks.map(b=>b.templateId);
    
    // Добавляем новые блоки, в место в соотвествие с шаблоном
    let blocksToAdd: IBlock[] = [];
    let chIndx = 0;
    for(const tempId of templates){
        if(currUsedTemplatesId.indexOf(tempId) < 0){
            const newEl = createElementBlockByTemplate(tempId, block.id);
            if (newEl) {
                newChildren.splice(chIndx, 0, newEl.id);
                // BaseBlock.addBlock(newEl);
                blocksToAdd = [...blocksToAdd, newEl];
            }
        }
        chIndx++;
    }

    // Удаляем блоки, которых нет в шаблоне
    let idsToRemove:string[] = [];
    for(const b of currUsedBlocks){
        if(b.templateId && templates.indexOf(b.templateId)<0){
            idsToRemove = [...idsToRemove, b.id];
            const index = newChildren.indexOf(b.id);
            if (index >= 0) {
                newChildren.splice(index, 1);
            }
        }
    }
    BaseBlock.updateBlock({...block, children:[...newChildren]},true);
    BaseBlock.addBlocks(blocksToAdd);
    BaseBlock.removeBlocks(idsToRemove);
}

export function dissemblyCompositeBlock(block:IBlock, template?: IBlock){

    let bloksToUpdate:IBlock[] = [];

    if(block.coord){
        let stepY = 0;
        for(const chId of block.children){
            const b = BaseBlock.getBlock(chId);
            if(!b) continue;
            b.parentId = undefined;
            b.templateId = undefined;
            b.coord = {x:block.coord.x, y:block.coord.y+stepY};

            bloksToUpdate = [...bloksToUpdate, b];

            stepY = stepY + blockHeight(b.id,true) + MIN_STEP * 2 + MIN_STEP;
        } 
    }
    else if(block.parentId){
        const parent = BaseBlock.getBlock(block.parentId);
        if(!parent) return;
        let indexInParent = parent.children.indexOf(block.id);
        for(const chId of block.children){
            const b = BaseBlock.getBlock(chId);
            if(!b) continue;
            b.parentId = parent.id;
            b.templateId = undefined;
            BaseBlock.addChild(parent,chId, indexInParent);
            indexInParent++;            

            bloksToUpdate = [...bloksToUpdate, b];
        }      
        
        bloksToUpdate = [...bloksToUpdate, parent];
    }
    BaseBlock.removeBlock(block.id, true);
    BaseBlock.updateBlocks(bloksToUpdate, true);
    if(template) BaseBlock.removeBlock(template.id, true);
}