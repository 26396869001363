import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS, actions} from '../../actions/healthCheck';
import { healthCheck } from '../../../api/healthCheck';
import { IErrorResponse } from '../../../api';

export function* sagaHandler(action: Action) {
    try {
        const result: boolean = yield call(healthCheck);
        yield put(actions.healthCheck.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.healthCheck.fail(c))
    }
}

export function* beatSaga() {
    yield takeLatest(ACTIONS.HEALTH_CHECK.HEALTH_CHECK.INIT, sagaHandler);
}
