import { IErrorResponse } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'USERS_CONFIRM';

export const ACTIONS = {
    CONFIRM: actionsSet(prefix, "")
};

export interface IConfirmInitAction extends Action {
    token: string;
}

export interface IConfirmDoneAction extends Action {
}

export const actions = {
    init: (token: string) => ({type: ACTIONS.CONFIRM.INIT, token}) as IConfirmInitAction,
    done: () => ({type: ACTIONS.CONFIRM.DONE}) as IConfirmDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.CONFIRM.FAIL, error}) as IFailAction
}
