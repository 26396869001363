import React from "react";
import './style.scss'
import { Action } from "redux";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import ProfileButton from "../ProfileButton";
import { actions } from "../../../redux/actions";
import { IUserRead } from "../../../api/user";
import { IState } from "../../../redux/reducers";
import { LogoBig } from "../../../icons";
import HeaderButton from "../Button";
import * as H from "history";
import {RouteComponentProps, withRouter} from "react-router";

interface IRouterProps {
    match: any;
    history: H.History;
    location: H.Location;
}

interface IProps extends RouteComponentProps<any>{
    className: any;
}

interface IDispatchProps {
    showSignUpForm: () => Action
    showSignInForm: (isAfterConfirmEmail: boolean) => Action
}

interface IRouterProps {
    history: H.History;
}

interface IStateToProps {
    user: IUserRead | null
}

const mapDispatchToProps: IDispatchProps = {
    showSignUpForm: actions.modalForm.signUp.open,
    showSignInForm: actions.modalForm.signIn.open
};

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        user: state.currentUser.item
    }
};

export class HeaderAuth extends React.Component<WithTranslation & IDispatchProps & IStateToProps & IProps & IRouterProps> {
    constructor(props: any) {
        super(props);
        this.handleSignUpClick = this.handleSignUpClick.bind(this);
        this.handleSignInClick = this.handleSignInClick.bind(this);
        this.handlePriceClick = this.handlePriceClick.bind(this);
    }

    handleSignUpClick() {
        this.props.showSignUpForm();
    }

    handleSignInClick() {
        this.props.showSignInForm(false);
    }

    handlePriceClick() {
        if (this.props.history) {
            this.props.history.push(`/prices`)
        }
    }

    render() {
        const {t, className = "header-auth", history} = this.props
        return (<div className={className}>
            <LogoBig />
            <div className="button-group">
                {/* <HeaderButton title={t('header.learn')} className="header-button" onClick={this.handleSignUpClick} /> */}
                <HeaderButton title={t('header.price')} className="header-button" onClick={this.handlePriceClick} />
                <ProfileButton className={"profile-button-round"} />
            </div>
        </div>)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HeaderAuth)));
