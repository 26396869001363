import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS, actions } from '../../actions/tariffs';
import {ITariffCurrency, getTariffs, IErrorResponse} from '../../../api';
import {IFetchInitAction} from "../../actions/tariffs/tariffs";

export function* sagaHandler(action: Action) {
    try {
        const {cultureInfo} = action as IFetchInitAction;
        const result: ITariffCurrency = yield call(getTariffs, cultureInfo);
        yield put(actions.view.fetch.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.view.fetch.fail(c))
    }
}

export function* viewSaga() { 
    yield takeLatest(ACTIONS.VIEW.FETCH.INIT, sagaHandler);
}
