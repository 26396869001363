import {ACTIONS, IOpenInitAction} from '../../actions/modalForm/signInForm';
import { Action } from 'redux';

export interface IState {
    isShow: boolean;
    isAfterConfirmEmail: boolean
}

export const initial: IState = {
    isShow: false,
    isAfterConfirmEmail: false
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.SIGN_IN_FORM.INIT:
            const {isAfterConfirmEmail} = action as IOpenInitAction
            return {
                ...state,
                isShow: true,
                isAfterConfirmEmail: isAfterConfirmEmail
            };

        case ACTIONS.SIGN_IN_FORM.DONE:
        {
            return initial;
        }
        default:
            return state;
    }
}
