import React from 'react';
import './style.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IFeedbackQuery, FeedbackType } from "../../api/feedback";
import { Action } from "redux";
import { IState } from "../../redux/reducers";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";
import * as firstScreen from "../../video/FirstScreen.mp4"
import { Spinner } from "../Spinner";
import { IErrorResponse } from "../../api";
import InputContainerDark from '../InputContainerDark';
import { RadioButtonOnIcon, RadioButtonOffIcon } from '../../icons';
import Video from '../Video';
import Footer from '../Footer';
import Header from "../Header";
import SignForm from "../SignForm";
import SubmitButton from "../Buttons/SubmitButton";
import HeaderAuth from "../Header/HeaderAuth";

const minMessageLength = 30;
interface IStateProps {
    send_feedback: IFeedbackQuery | null,
    send_inProgress: boolean,
    send_error: IErrorResponse | string | null,
    isAuthorized: boolean
}

interface IDispatchProps {
    send: (item: IFeedbackQuery) => Action,
}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        isAuthorized: state.auth.isAuthorized,
        send_feedback: state.feedback.feedback.item,
        send_inProgress: state.feedback.feedback.inProgress,
        send_error: state.feedback.feedback.error
    };
};

const mapDispatchToProps: IDispatchProps = {
    send: actions.actions.feedback.feedback.send.init,
};

interface IFeedbackState {
    isSubmitEnable: boolean;
    formControls: IFormControls;
    formErrors: IFormErrors;

}
interface IFormErrors {
    emailError: string;
    messageError: string;

}
interface IFormControls {
    feedbackTypeRadio: IRadio;
    messageTextArea: ITextArea;
    emailInput: IInput;
}

interface IRadio extends ICompNameValues {
    value: FeedbackType;
}
interface ITextArea extends ICompNameValues {

    textareaValid: boolean;
}
interface IInput extends ICompNameValues {
    error: string;
    emailValid: boolean;
}

interface ICompNameValues {
    name: string;
    value: string;
}

type IProps = WithTranslation & IStateProps & IDispatchProps;

class Feedback extends React.Component<IProps, IFeedbackState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            isSubmitEnable: false,
            formErrors: {
                emailError: '',
                messageError: '',
            },

            formControls: {
                feedbackTypeRadio: {
                    name: 'feedbackTypeRadio',
                    value: FeedbackType.Other
                },
                messageTextArea: {
                    name: 'messageTextArea',
                    value: '',
                    textareaValid: false
                },
                emailInput: {
                    error: '',
                    name: 'emailInput',
                    value: '',
                    emailValid: false

                }
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.formSubmit = this.formSubmit.bind(this);

        this.validateForm = this.validateForm.bind(this);
        this.validateField = this.validateField.bind(this);
    }

    formSubmit(e: any) {
        e.preventDefault();
        this.handleSubmit();
    }

    handleSubmit() {
        const { formControls } = this.state;
        const { t } = this.props;

        if (this.state.isSubmitEnable) {
            const feedback: IFeedbackQuery = {
                email: formControls.emailInput.value,
                type: formControls.feedbackTypeRadio.value,
                message: formControls.messageTextArea.value,
            };
            this.props.send(feedback);
        }
        else {
            const { formControls } = this.state;
            let newFormErrors: any = {};

            if (!this.isValidEmail(formControls.emailInput.value)) {
                newFormErrors['emailError'] = t('feedback.errors.wrongEmailFormat');

            }
            if (formControls.messageTextArea.value.length < minMessageLength) {
                newFormErrors['messageError'] = t('feedback.errors.tooShotMessage');
            }

            this.setState({ formErrors: newFormErrors });
        }
    }

    isValidEmail(value: string): boolean {
        if (value !== "") {
            return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
        }

        return true;
    }

    validateField(name: string, value: string) {
        const { t } = this.props;

        let fieldValidationErrors = this.state.formErrors;

        const { formControls } = this.state;

        let emailValid: any = this.state.formControls.emailInput.emailValid;
        let textareaValid: any = this.state.formControls.messageTextArea.textareaValid;
        const controls: any = {};

        switch (name) {
            case 'emailInput':
                emailValid = this.isValidEmail(value);

                if (fieldValidationErrors) {
                    fieldValidationErrors.emailError = emailValid ? '' : t('feedback.errors.wrongEmailFormat');
                }

                controls[name] = { name, value, emailValid };
                break;
            case 'messageTextArea':
                textareaValid = value.length >= minMessageLength;

                if (fieldValidationErrors) {
                    fieldValidationErrors.messageError = textareaValid ? '' : t('feedback.errors.tooShotMessage');
                }
                controls[name] = { name, value, textareaValid };

                break;
            default:
                break;
        }

        const newFromControls = { ...formControls, ...controls };

        this.setState({
            formErrors: fieldValidationErrors,
            formControls: newFromControls,
        }, this.validateForm);
    }

    validateForm() {
        this.setState({ isSubmitEnable: this.state.formControls.emailInput.emailValid && this.state.formControls.messageTextArea.textareaValid });
    }

    handleBlur(e: any) {
        const { formControls } = this.state;
        const name = e.target.name;
        const value = e.target.value;

        const controls: any = {};
        controls[name] = { name, value };

        const newFromControls = { ...formControls, ...controls };
        this.setState({ formControls: newFromControls, }, () => { if (value !== "") {this.validateField(name, value)} });
    }

    handleChange(e: any) {
        const { formControls } = this.state;

        const name = e.target.name;
        const value = e.target.value;

        const controls: any = {}
        controls[name] = { name, value };

        this.setState({
            formControls: { ...formControls, ...controls }
        });
    }

    renderSignForm(){
        return <SignForm/>
    }

    renderHeader() {
        if (this.props.isAuthorized) {
            return (<HeaderAuth />)
        } else {
            return (<Header />)
        }
    }

    render() {
        const { t } = this.props;
        const { formControls } = this.state;

        const checked = <RadioButtonOnIcon />;
        const unchecked = <RadioButtonOffIcon />;

        return (
            <>
                {this.renderHeader()}
                {this.renderSignForm()}
                <div className="feedback" >
                    <div className="feedback-title-block">
                        <h1 className="feedback-title">
                            {t('feedback.title')}
                        </h1>

                        <div className="feedback-info">
                            {t('feedback.info')}
                        </div>
                    </div>

                    <form className="feedback--form" onSubmit={this.formSubmit}>

                        <InputContainerDark
                            classes={'feedback--form--email__input'}
                            name={formControls.emailInput.name}
                            title={t('feedback.emailInput')} value={formControls.emailInput.value}
                            error={formControls.emailInput.error}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}

                        />

                        <div className="feedback--form__radio">
                            <div className="feedback--form__radio-item">
                                <input type="radio" checked={formControls.feedbackTypeRadio.value === FeedbackType.Bug} onChange={this.handleChange}
                                    name={formControls.feedbackTypeRadio.name} value={FeedbackType.Bug} />

                                {formControls.feedbackTypeRadio.value === FeedbackType.Bug ? checked : unchecked}
                                <label >{t('feedback.radio.bug')}</label>
                            </div>

                            <div className="feedback--form__radio-item">
                                <input type="radio" checked={formControls.feedbackTypeRadio.value === FeedbackType.Question} onChange={this.handleChange}
                                    name={formControls.feedbackTypeRadio.name} value={FeedbackType.Question} title={t('feedback.radio.question')} />

                                {formControls.feedbackTypeRadio.value === FeedbackType.Question ? checked : unchecked}
                                <label >{t('feedback.radio.question')}</label>
                            </div>

                            <div className="feedback--form__radio-item">
                                <input type="radio" checked={formControls.feedbackTypeRadio.value === FeedbackType.Suggestion} onChange={this.handleChange} name={formControls.feedbackTypeRadio.name}
                                    value={FeedbackType.Suggestion} title={t('feedback.radio.suggestion')} />

                                {formControls.feedbackTypeRadio.value === FeedbackType.Suggestion ? checked : unchecked}
                                <label >{t('feedback.radio.suggestion')}</label> <br />
                            </div>

                            <div className="feedback--form__radio-item">
                                <input type="radio" checked={formControls.feedbackTypeRadio.value === FeedbackType.Other} onChange={this.handleChange} name={formControls.feedbackTypeRadio.name}
                                    value={FeedbackType.Other} title={t('feedback.radio.other')} />
                                {formControls.feedbackTypeRadio.value === FeedbackType.Other ? checked : unchecked}
                                <label >  {t('feedback.radio.other')}</label>
                                <br />
                            </div>

                        </div>
                        <div className="feedback--form-user-message">
                            <textarea onBlur={this.handleBlur} name={formControls.messageTextArea.name} onChange={this.handleChange} value={formControls.messageTextArea.value} className="feedback--form-user-message__textarea">
                            </textarea>
                        </div>

                        <SubmitButton classes={"feedback--form__submit-button"} title={t('feedback.sendMessageButton')} onSubmit={this.handleSubmit} inProgress={this.props.send_inProgress}/>
                        <div style={{ background: 'red', color: 'white'}}>
                            {this.state.formErrors && this.state.formErrors.emailError}
                        </div>
                        <div style={{ background: 'red', color: 'white'}}>
                            {this.state.formErrors && this.state.formErrors.messageError}
                        </div>
                    </form>

                </div >
                <Video startBlock={-1} endBlock = {-1} number="123" path={firstScreen as any} isAdditional={false} isLoop={true} />

                <Footer/>
            </>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Feedback));
