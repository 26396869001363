import React from 'react';
import cn from "classnames";
import './style.scss';

interface IDefaultButtonProps {
    title:string;
    disabled?:boolean;
    className?:string;
    onClick?: (e:any) => void;
}

export class DefaultButton extends React.Component<IDefaultButtonProps> {

    constructor(props:any){
        super(props);
    }

    render(){
        const {title, className, onClick } = this.props;

        return (<div className={cn("default-button",className)}>
                    <input onClick={onClick} type="button" value={title}/>
                </div>
        );
    }
}