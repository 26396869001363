import React from "react";
import './style.scss'
import {FacebookIcon, InstagramIcon, YouTubeIcon, TelegramIcon} from "../../icons";
import { withTranslation, WithTranslation } from 'react-i18next';
import Language from "./Language";
import * as H from "history";
import {withRouter} from "react-router";
import {connect} from "react-redux";

interface IRouterProps {
    history: H.History;
}

class Footer extends React.Component<WithTranslation & IRouterProps> {
    constructor(props: any) {
        super(props);
    }

    renderPoint(){
        return <div className="round-container">
                    <div className="round"/>
                </div>
    }

    render() {
        const { t, history } = this.props;

        return (<div className="footer">
            <Language/>
            <div className="copyright">
                <span>2019 @ {t('footer.company')}</span>
                {this.renderPoint()}
                <a href='' onClick={() => { history.push('/feedback')}}>{t('feedback.title')}</a>
                {this.renderPoint()}
                <a target="_blank" href='/userAgreement'>{t('footer.termsOfUse')}</a>
                {this.renderPoint()}
                <a target="_blank" href='/privacyPolicy'>{t('footer.privacyPolicy')}</a>
            </div>
            <div className="social">
                <div onClick={()=>window.open('https://www.facebook.com/vizls','_blank')}><FacebookIcon /></div>
                <div onClick={()=>window.open('https://t.me/vizls_com','_blank')}><TelegramIcon /></div>
                {/* <InstagramIcon /> */}
                {/* <YouTubeIcon /> */}
            </div>
        </div>)
    }
}

export default  withRouter(withTranslation()(Footer) as any);