import React from 'react';
import './style.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import InputContainer from '../../InputContainer';
import SubmitButton from '../../Buttons/SubmitButton';
import {IUserWrite, IChangePassword, IErrorResponse, IUserRead, IUserSettings, IUserEdit} from '../../../api';
import { IState } from '../../../redux/reducers/';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { actions } from '../../../redux/actions';
import defaultImg from '../../../images/avatar.png'
import {PasswordService} from "../../Services/PasswordService";


interface IUserProfileState {
    email: string;
    emailChanged: boolean;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    showPasswordInputs: boolean;
    errorCurrPassText: string | null;
    errorNewPassText: string | null;
    errorConfPassText: string | null;
    errPasswordNotEqualtxt: string | undefined;
    errorEmail: string | undefined;
    emailChangedTxt: string | undefined;
    passwordChanged: string | undefined;
}

interface IStateProps {
    user: IUserRead | null,
    serverError: IErrorResponse | null;
    userProgress: boolean;
    inProgressServer: boolean;
}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        user: state.currentUser.item,
        userProgress: state.currentUser.inProgress,
        serverError: state.user.updatePassword.error,
        inProgressServer: state.user.updatePassword.inProgress,
    };
}

interface IDispatchProps {
    fetchCurrentUser: () => Action;
    updateUser: (id: string, item: IUserEdit) => Action;
    updatePassword: (passwords: IChangePassword) => Action;
}

const mapDispatchStateToProps: IDispatchProps = {
    fetchCurrentUser: actions.users.currentUser.fetch.init,
    updateUser: actions.users.update.save.init,
    updatePassword: actions.users.updatePassword.save.init,
}

type UserProfilePropsType = WithTranslation & IDispatchProps & IStateProps;

class UserProfile extends React.Component<UserProfilePropsType, IUserProfileState> {
    public passwordService: PasswordService;
    constructor(props: UserProfilePropsType) {
        super(props);
        this.state = {
            email: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',

            emailChanged: false,
            showPasswordInputs: false,

            errorCurrPassText: '',
            errorNewPassText: '',
            errorConfPassText: '',
            errPasswordNotEqualtxt: '',
            errorEmail: '',
            emailChangedTxt: '',
            passwordChanged: '',
        };

        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.showChangePasswordInputs = this.showChangePasswordInputs.bind(this);
        this.renderPasswordInputs = this.renderPasswordInputs.bind(this);
        this.handleBlurCurrentPassword = this.handleBlurCurrentPassword.bind(this);
        this.handleBlurNewPassword = this.handleBlurNewPassword.bind(this);
        this.handleBlurConfirmPassword = this.handleBlurConfirmPassword.bind(this);

        this.handleEnterCurrentPassword = this.handleEnterCurrentPassword.bind(this);
        this.handleEnterNewPassword = this.handleEnterNewPassword.bind(this);
        this.handleEnterConfirmNewPassword = this.handleEnterConfirmNewPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.passwordService = new PasswordService();
    }

    componentDidMount() {
        const { user, fetchCurrentUser } = this.props;
        fetchCurrentUser();
        if (!!user) {

            this.setState({ email: user.email });
        }
    }

    handleChangeEmail(email: string) {
        this.setState((state) => { return { ...state, emailChanged: true, email } });
    }

    showChangePasswordInputs() {
        this.setState((state) => { return { ...state, showPasswordInputs: !state.showPasswordInputs } });
    }

    handleEnterCurrentPassword(password: string) {
        this.setState((state) => {
            return {
                ...state,
                currentPassword: password,
            }
        });
    }

    handleEnterNewPassword(password: string) {
        this.setState((state) => {
            return {
                ...state,
                newPassword: password,
            }
        });
    }

    handleEnterConfirmNewPassword(password: string) {
        this.setState((state) => {
            return {
                ...state,
                confirmPassword: password
            }
        });
    }

    handleSubmit() {
        const { user, updateUser, updatePassword, t } = this.props;
        const { newPassword, confirmPassword,
            errorCurrPassText, errorConfPassText, errorNewPassText,
            email, currentPassword,
            showPasswordInputs, errPasswordNotEqualtxt } = this.state;

        this.setState({ emailChangedTxt: '', errorEmail: '', showPasswordInputs: false});

        if (!!user && !errorCurrPassText && !errorConfPassText && !errorNewPassText
        ) {
            // нужно ли менять пароль-> нажата ли кнопка 'Изменить пароль'
            if (showPasswordInputs) {
                // новый пароль и подтверждение совпадают
                if (!!confirmPassword && !!newPassword && !!currentPassword) {
                    if (!errPasswordNotEqualtxt) {
                        if (email !== user.email) {
                        }

                        const passwords: IChangePassword = {
                            oldPassword: currentPassword,
                            newPassword: newPassword
                        }
                        updatePassword(passwords);
                        setTimeout(() => {
                            this.props.fetchCurrentUser();
                            this.setState({ passwordChanged: t('signup.warnings.passwordChanged') });
                        }, 300);

                    } else {
                        this.setState({ errPasswordNotEqualtxt: t('signup.warnings.passwordsAreNotEqual') })
                    }
                } else {
                    this.setState({
                        errorCurrPassText: this.passwordService.validatePassword(this.state.currentPassword),
                        errorNewPassText: this.passwordService.validatePassword(this.state.newPassword),
                        errorConfPassText: this.passwordService.validatePassword(this.state.confirmPassword),
                    })
                }
            } else {
                //старый пароль, новый email
                if (email !== user.email) {
                    setTimeout(() => {
                        this.props.fetchCurrentUser();
                        this.setState({
                            email,
                            emailChangedTxt: t('signup.warnings.emailChanged')
                        });

                    }, 300);

                    const updatedUser: IUserEdit = {
                        email: email,
                        name: user.name,
                        logo: user.logo == null ? null : user.logo,
                        language: user.language
                    }

                    if (user.id) {
                        updateUser(user.id, updatedUser)
                    }
                }
                else {
                    this.setState({ errorEmail: t('signup.warnings.enterNewEmail') });
                }
            }
        }
    }

    handleBlurCurrentPassword() {
        const error = this.passwordService.validatePassword(this.state.currentPassword);
        this.setState({ ...this.state, errorCurrPassText: error });
    }

    handleBlurNewPassword() {
        const { t } = this.props;
        const error = this.passwordService.validatePassword(this.state.newPassword);
        let errorPasswdNotEqual = undefined
        if (!error && this.state.newPassword != this.state.confirmPassword) {
            errorPasswdNotEqual = t('signup.warnings.passwordsAreNotEqual');
        }
        this.setState({ ...this.state, errorNewPassText: error, errPasswordNotEqualtxt: errorPasswdNotEqual });
    }

    handleBlurConfirmPassword() {
        const { t } = this.props;
        const error = this.passwordService.validatePassword(this.state.confirmPassword);
        let errorPasswdNotEqual = undefined
        if (!error && this.state.newPassword != this.state.confirmPassword) {
            errorPasswdNotEqual = t('signup.warnings.passwordsAreNotEqual');
        }

        this.setState({ ...this.state, errorConfPassText: error, errPasswordNotEqualtxt: errorPasswdNotEqual });
    }

    renderPasswordInputs() {
        const { t } = this.props;

        let errCurPassword = this.state.errorCurrPassText;
        if (errCurPassword) {
            errCurPassword = this.state.currentPassword !== "" ? this.passwordService.validatePassword(this.state.currentPassword) : null;
        }

        let errNewPassword = this.state.errorNewPassText;
        if (errNewPassword) {
            errNewPassword = this.state.newPassword !== "" ? this.passwordService.validatePassword(this.state.newPassword) : null;
        }

        let errConfPassword = this.state.errorConfPassText;
        if (errConfPassword) {
            errConfPassword = this.state.confirmPassword !== "" ? this.passwordService.validatePassword(this.state.confirmPassword) : null;
        }

        return (
            <div className="user-profile-change-password">
                <InputContainer title={t('user.profile.currentPassword')}
                    error={errCurPassword}
                    onChange={this.handleEnterCurrentPassword}
                    onBlur={this.handleBlurCurrentPassword}
                    type="password" classes="user-profile-email"
                    value={this.state.currentPassword} />

                <InputContainer title={t('user.profile.newPassword')}
                    onChange={this.handleEnterNewPassword}
                    error={errNewPassword}
                    type="password"
                    classes="user-profile-email"
                    onBlur={this.handleBlurNewPassword}
                    value={this.state.newPassword} />

                <InputContainer title={t('user.profile.confirmNewPassword')}
                    error={errConfPassword}
                    onBlur={this.handleBlurConfirmPassword}
                    onChange={this.handleEnterConfirmNewPassword} type="password" classes="user-profile-email"
                    value={this.state.confirmPassword} />
            </div>
        );
    }

    render() {
        const { t, user, userProgress, inProgressServer, } = this.props;
        const { showPasswordInputs} = this.state;

        return (!!user ? <div className="user-layout">

            <div className="user-layout-main-title">{t('user.profile.title')}</div>
            <div className="user-layout-profile">
                <div className="user-layout-logo">
                    <img src={user == null || user.logo == null ? defaultImg: `data:image/jpeg;base64,${user.logo}`} />
                </div>
                <div className="user-layout-main">
                    <InputContainer title={t('user.profile.email')} onChange={this.handleChangeEmail}
                        classes="user-profile-email" value={this.state.email} />
                    <div className="user-profile-change-pass-button" onClick={this.showChangePasswordInputs}>{t('user.profile.changePassword')}</div>

                    {showPasswordInputs && this.renderPasswordInputs()}

                    <SubmitButton classes={"user-profile-submit"} title={t('user.profile.save')} disabled={inProgressServer} onSubmit={this.handleSubmit} inProgress={userProgress || inProgressServer}/>
                </div>
            </div>
        </div>
            : <></>
        );
    }
}

export default connect(mapStateToProps, mapDispatchStateToProps)(withTranslation()(UserProfile));
