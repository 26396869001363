import React from 'react';
import i18next from "i18next";
import PrivacyPolicy_RU from './PrivacyPolicy_RU';
import PrivacyPolicy_EN from './PrivacyPolicy_EN';
import './PrivacyPolicy.scss';
import '../legal-doc.scss';

import { IState } from "../../../redux/reducers";
import { connect } from "react-redux";
import Header from "../../Header";
import HeaderAuth from "../../Header/HeaderAuth";
import Footer from "../../Footer";
import SignForm from "../../SignForm";

interface IStateProps {
    isAuthorized: boolean
}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        isAuthorized: state.auth.isAuthorized
    };
};

class PrivacyPolicy extends React.Component<IStateProps> {
    render() {
        const lng = i18next.language;

        return (<>
            {this.renderHeader()}
            <div className='legal-doc legal-scroll'>
                {this.renderSignForm()}
                {lng === 'ru' && <PrivacyPolicy_RU />}
                {lng === 'en' && <PrivacyPolicy_EN />}
            </div>
            {this.renderFooter()}
        </>
        );
    }

    renderSignForm() {
        return <SignForm />
    }

    renderHeader() {
        if (this.props.isAuthorized) {
            return (<HeaderAuth />)
        } else {
            return (<Header />)
        }
    }

    renderFooter() {
        return (<Footer />)
    }
}

export default connect(mapStateToProps, null)(PrivacyPolicy);
