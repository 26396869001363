export interface IPoint{
    x: number,
    y: number
}

export function pointToString(p:IPoint){
    return `${p.x},${p.y}`;
}

export function pointFromString(p:string){
    const p_arr = p.split(',');
    return {x: +p_arr[0], y: +p_arr[1]} as IPoint;
}

export function pointsToString(points:IPoint[]){
    const str = points.map(p=>`${p.x},${p.y}`).join(" ");
    return str;
}