import { Action } from 'redux';
import undoable from 'redux-undo';
import * as ACTIONS from '../actions/blocks';
import { IBlock } from '../../models/block';

export type IState = IBlock[];

export const initialState = [];

const blockReducer = (action: Action, state: IBlock) => {
    
    switch (action.type) {
        case ACTIONS.BLOCK_ADD:
            const added = action as ACTIONS.IBlockAddAction;
            return { ...added.block, children: [...added.block.children] };

        case ACTIONS.BLOCK_UPDATE_VISUAL:
        case ACTIONS.BLOCK_UPDATE:

            const upd = action as ACTIONS.IBlockAddAction;

            if (state && state.id !== upd.block.id) {
                return {...state, children: [...state.children] };
            }

            if(action.type === ACTIONS.BLOCK_UPDATE)
                return { ...upd.block, children: [...upd.block.children], isSelected: false, isTargetBlock: false, isHover: false };

            return { ...upd.block, children: [...upd.block.children] };

        case ACTIONS.BLOCK_MOVE:
            const movedData = action as ACTIONS.IBlockMoveAction;

            if (state && state.id !== movedData.id) {
                return {...state, children: [...state.children] };
            }

            return { ...state, parentId: undefined, coord: { ...movedData.newCoord } };

        case ACTIONS.BLOCK_UPDATE_CONTENT_ITEMS:
            const contentItems = action as ACTIONS.IBlockUpdateContentItemsAction;

            if (state && state.id !== contentItems.id) {
                return {...state, children: [...state.children] };
            }

            const {content} = state;

            if(!content) return state;            

            return { ...state, content: {...content, items:contentItems.items.map(arr=>arr.slice())} };

        default:
            return state;
    }
}

const blocks = (state: IState = initialState, action: Action):IBlock[] => {

    switch (action.type) {

        case ACTIONS.BLOCKS_ADD:
            const {blocks} = action as ACTIONS.IBlocksAddAction;
            return [
                ...state,
                ...blocks.map(b=>{return {...b, children: [...b.children]}}) 
            ]

        case ACTIONS.BLOCK_ADD:
            const added = action as ACTIONS.IBlockAddAction;
            return [
                ...state,
                blockReducer(action, added.block) 
            ]
        
        case ACTIONS.BLOCK_UPDATE_VISUAL:
        case ACTIONS.BLOCK_UPDATE:
        case ACTIONS.BLOCK_MOVE:
        case ACTIONS.BLOCK_UPDATE_CONTENT_ITEMS:
            
            return state.map(b =>
                blockReducer(action, b)
            );

        case ACTIONS.BLOCK_REMOVE_VISUAL:
        case ACTIONS.BLOCK_REMOVE:
            const { id } = action as ACTIONS.IBlockRemoveAction;
            return state
                    .filter((b) => b.id !== id)
                    .map(b=>{return {...b, children: [...b.children]}});
        
        case ACTIONS.BLOCKS_REMOVE:
            const { ids } = action as ACTIONS.IBlocksRemoveAction;

            if(ids)
                return state
                    .filter((b) => ids.indexOf(b.id)<0)
                    .map(b => { return { ...b, children: [...b.children] } });

            return [];

        case ACTIONS.BLOCK_RESET_SELECTION:
            const { selection } = action as ACTIONS.IBlockResetSelectionAction;
            return state.map(b => {
                const new_b = {
                    ...b,
                    children: [...b.children]
                }
                if(selection.indexOf(b.id)>=0)                    
                    new_b.isSelected = false;
                
                return new_b;
            });

        default:
            return state;
    }
}

const includeActions = [
    ACTIONS.BLOCKS_ADD,
    ACTIONS.BLOCK_UPDATE_CONTENT_ITEMS,
    ACTIONS.BLOCK_UPDATE,
    ACTIONS.BLOCK_MOVE,
    ACTIONS.BLOCK_REMOVE,
    ACTIONS.BLOCKS_REMOVE]

function customFilter(action:Action) {
    if(action.type.indexOf("@@redux/INIT") > -1
        || action.type.indexOf("@@redux/PROBE_UNKNOWN_ACTION") > -1
        || includeActions.includes(action.type))
        return true;

    return false;
}


export const reducer = undoable(blocks, {
    limit: 10, 
    // debug: true,
    // initialState: [],
    filter: customFilter});
