import {ACTIONS, IUserSettingsDoneAction} from '../../actions/userSettings/view';
import { Action } from 'redux';
import {IErrorResponse, IUserSettings} from '../../../api/index';
import { IFailAction } from '../../../actions';

export interface IState {
    item: IUserSettings | null;
    inProgress: boolean;
    error: IErrorResponse | null;
}

export const initial: IState = {
    item: null,
    inProgress: false,
    error: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.VIEW.INIT:
            return {
                ...state,
                inProgress: true,
                error: null
            };

        case ACTIONS.VIEW.DONE:
        {
            const { userSettings } = action as IUserSettingsDoneAction;
            return {
                ...state,
                item: userSettings,
                inProgress: false,
                error: null
            };
        }

        case ACTIONS.VIEW.FAIL:
        {
            const { error } = action as IFailAction;
            return {
                ...state,
                inProgress: false,
                error: error
            };
        }

        default:
            return state;
    }
}
