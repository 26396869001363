import { all, fork } from "redux-saga/effects";
import { viewSaga } from "./users";
import { currentUserSaga } from "./currentuser";
import { confirmSaga } from "./confirm";
import { updateSaga } from "./updateUser";
import { currentUserTrialPeriodSaga } from "./currentuserTrialPeriod";

export function* userSaga() {
    yield all([
        fork(currentUserSaga),
        fork(updateSaga),
        fork(viewSaga),
        fork(confirmSaga),
        fork(currentUserTrialPeriodSaga),
    ]);
}
