import { IErrorResponse } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'FORGET_PASSWORD';

export const ACTIONS = {
    FORGET_PASSWORD: actionsSet(prefix, ""),
    CLEAR: "FORGET_PASSWORD_CLEAR"
};

export interface IForgetPasswordInitAction extends Action {
    userName: string;
}

export interface IForgetPasswordDoneAction extends Action {
}

export const actions = {
    init: (userName: string) => ({type: ACTIONS.FORGET_PASSWORD.INIT, userName}) as IForgetPasswordInitAction,
    done: () => ({type: ACTIONS.FORGET_PASSWORD.DONE}) as IForgetPasswordDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.FORGET_PASSWORD.FAIL, error}) as IFailAction,
    clear: () => ({type: ACTIONS.CLEAR})
}
