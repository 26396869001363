import { BlockType } from "../../models";

type comparerResult = 1 | 0 | -1;

// t1 > t2 => 1
// t1 < t2 => 0
export function comparerBlockType(t1:BlockType, t2:BlockType): comparerResult {
    
    if(t1 === t2 
        || (t1 === BlockType.TemplateComposite && t2 === BlockType.Composite)
        || (t1 !== BlockType.Page && t2 === BlockType.Fixed)
        || (t2 !== BlockType.Page && t1 === BlockType.Fixed)) 
        return 0;
    
    if(t1 === BlockType.Page 
        && (t2 === BlockType.Section || t2 === BlockType.Composite || t2 === BlockType.Fixed)) return 1;

    if(t1 === BlockType.Page && t2 === BlockType.Element) return 1;

    if((t1 === BlockType.Section || t1 === BlockType.TemplateComposite)
        && (t2 === BlockType.Element || t2 === BlockType.Composite || t2 === BlockType.Fixed)) return 1;

    if (t1 === BlockType.TemplateFixed
        && (t2 === BlockType.Section || t2 === BlockType.Element)) return 1;    

    return -1;
}