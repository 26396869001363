import { IErrorResponse, IUserQuery } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';
import { IAppSettings } from '../../../api/appSettings';

const prefix = 'APP_SETTINGS_VIEW';

export const ACTIONS = {
    FETCH: actionsSet(prefix, 'FETCH')
};

export interface IFetchInitAction extends Action {
}

export interface IFetchDoneAction extends Action {
    item: IAppSettings;
}

export const actions = {
    fetch: {
        init: () => ({ type: ACTIONS.FETCH.INIT }) as IFetchInitAction,
        done: (item: IAppSettings) => ({ type: ACTIONS.FETCH.DONE, item }) as IFetchDoneAction,
        fail: (error: IErrorResponse) => ({ type: ACTIONS.FETCH.FAIL, error }) as IFailAction
    }
}

