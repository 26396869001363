import { IBlock, IPoint } from "../../../models";
import { calcPaddingLeftByNearestBlock, calcPaddingTopByNearestBlock, calcClosestTopBlockHeight, calcClosestLeftBlock, calcBlockFactHeight } from "../../../core/blocks-layout/paddings";
import { DEFAULT_BLOCK_WIDTH_1LVL, DEFAULT_BLOCK_WIDTH_2LVL, MIN_STEP } from "../../../consts";
import { store } from "../../../store/configureStore";

interface IClosestBlock {
    block?: IBlock;
    dist: number;
}
interface IPaddings{
    left: IClosestBlock;
    top: IClosestBlock;
}

export const blocksLayoutPaddings = new Map<string, IPaddings>();
export const blocksCoords = new Map<string, IPoint>();

export default function detailLevelUpdateCoords(blocks: IBlock[]) {
    
    const blocksCopy = blocks.map(b=>{
        
        let coordNew = undefined;
        if(b.coord){
            coordNew = {x: b.coord.x, y: b.coord.y};
        }
        return { 
            ...b, 
            coord: coordNew, 
            children: [...b.children] 
        }});

    if(blocksLayoutPaddings.size === 0){
        blocksCopy.forEach(b => {

            if (!b.coord) return b;
    
            if(!blocksLayoutPaddings.has(b.id)) {
                const { paddingLeft, closestBlock: closestLeftBlock} = calcPaddingLeftByNearestBlock(blocksCopy, b);
                const{ paddingTop, closestBlock: closestTopBlock } = calcPaddingTopByNearestBlock(blocksCopy, b);

                blocksLayoutPaddings.set(b.id,{
                    left: {block: closestLeftBlock && closestLeftBlock, dist: paddingLeft}, 
                    top: {block: closestTopBlock && closestTopBlock.block, dist: paddingTop}
                });
            }
                  
        });
    }

    // blocksCoords.clear();
    const updatedBlocks = blocksCopy.map(b => {

        if (!b.coord) return b;

        let left = 0;
        let top = 0;
        
        const p = blocksLayoutPaddings.get(b.id);
        if (p) {
            left = p.left.dist;
            top = p.top.dist;
        }

        const closestTopBlock = p && p.top.block;
        if (closestTopBlock && closestTopBlock.coord) {            
            const coord = blocksCoords.get(closestTopBlock.id);
            if(coord)
                top += calcBlockFactHeight(closestTopBlock.id) + coord.y;
            else
                top += calcBlockFactHeight(closestTopBlock.id) + closestTopBlock.coord.y;
        }
        else {
            top = b.coord.y - top;
        }

        const closestLeftBlock = p && p.left.block;
        if (closestLeftBlock) {
            const coord = blocksCoords.get(closestLeftBlock.id)
            if (coord)
                left += DEFAULT_BLOCK_WIDTH_2LVL + coord.x;
            else
                left = b.coord.x; 
        }
        else {
            left = b.coord.x;
        }                  
        
        b.coord = { x: Math.floor(left / MIN_STEP) * MIN_STEP, y: Math.floor(top / MIN_STEP) * MIN_STEP };
        blocksCoords.set(b.id, {...b.coord});
  
        return b;

    });

    return updatedBlocks;
}