import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/users/index';
import { IUserWrite } from "../../../api/user/index";
import { actions } from "../../actions/index";
import { ISaveInitAction } from "../../actions/users/updatePassword";
import { IErrorResponse } from "../../../api/index";
import {changePassword} from "../../../api/userSecurity/index";

export function* sagaHandler(action: Action) {
    try {
        const { item } = action as ISaveInitAction;
        const result: IUserWrite = yield call(changePassword, item);
        yield put(actions.users.updatePassword.save.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.users.updatePassword.save.fail(c))
    }
}

export function* changePasswordSaga() {
    yield takeLatest(ACTIONS.updatePasswordAction.SAVE.INIT, sagaHandler);
}
