import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS, actions } from '../../actions/invoices/tariff';
import {IErrorResponse, ITariffSettings, getTariff,} from '../../../api';

export function* sagaHandler(action: Action) {
    try {
        const result: ITariffSettings = yield call(getTariff);
        yield put(actions.fetch.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.fetch.fail(c))
    }
}

export function* tariffSaga() {
    yield takeLatest(ACTIONS.FETCH.INIT, sagaHandler);
}
