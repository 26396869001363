import {ACTIONS, ISaveInitAction} from '../../actions/trial/create';
import { Action } from 'redux';
import {IErrorResponse, IUserWrite} from '../../../api/index';
import { ISaveDoneAction } from "../../actions/trial/create";
import { IFailAction } from '../../../actions';

export interface IState {
    userId: string;
    inProgress: boolean;
    error: IErrorResponse | null;
    item: IUserWrite | null;
}

export const initial: IState = {
    userId: "",
    inProgress: false,
    error: null,
    item: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.SAVE.INIT:
            const { userId } = action as ISaveInitAction;
            return {
                ...state,
                userId,
                inProgress: true,
                error: null
            };

        case ACTIONS.SAVE.DONE:
        {
            const { item } = action as ISaveDoneAction;
            return {
                ...state,
                item,
                inProgress: false,
                error: null
            };
        }

        case ACTIONS.SAVE.FAIL:
        {
            const { error } = action as IFailAction;
            return {
                ...state,
                inProgress: false,
                error: error
            };
        }

        default:
            return state;
    }
}
