import { combineReducers } from 'redux';
import * as projectSettings from './projectSettings';
import * as projectSettingsCreate from './projectSettingsCreate';
import * as signInForm from './signInForm';
import * as signUpForm from './signUpForm';
import * as messageSendingForm from './messageSendingForm';
import * as forgetPasswordForm from './forgetPasswordForm';
import * as resetPasswordForm from './resetPasswordForm';
import * as dropDownMenu from './dropDownMenu';


export interface IState {
    projectSettings: projectSettings.IState,
    projectSettingsCreate: projectSettingsCreate.IState,
    signInForm: signInForm.IState,
    signUpForm: signUpForm.IState,
    messageSendingForm: messageSendingForm.IState,
    forgetPasswordForm: forgetPasswordForm.IState,
    resetPasswordForm: resetPasswordForm.IState,
    dropDownMenu: dropDownMenu.IState
}

export const initial: IState = {
    projectSettings: projectSettings.initial,
    projectSettingsCreate: projectSettingsCreate.initial,
    signInForm: signInForm.initial,
    signUpForm: signUpForm.initial,
    messageSendingForm: messageSendingForm.initial,
    forgetPasswordForm: forgetPasswordForm.initial,
    resetPasswordForm: resetPasswordForm.initial,
    dropDownMenu: dropDownMenu.initial
}

export const reducer = combineReducers({
    projectSettings: projectSettings.reducer,
    projectSettingsCreate: projectSettingsCreate.reducer,
    signInForm: signInForm.reducer,
    signUpForm: signUpForm.reducer,
    messageSendingForm: messageSendingForm.reducer,
    forgetPasswordForm: forgetPasswordForm.reducer,
    resetPasswordForm: resetPasswordForm.reducer,
    dropDownMenu: dropDownMenu.reducer
});
