import { Action } from "redux";
import { IBlock, BlockType, ElementBlockType } from "../../models/block";
import { IPoint, DetailLevel } from "../../models";

/*
 * action types
 */

export const APP_CHANGE_WORKAREA_POSITION = 'APP_CHANGE_WORKAREA_POSITION';

export const APP_NEW_POSITION_BLOCK_PLACEHOLDER = 'APP_NEW_POSITION_BLOCK_PLACEHOLDER';

export const APP_SELECT_BLOCK_CREATOR_TOOL_TYPE = 'APP_SELECT_BLOCK_CREATOR_TOOL_TYPE';

export const APP_EDIT_TEXT = 'APP_EDIT_TEXT';

export const APP_CATCHED_SELECTION = 'APP_CATCH_SELECTION';

export const APP_CATCHED_SELECTION_MOVE_CANCEL = 'APP_CATCHED_SELECTION_MOVE_CANCEL';

export const APP_DISPLAY_TYPE_POPUPMENU = 'APP_DISPLAY_TYPE_POPUPMENU';

export const APP_DETAIL_LEVEL = 'APP_DETAIL_LEVEL';

export const APP_CLOSE_ALL_POPUPMENUS = 'APP_CLOSE_ALL_POPUPMENUS';

export const APP_WORKCANVAS_TOOLTIP = 'APP_WORKCANVAS_TOOLTIP';

/*
 * action creator payloads
 */

 export interface IAppChangeWorkareaPositionAction extends Action {
    point: IPoint;
 }

 export interface IAppNewPositionBlockPlaceholderAction extends Action {
    point: IPoint;
 }

 export interface IAppSelectedBlockCreatorToolType extends Action {
    toolType: BlockType | ElementBlockType;
    blockTemplateId?: string;
 }

 export interface IAppEditText extends Action {
    editTextBlockId?: string
 }

 export interface IAppCatchedSelection extends Action {
    catchedSelection: boolean;
 }

 export interface IAppCatchedSelectionMoveCancel extends Action {
    catchedSelectionMoveCancel: boolean;
 }

 export interface IAppDisplayTypePopupMenu extends Action {
    displayId: string;
 }

 export interface IAppDisplayTypePopupMenu extends Action {
    displayId: string;
 }

 export interface IAppCloseAllPopupMenus extends Action {
    closeAllPopupMenus: boolean;
 }

 export interface IAppWorkCanvasTooltip extends Action {
   coord?: IPoint;
   text?: string;
}

 /*
 * action creators
 */

export function appWorkareaPositionChange(point: IPoint) {
    return { type: APP_CHANGE_WORKAREA_POSITION, point } as IAppChangeWorkareaPositionAction;
}

export function appNewPositionBlockPlaceHolder(point?: IPoint) {
    return { type: APP_NEW_POSITION_BLOCK_PLACEHOLDER, point } as IAppNewPositionBlockPlaceholderAction;
}

export function appSelectBlockCreaterToolType(toolType: BlockType | ElementBlockType, blockTemplateId?:string) {
    return { type: APP_SELECT_BLOCK_CREATOR_TOOL_TYPE, toolType, blockTemplateId } as IAppSelectedBlockCreatorToolType;
}

export function appEditText(editTextBlockId?:string) {
    return { type: APP_EDIT_TEXT, editTextBlockId } as IAppEditText;
}

export function appCatchedSelection(catchedSelection:boolean) {
    return { type: APP_CATCHED_SELECTION, catchedSelection } as IAppCatchedSelection;
}

export function appCatchedSelectionMoveCancel(catchedSelectionMoveCancel:boolean) {
    return { type: APP_CATCHED_SELECTION_MOVE_CANCEL, catchedSelectionMoveCancel } as IAppCatchedSelectionMoveCancel;
}

export function appDisplayIdTypePopupMenu(displayId:string) {
    return { type: APP_DISPLAY_TYPE_POPUPMENU, displayId } as IAppDisplayTypePopupMenu;
}

export function appCloseAllPopupMenus(closeAllPopupMenus: boolean) {
    return { type: APP_CLOSE_ALL_POPUPMENUS, closeAllPopupMenus } as IAppCloseAllPopupMenus;
}

export function appWorkCanvasTooltip(coord?:IPoint, text?:string) {
   return { type: APP_WORKCANVAS_TOOLTIP, coord, text } as IAppWorkCanvasTooltip;
}
