import PasswordValidator from "../../password-validator";
import i18next from 'i18next';
import {getI18n} from "react-i18next";

export class PasswordService {

    validatePassword(password: string) {
        const t = getI18n();
        if(password!= "" && !PasswordValidator.validate(password)){
            return  i18next.t('signUp.simplepass');
        }
        else {
            return null;
        }
    }
}