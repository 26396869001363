import { combineReducers } from 'redux';

import * as feedback from './feedback';


export interface IState {
    feedback: feedback.IState,
  
}

export const initial: IState = {
    feedback: feedback.initial,
    
}

export const reducer = combineReducers({
    feedback: feedback.reducer,
  
});
