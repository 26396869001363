import React from 'react';
import ComboBox from '../ComboBox';
import ComboBoxDivider from '../ComboBoxDivider';
import BaseBlock from '../../../../core/blocks/base';
import { BlockType, IBlock, ContentStyle, ElementBlockType, ContentType, ContentComponentType, ContentTextDisplayedRows } from '../../../../models';

export function renderComboBoxPanel(blockId?: string){

    if(!blockId) return <></>;
    const block = BaseBlock.getBlock(blockId);
    if(!block) return <></>;
   
    return comboBoxPanelByType(block);
}

function comboBoxPanelByType(block:IBlock){
    
    if(block.type === BlockType.Section){
        return sectionComboBoxPanel(block);
    }
    else if(block.type === BlockType.Element && block.subType){

        switch(block.subType){
            case ElementBlockType.Text:
                return textComboBoxPanel(block);
            case ElementBlockType.List:
                return listComboBoxPanel(block);
            case ElementBlockType.Link:
                return linkComboBoxPanel(block);
            case ElementBlockType.Gallery:
                return galleryComboBoxPanel(block);
        }
    }

    return <></>;
}

function sectionComboBoxPanel(block:IBlock){
    if(!block.content || !block.content.style || !block.content.type) return <></>;

    const itemsType = [ContentType.Normal, ContentType.Slider];
    const selectedTypeIndex = itemsType.indexOf(block.content.type);

    const itemsStyle = [ContentStyle.Small, ContentStyle.Normal, ContentStyle.Emphasis, ContentStyle.Spacing];
    const selectedStyleIndex = itemsStyle.indexOf(block.content.style);

    return <>
            <ComboBox blockId={block.id} items={itemsType} multiSelect={false} title="Type" selectedIndex={[selectedTypeIndex]} changeSelection={i=>BaseBlock.updateContentType(block,itemsType[i[0]])} />
            <ComboBoxDivider />
            <ComboBox blockId={block.id} items={itemsStyle} multiSelect={false} title="Style" selectedIndex={[selectedStyleIndex]} changeSelection={i=>BaseBlock.updateContentStyle(block,itemsStyle[i[0]])} />
          </>;
           
}

function textComboBoxPanel(block:IBlock){
    if(!block.content || !block.content.style || !block.content.lines) return <></>;

    const itemsStyle = [ContentStyle.Small, ContentStyle.Normal, ContentStyle.Emphasis, ContentStyle.Title, ContentStyle.Subtitle];
    const selectedStyleIndex = itemsStyle.indexOf(block.content.style);

    const displayedLines = ['All', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const selectedLines = displayedLines.indexOf(block.content.lines);

    return  <>
                <ComboBox blockId={block.id} items={itemsStyle} multiSelect={false} title="Style" selectedIndex={[selectedStyleIndex]} changeSelection={i=>BaseBlock.updateContentStyle(block,itemsStyle[i[0]])} />
                <ComboBoxDivider />
                <ComboBox blockId={block.id} items={displayedLines} multiSelect={false} title="Max lines displayed" selectedIndex={[selectedLines]} changeSelection={i=>BaseBlock.updateContentDisplayedLines(block, displayedLines[i[0]] as ContentTextDisplayedRows)} />
            </>
        
           
}

function listComboBoxPanel(block:IBlock){
    if(!block.content || !block.content.style) return <></>;

    const itemsStyle = [ContentStyle.Ordered, ContentStyle.Unordered, ContentStyle.Composite];
    const selectedTypeIndex = itemsStyle.indexOf(block.content.style);

    const listComponetsComboBox = (block:IBlock) => {
        if(!block.content) return <></>;
        
        let currComps:ContentComponentType[] = [];
        if(block.content.comps)
            currComps = [...block.content.comps];

        const itemsComps = [ContentComponentType.Image, ContentComponentType.Title, ContentComponentType.Text, ContentComponentType.Link];
        const selectedComponentIndexes = currComps.map(v=>itemsComps.indexOf(v)).filter(v=>v>=0);
    
        return <>
                <ComboBoxDivider />
                <ComboBox blockId={block.id} items={itemsComps} multiSelect={true} title="Components" selectedIndex={selectedComponentIndexes} 
                    changeSelection={c=>BaseBlock.updateContentComps(block, c.map(i=>itemsComps[i]))} />
              </>
    }

    return <>
            <ComboBox blockId={block.id} items={itemsStyle} multiSelect={false} title="Type" selectedIndex={[selectedTypeIndex]} changeSelection={i=>BaseBlock.updateContentStyle(block,itemsStyle[i[0]])} />
            {block.content.style === ContentStyle.Composite && listComponetsComboBox(block)}
          </>;
           
}

function linkComboBoxPanel(block:IBlock){
    if(!block.content || !block.content.style || !block.content.type) return <></>;

    const itemsType = [ContentType.Button, ContentType.Link, ContentType.DashedLink];
    const selectedTypeIndex = itemsType.indexOf(block.content.type);

    const itemsStyle = [ContentStyle.Grey, ContentStyle.Colored];
    const selectedStyleIndex = itemsStyle.indexOf(block.content.style);

    

    return <>
            <ComboBox blockId={block.id} items={itemsType} multiSelect={false} title="Type" selectedIndex={[selectedTypeIndex]} changeSelection={i=>BaseBlock.updateContentType(block,itemsType[i[0]])} />
            <ComboBoxDivider />
            <ComboBox blockId={block.id} items={itemsStyle} multiSelect={false} title="Style" selectedIndex={[selectedStyleIndex]} changeSelection={i=>BaseBlock.updateContentStyle(block,itemsStyle[i[0]])} />
          </>;
           
}

function galleryComboBoxPanel(block:IBlock){
    if(!block.content || !block.content.style) return <></>;

    const itemsStyle = [ContentStyle.LegendOn, ContentStyle.LegendOff];
    const selectedStyleIndex = itemsStyle.indexOf(block.content.style);
    

    return <>
            <ComboBox blockId={block.id} items={itemsStyle} multiSelect={false} title="Legend" selectedIndex={[selectedStyleIndex]} changeSelection={i=>BaseBlock.updateContentStyle(block,itemsStyle[i[0]])} />
          </>;
           
}