import React from 'react';
import cn from 'classnames';
import ResizableTextArea from '../ResizableTextArea';
import { IContentItem } from '../../../../../models';
import MovementPanel from '../../../../MovementPanel';

interface ISimpleListItemState {
    value: string;
    hovered: boolean;
}

interface ISimpleListItemOwnProps {
    index: number;
    item: IContentItem;
    isPresentationMode: boolean;
    updateValue: (value:IContentItem, index: number) => void;
    selectBlock: () => void;
    moveup: (id: number|string) => void;
    movedown: (id: number|string) => void;
    remove: (id: number|string) => void;
    itemsMoreThan1: boolean;
}

export default class SimpleListItem extends React.Component<ISimpleListItemOwnProps, ISimpleListItemState> {

    constructor(props:any){
        super(props);

        this.state = {value: '', hovered: false};

        this.handleChange = this.handleChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    onFocus(e:any){
        this.props.selectBlock(); // активируем блок
    }

    onBlur(e:any){      
        this.props.updateValue({isDefault:false, value:this.state.value}, this.props.index);
    }    

    handleChange(e:any) {
        let value = e && e.target && e.target.value;
        
        this.setState(state => {return {...state, value: value}});

        if(this.props.item.isDefault)
            this.props.updateValue({isDefault:false, value:value}, this.props.index);
    }

    componentDidMount() {
        this.setState(state => {return { ...state, value: this.props.item.value }});
    }

    componentDidUpdate(prevProps:any){
        if(this.props.item.value  !== prevProps.item.value )
            this.setState(state => {return {...state, value: this.props.item.value }});
    }

    handleMouseEnter(e:any){
        this.setState(state => {return {...state, hovered: true }});
    }

    handleMouseLeave(e:any){
        this.setState(state => {return {...state, hovered: false }});
    }

    render(){

        const { isPresentationMode, item, index, movedown, moveup, remove, itemsMoreThan1 } = this.props;
        const style = cn('content-list-item', item.isDefault ? 'content-list-item-default': '');

        return <li className={style} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>                    
                    <ResizableTextArea value={this.state.value} onChange={this.handleChange} 
                        disabled={isPresentationMode} onFocus={this.onFocus} onBlur={this.onBlur} />  
                    {this.state.hovered && <MovementPanel id={index} movedown={movedown} moveup={moveup} remove={itemsMoreThan1 ? remove : undefined}/>}
               </li>; 
    }
}