import React from 'react';

interface IBlockPlaceholderItemProps{
    top: number;
    height:number;
}

export default class BlockPlaceholderItem extends React.Component<IBlockPlaceholderItemProps> {

    render(){

        const {top, height} = this.props;

        const style={top:top, height:height}; 

        return (<div style={style} className="block-placeholder-item"></div>)
    }
}