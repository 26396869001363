import { IBlock, TargetPosition } from ".";
import BaseBlock from "../core/blocks/base";
import { IPoint } from "./point";
import {IBlockToMove} from '../core/blocks-movements';
import { BlockType } from "./block";

class BlockCreation implements IBlockToMove {

    private preCreatedBlock?: IBlock;
    private children: IBlock[]=[];

    constructor(){
        this.setLeadBlock = this.setLeadBlock.bind(this);
        this.getLeadBlock = this.getLeadBlock.bind(this);
        this.reset = this.reset.bind(this);
        this.move = this.move.bind(this);
    }

    isFewBlocksToMove(){
        return true; 
    }

    setLeadBlock(b:IBlock, children?:IBlock[]){
        this.preCreatedBlock = b;
        if(children)
            this.children = children;
    }

    getLeadBlock(){
        return this.preCreatedBlock;
    }


    reset(){
        if(this.preCreatedBlock){
           const checked = BaseBlock.getBlock(this.preCreatedBlock.id);
           if(checked && !checked.coord && !checked.parentId){ // если блок не был никуда добавлен, то удаляем его                
                BaseBlock.remove(checked, true); // TODO: Переписать на BaseBlock.removeBlocks
           }
        }
        
        this.preCreatedBlock = undefined;
    }
    
    /*
     * Перемещает выделенные блоки на новое место
     */
    move(newPosition: TargetPosition){
        
        if(this.preCreatedBlock && newPosition && (<IPoint>newPosition).x){
            const p = newPosition as IPoint;
            this.preCreatedBlock.coord = p;
            let newBlocks = [{...this.preCreatedBlock}, ...this.children]
            this.reset();            
            BaseBlock.addBlocks(newBlocks);
            return;
        }
       
        if(this.preCreatedBlock){
            BaseBlock.move(this.preCreatedBlock, newPosition);
        }
            
    }
}

const newBlockCreate = new BlockCreation();
export default newBlockCreate;