import React from 'react';

export default class PrivacyPolicy_RU extends React.Component {

    render(){

        return <div id="pp_wrapper">
                    <h1>Политика конфиденциальности ООО “Визлс”</h1>
                    <h6>Дата публикации: 06 октября 2019</h6>
                    <h2>1. Что регулирует настоящая политика конфиденциальности</h2>
                    <p>Настоящая политика конфиденциальности (далее — Политика) действует в отношении всей информации о Пользователе, включая персональные данные в понимании действующего российского законодательства (далее — «Персональная информация»), которую ООО «Визлс» (далее - Компания), может получить о Пользователе в процессе использования последним программного комплекса Компании, расположенного по адресу <a href="https://vizls.com">https://vizls.com</a> (далее — «Платформа»), а также в ходе исполнения соглашения, заключенного с Пользователем в связи с использованием Платформы.</p>
                    
                    <h2>2. Кто обрабатывает информацию</h2>
                    <p>Для обеспечения использования Платформы и ее Сервисов Персональная информация Пользователей собирается и используется обществом с ограниченной ответственностью «Визлс», юридическим лицом, созданным по законодательству Российской Федерации и зарегистрированным по адресу: 129336, Москва, ул. Стартовая, дом 39, кв. 81. </p>
                    
                    <h2>3. Какова цель данной Политики</h2>
                    <p>Защита Персональной информации и конфиденциальности Пользователя чрезвычайно важны для Компании. Поэтому при использовании Платформы Пользователем Компания защищает и обрабатывает Персональную информацию последнего в строгом соответствии с действующим законодательством.</p>
                    <p>Следуя нашим обязанностям защищать Персональную информацию Пользователя, в этой Политике мы хотели бы наиболее прозрачно проинформировать о следующих моментах:</p>
                    <p className="li-dot">a.</p> <p className="li-item">зачем и как Компания использует («обрабатывает») Персональную информацию, когда Пользователь использует Платформу;</p>
                    <p className="li-dot">b.</p> <p className="li-item">какова роль и обязанности Компании как юридического лица, принимающего решение о том, зачем и как обрабатывать Персональную информацию;</p>
                    <p className="li-dot">c.</p> <p className="li-item last-item">каковы права Пользователя в рамках проводимой обработки Персональной информации.</p>
                    
                    <h2>4. Какую Персональную информацию собирает Компания</h2>
                    <p>Компания может собирать следующие категории Персональной информации о Пользователе во время использования  Платформы:</p>
                    <p className="li-dot">I.</p> <p className="li-item">Персональная информация, предоставленная при регистрации (создании учетной записи), такая как псевдоним, адрес электронной почты Пользователя;</p>
                    <p className="li-dot">II.</p> <p className="li-item">электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, данные об идентификаторе браузера, информация об аппаратном и программном обеспечении);</p>
                    <p className="li-dot">III.</p> <p className="li-item last-item">дата и время осуществления доступа к Платформе;</p>
                    <p>Также Компания может использовать файлы cookie для сбора Персональной информации и связывания такой личной информации с устройством и веб-браузером Пользователя (см. раздел 11 настоящей Политики).</p>
                    <p>Компания не собирает информацию, относящуюся к категории персональных данных в соответствии с Федеральным законом от 27 июля 2006 г. N 152-ФЗ "О персональных данных" (далее - Закон № 152-ФЗ), но в случае предоставления таковой Пользователем по инициативе Пользователя выполняет обработку указанных персональных данных в целях исполнения Соглашения в соответствии с требованиями, установленными Законом N 152-ФЗ.</p>
                    <p>Компания также не собирает чувствительную Персональную информацию (такую как расовое происхождение, политические взгляды, информация о здоровье и биометрические данные).</p>
                    <p>Компания не собирает данные с целью составления «портрета» Пользователя в той степени, при которой это может существенно повлиять на его права и свободы в соответствии с действующим законодательством.</p>
                    
                    <h2>5. Правовая основа и цели обработки Персональной информации</h2>
                    <p>Компания не вправе обрабатывать Персональную информацию без достаточных на то правовых оснований. Поэтому Компания обрабатывает Персональную информацию только в том случае, если:</p>
                    <p className="li-dot">I.</p> <p className="li-item">обработка необходима для выполнения договорных обязательств Компании перед Пользователем, включая обеспечение полной функциональности Платформы (например, после регистрации вы получает возможность пользоваться Платформой);</p>
                    <p className="li-dot">II.</p> <p className="li-item">обработка необходима для соблюдения установленных законодательством обязательств;</p>
                    <p className="li-dot">III.</p> <p className="li-item last-item">когда это предусмотрено законодательством, обработка необходима для обеспечения законных интересов Компании в случае, если такая обработка не оказывает существенного влияния на интересы Пользователя, его фундаментальные права и свободы. Обратите внимание, что при обработке персональной информации на указанном основании Компания всегда будет стремиться поддерживать баланс между своими законными интересами и защитой конфиденциальности.</p>
                    <p>Компания обрабатывает Персональную информацию для обеспечения своих законных интересов, например, в следующих случаях:</p>
                    <p className="li-dot">a.</p> <p className="li-item">чтобы лучше понимать, как Пользователь взаимодействует с Платформой;</p>
                    <p className="li-dot">b.</p> <p className="li-item">чтобы совершенствовать, менять, персонализировать или иным образом улучшать Платформу в интересах всех пользователей;</p>
                    <p className="li-dot">c.</p> <p className="li-item last-item">для конкретных целей мы можем запросить отдельное согласие Пользователя на обработку Персональной информации.</p>
                    <p>Компания всегда обрабатывает Персональную информацию в определенных целях и только ту Персональную информацию, которая имеет отношение к достижению таких целей. В частности, мы обрабатываем Персональную информацию для следующих целей:</p>
                    <p className="li-dot">I.</p> <p className="li-item">предоставление Пользователю доступа к Платформе;</p>
                    <p className="li-dot">II.</p> <p className="li-item">предоставление доступа Пользователя к Учетной записи;</p>
                    <p className="li-dot">III.</p> <p className="li-item">осуществление связи с Пользователем для направления уведомлений, запросов и информации, относящейся к работе Платформы, выполнения соглашения с Пользователем и обработки его запросов и заявок;</p>
                    <p className="li-dot">IV.</p> <p className="li-item">направления рекламных сообщений и предложений, если Пользователь не отказался от получения такого вида сообщений;</p>
                    <p className="li-dot">V.</p> <p className="li-item last-item">защита прав Пользователя и прав Компании.</p>
                    
                    <h2>6. Защита Персональной информации</h2>
                    <p>В большинстве случаев Персональная информация обрабатывается автоматически без доступа к ней кого-либо из сотрудников Компании. В случае если такой доступ понадобится, то он может быть предоставлен только тем сотрудникам Компании, которые нуждаются в этом для выполнения своих задач. Для защиты и обеспечения конфиденциальности данных все сотрудники должны соблюдать внутренние правила и процедуры в отношении обработки Персональной информации. Они также должны следовать всем техническим и организационным мерам безопасности, действующим для защиты Персональной информации.</p>
                    <p>Компания также внедрила достаточные технические и организационные меры для защиты Персональной информации от несанкционированного, случайного или незаконного уничтожения, потери, изменения, недобросовестного использования, раскрытия или доступа, а также иных незаконных форм обработки. Данные меры безопасности были реализованы с учетом современного уровня техники, стоимости их реализации, рисков, связанных с обработкой и характером Персональной информации.</p>
                    
                    <h2>7. Доступ к Персональной информации третьих лиц</h2>
                    <p>Компания может передавать Персональную информацию третьим лицам только для достижения целей, указанных в разделе 5 настоящей Политики. К таким третьим лицам могут относиться:</p>
                    <p className="li-dot">I.</p> <p className="li-item">государственный и/или международный регулирующий орган, правоохранительные органы, центральные или местные исполнительные органы власти, другие официальные или государственныее органы или суды, в отношении которых Компания обязана предоставлять информацию в соответствии с законодательством по соответствующему запросу;</p>
                    <p className="li-dot">II.</p> <p className="li-item">третьи лица, в случае если Пользователь выразил согласие на передачу Персональной информации либо передача Персональной информации требуется для предоставления Пользователю соответствующего Сервиса или выполнения определенного соглашения или договора, заключенного с Пользователем;</p>
                    <p className="li-dot">III.</p> <p className="li-item last-item">любые третьи лица в целях обеспечения правовой защиты Компании или третьих лиц при нарушении Пользователем Пользовательского соглашения, настоящей Политики, либо в ситуации, когда существует угроза такого нарушения.</p>
                    
                    <h2>8. Где хранится и обрабатывается Персональная информация</h2>
                    <p>Персональная информация, относящаяся к категории персональных данных в соответствии с Законом № 152-ФЗ, храниться в Российской Федерации. Компания осуществляет запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации.</p>
                    <p>Хранение и обработка иной Персональной информации может осуществляться Компанией как на территории Российской Федерации, так и за ее пределами.</p>
                    <h2>9. Как долго мы храним Персональную информацию</h2>
                    <p>Компания будет хранить Персональную информацию столько времени, сколько это Компании необходимо для достижения цели, для которой она была собрана, или для соблюдения требований законодательства и нормативных актов.</p>
                    <p>Если Пользователь хочет, чтобы какая-либо Персональная информация была удалена из баз данных Компании, он может самостоятельно удалить необходимую Персональную информацию с использованием своей Учетной записи или направить Компании соответствующий запрос (где это применимо). При этом удаление такой Персональной информации как логин и (или) пароль влечет удаление Учетной записи Пользователя.</p>
                    <h2>10. Права Пользователя</h2>
                    <h3>10.1. Пользователь имеет право на доступ к своей Персональной информации, обрабатываемой Компанией в соответствии с настоящей Политикой.</h3>
                    <p>Если Пользователь считает, что какая-либо информация, которую Компания хранит о нем, некорректная или неполная, то Пользователь может войти в свою Учетную запись и исправить Персональную информацию самостоятельно.</p>
                    <p>Пользователь имеет право:</p>
                    <p className="li-dot dot">•</p> <p className="li-item">требовать удаления Персональной информации и (или) отзыва согласия на обработку персональных данных;</p>
                    <p className="li-dot dot">•</p> <p className="li-item">требовать ограничений на обработку Персональной информации;</p>
                    <p className="li-dot dot">•</p> <p className="li-item last-item">возражать против обработки Персональной информации, если это предусмотрено законодательством.</p>
                    <p>Компания будет выполнять указанные запросы в соответствии с действующим законодательством.</p>
                    <p className="last-item">В случаях, предусмотренных законодательством, Пользователь может также обладать другими правами, не указанными выше.</p>
                    <h3>10.2. Порядок реализации прав Пользователя:</h3>
                    <p>Для осуществления вышеуказанных прав Пользователю необходимо войти в свою Учетную запись, а в случае отсутствия специальной функции в интерфейсе связаться с Компанией (см. раздел 13 настоящей Политики).</p>
                    <p>Если Пользователь не удовлетворен тем, как Компания обрабатывает его Персональную информацию, он вправе направить Компании претензию. В случае несогласия с ответом Компании, Пользователь имеет право подать жалобу в компетентный орган.</p>
                    <h2>11. Как мы используем файлы cookie и другие подобные технологии на Платформе</h2>
                    <h3>11.1. Что такое файлы cookie и для чего их использует Компания</h3>
                    <p>Файлы cookie — это небольшие текстовые файлы, размещенные на устройстве, которое Вы используете для доступа к Платформе. Они содержат информацию, которая собирается с устройства Пользователя и отправляется обратно на Платформу при каждом последующем их посещении для того, чтобы помнить действия и предпочтения Пользователя по истечении времени.</p>
                    <p>На Платформе используются следующие типы файлов cookie:</p>
                    <p className="li-dot dot">•</p> <p className="li-item">строго необходимые файлы cookie / технические файлы cookie: эти файлы cookie необходимы для работы Платформы и предоставления Вам Сервисов; кроме всего прочего, они позволяют Компании идентифицировать аппаратное и программное обеспечение Пользователя, включая тип браузера;</p>
                    <p className="li-dot dot">•</p> <p className="li-item">статистические / аналитические файлы cookie: эти файлы cookie позволяют распознавать пользователей, подсчитывать их количество и собирать информацию, такую как произведенные Вами операции на Платформе, включая информацию о посещенных веб-страницах и контенте, который Пользователь получает;</p>
                    <p className="li-dot dot">•</p> <p className="li-item">технические файлы cookie: эти файлы cookie собирают информацию о том, как пользователи взаимодействуют с Платформой, что позволяет выявлять ошибки и тестировать новые функции для повышения производительности Платформы и ее сервисов;</p>
                    <p className="li-dot dot">•</p> <p className="li-item">функциональные файлы cookie: эти файлы cookie позволяют предоставлять определенные функции, чтобы облегчить использование Пользователем Платформы, например, сохраняя  предпочтения Пользователя (такие как язык и местоположение);</p>
                    <p className="li-dot dot">•</p> <p className="li-item last-item">(сторонние) файлы отслеживания / рекламные файлы cookie: эти файлы cookie собирают информацию о пользователях, источниках трафика, посещенных страницах и рекламе, отображенной для Вас, а также той, по которой Вы перешли на рекламируемую страницу. Они позволяют отображать рекламу, которая может Вас заинтересовать, на основе анализа Персональной информации, собранной о Вас. Они также используются в статистических и исследовательских целях.</p>
                    <h3>11.2. Как долго файлы cookie хранятся на устройстве Пользователя:</h3>
                    <p className="last-item">Компания использует информацию, содержащуюся в файлах cookie только в указанных выше целях, после чего собранные данные будут храниться на Вашем устройстве в течение периода, который может зависеть от соответствующего типа файлов cookie, но не превышая срока, необходимого для достижения их цели, после чего они будут автоматически удалены из Вашей системы.</p>
                    <h3>11.3. Кто еще имеет доступ к информации, содержащейся в файлах cookie:</h3>
                    <p>Персональная информация, собранная с помощью файлов cookie, размещенных на устройстве Пользователя, может быть передана и доступна Компании и/или третьим лицам, указанным в разделе 7 настоящей Политики. </p>
                    <p>При первом посещении Платформы может быть запрошено согласие Пользователя на использование файлов cookie. Если после того, как Пользователь одобрил использование файлов cookie, и захотел изменить свое решение, Пользователь сможет сделать это, удалив файлы cookie, хранящиеся в его браузере (обычно это можно сделать в настройках браузера — пожалуйста, обратитесь к руководству по работе с браузером или Платформой у его разработчика). После этого может быть снова отображено всплывающее окно, запрашивающее согласие Пользователя, и он сможете сделать иной выбор. Если Пользователь отказывается от использования файлов cookie, это может привести к тому, что некоторые функции Платформы будут ему недоступны, что повлияет на возможность использования Платформы. Пользователь также может изменить настройки своего браузера, чтобы принимать или отклонять по умолчанию все файлы cookie или файлы cookie с определенных сайтов, включая Платформу Компании.</p>
                    <h2>12. Обновление настоящей Политики</h2>
                    <p>В настоящую Политику могут быть внесены изменения. Компания имеет право вносить изменения по своему усмотрению, в том числе, но не ограничиваясь, в случаях, когда соответствующие изменения связаны с изменениями в законодательстве, а также когда соответствующие изменения связаны с изменениями в работе Платформы.</p>
                    <p>Компания обязуется не вносить существенных изменений, не налагать дополнительных обременений или ограничений прав пользователей, установленных настоящей Политикой, без соответствующего уведомления. Уведомления могут быть отображены на Платформе (например, через всплывающее окно или баннер) до того, как такие изменения вступят в силу, или могут быть отправлены Пользователю по другим каналам связи (например, по электронной почте).</p>
                    <h2>13. Вопросы и предложения</h2>
                    <p>Компания приветствует вопросы и предложения Пользователей, касающиеся исполнения или изменения настоящей Политики. Пользователь также может воспользоваться адресом <a href="mailto:feedback@vizls.com">feedback@vizls.com</a> для направления запросов о реализации своих прав или подачи жалоб относительно некорректности Персональной информации или незаконности ее обработки.</p>
                </div>;
    }
}