import * as React from 'react';
import './payment.css';
import { IPayment } from "../../api/payment";
import i18next from "i18next";

declare const cp: any;

interface IProps {
    payment: IPayment
}

export class Payment extends React.Component<IProps> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount(){
        this.pay(this.props.payment);
    }

    componentDidUpdate(prevProps: Readonly<IProps>){
        if (this.props.payment && prevProps.payment !== this.props.payment) {
            this.pay(this.props.payment);
        }
    }

    pay(payment: IPayment){
        // const cultureInfo: string = this.wigetLanguage(payment.currency);
        const widget = new cp.CloudPayments({language: i18next.languages[0]});
        widget.charge({
                publicId: "pk_2e0b506ac3a1d185e3a67096d35b2",
                description: payment.description,
                amount: payment.amount,
                currency: payment.currency,
                invoiceId: payment.order,
                accountId: payment.email,
                email: payment.email,
                skin: "mini",
                data: this.getRecurent(payment)
            },

            (options: any) => {
            },

            (reason: any, options: any) => {
            });
    }


    getRecurent(payment: IPayment): any  {
        const data: any = {};

        const receipt = {
            Items: [//товарные позиции
                {
                    label: payment.description,
                    price: payment.amount,
                    quantity: 1,
                    amount: payment.amount,
                    vat: 0, //ставка НДС
                    method: 0,
                    object: 0,
                }
            ],
            taxationSystem: 0, //система налогообложения; необязательный, если у вас одна система налогообложения
            email: payment.email,
            isBso: false,
            amounts:
                {
                    electronic:  payment.amount,
                    advancePayment: 0.00,
                    credit: 0.00,
                    provision: 0.00
                }
        };

        data.cloudPayments = {recurrent: { interval: 'Month', period: 1, customerReceipt: receipt}};
        
        return data;
    }

    wigetLanguage(currency: string): string{
        switch (currency) {
            case "RUB": {return "ru-RU"}
            default: {return "en-US"}
        }
    }

    render() {
        return (
            <>
            </>
        );
    }
}
