import { httpGet, httpPost } from '../common';
import { DetailLevel, IPoint } from '../../models';

export interface IProjectSettings {
    id?: string;
    projectId: string;
    isCollapseLeftSideBar: boolean;
    isCollapseRightSideBar: boolean;
    detailLevel: DetailLevel;
    workCanvasPosition: string;

}
export function createProjectSettings(projectSettings: IProjectSettings) {
    return httpPost<IProjectSettings>('/api/projectSettings', projectSettings, { authorize: true });
}

export function getProjectsSettingsById(projectId: string) {
    return httpGet<IProjectSettings>(`/api/projectSettings/${projectId}`, { authorize: true });
}
