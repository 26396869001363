import { IBlock, TargetPosition } from ".";
import BaseBlock, { getHighestBlock } from "../core/blocks/base";
import { IPoint } from "./point";
import { blockHeight } from "../core/blocks";
import { MIN_STEP } from "../consts";
import { comparerBlockType } from "../core/comparer";
import { IBlockToMove } from "../core/blocks-movements";
import removeBlocks from "../core/remover/removeBlocks";
import { BlockType } from "./block";

class SelectedBlocks implements IBlockToMove {

    private leadBlock?: IBlock; // максимальный элемент в выделение.
    private selectedBlock: IBlock[] = []
    private selectedBlockId: string[] = []

    private setLeadBlock(b:IBlock){
        if(!this.leadBlock) this.leadBlock = b;
        else {
            if(comparerBlockType(b.type, this.leadBlock.type)==1)
                this.leadBlock = b;
        }
    }

    isFewBlocksToMove(){
        if(this.selectedBlock.length>1) return true;
        return false;
    }

    getBlocks(){
        return this.selectedBlock;
    }

    // removeBlocks(){
    //     const blocksToRemove = this.selectedBlock;
    //     this.reset();
    //     removeBlocks(blocksToRemove);
    //     // for(const b of blocksToRemove){
    //     //     BaseBlock.remove(b);
    //     // }        
    // }

    getSelectedBlocksId(){
        return this.selectedBlockId;
    }

    getSelectedBlocksSize(){
        return this.selectedBlockId.length;
    }

    getLeadBlock(){
        return this.leadBlock;
    }

    add(id: string) {
        const block = BaseBlock.getBlock(id);
        
        if (block && !this.isHighestBlockTemplate(id)) {
            this.selectedBlockId.push(block.id);
            this.selectedBlock.push(block);
            this.setLeadBlock(block);
        }
    }

    isHighestBlockTemplate(id: string){
        const highest = getHighestBlock(id); 
        if (highest 
                && highest.id === id
                && (highest.type === BlockType.TemplateComposite || highest.type === BlockType.TemplateFixed) )
            return true;

        return false;
    }

    reset(){
        this.leadBlock = undefined;
        this.selectedBlock=[];
        this.selectedBlockId=[];
    }
    
    /*
     * Перемещает выделенные блоки на новое место
     */
    move(newPosition: TargetPosition){
        let moveResult = true;
        if(newPosition && (<IPoint>newPosition).x){
            const p = newPosition as IPoint;
            let height = 0;
            for(const b of this.selectedBlock){
                const res1 = BaseBlock.move(b, { x: p.x, y: p.y + height });
                moveResult = moveResult && res1;
                height += (blockHeight(b.id,true) + MIN_STEP * 2);
            }
        }

        else
            for(const b of this.selectedBlock){
                const res2 = BaseBlock.move(b, newPosition);
                moveResult = moveResult && res2;
            }

        if(moveResult){
            this.reset();
            BaseBlock.resetSelection();
        }
    }
}

const selectedBlocks = new SelectedBlocks();
export default selectedBlocks;