import {IStore, store, APP_STATE} from "./configureStore";
import {IUserRead} from "../api/user";
import {IProject} from "../api/projects";
import idbApp from "../indexeddb";

export interface IPersistedState {
    auth: {
        accessToken: string | null;
        authenticationScheme: string | null;
        refreshToken: string | null;
        isAuthorized: boolean;
    },
    currentUser : IUserRead | null
}

export interface IPersistedProjectState {
    project : IProject | null
}

function selectProject(state: any) {
    const storedState: IPersistedProjectState = {
        project: state.projects.edit.item
    };
    return storedState
}


function select(state: any) {
    const storedState: IPersistedState = {
        auth: {
            accessToken: state.auth.accessToken,
            authenticationScheme: state.auth.authenticationScheme,
            refreshToken: state.auth.refreshToken,
            isAuthorized: !!state.auth.accessToken
        },
        currentUser: state.currentUser.item
    };
    return storedState
}

let currentValue: IPersistedState;
let currentProject: IPersistedProjectState;

export const saveState = (store: IStore) => {
    let previousValue = currentValue
    currentValue = select(store.getState())

    if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
        localStorage.setItem(APP_STATE, JSON.stringify({isAuthorized: currentValue.auth.isAuthorized}));
        idbApp.set(APP_STATE, JSON.stringify(currentValue));
    }

    let previousProjectValue = currentProject
    currentProject = selectProject(store.getState())

    if (currentProject.project && JSON.stringify(previousProjectValue) !== JSON.stringify(currentProject)) {
        idbApp.set(`${APP_STATE}_${currentProject.project.id}`, JSON.stringify(currentProject));
    }
};


