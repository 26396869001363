import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { timeFormat } from 'd3';
import ProjectDropdownMenu from '../ProjectDropdownMenu';
import { IState } from '../../../redux/reducers';
import { connect } from 'react-redux';

interface IProjectDropdownMenuProps {
    closePopup: boolean;
}

const mapStateToProps = (state: IState): IProjectDropdownMenuProps => {
    return {
        closePopup: state.app.closeAllPopupMenus
    };
};

interface IDashboardItemProps {
    name: string;
    lastEditTime: Date;
    backgroundColor: string;
    handleSettingsClick: (e: number) => void;
    handleEditClick: (e: number) => void;
    handleDeleteClick: (e: number) => void;
}

interface IDashboardItemState {
    show: boolean;
}

const timeFormatter = timeFormat('%H:%M %_d.%m.%Y');

class DashboardItem extends React.Component<IDashboardItemProps & WithTranslation & IProjectDropdownMenuProps, IDashboardItemState> {
    constructor(props: any) {
        super(props);

        this.state = { show: false };

        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleSettingsClick = this.handleSettingsClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
    }

    componentDidUpdate() {
        if (this.state.show && this.props.closePopup) {
            this.setState((state) => { return { ...state, show: false } });
        }
    }

    handleEditClick(e: any) {
        this.props.handleEditClick(e.target.value);
        e.stopPropagation();
    }

    handleSettingsClick(e: any) {
        this.props.handleSettingsClick(e.target.value);
        e.stopPropagation();
    }

    handleDeleteClick(e: any) {
        this.props.handleDeleteClick(e.target.value);
        e.stopPropagation();
    }

    showMenu(e: any) {
        e.stopPropagation();
        this.setState((state) => { return { ...state, show: true } });
    }

    hideMenu() {
        this.setState((state) => { return { ...state, show: false } });
    }

    render() {
        const { name, lastEditTime, backgroundColor, t } = this.props;
        const lastEditDateServerTime = new Date(lastEditTime);

        var currentTimeZoneOffsetInHours = lastEditDateServerTime.getTimezoneOffset() / 60;

        lastEditDateServerTime.setHours(lastEditDateServerTime.getHours() + ((-1) * currentTimeZoneOffsetInHours));
        return (<div className="dashboard-projects-item">
            <div className="dashboard-projects-item--logo" style={{ backgroundColor: backgroundColor }} onClick={this.handleEditClick}>
                <div className="dashboard-projects-item--logo__name">
                    {name}

                </div>
            </div>
            <div className="dashboard-projects-item-title">
                <div className="info">
                    <div className="dashboard-projects-item-title__name">{name}</div>
                    <div className="dashboard-projects-item-title__time">{t('dashBoard.lastEdited')}: {timeFormatter(lastEditDateServerTime)}</div>
                </div>
                <div className="dashboard-projects-item-title-menu__button" onMouseDown={this.showMenu}>
                    <ProjectDropdownMenu handleEditClick={this.handleEditClick} show={this.state.show} hideMenu={this.hideMenu}
                        handleSettingsClick={this.handleSettingsClick}
                        handleDeleteClick={this.handleDeleteClick} />
                </div>
            </div>
        </div>);
    }
}

export default connect(mapStateToProps)(withTranslation()(DashboardItem));