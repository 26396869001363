import { IUserWrite, IErrorResponse, IUserQuery} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'USERS_TABLE';

export const ACTIONS = {
    TABLE: actionsSet(prefix, 'FETCH')
};

export interface ISaveInitAction extends Action {
    params: IUserQuery;
}

export interface ISaveDoneAction extends Action {
    items: IUserWrite[];
}

export const actions = {
    save: {
        init: (params: IUserQuery) => ({type: ACTIONS.TABLE.INIT, params}) as ISaveInitAction,
        done: (items: IUserWrite[]) => ({type: ACTIONS.TABLE.DONE, items}) as ISaveDoneAction,
        fail: (error: IErrorResponse) => ({type: ACTIONS.TABLE.FAIL, error}) as IFailAction
    }
}

