import { all, fork } from "redux-saga/effects";
import { createSaga } from "./invoices";
import { lastInvoiceSaga } from "./lastInvoice";
import { tariffSaga } from "./tariff";

export function* invoicesSaga() {
    yield all([
        fork(createSaga),
        fork(lastInvoiceSaga),
        fork(tariffSaga)
    ]);
}