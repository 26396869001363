import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/projectSettings/index';
import {actions} from "../../actions/index";
import {ISaveInitAction} from "../../actions/projectSettings/view";
import {IErrorResponse} from "../../../api";
import {getProjectsSettingsById, IProjectSettings} from "../../../api/projectSettings";

export function* sagaHandler(action: Action) {
    try {
        const { id } = action as ISaveInitAction;
        const result: IProjectSettings =  yield call(getProjectsSettingsById, id);
        yield put(actions.projectSettings.view.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.projectSettings.view.fail(c))
    }
}

export function* viewSaga() {
    yield takeLatest(ACTIONS.VIEW.VIEW.INIT, sagaHandler);
}
