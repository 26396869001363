import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './app/serviceWorker';
import { Provider } from 'react-redux'
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router-dom';
import {store, history} from "./store/configureStore";
import { loadStateAsync } from './store/loadStateAsync';
import { actions } from './redux/actions';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-157434808-1');

loadStateAsync().then(() =>
  ReactDOM.render(
      <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <Router history={history}>
              <App/>
            </Router>
          </I18nextProvider>
      </Provider>
    , document.getElementById('root')));

window.addEventListener("beforeunload", function () {
    const r = document.getElementById('root');
    if(r !== null) {
        ReactDOM.unmountComponentAtNode(r);
    }
}, false);

window.addEventListener('storage', e => {

  if(e.key === 'VizlsApp' && e.oldValue && e.newValue) {
    try {
      const isAuthorized = JSON.parse(e.newValue).isAuthorized;
      
      if(!isAuthorized) {
        store.dispatch(actions.auth.logout())
      }
      else {
        window.location.href = ('/dashboard');
      }
    }
    catch(ex){}
  }

});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
