import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/index';
import {resetPassword} from "../../../api/userSecurity/index";
import {actions} from "../../actions/index";
import {IErrorResponse} from "../../../api/index";
import {IResetPasswordInitAction} from "../../actions/userSecurity/resetPassword";
import {push} from "react-router-redux";

export function* sagaHandler(action: Action) {
    try {
        const { token, password } = action as IResetPasswordInitAction;
        const result: boolean =  yield call(resetPassword, token, password);
        yield put(actions.userSecurity.resetPassword.done());
        yield put(push(`/`));
        yield put(actions.modalForm.resetPasswordForm.close());
        yield put(actions.modalForm.signIn.open(false));
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.userSecurity.resetPassword.fail(c))
    }
}

export function* resetPasswordSaga() {
    yield takeLatest(ACTIONS.userSecurity.resetPassword.RESET_PASSWORD.INIT, sagaHandler);
}
