import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { actions, ACTIONS } from '../actions';
import { ILoginInitAction } from '../actions/auth';
import {authorize, IAuthResult} from "../../api/auth/index";
import {IErrorResponse} from "../../api/index";
import {push} from "react-router-redux";

export function* loginHandler(action: Action) {
    try {
        const { username, password } = action as ILoginInitAction;
        const result: IAuthResult =  yield call(authorize, username, password);
        yield put(actions.auth.login.done(result))
        yield put(actions.users.currentUser.fetch.init());
        yield put(actions.modalForm.signIn.close());
        yield put(push(`/dashboard`));
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.auth.login.fail(c))
    }
}
export function* logoutHandler(action: Action) {
        yield put(actions.users.currentUser.clear.init());
        yield put(push(`/`));
}


export function* authSaga() {
    yield takeLatest(ACTIONS.auth.LOGIN_INIT, loginHandler);
    yield takeLatest(ACTIONS.auth.LOGOUT, logoutHandler);
}
