import { BlockType, IPoint, IBlock } from "../../models";
import BaseBlock from "./base";


export default class TemplateFixedBlock extends BaseBlock {    

    constructor(title:string, id?:string, children: string[] = []){
        super(title,BlockType.TemplateFixed, children, id, undefined, undefined, undefined);
    }
}

export function removeSeletedFixedTemplates(){
    let blocksToRemove: IBlock[] = [];
    const blocksSel = BaseBlock.getBlocks().filter(b=>b.isSelected && b.type === BlockType.TemplateFixed);
    if(blocksSel.length>0 && blocksSel[0].type === BlockType.TemplateFixed){
        const templatesFixed = blocksSel.map(b=>b.id);
        blocksToRemove = BaseBlock.getBlocks()
                                     .filter(b=>b.templateId && b.type===BlockType.Fixed && templatesFixed.indexOf(b.templateId)>=0); 
    }
    return blocksToRemove;
}