import React from 'react';
import {AddEditor, LogoWorkCanvas, Preview} from '../../../../icons';
import './topmenu.scss';
import { withRouter } from 'react-router';
import * as H from 'history';
import { DetailLevel } from '../../../../models';
import { renderComboBoxPanel } from './comboBoxPanel';
import ProfileButton from "../../../../components/Header/ProfileButton";
import {Link} from "react-router-dom";
import ShareLink from "../../../../components/ShareLink";

interface IRouterProps {
    match: any;
    history: H.History;
    location: H.Location;
}
interface IWorkCanvasTopMenuProps {
    isAuthorized?: boolean;
    detailLevel: DetailLevel;
    editedBlockId?: string
}

interface IStateWorkCanvasTopMenu {
    isOpen: boolean;
}


class WorkCanvasTopMenu extends React.Component<IWorkCanvasTopMenuProps & IRouterProps, IStateWorkCanvasTopMenu> {
    constructor(props: any) {
        super(props);
        this.state = ({isOpen: false})
        this.handlePresentationModeClick = this.handlePresentationModeClick.bind(this);
        this.handleAllowModeClick = this.handleAllowModeClick.bind(this);
    }

    handlePresentationModeClick(){
        if (this.props.history && this.props.match && this.props.match.params.id) {
            this.props.history.push(`/presentation-mode/view/${this.props.match.params.id}`)
        }
    }

    handleAllowModeClick(){
        this.setState({...this.state, isOpen: !this.state.isOpen})
    }

    renderLink(){
        const {isOpen} = this.state
        if (isOpen) {
            return <>
                <div className="workcanvas-link-triangle"/>
                <div className="workcanvas-link-inner-triangle"/>
                <div className="workcanvas-link-block">
                    <ShareLink id={this.props.match.params.id}/>
                </div>
            </>
        }
    }

    renderAddBlock(){
        return(
            <div className="workcanvas-action-container">
                <div className="workcanvas-action-button" onClick={this.handleAllowModeClick}>
                    <AddEditor/>
                </div>

                {this.renderLink()}
            </div>)
    }

    render() {
        const { history, isAuthorized, detailLevel, editedBlockId } = this.props;
       
        return (
            <div className="workcanvas-topmenu">

                <div className="workcanvas-topmenu-logo" onClick={() => { if (isAuthorized) history.push('/dashboard') }}>
                    <LogoWorkCanvas />
                </div>

                {detailLevel === 2 && renderComboBoxPanel(editedBlockId)}

                <div className="workcanvas-profile-button">
                    {this.renderAddBlock()}
                    <div className="workcanvas-action-button">
                        <Link className="" to={`/presentation-mode/view/${this.props.match.params.id}`} target="_blank"><Preview/></Link>
                    </div>
                 <ProfileButton className={"profile-button-square"} />
                </div>

            </div>);
    }
}

export default withRouter(WorkCanvasTopMenu);
