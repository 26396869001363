import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import '../style.scss'
import { IProject } from "../../../../api/projects";
import { Action } from "redux";
import { IState } from "../../../../redux/reducers";
import * as actions from "../../../../redux/actions";
import { connect } from "react-redux";
import cn from 'classnames';
import { IErrorResponse } from "../../../../api";
import InputContainerDark from '../../../InputContainerDark';
import { CoverColorSelectionIcon } from '../../../../icons';
import { white, red, orange, gold, green, light_blue, blue, purple, pink, grey } from '../../../../consts';
import ModalForm from "../../../Landing/ModalForm";
import SubmitButton from "../../../Buttons/SubmitButton";
import { DefaultButton } from "../../../Buttons/DefaultButton";

interface IStateProps {
    isOpen: boolean,
    save_project: IProject | null,
    save_inProgress: boolean,
    save_error: IErrorResponse | null,
}

interface IDispatchProps {
    save: (item: IProject) => Action,
    close: () => Action,
}

interface ISettingsFormState {
    title: string;
    description: string;
    color: string;
    disabled: boolean;
    errorDescription: string;
    errorTitle: string
}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        isOpen: state.modalForm.projectSettingsCreate.isOpen,
        save_project: state.projects.create.item,
        save_inProgress: state.projects.create.inProgress,
        save_error: state.projects.create.error
    };
};

const mapDispatchToProps: IDispatchProps = {
    save: actions.actions.projects.create.init,
    close: actions.actions.modalForm.projectSettingsCreate.close,
};

class ProjectSettingsModalCreate extends React.Component<WithTranslation & IStateProps & IDispatchProps, ISettingsFormState> {
    constructor(props: any) {
        super(props);

        this.state = { errorDescription: '', errorTitle: '', title: "", description: "", color: white, disabled: false };
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeColor = this.handleChangeColor.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.handleBlurTitle = this.handleBlurTitle.bind(this);
        this.handleBlurDescription = this.handleBlurDescription.bind(this);
        this.validate = this.validate.bind(this);
        this.renderColorsPanel = this.renderColorsPanel.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<WithTranslation & IStateProps & IDispatchProps>) {
        const isError = this.props.save_error;
        if (this.props && !isError && !this.props.save_inProgress && this.props.save_inProgress !== prevProps.save_inProgress) {
            this.handleClose();
        }
    }

    handleClose() {
        this.setState({ ...this.state, title: "", color: "", description: "", disabled: false })
        this.props.close();
    }

    handleChangeTitle(e: any) {
        this.setState({ ...this.state, title: e.target.value });
    }

    handleChangeDescription(e: any) {
        this.setState({ ...this.state, description: e });
    }

    handleChangeColor(e: any) {
        this.setState({ ...this.state, color: e });
    }

    handleSubmit() {
        this.setState({ ...this.state, disabled: true })
        const { title, color, errorDescription, errorTitle } = this.state;
        const { save } = this.props;
        const newProject = {} as IProject;

        const defaultColor = !color ? white : color;

        if (newProject && title && defaultColor
            && !errorDescription && !errorTitle) {
                newProject.title = title;
                newProject.color = defaultColor;
                newProject.description = ' ';
                newProject.version = 1;

            save(newProject);
        } else {
            this.setState({
                ...this.state,
                disabled: false,
                errorDescription: ''/*this.validate(this.state.description)*/,
                errorTitle: this.validate(this.state.title),
            })
        }
    }

    handleBlurDescription() {
        const error = !this.state.description ? this.props.t('projectSettings.emptyFieldError') : '';
        this.setState({ ...this.state, errorDescription: error });
    }

    handleBlurTitle() {
        const error = !this.state.title ? this.props.t('projectSettings.emptyFieldError') : '';
        this.setState({ ...this.state, errorTitle: error });
    }

    validate(description: string) {
        if (!description) {
            return this.props.t('projectSettings.emptyFieldError');
        }
        else {
            return '';
        }
    }
    renderColorsPanel() {
        const { color } = this.state;
        return (<>
            <div className="colors-panel-row">
                <div className={cn("colors-panel-row-color-element", "white")}
                    onClick={() => this.setState({ color: white }) as any}
                >
                    {color === white && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "red")}
                    onClick={() => this.setState({ color: red }) as any}>
                    {color === red && <CoverColorSelectionIcon />}

                </div>
                <div className={cn("colors-panel-row-color-element", "orange")}
                    onClick={() => this.setState({ color: orange }) as any}
                >
                    {color === orange && <CoverColorSelectionIcon />}
                </div>

                <div className={cn("colors-panel-row-color-element", "gold")}
                    onClick={() => this.setState({ color: gold }) as any}
                >
                    {color === gold && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "green")}
                    onClick={() => this.setState({ color: green }) as any}
                >
                    {color === green && <CoverColorSelectionIcon />}
                </div>
            </div>

            <div className="colors-panel-row">
                <div className={cn("colors-panel-row-color-element", "light-blue")}
                    onClick={() => this.setState({ color: light_blue }) as any}
                >
                    {color === light_blue && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "blue")}
                    onClick={() => this.setState({ color: blue }) as any}
                >
                    {color === blue && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "purple")}
                    onClick={() => this.setState({ color: purple }) as any}
                >
                    {color === purple && <CoverColorSelectionIcon />}
                </div>

                <div className={cn("colors-panel-row-color-element", "pink")}
                    onClick={() => this.setState({ color: pink }) as any}
                >
                    {color === pink && <CoverColorSelectionIcon />}
                </div>
                <div className={cn("colors-panel-row-color-element", "grey")}
                    onClick={() => this.setState({ color: grey }) as any}
                >
                    {color === grey && <CoverColorSelectionIcon />}
                </div>
            </div>
        </>

        );
    }
    render() {
        const { t, isOpen, save_inProgress } = this.props;
        const { description, color, title, disabled } = this.state;
        const { errorTitle, errorDescription } = this.state;

        if (isOpen) {
            return (
                <ModalForm title={''} closeForm={this.handleClose} inProgress={save_inProgress} classes="settings" keysHandlers={this.handleSubmit}>
                    <div className="popup-content">
                        <div className="popup-content-left-panel" >
                            <div className="popup-content-left-panel--info" style={{ background: !!color ? color : white }}>
                                <div className="popup-content-left-panel--info__name">
                                    {title}
                                </div>
                            </div>
                            <div className="popup-content-left-panel--color-picker">
                                {this.renderColorsPanel()}
                            </div>

                        </div>
                        <div className={cn("popup-content-right-panel", save_inProgress ? "disabled" : "")}>
                            <div className="inputs-block">
                                <h1 className="inputs-block-heading">
                                    {t('projectSettings.header')}
                                </h1>
                                <InputContainerDark title={t('projectSettings.title')} value={title}
                                    error={errorTitle} onBlur={this.handleBlurTitle}
                                    onChange={this.handleChangeTitle}/>

                                <div className="members">

                                    {/* <div className="members--icons">
                                    </div>
                                    <div className="members--add-button">
                                        <ProjectInviteIcon />
                                    </div>
                                    <div className="members--open-menu-button">Members</div> */}

                                </div>

                                {/* <div className="share-link">&nbsp;</div> */}

                                <div className="button-container">
                                    <div className="button-container-save">
                                        <SubmitButton title={t('projectSettings.ok')} onSubmit={this.handleSubmit} disabled={save_inProgress} inProgress={save_inProgress}/>
                                    </div>

                                    <DefaultButton className="button-container-cancel" title={t('projectSettings.cancel')} disabled={save_inProgress} onClick={this.handleClose} />
                                </div>

                            </div>
                            {this.renderError()}

                        </div>


                    </div>
                </ModalForm>);
        }

        return null;
    }

    renderError = () => {
        const { save_error } = this.props
        if (save_error) {
            return <div className="form-error">
                <span>{save_error.message}</span>
            </div>
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectSettingsModalCreate));
