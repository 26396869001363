import * as React from "react";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import ModalForm from "../../Landing/ModalForm/index";
import {Action} from "redux";
import * as actions from "../../../redux/actions/index";
import InputContainer from "../../InputContainer/index";
import './style.scss'
import door from '../../../images/door@422.png'
import facebook from '../../../images/sign-facebook.png'
import vk from '../../images/sign-vk.png'
import google from '../../../images/sign-google.png'
import cn from "classnames";
import {IState} from "../../../redux/reducers";
import SubmitButton from "../../Buttons/SubmitButton";
import {Spinner} from "../../Spinner";

interface IDispatchProps{
    closeSignInForm:() => Action
    signIn:(username: string, password: string) => Action
    opensSignUpForm:() => Action;
    openForgetPasswordForm:() => Action;
    signInFacebookUser:(token: string)=> Action;
    signInGoogleUser:(token: string)=> Action;
    clearSignIn:() => Action
    clearSignInFacebookUser:()=> Action;
    clearSignInGoogleUser:()=> Action;
}

interface IStateToProps{
    inProgress: boolean;
    inProgressFacebook: boolean;
    inProgressGoogle: boolean;
    isAfterConfirmEmail: boolean;
    error: string | null;
    errorFacebook: string | null;
    errorGoogle: string | null;
}

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        inProgress: state.auth.inProgress,
        inProgressFacebook: state.externalAuth.facebook.signIn.inProgress,
        inProgressGoogle: state.externalAuth.google.signIn.inProgress,
        isAfterConfirmEmail: state.modalForm.signInForm.isAfterConfirmEmail,
        error: state.auth.error,
        errorFacebook: state.externalAuth.facebook.signIn.error,
        errorGoogle: state.externalAuth.google.signIn.error,
    }
};

const mapDispatchToProps: IDispatchProps = {
    closeSignInForm: actions.actions.modalForm.signIn.close,
    signIn: actions.actions.auth.login.init,
    opensSignUpForm: actions.actions.modalForm.signUp.open,
    openForgetPasswordForm: actions.actions.modalForm.forgetPasswordForm.open,
    signInFacebookUser: actions.actions.externalAuth.facebook.signIn.init,
    signInGoogleUser: actions.actions.externalAuth.google.signIn.init,
    clearSignIn: actions.actions.auth.login.clear,
    clearSignInFacebookUser: actions.actions.externalAuth.facebook.signIn.clear,
    clearSignInGoogleUser: actions.actions.externalAuth.google.signIn.clear
};

interface IStateProps {
    email: string;
    password: string;
    error: string | null;
}

export class SignInForm extends React.Component<WithTranslation & IDispatchProps & IStateToProps, IStateProps> {
    constructor(props:any){
        super(props);
        this.state = {email: "", password: "", error: null}
        this.handleCloseForm = this.handleCloseForm.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleAuth = this.handleAuth.bind(this);
        this.handleOpenSignUpForm = this.handleOpenSignUpForm.bind(this);
        this.handleOpenForgetPasswordForm = this.handleOpenForgetPasswordForm.bind(this);
        this.handleFaceBookClick = this.handleFaceBookClick.bind(this);
        this.handleGoogleClick = this.handleGoogleClick.bind(this);
    }

    componentDidMount(){
        this.facebookInit(window);
    }

    componentDidUpdate(prevProps: Readonly<WithTranslation & IDispatchProps & IStateToProps>){
        if (this.props.error && prevProps.error !== this.props.error){
            this.setState({...this.state, error: this.props.error})
        }

        if (this.props.errorFacebook && prevProps.errorFacebook !== this.props.errorFacebook) {
            this.setState({...this.state, error: this.props.errorFacebook})
        }

        if (this.props.errorGoogle && prevProps.errorGoogle !== this.props.errorGoogle) {
            this.setState({...this.state, error: this.props.errorGoogle})
        }
    }

    handleCloseForm(){
        this.props.closeSignInForm();
        this.props.clearSignIn();
        this.props.clearSignInFacebookUser();
        this.props.clearSignInGoogleUser();
    }

    handleChangeEmail(e: any){
        this.setState({...this.state, email: e})
    }

    handleChangePassword(e: any){
        this.setState({...this.state, password: e})
    }

    handleAuth(){
        const {email, password} = this.state
        if (email && password) {
            this.props.signIn(email, password);
        }
    }

    isButtonActive(): boolean{
        const {email, password} = this.state
        const {inProgress} = this.props
        if (email && email !== "" && password && password !== "" && !inProgress){
            return true
        }

        return false
    }

    isButtonSocialActive(): boolean{
        const {inProgressGoogle, inProgressFacebook} = this.props

        return inProgressGoogle || inProgressFacebook
    }

    isInProgressForm(): boolean {
        const {inProgress, inProgressFacebook, inProgressGoogle} = this.props;
        return inProgress || inProgressFacebook || inProgressGoogle;
    }

    handleOpenSignUpForm(){
        this.props.closeSignInForm();
        this.props.opensSignUpForm();
    }

    handleOpenForgetPasswordForm(){
        this.props.closeSignInForm();
        this.props.openForgetPasswordForm();
    }

    handleFaceBookClick(){
        //  FB.login(() => {
        //      FB.getLoginStatus((response) => {
        //          if (response.status === 'connected') {
        //              this.props.signInFacebookUser(response.authResponse.accessToken)
        //          }
        //      })},{scope: 'email'});
    }

    handleGoogleClick(){
        // gapi.load('auth2', () => {
        //     const auth2: any = this.init(gapi)

        //     auth2.signIn()
        //         .then(() => {
        //             this.props.signInGoogleUser(auth2.currentUser.get().Zi.access_token);
        //         });
        // });
    }

    init(gapis: any): any{
        return gapis.auth2.init({
            'apiKey': '1_A5dsa2BPqZCwf3qnmN5j9J',
            'client_id': '291201890790-kf0hnml86ntlgrmacfem38v9fl8glhal.apps.googleusercontent.com'
        });
    }

    facebookInit(w: any){
        w.fbAsyncInit();

    }

    renderButton(){
        const {t, inProgress} = this.props
            return <div className="button-block-sign-in">
                <SubmitButton title={t('signIn.ok')} onSubmit={this.handleAuth} disabled={!this.isButtonActive()} inProgress={inProgress}/>
            </div>
    }

    renderSpinner() {
        let spinnerClass: string  ="";
        const {inProgressFacebook, inProgressGoogle} = this.props
        if (inProgressGoogle || inProgressFacebook) {
            if (inProgressFacebook) {
                spinnerClass = "signFacebookSpinner"
            } else if (inProgressGoogle){
                spinnerClass = "signGoogleSpinner"
            }
            return <Spinner className={spinnerClass}/>;
        }
    }

    renderOffer(){
        const {t, isAfterConfirmEmail} = this.props;
        return isAfterConfirmEmail ?
        <span className="offer">{t('signIn.tos.offerConfirm')}</span>
        : <span className="offer">{t('signIn.tos.offer')} <a href="#" onClick={this.handleOpenSignUpForm}> {t('signIn.tos.signUp')}</a></span>
    }

    render(){
        const {t} = this.props;
        const {error} = this.state;
        return <ModalForm title="Sign in" closeForm={this.handleCloseForm} classes="sign-in" error={error} keysHandlers={this.handleAuth}>
            <div className="sign-in">
                <div className="door">
                    <img src={door}/>
                </div>
                <div className={cn("information", this.isInProgressForm() ? "disabled" : "")}>
                    <h1>{t('signIn.title')}</h1>
                    {this.renderOffer()}
                    <div className="signIn-input-block">
                        <InputContainer title={t('signIn.email')} hidden={false} onChange={this.handleChangeEmail}/>
                        <InputContainer title={t('signIn.password')} type="password" hidden={false} onChange={this.handleChangePassword} />
                    </div>

                    <div className="button-block">
                        {this.renderButton()}
                        <button title="sign in" className="button-block-social" disabled={this.isButtonSocialActive()}>
                            {this.renderSpinner()}
                            <span className="title">{t('signIn.end-to-end')}</span>
                            <div className="images">
                                <img src={facebook} className="signFacebook" onClick={this.handleFaceBookClick}/>
                                {/* <img src={vk} className="signVK" onClick={() => {}}/> */}
                                <img src={google} className="signGoogle" onClick={this.handleGoogleClick}/>
                            </div>
                        </button>
                    </div>
                    <div className="recover-password" >
                    <a href="#" onClick={this.handleOpenForgetPasswordForm}>{t('signIn.remind')}</a>
                    </div>
                </div>
            </div>

        </ModalForm>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignInForm));
