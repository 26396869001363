import React from 'react';
import './style.scss';

type TooltipPosition = "top" | "bottom" | "right" | "left";

interface ITooltipProps {
    message: string | React.ReactNode;
    position: TooltipPosition;
    displayTooltip?: boolean;
}

interface ITooltipState {
    displayTooltip: boolean;
}


export default class Tooltip extends React.Component<ITooltipProps, ITooltipState> {
    constructor(props:any) {
      super(props);
  
      this.state = {
        displayTooltip: false
      };
      this.hideTooltip = this.hideTooltip.bind(this);
      this.showTooltip = this.showTooltip.bind(this);
    }
    
    hideTooltip() {
      this.setState({displayTooltip: false});
      
    }
    showTooltip () {
      this.setState({displayTooltip: true});
    }
  
    render() {
      const { message, position, displayTooltip } = this.props;

      let showTooltip = false;

      if(displayTooltip){
        showTooltip = displayTooltip;
      }
      else{
        showTooltip = this.state.displayTooltip;
      }

      return (<span className='tooltip' onMouseLeave={this.hideTooltip} onClick={this.showTooltip}>
                {showTooltip &&
                <div className={`tooltip-bubble tooltip-${position}`}>
                    <div className='tooltip-message'>{message}</div>
                </div>
                }
                <span className='tooltip-trigger' onMouseOver={this.showTooltip}>
                    {this.props.children}
                </span>
             </span>);
    }
  }
  