import React from 'react';
import { ErrorInputIcon } from '../../icons';
import cn from 'classnames';
import Tooltip from '../Tooltip';
import './style.scss';

type InputType = "text" | "password";

interface IInputContainerProps {
    title: string;
    error?: string | React.ReactNode;
    onChange: (e: any) => void;
    type?: InputType;
    value?: string;
    onBlur?: (e: any) => void;
    classes?: string;
    hidden?: boolean;
    name?: string;
}

interface IInputContainerState {
    showLabelTooltip: boolean;
}

export default class InputContainerDark extends React.Component<IInputContainerProps, IInputContainerState> {

    constructor(props: any) {
        super(props);

        this.state = { showLabelTooltip: false };
        this.onChange = this.onChange.bind(this);
    }

    onChange(e: any) {
        const { onChange } = this.props;
        onChange(e);
    }

    render() {
        const { title, error, onBlur, type, classes, hidden, value } = this.props;
        
        return (<div className={cn("input-container__dark", classes)} hidden={hidden}>
            <input autoFocus={true} name={this.props.name} className={cn("text-input__dark", classes)} type={type ? type : "text"} onChange={this.onChange} value={value} autoComplete="off" required onBlur={onBlur} />

            <label className={cn("label__dark", error ? "label-error__dark" : "")}>
                {title}
            </label>

            <div className="tooltip-container__dark">

                {error &&
                    <Tooltip message={error} position="top" displayTooltip={true}>
                        <ErrorInputIcon />
                    </Tooltip>}
            </div>
        </div>);
    }
}
