import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS, actions } from '../../actions/appSettings';
import { IErrorResponse } from '../../../api';
import { IAppSettings, getAppSettings } from '../../../api/appSettings';

export function* sagaHandler(action: Action) {
    try {
        const result: IAppSettings = yield call(getAppSettings);
        yield put(actions.view.fetch.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.view.fetch.fail(c))
    }
}

export function* viewSaga() {
    yield takeLatest(ACTIONS.VIEW.FETCH.INIT, sagaHandler);
}
