import {IPoint, IBlock, ElementBlockType, IContent} from "../../models";
import BaseBlock from "../blocks/base";
import SectionBlock from "../blocks/section";
import { createDefaultElementBlock } from "./createElementBlock";

export const DEFAULT_CONTENT_VALUE_SECTION = 'Some section'

export function createDefaultSectionBlock(position?:IPoint, parentId?: string, title:string=DEFAULT_CONTENT_VALUE_SECTION){
    const newSection = new SectionBlock(title, undefined, position, parentId);
    const newElement = createDefaultElementBlock(ElementBlockType.Text, undefined, newSection.id, "Some text");
    BaseBlock.addChild(newSection, newElement.id,0);
    BaseBlock.addBlock(newSection);
   
    return [newSection as IBlock, newElement];
}

export function createSectionBlock(title:string, id:string, content?: IContent, point?:IPoint, parentId?:string, children: string[] = []){
    if (parentId == id) {
        parentId = undefined;
    }

    const newSection = new SectionBlock(title, id, point, parentId, children)
    if(content) newSection.content = content;
    return newSection;
}