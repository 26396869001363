import React from "react";
import './style.scss'
import {ScrollIcon, ScrollButtonIcon} from "../../../icons";

interface IProps {
    onclick(): void
}

export default class ScrollButton extends React.Component<IProps> {
    constructor(props: any) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        this.props.onclick();
    }

    render() {
        return (<div className="scroll">
            <span className="scroll-picture"><ScrollIcon/></span>
            <span className="scroll-point" onClick={this.handleClick}><ScrollButtonIcon/>
            </span>
        </div>)
    }
}