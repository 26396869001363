import * as React from "react";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import ModalForm from "../../Landing/ModalForm/index";
import {Action} from "redux";
import * as actions from "../../../redux/actions/index";
import InputContainer from "../../InputContainer/index";
import './style.scss'
import door from '../../../images/door@353.png'
import cn from "classnames";
import * as H from "history";
import {match, withRouter} from "react-router";
import {PasswordService} from "../../Services/PasswordService";
import {IState} from "../../../redux/reducers";
import SubmitButton from "../../Buttons/SubmitButton";

interface IDispatchProps{
    closeResetPasswordForm:() => Action
    reset:(token: string, password: string) => Action
    clear: () => Action
}

interface IStateToProps{
    inProgress: boolean;
    error: string | null
}

interface IRouterProps {
    history: H.History;
    location: H.Location;
    match?: match<{ token: string }>;
}

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        inProgress: state.userSecurity.resetPassword.inProgress,
        error: state.userSecurity.resetPassword.error
    }
};

const mapDispatchToProps: IDispatchProps = {
    closeResetPasswordForm: actions.actions.modalForm.resetPasswordForm.close,
    reset: actions.actions.userSecurity.resetPassword.init,
    clear: actions.actions.userSecurity.resetPassword.clear
};

interface IStateProps {
    password: string;
    passwordConfirm: string;
    errorPassword: string | null;
    token: string;
}

export class ResetPasswordForm extends React.Component<WithTranslation & IDispatchProps & IStateToProps & IRouterProps, IStateProps> {
    public passwordService: PasswordService;
    constructor(props:any){
        super(props);
        this.state = {password: "", passwordConfirm: "", token: "", errorPassword: null}
        this.handleCloseForm = this.handleCloseForm.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordConfirm = this.handleChangePasswordConfirm.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.passwordService = new PasswordService();
    }

    componentDidMount(){
        if (this.props.match){
            this.setState({...this.state, token: this.props.match.params.token});
        }
    }

    handleCloseForm(){
        this.props.closeResetPasswordForm();
        this.props.clear();
        this.props.history.push('/');
    }

    handleChangePassword(e: any){
        this.setState({...this.state, password: e})
        setTimeout(() => {
            const error = this.passwordService.validatePassword(this.state.password);
            this.setState({...this.state, errorPassword: error});
        }, 1000)
    }

    handleChangePasswordConfirm(e: any){
        this.setState({...this.state, passwordConfirm: e})
    }

    handleReset(){
        const {password, passwordConfirm} = this.state
        
        const token = this.state.token;
        if (password != "" && passwordConfirm != "" && password == passwordConfirm) {
            this.props.reset(token, password);
        }
    }

    isButtonActive(): boolean{
        const {password, passwordConfirm, errorPassword} = this.state
        const {inProgress} = this.props
        if (password != "" && passwordConfirm != "" && password == passwordConfirm && !inProgress  && errorPassword === null){
            return true
        }

        return false
    }

    render(){
        const {t, inProgress, error} = this.props;
        const {errorPassword} = this.state;
        return <ModalForm title={'resetPassword.title'} closeForm={this.handleCloseForm} classes="resetPassword" error={error}  keysHandlers={this.handleReset}>
            <div className="sign-in">
                <div className="door">
                    <img src={door}/>
                </div>
                <div className={cn("information", inProgress ? "disabled" : "")}>
                    <h1>{t('resetPassword.title')}</h1>
                    <span className="offer">{t('resetPassword.offer')}</span>
                    <div className="signIn-input-block">
                        <InputContainer title={t('resetPassword.password')} type="password"  hidden={false} onChange={this.handleChangePassword}  error={errorPassword}/>
                        <InputContainer title={t('resetPassword.passwordConfirm')} type="password" hidden={false} onChange={this.handleChangePasswordConfirm} />
                    </div>

                    <div className="button-block">
                        <div className="button-block-forget">
                            <SubmitButton title={t('resetPassword.ok')} onSubmit={this.handleReset} disabled={!this.isButtonActive()} inProgress={inProgress}/>
                        </div>
                    </div>
                </div>
            </div>

        </ModalForm>
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResetPasswordForm)) as any);
