import { IPoint } from "./point";
import { IContent } from "./content";

export interface ITargetParentPosition {
    indexOfChildren: number;
    block:IBlock;
}

export type TargetPosition = ITargetParentPosition | IPoint | undefined;

export enum BlockType{
   SelectTool = "select-tool",
   HandTool = "hand-tool",
   Page = "page",
   TemplateComposite = "template_composite",
   TemplateFixed= "template_fixed",
   Fixed = "fixed",
   Composite = "composite",
   Section = "section",
   Element = "element",
   Placeholder = "placeholder"
};

export enum ElementBlockType {
    Text = "Text",
    List = "List",
    Link = "Link",
    Image = "Image",
    Gallery = "Gallery",
    Table = "Table",
    Form = "Form",
    External = "External"
}

export enum MoveDirection{
    Top = "top",
    Bottom = "bottom",
    Baseline = "baseline",
    Undef = "undef"
}

export interface IBlock {
    id: string;
    title: string;
    type: BlockType;
    subType?: ElementBlockType;
    parentId?: string;
    coord?: IPoint;
    children: string[];

    templateId?: string;

    isSelected: boolean;
    isTargetBlock: boolean;
    isHover: boolean;

    content?: IContent;
}