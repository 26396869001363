import { combineReducers } from 'redux';
import * as forgetPassword from '../userSecurity/forgetPassword';
import * as resetPassword from '../userSecurity/resetPassword';


export interface IState {
    forgetPassword: forgetPassword.IState,
    resetPassword: resetPassword.IState
}

export const initial: IState = {
    forgetPassword: forgetPassword.initial,
    resetPassword: resetPassword.initial
}

export const reducer = combineReducers({
    forgetPassword: forgetPassword.reducer,
    resetPassword: resetPassword.reducer
});
