import {IPoint, IBlock, IContent} from "../../models";
import BaseBlock from "../blocks/base";
import CompositeBlock from "../blocks/composite";
import { createElementBlockByTemplate } from "./createElementBlock";

export function createDefaultCompositeBlock(templateId?:string, coord?:IPoint){

    if(!templateId) return;

    const template = BaseBlock.getBlock(templateId);

    if(!template) return;

    const newComposite = new CompositeBlock(template.title, template.id, [], coord);
    let children:IBlock[] = []

    for (const id of template.children) {
        const newEl = createElementBlockByTemplate(id, newComposite.id);
        if (newEl) {
            children = [...children, newEl];
            BaseBlock.addBlock(newEl);
        }
    }

    newComposite.children = children.map(b=>b.id);   
    BaseBlock.addBlock(newComposite);

    return [newComposite, ...children];
}

export function createCompositeBlock(title:string, id:string, content?: IContent, point?:IPoint, parentId?:string, children: string[] = [], templateId?:string){
    if (parentId == id) {
        parentId = undefined;
    }

    const newComposite = new CompositeBlock(title, templateId, children, point, id, parentId)
    newComposite.content = content;
    return newComposite;
}