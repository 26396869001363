import React from 'react';
import { SectionSliderLeft, SectionSliderRight } from '../../../../../icons';
import './style.scss';

export default class SectionSlider extends React.Component {

    render(){

        return (<div className='section-slider '>
                    <div className='section-slider-item-right'>
                        <SectionSliderRight/>
                    </div>
                    <div className='section-slider-item-left'>
                        <SectionSliderLeft/>
                    </div>
                </div>)
    }
}