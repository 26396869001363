import React from 'react';
import {BlockType, IBlock} from "../../../models";
import BlockPresenterDetailLevel from "..";
import BaseBlock from '../../../core/blocks/base';

/**
  * Generate block as React Component
  * @param props
  * @returns Generated React Component
  */
export function blockPresenter(b?: IBlock, parentId?: string, childrenIndex:number = -1) {
   
    // Template
    if(b && b.type === BlockType.TemplateComposite){
      return renderBlockPresenter(b, childrenIndex, b.id);
    }

    // Fixed
    if(b && parentId && b.type === BlockType.Fixed && b.templateId){
      const template = BaseBlock.getBlock(b.templateId);
      return renderBlockPresenter(b, childrenIndex, b.templateId, template && template.children);
    }

    // Обработка стандартных случаев: блок на холсте, или внутри другого блока
    if (b && b.coord) {
        return renderBlockPresenter(b, childrenIndex, b.id);
    }
    
    if (b && parentId) {
        return renderBlockPresenter(b, childrenIndex, parentId);
    }    
}

function renderBlockPresenter(b: IBlock, childrenIndex:number, parentId?: string, children?: string[]){  
  return <BlockPresenterDetailLevel key={b.id} block={b} childrenIndex={childrenIndex}>
            {b.type !== BlockType.Fixed && b.children.map((id,i) => b.type !== BlockType.Section ?
                                                                                blockPresenter(BaseBlock.getBlock(id), parentId, -1)
                                                                                : blockPresenter(BaseBlock.getBlock(id), parentId, i))}
            {b.type === BlockType.Fixed && children && children.map((id,i) => blockPresenter(BaseBlock.getBlock(id), parentId, i))}
         </BlockPresenterDetailLevel>
}