import * as authSaga from '../redux/sagas/auth';
import * as userSaga from './sagas/user';
import * as projectSaga from './sagas/projects';
import * as tariffsSaga from './sagas/tariffs';
import * as invoicesSaga from './sagas/invoices';
import * as modalSaga from './sagas/modalForm';
import * as appSettings from './sagas/appSettings';
import * as trialSaga from './sagas/trial';
import * as projectSettingsSaga from './sagas/projectSettings';
import * as presentationModeSaga from './sagas/presentationMode';
import * as feedbackSaga from './sagas/feedback';
import * as externalAuthSaga from './sagas/external-auth';
import * as healthCheckSaga from './sagas/healthCheck';
import * as userSecuritySaga from './sagas/userSecurity';
import * as userSettingsSaga from './sagas/userSettings';


import { fork, all } from 'redux-saga/effects'

export function* saga() {
    yield all([
        fork(authSaga.authSaga),
        fork(userSaga.userSaga),
        fork(projectSaga.projectSaga),
        fork(tariffsSaga.tariffsSaga),
        fork(invoicesSaga.invoicesSaga),
        fork(appSettings.appSettingsSaga),
        fork(modalSaga.projectSettingsShowSaga),
        fork(projectSettingsSaga.projectSettingsSaga),
        fork(presentationModeSaga.presentationModeSaga),
        fork(trialSaga.trialSaga),
        fork(feedbackSaga.feedbackSaga),
        fork(externalAuthSaga.externalAuthSaga),
        fork(healthCheckSaga.healthCheckSaga),
        fork(userSecuritySaga.userSecuritySaga),
        fork(userSettingsSaga.userSettingsSaga)
    ]);
}
