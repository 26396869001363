import React from "react";
import './style.scss';
import './style1.scss';
import cn from "classnames";
import {HeaderNameMenuDropboxIcon} from "../../../icons";
import {IUserRead} from "../../../api/user";
import defaultImg from '../../../images/avatar.png'
import {IState} from "../../../redux/reducers";
import {actions} from "../../../redux/actions";
import {connect} from "react-redux";
import DropDownMenu from "../HeaderAuth/DropDownMenu";

interface IProps {
    className?: string
}
interface IStateToProps {
    user: IUserRead | null,
    isShow: boolean
}

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        user: state.currentUser.item,
        isShow: state.modalForm.dropDownMenu.isShow
    };
}

interface IDispatchProps {
    showDropDownMenu: () => void;
    hideDropDownMenu: () => void;
    hideMenu: () => void;
}

const mapDispatchToProps: IDispatchProps = {
    showDropDownMenu: actions.modalForm.dropDownMenu.open,
    hideMenu: actions.modalForm.dropDownMenu.close,
    hideDropDownMenu: actions.modalForm.dropDownMenu.close
};

export class ProfileButton extends React.Component<IProps & IStateToProps & IDispatchProps> {
    constructor(props:any){
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleHideMenu = this.handleHideMenu.bind(this);
    }

    handleClick(){
        if (this.props.isShow){
            this.props.hideDropDownMenu();
        }else{
            this.props.showDropDownMenu();
        }
    }

    handleHideMenu() {
        this.props.hideMenu();
    }

    render(){
        const {user, className, isShow} = this.props
        if (user) {
            return (<button className={cn(className)}>
                <div className="button-container" onMouseLeave={this.handleHideMenu}>
                    <div className = "button-container-top" onClick = {this.handleClick}>
                        <HeaderNameMenuDropboxIcon className = {isShow ? "rotate" : ""}/>
                        <span>{user.name === "" ? user.email : user.name}</span>
                        <img src={user == null || user.logo == null ? defaultImg: `data:image/jpeg;base64,${user.logo}`} />
                    </div>
                    <DropDownMenu/>
                </div>
            </button>)
        }

        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((ProfileButton));