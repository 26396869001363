import React from 'react';
import cn from 'classnames';
import BlockContent from './BlockContent';
import { BlockType, IBlock } from '../../models';
import { getIconByType } from '../helpers/getIconByType';
import getContentClassName from './BlockContent/helpers/getContentClassName';

interface IBlockContainer {
    block: IBlock;
    isPresentationMode: boolean;
    selectBlock: () => void;
    childrenIndex: number;
}

export default function BlockContainer(props: IBlockContainer) {
    const { block } = props;
    const contentClasses = cn('block-dl-container', block.isSelected ? 'default-select-outline' : '');

    const contentClassName = getContentClassName(block);
    const isTitle = contentClassName.endsWith('title');

    return <div className={contentClasses}>
        <div className={isTitle ? 'left-interval-border-title' : 'left-interval-border'}>
            {block.type !== BlockType.Section && getIconByType(block)}
        </div>

        <BlockContent {...props}/>

    </div>
}