import React from 'react';
import { IBlock, ElementBlockType, BlockType } from "../../models";
import { ButtonIcon, PopupIcon, ExternalLinkIcon, FormIcon, GalleryIcon, ImageIcon, InteractIcon, LinkIcon, PageIconLeftAlign, CompositeIcon, TitleIcon, TextIcon, ListIcon, TabAjaxIcon, TableIcon, FixedIcon, SectionIcon, SelectToolIcon } from "../../icons";

export function getIconByType(block: IBlock) {

    switch (block.subType) {
        case ElementBlockType.External:
            return <ExternalLinkIcon />;
        case ElementBlockType.Form:
            return <FormIcon />;
        case ElementBlockType.Gallery:
            return <GalleryIcon />;
        case ElementBlockType.Image:
            return <ImageIcon />;
        case ElementBlockType.Link:
            return <LinkIcon />;

        case ElementBlockType.List:
            return <ListIcon />;
        case ElementBlockType.Table:
            return <TableIcon />;
        case ElementBlockType.Text:
            return <TextIcon />;

        default: {
            switch (block.type) {
                case BlockType.Element:
                    return '';
                case BlockType.HandTool:
                    return '';
                case BlockType.Page:
                    return <PageIconLeftAlign />;
                case BlockType.Composite:
                case BlockType.TemplateComposite:
                    return <CompositeIcon />;
                case BlockType.Fixed:
                case BlockType.TemplateFixed:
                    return <FixedIcon />;
                case BlockType.Section:
                    return <SectionIcon />;
                case BlockType.SelectTool:
                    return <SelectToolIcon />;
                default:
                    return '';
            }
        }

    }

}
