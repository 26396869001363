import { IAuthResult, IErrorResponse } from "../../api/index"
import { Action } from "redux";

export const ACTIONS = {
    LOGIN_INIT: 'LOGIN_INIT',
    LOGIN_DONE: 'LOGIN_DONE',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGIN_CLEAR: 'LOGIN_CLEAR',
    LOGOUT: 'LOGOUT'
};

export interface ILoginInitAction extends Action {
    username: string;
    password: string;
}

export interface ILoginDoneAction extends Action {
    result: IAuthResult;
}

export const actions = {
    login: {
        init: (username: string, password: string) => ({type: ACTIONS.LOGIN_INIT, username, password}),
        done: (result: IAuthResult) => ({type:ACTIONS.LOGIN_DONE, result}),
        clear: () => ({type:ACTIONS.LOGIN_CLEAR}),
        fail: (error: IErrorResponse) => ({type:ACTIONS.LOGIN_FAIL, error })
    },
    logout: () => ({type: ACTIONS.LOGOUT})
}