import React from "react";
import './style.scss'
import cn from "classnames";

interface IProps {
    title: string
    className?: string
    onClick(): void
}

export default class HeaderButton extends React.Component<IProps> {
    constructor(props:any){
        super(props);
    }

    render(){
        const {title, className} = this.props
        return (<button className={cn(className, "header-button")} onClick={this.props.onClick}>{title}</button>)
    }
}