
export interface IContent {
    style?: ContentStyle;
    type?: ContentType;
    comps?: ContentComponentType[];
    lines?: ContentTextDisplayedRows;
    items: IContentItem[][];
}

export interface IContentItem {
    isDefault: boolean;
    value: string;
}

export enum ContentType {
    Normal = 'normal',
    Slider = 'slider',
    Button = 'button',
    Link = 'link',
    DashedLink ='dashed-link'
}

export enum ContentStyle {
    Small = 'small',
    Normal = 'normal',
    Emphasis = 'emphasis',
    Spacing = 'spacing',
    Title = 'title',
    Subtitle = 'subtitle',
    Ordered = 'ordered',
    Unordered = 'unordered',
    Composite = 'composite',
    Grey = 'grey',
    Colored = 'colored',
    LegendOn = 'on',
    LegendOff = 'off',
}

export enum ContentComponentType {
    Image = 'image',
    Title = 'title',
    Text = 'text',
    Link = 'link'
}

export type ContentTextDisplayedRows = 'All' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;