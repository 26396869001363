import { IErrorResponse } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'PRESENTATION_MODE';

export const ACTIONS = {
    SHARE: actionsSet(prefix, 'SHARE')
};

export interface ISaveInitAction extends Action {
    id: string;
}

export interface ISaveDoneAction extends Action {
    item: string;
}

export const actions = {
    init: (id: string) => ({type: ACTIONS.SHARE.INIT, id}) as ISaveInitAction,
    done: (item: string) => ({type: ACTIONS.SHARE.DONE, item}) as ISaveDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.SHARE.FAIL, error}) as IFailAction
}