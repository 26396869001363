import {ACTIONS} from '../../actions/healthCheck/healthCheck';
import { Action } from 'redux';
import {IErrorResponse} from '../../../api/index';

export interface IState {
    isPing: boolean;
    inProgress: boolean;
    error: IErrorResponse | null;
}

export const initial: IState = {
    isPing: true,
    inProgress: false,
    error: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.HEALTH_CHECK.INIT:
            return state;

        case ACTIONS.HEALTH_CHECK.DONE:
        {
            return {
                isPing: true,
                inProgress: false,
                error: null
            };
        }

        case ACTIONS.HEALTH_CHECK.FAIL:
        {
            return {
                isPing: false,
                inProgress: false,
                error: null
            };
        }

        default:
            return state;
    }
}
