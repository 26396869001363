import { IErrorResponse, IUserWrite } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'PROJECTS_DELETE';

export const ACTIONS = {
    DELETE: actionsSet(prefix, 'SAVE')
};

export interface ISaveInitAction extends Action {
    id: string;
}

export interface ISaveDoneAction extends Action {
}

export const actions = {
    init: (id: string) => ({ type: ACTIONS.DELETE.INIT, id }) as ISaveInitAction,
    done: () => ({ type: ACTIONS.DELETE.DONE }) as ISaveDoneAction,
    fail: (error: IErrorResponse) => ({ type: ACTIONS.DELETE.FAIL, error }) as IFailAction,

}
