import { BlockType, IPoint, ElementBlockType, IContent, ContentStyle, ContentType, ContentComponentType, ContentTextDisplayedRows } from "../../models";
import BaseBlock from "./base";
import selectedBlocks from "../../models/selection";

export function getDefaultContentItems(){
    return [
        {isDefault: true, value: 'Description of image'},
        {isDefault: true, value: 'Title of item'},
        {isDefault: true, value: 'Some text'},
        {isDefault: true, value: 'Link to'}
    ];
}

export function getDefaultContent(type: ElementBlockType) {
    switch (type) {
        case ElementBlockType.Link:
            return {
                type: ContentType.Link,
                style: ContentStyle.Colored,
                items: [getDefaultContentItems()]
            } as IContent;
        case ElementBlockType.Text:
            return {
                style: ContentStyle.Normal,
                lines: 'All',
                items: [getDefaultContentItems()]
            } as IContent;
        case ElementBlockType.Image:
            return {
                items: [getDefaultContentItems()]
            } as IContent;
        case ElementBlockType.List:
            return {
                style: ContentStyle.Unordered,
                comps: [ContentComponentType.Image, ContentComponentType.Title, ContentComponentType.Text, ContentComponentType.Link],
                items: [
                    getDefaultContentItems(), 
                    getDefaultContentItems()
                ]
            } as IContent;
        case ElementBlockType.Gallery:
            return {
                style: ContentStyle.LegendOn,
                items: [
                    getDefaultContentItems(),
                    getDefaultContentItems()
                ]
            } as IContent;
    }
}

export default class ElementBlock extends BaseBlock {    

    constructor(title:string, elementType: ElementBlockType, id?:string, coord?:IPoint, parentId?:string, children: string[] = [], templateId?:string){
        super(title, BlockType.Element, children, id,  elementType, coord, parentId, templateId, getDefaultContent(elementType));
    }    
}

export function changeSelectedElementSubType(elementType: ElementBlockType){
    const size = selectedBlocks.getSelectedBlocksSize();
    if(size != 1) return;

    const b = BaseBlock.getBlock(selectedBlocks.getSelectedBlocksId()[0]);
    if(!b || !b.subType) return;

    b.subType = elementType;
    BaseBlock.updateBlock(b);        
}