import React from 'react';
import './popupMenu.scss';
import cn from 'classnames';

interface IPopupMenuProps {
  position: string;
  displayPopupMenu: boolean;
  showPopupMenu: (event: any) => void;
  items: React.ReactNode;
}

export * from './MenuItem';



export class BlockTypePopupMenu extends React.Component<IPopupMenuProps> {
 
  render() {
    const { displayPopupMenu, position, showPopupMenu } = this.props;

    return (
      <div className="popupMenu">
        {displayPopupMenu &&
          <div className={`popupMenu-bubble popupMenu-${position}`}>
            <div className="popupMenu-items">{this.props.items}</div>
          </div>
        }

        <div className={cn("popupMenu-trigger", displayPopupMenu ? "popupMenu-trigger-selected" : "")} onMouseDown={showPopupMenu}>
          {this.props.children}
        </div>

      </div>
    )
  }
}
