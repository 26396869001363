import BaseBlock from "../blocks/base";
import FixedBlock from "../blocks/fixed";
import {IBlock, IContent} from "../../models";

export function createDefaultFixedBlock(templateId?:string, parentId?: string): BaseBlock[]{

    if(!templateId) return [];
    
    const template = BaseBlock.getBlock(templateId);

    if(!template) return [];

    const newFixed = new FixedBlock(template.title, template.id, []);
    newFixed.parentId = parentId;   
    BaseBlock.addBlock(newFixed);

    return [newFixed];
}

export function createFixedBlock(id:string, title: string, content?: IContent, parentId?: string, templateId?:string): IBlock {
    const newFixed = new FixedBlock(title, templateId, [], id, parentId);
    newFixed.content = content;
    return newFixed;
}