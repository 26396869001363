import {IPoint, ElementBlockType, IBlock, IContent} from "../../models";
import BaseBlock from "../blocks/base";
import ElementBlock from "../blocks/element";

export function createDefaultElementBlock(type:ElementBlockType, position?:IPoint, parentId?: string, title:string="Some element"){
    const newElement = new ElementBlock(title, type, undefined, position, parentId);
    BaseBlock.addBlock(newElement);
    return newElement as IBlock;
}

export function createElementBlock(title:string, id:string, content?: IContent, elementType?: ElementBlockType, point?:IPoint, parentId?:string, children: string[] = [], templateId?:string){
    const emptySubtype: ElementBlockType = ElementBlockType.Text;
    const newElement =  new ElementBlock(title, elementType !== undefined ? elementType: emptySubtype, id, point, parentId, children, templateId);
    newElement.content = content;
    return newElement;
}

export function createElementBlockByTemplate(templateId: string, parentId:string) {
    const b = BaseBlock.getBlock(templateId);
    if (!b) return;

    return new ElementBlock(b.title, b.subType ? b.subType : ElementBlockType.Text, undefined, undefined, parentId, undefined, b.id);
}