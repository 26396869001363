import * as resetPassword from '../userSecurity/resetPassword';
import * as forgetPassword from '../userSecurity/forgetPassword';

export const ACTIONS = {
    resetPassword: resetPassword.ACTIONS,
    forgetPassword: forgetPassword.ACTIONS
};

export const actions = {
    resetPassword: resetPassword.actions,
    forgetPassword: forgetPassword.actions
};
