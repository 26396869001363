import { IState } from "../reducers";
import { IBlock, BlockType } from "../../models";
import { createSelector } from 'reselect';

export function getById(state: IState, id: string) {
    const blocks = (state.blocks as any).present as IBlock[];
    return blocks.find(b => b.id === id);
}

export const getAllPresentBlocks = (state:IState) => (state.blocks as any).present as IBlock[];

export const getBlocks = (filters: BlockType[]) => {

    return createSelector(
        getAllPresentBlocks,
        (blocks) => {
            if(filters.length > 0)
                return blocks.filter(v=>filters.indexOf(v.type)>=0);        
            return blocks;
        });
}