import React from "react";
import './style.scss'
import { Action } from "redux";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import ProfileButton from "../ProfileButton";
import { IUserRead } from "../../../api/user";
import { actions } from "../../../redux/actions";
import { IState } from "../../../redux/reducers";
import HeaderButton from "../Button";
import { Logo } from "../../../icons";
import * as H from "history";
import {RouteComponentProps, withRouter} from "react-router";

interface IProps extends RouteComponentProps<any>{
    className?: any
}

interface IDispatchProps {
    showSignUpForm: () => Action
    showSignInForm: (isAfterConfirmEmail: boolean) => Action
}

interface IStateToProps {
    user: IUserRead | null
}

interface IRouterProps {
    history: H.History;
}

const mapDispatchToProps: IDispatchProps = {
    showSignUpForm: actions.modalForm.signUp.open,
    showSignInForm: actions.modalForm.signIn.open
};

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        user: state.currentUser.item
    }
};

type IHeaderAuthProfile = WithTranslation & IDispatchProps & IStateToProps & IRouterProps & IProps;

export class HeaderAuthProfile extends React.Component<IHeaderAuthProfile> {
    constructor(props: any) {
        super(props);
        this.handleSignUpClick = this.handleSignUpClick.bind(this);
        this.handleSignInClick = this.handleSignInClick.bind(this);
        this.handlePriceClick = this.handlePriceClick.bind(this);
    }

    handleSignUpClick() {
        this.props.showSignUpForm();
    }


    handleSignInClick() {
        this.props.showSignInForm(false);
    }

    handlePriceClick() {
        if (this.props.history) {
            this.props.history.push(`/prices`)
        }
    }

    render() {
        const {t, className = "header-auth-profile", history} = this.props
        return (<div className={className}>
                    <Logo />
                    <div className="button-group">
                        <HeaderButton title={t('header.home')} className="header-button"  onClick={() => { history.push('/')}}/>
                        {/* <HeaderButton title={t('header.learn')} className="header-button" onClick={() => { history.push('/payment')}}/> */}
                        <HeaderButton title={t('header.price')} className="header-button" onClick={this.handlePriceClick} />
                        <ProfileButton className={"profile-button-square"} />
                    </div>
        </div>)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HeaderAuthProfile)));
