import { IErrorResponse } from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';
import { IInvoice } from '../../../api/invoices';

const prefix = 'LAST_INVOICE';

export const ACTIONS = {
    FETCH: actionsSet(prefix, 'SAVE')
};

export interface IFetchInitAction extends Action {
    userId: string;
}

export interface IFetchDoneAction extends Action {
    item: IInvoice;
}

export const actions = {
    fetch: {
        init: (userId: string) => ({ type: ACTIONS.FETCH.INIT, userId }) as IFetchInitAction,
        done: (item: IInvoice) => ({ type: ACTIONS.FETCH.DONE, item }) as IFetchDoneAction,
        fail: (error: IErrorResponse) => ({ type: ACTIONS.FETCH.FAIL, error }) as IFailAction
    }
}