import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/projects/index';
import {createProject, IProject, IProjectQuery} from "../../../api/projects/index";
import {actions} from "../../actions/index";
import {ISaveInitAction} from "../../actions/projects/create";
import {IErrorResponse} from "../../../api";

export function* sagaHandler(action: Action) {
    try {
        const { item } = action as ISaveInitAction;
        const result: IProject =  yield call(createProject, item);
        yield put(actions.projects.create.done(result));
        
        if(!item.content && !item.id) {
            const query: IProjectQuery | null = null;
            yield put(actions.projects.table.init(query));
        }
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.projects.create.fail(c))
    }
}

export function* createSaga() {
    yield takeLatest(ACTIONS.create.SAVE.INIT, sagaHandler);
}
