import * as viewAction from './view';
import * as shareAction from './share';
import * as closeAction from './close';


export const ACTIONS = {
    view: viewAction.ACTIONS,
    share: shareAction.ACTIONS,
    close: closeAction.ACTIONS,
};

export const actions = {
    view: viewAction.actions,
    share: shareAction.actions,
    close: closeAction.actions,
};
