import React from 'react';

interface IDropdownMenuItemProps {
    title: string;
    onClick: (e: any) => void;
    subtitle?: string;
}

export default class DropdownMenuItem extends React.Component<IDropdownMenuItemProps> {
    render() {
        const { title, subtitle, onClick } = this.props;

        return (<div className="project-profile-menu-item" onMouseDown={onClick}>
            <div className="project-profile-menu-item-title">{title}</div>
            {subtitle && <div className="project-profile-menu-item-subtitle">{subtitle}</div>}
        </div>);
    }
}
