import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS } from '../../actions/projects/index';
import {getProjects, IProject} from "../../../api/projects/index";
import {actions} from "../../actions/index";
import {ISaveInitAction} from "../../actions/projects/table";
import {IErrorResponse, IPageResult} from "../../../api";

export function* sagaHandler(action: Action) {
    try {
        const { params } = action as ISaveInitAction;
        const result: IPageResult<IProject[]> =  yield call(getProjects, params);
        yield put(actions.projects.table.done(result))
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.projects.table.fail(c))
    }
}

export function* tableSaga() {
    yield takeLatest(ACTIONS.table.TABLE.INIT, sagaHandler);
}
