import React from "react";
import './style.scss'
import { LogoBig } from "../../icons";
import HeaderButton from "./Button";
import { Action } from "redux";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import * as H from "history";
import {withRouter} from "react-router";

interface IDispatchProps {
    showSignUpForm: () => Action
    showSignInForm: (isAfterConfirmEmail: boolean) => Action
}
interface IRouterProps {
    history: H.History;
}

const mapDispatchToProps: IDispatchProps = {
    showSignUpForm: actions.actions.modalForm.signUp.open,
    showSignInForm: actions.actions.modalForm.signIn.open
};

export class Header extends React.Component<WithTranslation & IDispatchProps & IRouterProps> {
    constructor(props: any) {
        super(props);
        this.handleSignUpClick = this.handleSignUpClick.bind(this);
        this.handleSignInClick = this.handleSignInClick.bind(this);
        this.handlePriceClick = this.handlePriceClick.bind(this);
    }

    handleSignUpClick() {
        this.props.showSignUpForm();
    }


    handleSignInClick() {
        this.props.showSignInForm(false);
    }

    handlePriceClick() {
        if (this.props.history) {
            this.props.history.push(`/prices`)
        }
    }

    render() {
        const {t, history} = this.props

        return (<div className="header">
            <LogoBig onClick={() => { history.push('/')}}/>
            <div className="button-group">
                {/* <HeaderButton title={t('header.learn')} className="header-button" onClick={() => { history.push('/dashboard')}}/> */}
                <HeaderButton title={t('header.price')} className="header-button" onClick={this.handlePriceClick} />
                <HeaderButton title={t('header.signIn')} className="header-button" onClick={this.handleSignInClick} />
                <HeaderButton title={t('header.signUp')} className="header-button-sign-up" onClick={this.handleSignUpClick} />
            </div>
        </div>)
    }
}

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(Header)) as any);