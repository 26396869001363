
import * as feedback from './feedback';

export const ACTIONS = {
    FEEDBACK: feedback.ACTIONS,
};

export const actions = {
    feedback: feedback.actions,
  
};
