import { call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { ACTIONS, actions, ISaveInitAction } from '../../actions/invoices/invoices';
import { IInvoice, postInvoices, IErrorResponse } from '../../../api';
import {push} from "react-router-redux";

export function* sagaHandler(action: Action) {
    try {
        const { item } = action as ISaveInitAction;

        const result: IInvoice = yield call(postInvoices, item);
        yield put(actions.save.done(result))
        yield put(push(`/dashboard`));
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(actions.save.fail(c))
    }
}

export function* createSaga() {
    yield takeLatest(ACTIONS.SAVE.INIT, sagaHandler);
}
