import { put, takeLatest } from 'redux-saga/effects'
import { ACTIONS } from '../../actions/modalForm/index';
import {push} from "react-router-redux";

export function* closeHandler() {
    yield put(push(`/dashboard`));
}


export function* projectSettingsSaga() {
    yield takeLatest(ACTIONS.projectSettings.MODAL_FORM.DONE, closeHandler);
}
