import { httpGet } from "../common";

export interface IAppSettings {
    terminalId?: string;
    // terminalPass?: string;
    // bookFeeRub?: number;
}

export function getAppSettings() {
    return httpGet(`/api/settings`, { authorize: false });
}
