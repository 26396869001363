import React from 'react';

interface IMenuItemProps {
    icon: React.ReactNode;
    title: string;
    onMouseDown: (event: any) => void;
}

export class MenuItem extends React.Component<IMenuItemProps>{
    render() {
        const { onMouseDown, icon, title } = this.props;

        return (
            <div className="popupMenuItem" onMouseDown={onMouseDown}>
                <div>{icon}</div>
                <div style={{ cursor: "default" }}>{title}</div>
            </div>

        );
    }
}
