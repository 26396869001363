import React from 'react';
import { DetailLevel } from '../../../../models';
import './style.scss';
import DetailLevel1_Horiz from '../../../../icons/levels/LOD_horiz_1.png';
import DetailLevel2_Horiz from '../../../../icons/levels/LOD_horiz_2.png';
import DetailLevel1_Vert from '../../../../icons/levels/LOD_vert_1.png';
import DetailLevel2_Vert from '../../../../icons/levels/LOD_vert_2.png';

interface IDetailLevelSwitcherProps {
    isCollapse: boolean,
    detailLevel: number,
    setDetailLevel:(event:any, level: DetailLevel) => void
}

export default class DetailLevelSwitcher extends React.Component<IDetailLevelSwitcherProps> {

    constructor(props:any){
        super(props);

        this.renderSwither = this.renderSwither.bind(this);
        this.switchLevel = this.switchLevel.bind(this);
    }

    switchLevel(event:any){
        event.stopPropagation();
        if(this.props.detailLevel === 1)
            this.props.setDetailLevel(event, 2);
        else
            this.props.setDetailLevel(event, 1);
    }

    renderSwither(){
        const { isCollapse, detailLevel} = this.props;

        // Вертикальное расположение
        if(isCollapse){
            if(detailLevel === 1)
                return <img src={DetailLevel1_Vert} />;

            return <img src={DetailLevel2_Vert} />;
        }

        // Горизотальное расположение
        if(detailLevel === 1)
            return <img src={DetailLevel1_Horiz} />;

        return <img src={DetailLevel2_Horiz} />;
    }

    render(){

        const { isCollapse } = this.props;
        
        const switcherClass = this.props.isCollapse ? 'detail-level-switcher-vert' : 'detail-level-switcher-horz';

        return <div className={switcherClass}>
                    {!isCollapse && <div className='detail-level-switcher-title'>Detail level</div>}
                    <div onMouseDown={this.switchLevel}>
                        {this.renderSwither()}
                    </div>
                </div>;
    }
}