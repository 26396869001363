import {httpGet, httpPost, httpPut, IBaseRead} from '../common';
import * as queryString from "query-string";

export interface IUserWrite {
    email: string;
    password: string;
    isAgreement: boolean;
    nickName: string;
}

export interface IUserSettings {
    language: string;
}

export interface IUserRead extends IBaseRead {
    id?: string;
    email: string;
    name: string;
    totalTrialTime: number;
    logo: string;
    language: string;
}

export interface IUserEdit {
    email: string;
    name: string;
    logo: string | null;
    language: string;
}


export interface IChangePassword {
    oldPassword: string;
    newPassword: string;
}

export interface IUserQuery {
    email: string | null;
    isEmailConfirm: string | null;
}

export function createUser(user: IUserWrite) {
    return httpPost<IUserWrite>('/api/user', user, { authorize: false });
}

export function updateUser(id: string, user: IUserEdit) {
    return httpPut<IUserRead>(`api/user/${id}`, user, { authorize: true });
}

export function getUserById(userId: string) {
    return httpGet<IUserRead>(`api/user/${userId}`, { authorize: false });
}

export function getUserByToken() {
    return httpGet<IUserRead>('/api/user/token', { authorize: true });
}

export function getUserTrialPeriod() {
    return httpGet<number>(`/api/user/subscription/trial`, { authorize: true });
}


