import * as React from "react";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import ModalForm from "../../Landing/ModalForm/index";
import {Action} from "redux";
import * as actions from "../../../redux/actions/index";
import InputContainer from "../../InputContainer/index";
import './style.scss'
import door from '../../../images/door@331.png'
import cn from "classnames";
import {IState} from "../../../redux/reducers";
import SubmitButton from "../../Buttons/SubmitButton";

interface IDispatchProps{
    closeForgetPasswordForm:() => Action
    forget:(username: string) => Action
}

interface IStateToProps{
    inProgress: boolean;
    error: string | null
}

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        inProgress: state.userSecurity.forgetPassword.inProgress,
        error: state.userSecurity.forgetPassword.error
    }
};

const mapDispatchToProps: IDispatchProps = {
    closeForgetPasswordForm: actions.actions.modalForm.forgetPasswordForm.close,
    forget: actions.actions.userSecurity.forgetPassword.init
};

interface IStateProps {
    email: string;
}

export class ForgerPasswordForm extends React.Component<WithTranslation & IDispatchProps & IStateToProps, IStateProps> {
    constructor(props:any){
        super(props);
        this.state = {email: ""}
        this.handleCloseForm = this.handleCloseForm.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleForget = this.handleForget.bind(this);
    }

    handleCloseForm(){
        this.props.closeForgetPasswordForm();
    }

    handleChangeEmail(e: any){
        this.setState({...this.state, email: e})
    }

    handleForget(){
        const {email} = this.state
        if (email) {
            this.props.forget(email);
        }
    }

    isButtonActive(): boolean{
        const {email} = this.state
        const {inProgress} = this.props
        if (email && email !== ""  && !inProgress){
            return true
        }

        return false
    }

    render(){
        const {t, inProgress, error} = this.props;
        return <ModalForm title={t('forget.title')} closeForm={this.handleCloseForm} classes="forget" keysHandlers={this.handleForget} error={error}>
            <div className="forget">
                <div className="door">
                    <img src={door}/>
                </div>
                <div className={cn("information", inProgress ? "disabled" : "")}>
                    <h1>{t('forget.title')}</h1>
                    <span className="offer">{t('forget.offer')}</span>
                    <div className="forget-input-block">
                        <InputContainer title={t('forget.email')} hidden={false} onChange={this.handleChangeEmail} />
                    </div>

                    <div className="button-block">
                        <div className="button-block-forget">
                            <SubmitButton title={t('forget.ok')} onSubmit={this.handleForget} disabled={!this.isButtonActive()} inProgress={inProgress}/>
                        </div>
                    </div>
                </div>
            </div>

        </ModalForm>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ForgerPasswordForm));
