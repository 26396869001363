import React from 'react';
import cn from 'classnames';
import * as d3 from 'd3';
import './style.scss';
import '../../styles/block-content-link.scss';
import '../../styles/block-content-image.scss';
import '../../styles/block-content-section.scss';
import '../../styles/block-content-composite.scss';
import { simulateMouseDown } from '../../../../WorkCanvas/helpers/simulateMouseDown';

interface IStyledInputOwnState {
    value: string
}

interface IStyledInputProps {
    value: string;
    classNames: string;
    isPresentationMode: boolean;
    updateValue: (value:string)=>void;
    selectBlock: ()=>void;
}

export default class StyledInput extends React.Component<IStyledInputProps, IStyledInputOwnState> {

    private component: any;

    constructor(props: any) {
        super(props);
        this.state = {value:""};
        this.handleChange = this.handleChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
    }

    onMouseDown(event:any){
        this.component.select("input").node().focus();
        event.stopPropagation();
    }

    componentDidMount() {
        this.setState({...this.state, value: this.props.value});
    }

    componentDidUpdate(prevProps: any){
        if (this.props.classNames !== prevProps.classNames)
            simulateMouseDown(false);
            
        if(this.props.value !== prevProps.value){
            this.setState({...this.state, value: this.props.value});
        }
    }

    onFocus(e:any){
        this.props.selectBlock(); // активируем блок
    }

    onBlur(e:any){
        //this.props.selectBlock(false); // де-активируем блок

        this.props.updateValue(this.state.value);
    }    

    handleChange(e: any) {

        let value = e && e.target && e.target.value;

        this.setState({...this.state, value});

        if(this.props.classNames.indexOf('-default')>=0)
            this.props.updateValue(value);
    }

    render(){

        const { isPresentationMode, classNames } = this.props;

        return <div className={'styled-input'} ref={handle => (this.component = d3.select(handle))}>

                <input className={cn('styled-input-text',classNames)} disabled={isPresentationMode} value={this.state.value} 
                    onChange={this.handleChange} onFocus={this.onFocus} onBlur={this.onBlur} 
                    onMouseDown={this.onMouseDown} />
            
               </div>;
    }
}