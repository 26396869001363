import { combineReducers } from 'redux';
import * as table from './table';
import * as view from './view';
import * as create from './create';
import * as confirm from './confirm';
import * as currentUser from './currentUser';
import * as updatePassword from './updatePassword';

export interface IState {
    table: table.IState,
    view: view.IState,
    create: create.IState,
    confirm: confirm.IState,
    edit: confirm.IState,
    currentUser: currentUser.IState,
    updatePassword: updatePassword.IState
}

export const initial: IState = {
    table: table.initial,
    view: view.initial,
    create: create.initial,
    confirm: confirm.initial,
    edit: confirm.initial,
    currentUser: currentUser.initial,
    updatePassword: updatePassword.initial
}

export const reducer = combineReducers({
    table: table.reducer,
    view: view.reducer,
    create: create.reducer,
    edit: confirm.reducer,
    currentUser: currentUser.reducer,
    confirm: confirm.reducer,
    updatePassword: updatePassword.reducer
});
