import React from 'react';
import './landingPage.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IState } from "../../../redux/reducers";
import { connect } from "react-redux";
import VideoCarousel from "../../VideoСarousel";
import Header from "../../Header";
import Footer from "../../Footer";
import {match, withRouter} from "react-router";
import firstScreen from "../../../video/FirstScreen.mp4"
import firstScreen_onOver from "../../../video/FirstScreen_onOver.mp4"
import secondScreen from "../../../video/SecondScreen2.mp4"
import HeaderAuth from "../../Header/HeaderAuth";
import {Action} from "redux";
import * as actions from "../../../redux/actions";
import * as H from "history";
import SignForm from "../../SignForm";

interface IStateProps {
    isAuthorized: boolean
}

interface IDispatchProps{
    openResetPasswordForm:() => Action
}

interface IRouterProps {
    history: H.History;
    location: H.Location;
    match?: match<{ token: string }>;
}

const mapStateToProps = (state: IState): IStateProps => {
    return {
        isAuthorized: state.auth.isAuthorized
    };
};

const mapDispatchToProps: IDispatchProps = {
    openResetPasswordForm: actions.actions.modalForm.resetPasswordForm.open
};

type LandingPageProps = WithTranslation & IStateProps & IDispatchProps & IRouterProps;
class LandingPage extends React.Component<LandingPageProps> {

    constructor(props: LandingPageProps) {
        super(props);
    }

    componentDidMount(){
        if (this.props.location.pathname.indexOf('resetPassword') > 0){
            this.props.openResetPasswordForm();
        }
    }

     renderCarousel(){

        return (
            <VideoCarousel firstVideo={{number: "123", path: firstScreen, isLoop: true, isAdditional: false, className: "", startBlock: 0, endBlock: 0}}
            secondVideo={{number: "124", path: firstScreen_onOver, isLoop: false, isAdditional: true, className: "hideVideo", startBlock: -1, endBlock: -1}}
            thirdVideo={{number: "125", path: secondScreen, isLoop: false, isAdditional: false, className: "video-down", startBlock: 0, endBlock: 10}}/>
        );
    }

    render() {
        return (<div className="landingPage">
            {this.renderHeader()}
            {this.renderSignForm()}
            {this.renderCarousel()}
            {this.renderFooter()}
        </div>);
    }

    renderSignForm(){
        return <SignForm/>
    }

    renderHeader(){
        if (this.props.isAuthorized){
            return (<HeaderAuth />)
        } else {
            return (<Header/>)
        }
    }

    renderFooter(){
        return (<Footer/>)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LandingPage))as any);
