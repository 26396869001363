import * as signIn from './signIn';


export const ACTIONS = {
    signIn: signIn.ACTIONS
};

export const actions = {
    signIn: signIn.actions
};
