import { ACTIONS } from '../../actions/userSecurity/forgetPassword';
import { Action } from 'redux';
import {printErrorResponse} from "../../../api";
import { IFailAction } from '../../../actions';

export interface IState {
    isDone: boolean;
    inProgress: boolean;
    error: string | null;
}

export const initial: IState = {
    isDone: false,
    inProgress: false,
    error: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.FORGET_PASSWORD.INIT:
            return {
                ...state,
                isDone: false,
                inProgress: true,
                error: null
            };

        case ACTIONS.FORGET_PASSWORD.DONE:
        {
            return {
                ...state,
                isDone: true,
                inProgress: false,
                error: null
            };
        }

        case ACTIONS.FORGET_PASSWORD.FAIL:
        {
            const { error } = action as IFailAction;
            return {
                ...state,
                inProgress: false,
                error: printErrorResponse(error)
            };
        }

        case ACTIONS.CLEAR:
        {
            return initial;
        }

        default:
            return state;
    }
}
