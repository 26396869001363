import React from 'react';

const dividerStyle = {
    width: "1px", 
    height: "26px",
    backgroundColor: "#3d3d3d",
    marginLeft: "20px",
    marginTop: "11px"
}


export default class ComboBoxDivider extends React.Component {

    render(){
        return <div style={dividerStyle}></div>
    }
}