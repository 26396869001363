import { IBlock } from "../../models";
import { DEFAULT_BLOCK_WIDTH_1LVL, DEFAULT_BLOCK_WIDTH_2LVL } from "../../consts";

const delta2LVL_1LVL = DEFAULT_BLOCK_WIDTH_2LVL-DEFAULT_BLOCK_WIDTH_1LVL;

export function calcPaddingLeftByNearestBlock(blocks: IBlock[], currentBlock: IBlock) {

    let { currentblockX } = calcCoords(currentBlock);

    let paddingLeft: number = 0;
    const closestBlock = calcClosestLeftBlock(blocks, currentBlock);
    if (closestBlock && closestBlock.coord) {
        paddingLeft = currentblockX - (closestBlock.coord.x + DEFAULT_BLOCK_WIDTH_1LVL);
        if(paddingLeft < 0){ // Означает, что координаты левого блока уже преобразовались ко 2ому уровню 
            // и нужно сместить координаты текущего блока для корректного расчета
            paddingLeft = currentblockX + delta2LVL_1LVL - (closestBlock.coord.x + DEFAULT_BLOCK_WIDTH_1LVL);
        }
    }
    return {paddingLeft, closestBlock};
}

export function calcClosestLeftBlock(blocks: IBlock[], currentBlock: IBlock){
    let { currentblockX, currentblockY, currentblockHeight } = calcCoords(currentBlock);

    const blocksWithoutCurrent = blocks.filter(e => e.id !== currentBlock.id);

    const isLefterThanCurrent = (e: IBlock) => {
        if(e.coord && e.coord.x < currentblockX){
            return true;
        }            
        return false;
    }
    const blocksLefterThanCurrent = blocksWithoutCurrent.filter(isLefterThanCurrent)

    if (blocksLefterThanCurrent.length !== 0) {

        const isOnSameLineLefterThanCurrentBlock = (b: IBlock) => {
            if(!b.coord) return false;
            const bHeight = calcBlockFactHeight(b.id);

            // if(currentblockY <= b.coord.y && b.coord.y <= currentblockY + currentblockHeight) return true;
            // if(currentblockY <= b.coord.y + bHeight && b.coord.y + bHeight <= currentblockY + currentblockHeight) return true;

            if(b.coord.y <= currentblockY && currentblockY <= b.coord.y + bHeight) return true;
            if(b.coord.y <= currentblockY+currentblockHeight && currentblockY+currentblockHeight <= b.coord.y + bHeight) return true;


            return false;
        };

        const blocksOnSameLineLefterCurrentBlock = blocksLefterThanCurrent.filter(isOnSameLineLefterThanCurrentBlock);
        
        if (blocksOnSameLineLefterCurrentBlock.length > 0) {
            let closestElement = blocksOnSameLineLefterCurrentBlock[0];

            let destPrev = 100000000;
            for (const b of blocksOnSameLineLefterCurrentBlock) {
                if (!b.coord || currentblockX < b.coord.x) continue;

                const dest = currentblockX - b.coord.x;
                if (dest < destPrev) {
                    destPrev = dest;
                    closestElement = b;
                }
            }

            return closestElement
        }
    }

}

export function calcPaddingTopByNearestBlock(blocks: IBlock[], currentBlock: IBlock) {

    let { currentblockY } = calcCoords(currentBlock);

    let paddingTop: number = 0;
    const closestBlock = calcClosestTopBlockHeight(blocks, currentBlock);    
    if (closestBlock && closestBlock.block.coord) {
        paddingTop = currentblockY - (closestBlock.block.coord.y + closestBlock.height);
    }

    return {paddingTop, closestBlock}

}

export function calcClosestTopBlockHeight(blocks: IBlock[], currentBlock: IBlock){
    const closestBlock = calcClosestTopBlock(blocks, currentBlock);
    if(!closestBlock) return undefined;

    return {block: closestBlock, height: calcBlockFactHeight(closestBlock.id)};
}


function calcClosestTopBlock(blocks: IBlock[], currentBlock: IBlock) {
    let { currentblockX, currentblockY, currentblockWidth } = calcCoords(currentBlock);

    const blocksWithoutCurrent = blocks.filter(e => e.id !== currentBlock.id);
    
    const isUpperThanCurrent = (e: IBlock) => {
        if(e.coord && e.coord.y < currentblockY){
            return true;
        }            
        return false;
    }

    const blocksUpperThanCurrent = blocksWithoutCurrent.filter(isUpperThanCurrent)
    if (blocksUpperThanCurrent.length !== 0) {

        const isAboveCurrentBlock = (e: IBlock) => e.coord && (
            (e.coord.x <= currentblockX && currentblockX <= e.coord.x + DEFAULT_BLOCK_WIDTH_1LVL)
            || (e.coord.x <= currentblockX + currentblockWidth && currentblockX + currentblockWidth <= e.coord.x + DEFAULT_BLOCK_WIDTH_1LVL)
        );
        const blocksAboveCurrentBlock = blocksUpperThanCurrent.filter(isAboveCurrentBlock);

        if (blocksAboveCurrentBlock.length > 0) {
            let closestElement = blocksAboveCurrentBlock[0];
            let destPrev = 100000000;
            for(const b of blocksAboveCurrentBlock){
                if(!b.coord || currentblockY < b.coord.y) continue;

                const dest = currentblockY - b.coord.y;
                if(dest < destPrev){
                    destPrev = dest;
                    closestElement = b;
                }
            }

            return closestElement;
        }
    }
}

function calcCoords(b: IBlock) {
    let currentblockX = 0;
    if (b.coord) {
        currentblockX = b.coord.x;
    }
    let currentblockY = 0;
    if (b.coord) {
        currentblockY = b.coord.y;
    }

    let currentblockHeight = calcBlockFactHeight(b.id);
    let currentblockWidth = DEFAULT_BLOCK_WIDTH_1LVL;

    return { currentblockX, currentblockY, currentblockHeight, currentblockWidth }
}

export function calcBlockFactHeight(id:string){
    const domEl = document.getElementById(id);

    if(domEl==null) return 0;

    const domElRect = domEl.getBoundingClientRect() as DOMRect;

    return domElRect.height;
}