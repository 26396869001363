import React from 'react';
import CollapsedTextArea from "./items/CollapsedTextArea";
import StyledInput from "./items/StyledInput";
import { IBlock, ElementBlockType, BlockType, IContentItem } from "../../../models";
import BaseBlock from '../../../core/blocks/base';
import getContentClassName from './helpers/getContentClassName';
import StyledList from './items/StyledList';
import { getDefaultContentItems } from '../../../core/blocks';
import Gallery from './items/Gallery';

export function blockContentItem(block: IBlock, isPresentationMode: boolean, selectBlock: () => void) {
    
    if(!block.content) return <></>;
    
    const className = getContentClassName(block);

    if(block.type === BlockType.Section || block.type === BlockType.Composite)
        return <StyledInput value={block.title} isPresentationMode={isPresentationMode} 
                        classNames={className}
                        selectBlock={selectBlock} 
                        updateValue={(v:string) => {
                            BaseBlock.updateTitle(block, v);
                            BaseBlock.updateContentItems(block.id, [[{isDefault:false, value:v}]]);}}/>
       
    if (block.subType === ElementBlockType.Text)
        return <CollapsedTextArea value={block.content.items[0][2].value} isPresentationMode={isPresentationMode} 
                displayedRows={block.content.lines}
                classNames={className}
                selectBlock={selectBlock} 
                updateValue={(v:string) => { 
                    const items = getDefaultContentItems();
                    items[2] = {isDefault:false, value:v};
                    BaseBlock.updateContentItems(block.id, [items])}} />

    if (block.subType === ElementBlockType.List && block.content)
        return <StyledList blockId={block.id} content={block.content} isPresentationMode={isPresentationMode} 
                classNames={className}
                selectBlock={selectBlock}
                updateValue={(v:IContentItem[][]) => BaseBlock.updateContentItems(block.id, v)} />
   
    if (block.subType === ElementBlockType.Link)
        return <StyledInput value={block.content.items[0][3].value} isPresentationMode={isPresentationMode}
                classNames={className}
                selectBlock={selectBlock}
                updateValue={(v:string) => { 
                    const items = getDefaultContentItems();
                    items[3] = {isDefault:false, value:v};
                    BaseBlock.updateContentItems(block.id, [items])}} />

    if (block.subType === ElementBlockType.Image)
        return <StyledInput value={block.content.items[0][0].value} isPresentationMode={isPresentationMode}
            classNames={className}
            selectBlock={selectBlock}
            updateValue={(v: string) => {
                const items = getDefaultContentItems();
                items[0] = { isDefault: false, value: v };
                BaseBlock.updateContentItems(block.id, [items])
            }} />

    if (block.subType === ElementBlockType.Gallery && block.content)
        return <Gallery blockId={block.id} content={block.content} isPresentationMode={isPresentationMode}
            classNames={className}
            selectBlock={selectBlock}
            updateValue={(v: IContentItem[][]) => BaseBlock.updateContentItems(block.id, v)} />

    return <div>{block.content.items[0][0].value}</div>
}