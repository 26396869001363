import {IUserWrite, IErrorResponse, IUserRead, IUserSettings, IUserEdit} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';

const prefix = 'USERS_UPDATE';

export const ACTIONS = {
    SAVE: actionsSet(prefix, 'SAVE'),
};

export interface ISaveInitAction extends Action {
    id: string;
    item: IUserEdit;
}

export interface ISaveDoneAction extends Action {
    item: IUserRead;
}

export const actions = {
    save: {
        init: (id: string, item: IUserEdit) => ({ type: ACTIONS.SAVE.INIT, id, item }) as ISaveInitAction,
        done: (item: IUserRead) => ({ type: ACTIONS.SAVE.DONE, item }) as ISaveDoneAction,
        fail: (error: IErrorResponse) => ({ type: ACTIONS.SAVE.FAIL, error }) as IFailAction,
    },
}
