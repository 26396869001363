import { Action } from 'redux';
import { printErrorResponse } from '../../../api/index';
import { IInvoice } from '../../../api/invoices';
import { ACTIONS, ISaveInitAction, ISaveDoneAction } from '../../actions/invoices/invoices';
import { IFailAction } from '../../../actions';

export interface IState {
    inProgress: boolean;
    error: string | null;
    item: IInvoice | null;
}

export const initial: IState = {
    inProgress: false,
    error: null,
    item: null
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case ACTIONS.SAVE.INIT: {
            const { item } = action as ISaveInitAction;

            return {
                ...state,
                item,
                inProgress: true,
                error: null
            };
        }
        case ACTIONS.SAVE.DONE:
            const { item } = action as ISaveDoneAction;
            return {
                ...state,
                item,
                inProgress: false,
                error: null
            };

        case ACTIONS.SAVE.FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                inProgress: false,
                error: printErrorResponse(error)
            };

        default:
            return state;
    }
}
