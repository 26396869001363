import {IErrorResponse} from '../../../api/index';
import { Action } from 'redux';
import { IFailAction, actionsSet } from '../../../actions/index';
import {IProjectSettings} from "../../../api/projectSettings";

const prefix = 'PROJECT_SETTINGS';

export const ACTIONS = {
    CREATE: actionsSet(prefix, 'CREATE'),
};

export interface ISaveInitAction extends Action {
    item: IProjectSettings;
}

export interface ISaveDoneAction extends Action {
    item: IProjectSettings;
}

export const actions = {
    init: (item: IProjectSettings) => ({type: ACTIONS.CREATE.INIT, item}) as ISaveInitAction,
    done: (item: IProjectSettings) => ({type: ACTIONS.CREATE.DONE, item}) as ISaveDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.CREATE.FAIL, error}) as IFailAction
}
