import * as facebook from './facebook';
import * as google from './google';

export const ACTIONS = {
    facebook: facebook.ACTIONS,
    google: google.ACTIONS
};

export const actions = {
    facebook: facebook.actions,
    google: google.actions

};
