import { IBlock } from "../../models";
import BaseBlock from "../blocks/base";

export default function removeBlocks(blocks:IBlock[]){
    let blocksWithChildren: IBlock[] = [];

    for(const b of blocks){
        blocksWithChildren = [...blocksWithChildren, ...prepareToRemove(b)];
    }

    BaseBlock.removeBlocks(blocksWithChildren.map(b=>b.id));
    blocksWithChildren.forEach(b=>clearParent(b));
}

/*
 * Определяем блоки: так как у них могут быть дочерние, которые требуется удалить и подготавливаем состояние удаляемых блоков
 */
function prepareToRemove(block:IBlock){
    let blocks:IBlock[] = [block];
     // Перед удалением сбрасываем все стилистические свойства
     BaseBlock.updateBlock({...block, isSelected:false, isHover:false, isTargetBlock:false}, true);     
     for(const id of block.children){            
         const b = BaseBlock.getBlock(id);            
         if(b){
            blocks = [...blocks, ...prepareToRemove(b)];
         } 
     } 
     return blocks;
}

/*
 * Подчищаем родителя
 */
function clearParent(block: IBlock) {
    if (block.parentId) {
        const parent = BaseBlock.getBlock(block.parentId);
        if (parent) {
            BaseBlock.removeChild(parent, block.id);
            BaseBlock.updateBlock({ ...parent, isSelected: false, isHover: false, isTargetBlock: false }, true);
        }
    }
}