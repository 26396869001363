import React from 'react';
import { IPoint, IBlock } from '../../models';
import "./styles/block-placeholder.scss";
import BlockPlaceholderItem from './BlockPlaceholderItem';
import { MIN_STEP } from '../../consts';
import { blockHeight } from '../../core/blocks';
import canvasMovementTool from '../../core/common/canvasMovementTool';

interface IBlockPlaceholder {
    position: IPoint;
    selection: string[];
}

export default class BlockPlaceholderPresenter extends React.Component<IBlockPlaceholder> {
    
    renderBlocks(selection: string[]){
        const comps = [];
        let x = 0;
        for(const id of selection){
            const height = blockHeight(id, true, false);
            comps.push(<BlockPlaceholderItem key={id} top={x} height={height}/>)
            x += MIN_STEP*2;
        }

        return comps;
    }
    
    render(){

        const {position, selection} = this.props;
        const canvasPosition = canvasMovementTool.getCanvasPosition();
        
        return (<div style={{left:position.x + canvasPosition.x, top:position.y + canvasPosition.y}} className="block-placeholder">
            {this.renderBlocks(selection)}
        </div>)
    }
}