import { httpPost } from '../common';

export interface IAuthResult {
    accessToken: string;
    authenticationScheme: string;
    refreshToken: string;
    expiresIn: number;
}

export function authorize(username: string, password: string) {
    return httpPost<IAuthResult>('/api/user/login', { username, password }, { authorize: false });
}