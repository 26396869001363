import { BlockType, IPoint, ContentStyle, ContentType, IContent, IContentItem } from "../../models";
import BaseBlock from "./base";

function defaultContent(): IContent {
    return {
        style: ContentStyle.Small,
        type: ContentType.Normal,
        items: [[{isDefault: true, value:'Some section'}]]
    }
}

export default class SectionBlock extends BaseBlock {    
    constructor(title:string, id?:string, coord?:IPoint, parentId?:string, children: string[] = []){        
        super(title,BlockType.Section, children, id, undefined, coord, parentId, undefined, defaultContent());
    }
}