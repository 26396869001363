import React from "react";
import './style.scss'
import { withTranslation, WithTranslation } from 'react-i18next';
import { IUserSettings } from "../../../api/user";
import {Action} from "redux";
import * as actions from "../../../redux/actions";
import {connect} from "react-redux";
import {IState} from "../../../redux/reducers";
import i18next from "i18next";
import {EngLangIcon, RusLangIcon} from "../../../icons";

interface IDispatchProps {
    updateUserSettings:(userSetting: IUserSettings) => Action;
    fetchTariffs: (cultureInfo: string) => Action;
}

interface ILanguageState {
    userSettings: IUserSettings | null
}

interface IStateToProps {
    userSettings: IUserSettings | null
}

const mapDispatchToProps: IDispatchProps = {
    updateUserSettings: actions.actions.userSettings.view.done,
    fetchTariffs: actions.actions.tariffs.view.fetch.init
};

const mapStateToProps = (state: IState): IStateToProps => {
    return {
        userSettings: state.userSettings.view.item
    }
};

class Language extends React.Component<WithTranslation & IDispatchProps & IStateToProps, ILanguageState> {
    constructor(props: any) {
        super(props);
        this.state = { userSettings: null }
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<WithTranslation & IDispatchProps & IStateToProps>) {
        if (this.props.userSettings && (prevProps.userSettings == null || this.props.userSettings.language !== prevProps.userSettings.language) ) {
            this.setState({...this.state, userSettings: this.props.userSettings})
            if (this.props.userSettings.language == "RUSSIAN") {
                i18next.changeLanguage('ru')
            } else {
                i18next.changeLanguage('en')
            }

            this.props.fetchTariffs(i18next.languages[0]);
        }
    }

    changeLanguage(){
        if (this.state.userSettings && this.state.userSettings.language == "RUSSIAN"){
            this.setState(prevState => ({
                userSettings: {
                    ...prevState.userSettings,
                    language: "ENGLISH"
                }
            }), () => {
                this.updateUserSettings();
                i18next.changeLanguage('en')})
        } else {
            this.setState(prevState => ({
                userSettings: {
                    ...prevState.userSettings,
                    language: "RUSSIAN"
                }
            }), () => {this.updateUserSettings(); i18next.changeLanguage('ru')})
        }
    }

    updateUserSettings(){
        if (this.state.userSettings) {
            this.props.updateUserSettings(this.state.userSettings);
        }
    }

    handleClick(){
        this.changeLanguage();
    }

    renderEnglish(){
        const { t } = this.props;
        return <div className="language-block">
            <div className="language">
                {t('language.english')}
            </div>
            <div className="flag">
                <EngLangIcon/>
            </div>
        </div>
    }

    renderRussian(){
        const { t } = this.props;
        return <div className="language-block">
            <div className="language">
                {t('language.russian')}
            </div>
            <div className="flag">
                <RusLangIcon/>
            </div>
        </div>
    }

    renderLanguage(){
        const {t} = this.props
        if (this.props.userSettings == null){
            if (i18next.languages[0] == 'en') {
                return this.renderRussian();
            } else {
                return this.renderEnglish();
            }
        }

        if (this.props.userSettings.language == "RUSSIAN"){
            return this.renderEnglish();
        } else {
            return this.renderRussian();
        }
    }

    render() {
        return (<div className="language-container" onClick={this.handleClick}>
            {this.renderLanguage()}
        </div>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Language));