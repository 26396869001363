import { blockHeight } from "../../../core/blocks";
import { IBlock, BlockType } from "../../../models";

export function computeStyle(style: any, block: IBlock) {
    const height = blockHeight(block.id)
    style = { height: { height: height } };
    if (height == 60) {
        style = { ...style, padding: { paddingTop: 15 } }
    }
    else if (height == 45) {
        if (block.type == BlockType.Section)
            style = { ...style, padding: { paddingTop: 15 } }
        else if (block.type == BlockType.TemplateComposite)
            style = { ...style, padding: { paddingTop: 12 } }
        else if (block.type == BlockType.Element || block.type == BlockType.Composite)
            style = { ...style, padding: { paddingBottom: 15 } }
    }

    return style;
}