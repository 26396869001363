import { Action } from 'redux';
import {IAuthResult} from "../../../../api/auth";
import {actionsSet, IFailAction} from "../../../../actions";
import {IErrorResponse} from "../../../../api";

export const ACTIONS = {
    SIGN_IN_FACEBOOK: actionsSet("SIGN_IN_FACEBOOK", ""),
    CLEAR_FACEBOOK: "CLEAR_FACEBOOK"
};

export interface ISignInFacebookInitAction extends Action {
    token: string
}

export interface ISignInFacebookDoneAction extends Action {
    result: IAuthResult;
}

export const actions = {
    init: (token: string) => ({type: ACTIONS.SIGN_IN_FACEBOOK.INIT, token}) as ISignInFacebookInitAction,
    done: (result: IAuthResult) => ({type: ACTIONS.SIGN_IN_FACEBOOK.DONE, result}) as ISignInFacebookDoneAction,
    fail: (error: IErrorResponse) => ({type: ACTIONS.SIGN_IN_FACEBOOK.FAIL, error}) as IFailAction,
    clear: () => ({type: ACTIONS.CLEAR_FACEBOOK})
}
